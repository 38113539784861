.vcenter-credential {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hypervisor-vcd-credential {
  .col-xs-1 {
    width: auto;
  }
}

#toggle-vm-migration {
  display: inline-block;
  vertical-align: top;
}

#toggle-set-last-backup-details {
  display: inline-block;
  vertical-align: top;
}
