//Styles check-connection-to-network

check-connection-to-network {
  $circularBarLength: 12;

  .connection-check {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    margin-top: 10rem;
    flex-wrap: wrap;
  }

  .display-dots {
    display: contents;
  }

  .check-connection-text {
    text-align: center;
    font-size: 1rem;
    margin-top: 3rem;
  }

  .proxy-text {
    text-decoration: none !important;
    font-weight: bold;
  }

  .permission-box {
    text-align: center
  }

  .config-options {
    text-align: center;
    margin-top: 3rem;
  }

  .helios-connection {
    width: 8rem;
    height: 5rem;
    display: inline-block;
  }

  .cluster-logo {
    width: 14rem;
    height: 3rem;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    display: inline-block;
  }

  .failed-circle {
    margin-top: 2rem;
    height: 9px;
    border-radius: 100%;
    width: 9px;
  }

  .success-circle {
    margin-top: 2rem;
    height: 9px;
    border-radius: 100%;
    width: 9px;
  }

  .c-spinner {
    display: inline;
  }

  .close-button {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    display: inline-block;
    box-sizing: content-box;
    margin-top: 1rem;
    margin-left: 1rem;
    position: absolute;
    text-overflow: clip;
  }

  .circle-g {
    margin-top: 2rem;
    background-color: $colorPrimary;
    height: 10px;
    display: inline-block;
    width: 10px;
    animation-name: bounce_circleG;
      -o-animation-name: bounce_circleG;
      -ms-animation-name: bounce_circleG;
      -webkit-animation-name: bounce_circleG;
      -moz-animation-name: bounce_circleG;
    animation-duration: 2.24s;
      -o-animation-duration: 2.24s;
      -ms-animation-duration: 2.24s;
      -webkit-animation-duration: 2.24s;
      -moz-animation-duration: 2.24s;
    animation-iteration-count: infinite;
      -o-animation-iteration-count: infinite;
      -ms-animation-iteration-count: infinite;
      -webkit-animation-iteration-count: infinite;
      -moz-animation-iteration-count: infinite;
    animation-direction: normal;
      -o-animation-direction: normal;
      -ms-animation-direction: normal;
      -webkit-animation-direction: normal;
      -moz-animation-direction: normal;
    animation-fill-mode: backwards;
      -o-animation-direction: backwards;
      -ms-animation-direction: backwards;
      -webkit-animation-direction: backwards;
      -moz-animation-direction: backwards;
    border-radius: 20px;
      -o-border-radius: 20px;
      -ms-border-radius: 20px;
      -webkit-border-radius: 20px;
      -moz-border-radius: 20px;
  }

  @for $i from 1 through $circularBarLength {
    #circle-g-#{$i} {
      animation-delay: $i * (1s * 0.5);
      -o-animation-delay: $i * (1s * 0.5);
      -ms-animation-delay: $i * (1s * 0.5);
      -webkit-animation-delay: $i * (1s * 0.5);
      -moz-animation-delay: $i * (1s * 0.5);
    }
  }


  @keyframes bounce_circleG {
    0%{}

    100%{
      background-color: $colorNeutralMedium;
    }
  }

  @-o-keyframes bounce_circleG {
    0%{}

    100%{
      background-color: $colorNeutralMedium;
    }
  }

  @-ms-keyframes bounce_circleG {
    0%{}

    100%{
      background-color: $colorNeutralMedium;
    }
  }

  @-webkit-keyframes bounce_circleG {
    0%{}

    100%{
      background-color: $colorNeutralMedium;
    }
  }

  @-moz-keyframes bounce_circleG {
    0%{}

    100%{
      background-color: $colorNeutralMedium;
    }
  }
}

@include helix-themes() using ($theme) {
  check-connection-to-network {
    .proxy-text {
      color: helix-color($theme, status, info);
    }

    .failed-circle {
      background-color: helix-color($theme, status, error);
    }

    .success-circle {
      background-color: helix-color($theme, status, success);
    }

    .close-button {
      background-color: helix-color($theme, status, error);
    }
  }
}
