/*! angularjs-slider - v6.6.1 -
 (c) Rafal Zajac <rzajac@gmail.com>, Valentin Hervieu <valentin@hervieu.me>, Jussi Saarivirta <jusasi@gmail.com>, Angelin Sirbu <angelin.sirbu@gmail.com> -
 https://github.com/angular-slider/angularjs-slider -
 2018-06-30 */

$sliderLineColor: $colorBorderLight;
$sliderTickColor: $sliderLineColor;
$sliderTickLabelColor: $colorBorderDark;
$sliderGrabberColor: $textGrey;

.rzslider {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 4px;
  margin: 35px 0 15px 0;
  vertical-align: middle;
  user-select: none;
}

.rzslider.with-legend {
  margin-bottom: 40px;
}

.rzslider[disabled] {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-pointer {
  cursor: not-allowed;
  background-color: #d8e0f3;
}

.rzslider[disabled] .rz-draggable {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-selection {
  background: #8b91a2;
}

.rzslider[disabled] .rz-tick {
  cursor: not-allowed;
}

.rzslider[disabled] .rz-tick.rz-selected {
  background: #8b91a2;
}

.rzslider span {
  position: absolute;
  display: inline-block;
  white-space: nowrap;
}

.rzslider .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rzslider .rz-bar-wrapper {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 32px;
  padding-top: 16px;
  margin-top: -16px;
  box-sizing: border-box;
}

.rzslider .rz-draggable {
  cursor: move;
}

.rzslider .rz-bar {
  left: 0;
  z-index: 1;
  width: 100%;
  height: 1px;
  background: $sliderLineColor;
}

.rzslider .rz-bar-wrapper.rz-transparent .rz-bar {
  background: transparent;
}

.rzslider .rz-bar-wrapper.rz-left-out-selection .rz-bar {
  background: #df002d;
}

.rzslider .rz-bar-wrapper.rz-right-out-selection .rz-bar {
  background: #03a688;
}

.rzslider .rz-selection {
  z-index: 2;
  background: #0db9f0;
  border-radius: 2px;
}

.rzslider .rz-restricted {
  z-index: 3;
  background: #ff0000;
  border-radius: 2px;
}

.rzslider .rz-pointer {
  $pointerWidth: 0.4167rem;
  $pointerHeight: 1.0938rem;
  top: $pointerHeight * -0.75;
  z-index: 1003;
  height: $pointerHeight;
  width: $pointerWidth;
  cursor: ew-resize;
  color: $sliderGrabberColor;
  background-color: currentColor;

  &:focus,
  &:active {
    outline: none;
  }
}

.rzslider .rz-bubble {
  bottom: 16px;
  padding: 1px 3px;
  color: #55637d;
  cursor: default;
}

.rzslider .rz-bubble.rz-limit {
  color: #55637d;
}

.rzslider .rz-ticks {
  position: absolute;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 0;
  margin: 0;
  list-style: none;
  box-sizing: border-box;
}

.rzslider .rz-ticks-values-under .rz-tick-value {
  top: auto;
  bottom: -32px;
}

.rzslider .rz-tick {
  // 15px total width between tick and margin-left?
  position: absolute;
  top: 0;
  left: 0;
  width: 0.0625rem;
  height: 0.4688rem;
  margin-left: 0.1563rem;
  text-align: center;
  cursor: pointer;
  background: $sliderTickColor;
}

.rzslider .rz-tick.rz-selected {
  background: #0db9f0;
}

.rzslider .rz-tick-value {
  position: absolute;
  top: -30px;
  transform: translate(-50%, 0);
}

.rzslider .rz-tick-legend {
  position: absolute;
  top: 0.625rem;
  max-width: 50px;
  white-space: normal;
  transform: translate(-50%, 0);
  font-size: 0.75rem;
  color: $sliderTickLabelColor;
}

.rzslider.rz-vertical {
  position: relative;
  width: 4px;
  height: 100%;
  padding: 0;
  margin: 0 20px;
  vertical-align: baseline;
}

.rzslider.rz-vertical .rz-base {
  width: 100%;
  height: 100%;
  padding: 0;
}

.rzslider.rz-vertical .rz-bar-wrapper {
  top: auto;
  left: 0;
  width: 32px;
  height: 100%;
  padding: 0 0 0 16px;
  margin: 0 0 0 -16px;
}

.rzslider.rz-vertical .rz-bar {
  bottom: 0;
  left: auto;
  width: 4px;
  height: 100%;
}

.rzslider.rz-vertical .rz-pointer {
  top: auto;
  bottom: 0;
  left: -14px !important;
}

.rzslider.rz-vertical .rz-bubble {
  bottom: 0;
  left: 16px !important;
  margin-left: 3px;
}

.rzslider.rz-vertical .rz-ticks {
  top: 0;
  left: -3px;
  z-index: 1;
  width: 0;
  height: 100%;
}

.rzslider.rz-vertical .rz-tick {
  margin-top: 11px;
  margin-left: auto;
  vertical-align: middle;
}

.rzslider.rz-vertical .rz-tick-value {
  top: auto;
  left: 24px;
  transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-tick-legend {
  top: auto;
  right: 24px;
  max-width: none;
  white-space: nowrap;
  transform: translate(0, -28%);
}

.rzslider.rz-vertical .rz-ticks-values-under .rz-tick-value {
  right: 24px;
  bottom: auto;
  left: auto;
}
