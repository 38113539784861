// DIRECTIVE: cContextMenu
@use "@angular/material/core/theming/palette" as mat-theme-palette;

c-context-menu {
  display: inline-block;
  position: relative;

  &.measuring.measuring {
    overflow: hidden;

    .nav-panel {
      visibility: hidden;
      display: block;
      left: -1000px;
    }
  }
}

.c-context-menu {
  $panelMinWidth: 180px;
  $panelMaxWidth: 325px;
  position: relative;
  text-align: left;

  // explicit line-height override so this won't inherit line-height from parent
  // markup.
  line-height: 1rem;

  .nav-panel [class*="icn-"] {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  .menu-trigger {
    display: inline-block;
    position: relative;
    top: -2px;
    vertical-align: middle;
    cursor: default;

    &:hover {
      // override standard icon opacity
      opacity: 1;
    }
  }
  .c-context-item {
    padding: 0;
    margin: 0.5em 0 0 0;
    text-indent: 0;
    display: block;
    line-height: 1;

    &:first-child {
      margin-top: 0;
    }
    &:nth-child(1n) {
      background-color: transparent;
      border: none;
    }
  }

  // Wrapping panel allows box-shadow and arrow to animate in cleanly and
  // prevents flash of content when moving mouse over nav panel while animation
  // is still in progress.
  .nav-panel-wrapper {
    position: absolute;
    top: 0;
    right: -9px;

    // padding to leave room for box shadow and arrow
    padding: 30px 10px 10px;
    z-index: 700;

    &.left {
      right: auto;
      left: -9px;
    }

    &.reposition-menu {
      top: auto;
      bottom: 0;
      padding: 0.625rem 0.625rem 1.875rem;

      .nav-panel.group-container.flagged::before {
        top: auto;
        bottom: -0.625rem;
        text-shadow: none;
      }
    }

    // This class will get added while calculating the position of menu(top or
    // bottom) and once the calculation is done then class will get removed.
    // At the time of calculation context-menu will not be visible.
    &.move-out {
      position: fixed;
      visibility: hidden;
      z-index: -9999;
    }
  }
  .nav-panel {
    position: relative;
    margin: 0;
    padding: 14px 12px;
    box-shadow: $popoverShadow;
    border-radius: $defaultRadius;

    &.flagged {
      // The flag
      &::before {
        left: auto;
        right: 2px;
      }
      // The shadow
      &::after {
        left: auto;
        right: 5px;
      }
    }
    a,
    span {
      display: block;
      width: auto;
      height: auto;
      padding: 0.3em;
      min-width: $panelMinWidth;
      max-width: $panelMaxWidth;

      + small {
        display: block;
        margin-top: -0.5em;
        padding: 0.6em 0.6em 0.6em 2.6em;
        min-width: $panelMinWidth;
        max-width: $panelMaxWidth;
      }
    }
    .item-icon {
      position: relative;
      padding-left: 1.75em;
    }
    [position~="right"] & {
      right: auto;
      left: 0;
    }
    [position~="top"] & {
      top: auto;
      bottom: calc(100% + 2px);
    }
    [position~="center"] & {
      right: auto;
      left: calc(-200% - 1em);
    }
    [position~="middle"] & {
      bottom: auto;
      top: calc(-100%);
    }
    // This isn't working
    [position="middle right"] & {
      left: 100%;
    }
    [position="middle left"] & {
      right: 100%;
    }
  }
  .swivel {
    transition: transform 0.3s ease-in-out;
  }
  &.open {
    .swivel {
      transform: rotate(-360deg);
    }
  }

  // button implementation specific overrides
  &.button {
    // no flag for the flyout
    .group-container.flagged::before {
      content: none;
    }

    // Ensure the flayout nav wrapper and button don't overlap. This allows for
    // an unobstructed click-to-close on the button.
    .nav-panel-wrapper:not(.reposition-menu) {
      top: $buttonHeight;
    }

    .nav-panel-wrapper {
      // Padding to push the box-shadow away from the button a bit.
      padding-top: 6px;
    }
    .swivel {
      margin-left: 0.35em;
    }
    &.open {
      .swivel {
        transform: rotate(-180deg);
      }
    }
  }

  // Styling for Split button dropdown
  .context-menu-button-group {
    display: flex;
  }

  .split-button-action {
    padding-right: 0.54em;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 1px solid $colorWhite;
  }

  .split-button-caret {
    margin-left: 0;
    padding-left: 0.54em;
    padding-right: 0.8em;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;

    .caret {
      margin-left: 0;
    }
  }

  // Option to style the context menu as a page title
  .title-dropdown {
    @include font($size: $h1DefaultSize, $leading: 1.25, $weight: lighter);
    padding: 0;
    border: none;
    background: transparent;

    .caret.swivel {
      border-width: 0.4688rem;
      margin-bottom: 0.3125rem;
    }
  }
}

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .c-context-menu {
      .nav-panel {
        a {
          color: helix-color($theme, helix-secondary, 600);

          &:hover:not([disabled]) {
            background: map_get(mat-theme-palette.$light-theme-background-palette, hover);
          }
        }
      }
    }
  }
}

@include ibm-aqua-theme() {
  .c-context-menu {
    .nav-panel {
      padding: 0;

      .c-context-item {
        margin: 0;

        a {
          height: 3rem;
          padding: 1rem 1rem;
          text-decoration: none;
        }
      }
    }
  }
}
