.vlan-detail-page {
    .padding-left-0 {
        padding-left: 0;
    }
    .c-form-sleek input[type=number]:not([pu-elastic-input]) {
        width: 100% !important;
    }
    label {
        font: 400 0.875rem / 1rem "noto-sans", Helvetica, Arial, sans-serif !important;
    }
    .gateway-input {
        .error-msg {
            margin-left: 1rem !important;
        }
    }
}