// [c-toggle] Toggle (checkbox) styles
@use "@angular/material/core/style/elevation" as mat-elevation;
@use "@angular/material/core/theming/theming" as mat-theme;
@use "@angular/material/core/theming/palette" as mat-theme-palette;
// Styled to reasonably resemble Angular Material slide-toggle:
// https://material.angular.io/components/slide-toggle/overview

c-toggle {
  display: block;

  // This font should match standard form labels
  font-size: 0.875rem;
  line-height: 1em;
}

c-toggle .c-toggle {
  @include cog-type-level(body-1);

  // Prevent the selector/ruleset label/toggle from being too wide and taking
  // click events from way out to the side of the label
  display: inline-block;
  margin-bottom: 0;
}

// Override default form styling for `.info` so it sits nicely under the label
[class*="c-form"] fieldset c-toggle .info {
  display: block;
  margin: 0.125rem 0 0;
}

label.c-toggle {
  position: relative;

  input[type="checkbox"] {
    // float takes the element out of the flow and the margin removes it
    // from the visible page
    float: left;
    margin-left: -10000px;
    opacity: 0;
    height: 0;
    width: 0;

    & + .c-toggle-wrapper {
      position: relative;
      display: block;
      padding-left: 2.75rem;
      cursor: pointer;

      // the toggle container
      .c-toggle-bar {
        transition: background-color 0.25s ease;
        display: block;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 0.5rem;
        width: 2.25rem;
        height: 0.875rem;
      }

      // the switch wrapper
      .c-toggle-thumb-wrapper {
        transition-property: left;
        transition-duration: 80ms;
        transition-delay: 50ms;
        transition-timing-function: ease;
        display: block;
        position: absolute;
        top: -3px;
        left: 0;
      }

      // the actual switch
      .c-toggle-thumb {
        @include mat-elevation.elevation(1);
        width: 1.25rem;
        height: 1.25rem;
        border-radius: 50%;
      }

      // the switch ripple
      .c-toggle-thumb-ripple {
        position: absolute;
        left: calc(50% - 1.25rem);
        top: calc(50% - 1.25rem);
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        opacity: 0;
        pointer-events: none;
      }
    }

    // checked/on styling
    &:checked + .c-toggle-wrapper {
      .c-toggle-thumb-wrapper {
        left: 16px;
      }
    }

    // focused, hovered stylings
    &:focus + .c-toggle-wrapper,
    &:hover + .c-toggle-wrapper {
      .c-toggle-thumb-ripple {
        opacity: 0.1;
      }
    }

    // disabled styling
    &:disabled + .c-toggle-wrapper {
      cursor: not-allowed;

      .c-toggle-bar {
        opacity: $disabledOpacity;
      }

      .c-toggle-thumb-ripple {
        opacity: 0;
      }
    }

    &:checked:disabled + .c-toggle-wrapper {
      .c-toggle-bar {
        // The value is based on MDC.
        opacity: 0.38;
      }
    }

  }
}

@include helix-themes() using ($theme) {
  $is-dark: map-get($theme, is-dark);

  // NOTE: The following color var is taken directly from Angular Material's
  // _sllide-toggle-theme.scss.
  $thumb-unchecked-hue: if($is-dark, 400, 50);

  label.c-toggle {
    position: relative;

    input[type="checkbox"] {
      & + .c-toggle-wrapper {

        // the toggle container
        .c-toggle-bar {
          background-color: helix-color($theme, foreground, disabled);
        }

        // the actual switch
        .c-toggle-thumb {
          background-color: mat-theme.get-color-from-palette(mat-theme-palette.$grey-palette, $thumb-unchecked-hue);
        }

        // the switch ripple
        .c-toggle-thumb-ripple {
          background-color: helix-color($theme, foreground, base);
        }
      }

      // checked/on styling
      &:checked + .c-toggle-wrapper {
        .c-toggle-bar {
          background-color: rgba(helix-color($theme, primary, 500), 0.54);
        }

        .c-toggle-thumb,
        .c-toggle-thumb-ripple {
          background-color: helix-color($theme, primary, 500);
        }
      }
    }
  }
}
