.c-partition {
  ul.vip-list {
    margin: 15px 0 0;
    li {
      margin: 5px 0;
      line-height: 18px;
      .icn-delete {
        transition-property: opacity;
        transition-duration: 1s;
        transition-timing-function: $easeOutExpo;
        opacity: 0.2;
        vertical-align: text-bottom;
      }
      &:hover .icn-delete {
        opacity: 1;
      }
    }
  }
}

.cluster-vip-range-low-field-container {
  float: left;
}
.cluster-vip-range-high-field-container {
  float: left;
  .range-prefix-label {
    width: 50%;
    height: 32px;
    line-height: 39px;
    float: left;
    text-align: right;
    padding-right: 5px;
    font-style: italic;
  }
  .c-input {
    width: 50%;
  }
  .info {
    float: right;
    margin-top: 5px;
  }
}
.cluster-vip-range-button-container {
  float: left;
}


@include helix-themes() using ($theme) {
  // Override error default styles for VIPS High suffix
  .error .cluster-vip-range-high-field-container {
    .c-input {
      border: 1px solid $formInputInactive;
    }
    .c-input.active,
    .c-input:focus {
      border: 1px solid $formInputActive;
      color: $colorPrimaryDark;
    }
  }
  .error .cluster-vip-range-high-field-container.vip-high-error {
    .c-input {
      border: 1px solid helix-color($theme, warn, 500);
    }
  }
}
