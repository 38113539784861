// cModal & cModalSoft styles

//
// c-modal is the default modal style
//

.c-modal {
  display: block;
  border-radius: 0;

  .modal-dialog {
    border-radius: $defaultRadius;
  }

  // BOOTSTRAP OVERRIDE
  .modal-content,
  .c-modal-content {
    border-radius: 0;
    user-select: auto;
  }
  .c-modal-header {
    h2 {
      @include cog-type-level(headline);
      display: inline;
    }
    span.close {
      display: inline-block;
      width: 20px;
      height: 20px;
      background: red;
    }
  }
  .c-modal-body {
    margin-top: 1rem;
  }
  .c-modal-controls {
    text-align: right;
    padding-top: 10px;
  }
  .c-modal-controls.left-align {
    text-align: left;
  }
  .c-modal-main {
    padding: $cohesity-card-padding;
  }
}

@include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
  .modal-content {
    background-color: map_get($background, dialog);
    color: map-get($foreground, text);
  }

  .c-modal {
    a.close,
    a.close:hover,
    a.close:active {
      color: map-get($foreground, text);
    }
  }
}

@include helix-themes() using ($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);

  @if $is-jazz-theme {
    .modal-content {
      border-radius: helix-color($theme, component, dashcard-border-radius);
    }
  }
}

//
// c-modal-soft is used primarily for EULA & License Key prompts
//
.c-modal-soft {

  h1 {
    margin-bottom: 5px;
  }

  .modal-content {
    background-color: $colorNeutralLight;
  }

  .modal-dialog {
    max-width: 1000px;
    background: $colorLightGrey;
    border-radius: 6px;
    overflow: hidden;
    // margin positioning intentionally results in cMessages
    // completely covering top icon (see invalid key input
    // for license key)
    margin-top: 60px;
  }

  .c-modal-content {
    text-align: center;
    margin: 0;
  }
}

// Added a new width modifier for bootstrap modal. (sm + md)
.modal-dialog.modal-smd {
  width: 28.125rem;
}
