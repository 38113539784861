// Styles for legacy uib-tooltips to look like mat-tooltip.
@use "@angular/material/core/theming/palette" as mat-theme-palette;

.tooltip {
  .tooltip-inner {
    background-color: map_get(mat-theme-palette.$grey-palette, 700);
  }
  .tooltip-arrow {
    display: none;
  }
}