// EULA styles
.eula-page {
  padding-bottom: 4.5rem;

  .icn-lock {
    display: block;
    margin: 5px auto 15px;
  }

  .c-fade-md {
    height: 95vh;
  }

  .eula-wrapper {
    border-radius: $defaultRadius;
    background-color: $colorNeutralLight;
    margin: 0 0 10px 0;
    text-align: left;
    padding: 20px;

    ul,
    ol {
      margin-left: 10px;
      margin-bottom: 10px;
      li {
        padding-left: 5px;
        padding-bottom: 5px;
      }
    }
  }
}
