.c-apps {
  .apps-container {
    width: 100%;
  }

  .app-container {
    position: relative;
    display: inline-block;
    width: 50%;
    // set min-height based on icon size
    min-height: 100px;
    padding-left: 115px;
    margin-bottom: 15px;
    margin-top: 1.5rem;
  }

  .app-container:nth-child(odd) {
    max-width: calc(50% - 15px);
    margin-right: 15px;
  }

  .app-meta-data {

    a,
    span,
    button {
      float: left;
      display: block;
      clear: left;
    }

    .app-name {
      @include font($size: 1rem, $leading: 1rem);
      margin-bottom: 10px;
      margin-top: 5px;
    }

    .app-name.system-app {
      padding-right: 27px;
      position: relative;

      &::after {
        @include iconImage('s-mark.svg');
        display: inline-block;
        height: 22px;
        width: 22px;
        position: absolute;
        top: -4px;
        right: 0;
        content: '';
      }
    }

    .app-desc {
      display: inline-block;
      margin-bottom: 10px;
      max-height: 30px;
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .app-icon {
    @include font($weight: bold, $size: 3.4375rem, $leading: 6.125rem);
    position: absolute;
    left: 0;
    top: 0;
    width: 6.25rem;
    height: 6.25rem;
    background: $colorNeutralLight;
    border: 1px solid $textGreyLightest;
    border-radius: $defaultRadius;
    text-align: center;
  }

  .parent-row {
    overflow: hidden;
    position: relative;
    padding-left: 20px;

    .row-title {
      @include font($size: 1rem, $leading: 1rem);
      padding-top: 3px;
    }
  }

  .parent-row:hover {
    cursor: pointer;
  }

  table.c-table th.status-col {
    width: 200px;
  }

  .faux-right-col {
    display: inline-block;
    width: 200px;
    height: 25px;
    float: right;
    line-height: 25px;
  }

  div.meta-row-container {
    transition: max-height $defaultDuration ease;
    max-height: 0;
    overflow: hidden;

    .meta-row-content {
      padding: 10px 10px 10px 20px;
      margin: 10px 0;
      border-radius: $defaultRadius;
      border: 1px solid $colorNeutralDark;
      background-color: $colorNeutralMedium;

      .c-message-inline {
        line-height: 20px;
      }

      .faux-right-col {
        width: 190px;
      }
    }

    .c-ul-inline {
      margin-top: 10px;
    }
  }

  .expanded {
    div.meta-row-container {
      transition: max-height $defaultDuration ease;
      max-height: 300px;
      display: block;
      clear: both;
    }
  }
}

.c-app-detail ul.c-ul.clean li {
  float: none;
  margin-top: 10px;
}

.c-app-create {
  .file-name {
    @include font($weight: bold, $size: 0.875rem, $leading: 1);
    padding-left: 15px;
  }

  .ace-editor {
    min-height: 350px;
  }
}

.c-app-upload .c-ul li {
  word-wrap: break-word;
}

.c-app-name {
  align-items: center;
}

.c-app-label {
  background: #F41A2E;
  color: white;
  padding: 1px 4px;
  border-radius: 4px;
  font-size: 10px;
  margin-left: 0.5rem;
}
