// uiSelect style customizations

// Applying a class of `.match-meta-info` to uiSelect element will add
// additional line-height to the placeholder and search input so that the height
// should match up with matched item having a <small>meta info</small> line
// included.
// NOTE: This is only implemented for `.c-form-sleek`
$matchMetaInfoLineHeight: 2.1875rem;

// uiSelect hides the search element (when search is disabled) via a series of
// height/min-height/opacity rules
// (https://github.com/angular-ui/ui-select/blob/master/src/common.css#L172).
// Its unclear as to why they use such an approach, as toggling its display
// seems equally effective, and this prevents our customizations from
// overpowering their rules via greater specificity.
.ui-select-search-hidden {
  display: none !important;
}

// UI-SELECT
// Cohesity general overrides to ui-select:bootstrap theme
%main-ui-select-display-style {
  @include cog-type-level(input);

  // Custom caret replacement:
  // This fixes a uiSelect bug in which the dropdown does not open when
  // explicitly clicking on the caret/arrow if search-enabled=false, as
  // discussed here:
  // https://github.com/angular-ui/ui-select/issues/1797
  //
  // To fix this:
  // Hide the original caret...
  & > .caret {
    display: none;
  }

  // And show the new caret.
  &::after {
    // Disables any click event on the caret so it works without changing the
    // z-indexes.
    pointer-events: none;
    z-index: 0;
    content: '';
    position: absolute;
    top: 50%;
    right: 0.625rem;
    transform: translateY(-25%);
    border: 4px solid transparent;
    transition: border-color $defaultDuration ease;
  }

  border: 1px solid transparent;
  transition: border-color $defaultDuration ease;
  border-radius: $defaultRadius;

  height: auto;
  padding: 0 20px 0 12px;
  box-shadow: none;
}

.ui-select-container {
  height: 40px;

  // Setting auto-truncate to `display: block` fixes subtle issues with line
  // height differing which shifts content below the uiSelect around by a pixel
  // or two when opening/closing the uiSelect
  .auto-truncate {
    display: block;
  }

  .text-muted {
    font-weight: inherit;
  }

  &.inline {
    display: inline-block;
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    > * {
      pointer-events: none;
    }

    // Handles spinner+disabled styles
    &.spinning {
      .caret {
        display: none;
      }
    }
  }

  .ui-select-match-text {
    [class*="icn"] {
      position: absolute;
      right: 1rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  input.ui-select-search,
  .ui-select-toggle {
    @extend %main-ui-select-display-style;

    // This enforces the alignment when c-input-spinner (or similar) is added to
    // ui-select-match which gives center alignment.
    text-align: inherit;
  }

  .ui-select-toggle {

    .btn-link {
      top: 0.625rem;
    }

    .glyphicon {
      font-weight: bold;

      &:before {
        content: "\2715";
      }
    }

    // This overrides the ui-select styles to properly align the 'clear' button
    // as displayed in the context of our global styles.
    & > a.btn {
      margin-top: -3px;
      right: 9px;
    }
  }

  small {
    display: block;
    margin-top: 0.125rem;
  }

  .ui-select-choices {
    border-radius: 0;
    border: 1px solid transparent;
    box-shadow: $defaultBoxShadow;
    max-height: $uiSelectDropdownMaxHeight;
    padding: 0.5rem 0;

    &-full-width {
      width: auto;
      /* when using full-width, inherited margin shifts container
        and container becomes misaligned */
      margin: 0;
    }

    .wrap-overflow-text {
      overflow-wrap: break-word;
      white-space: normal;
    }

    // For each group-by label element in ui-select
    .ui-select-choices-group-label {
      @include font($weight: bold);
      margin-left: 0;
      padding-left: 1rem;

      &.empty {
        margin-top: -0.625rem;
        padding: 0;
      }
    }

    .ui-select-choices-row {
      position: relative;

      .ui-select-choices-row-inner {
        padding: 0.5rem 1rem;
        background: transparent;
        color: inherit;

        [class*="icn"] {
          margin-top: 0;
          position: absolute;
          right: 1rem;
          top: 50%;
          transform: translateY(-50%);

          // TODO(veetesh): need to re-think on how to have have icn
          // inside ui-select
          & + * {
            margin-right: 3.75rem;
          }
        }

        label {
          margin-bottom: 5px;
        }
      }

      &.selected {
        &::after {
          background: $formInputActive;
          border-radius: 4px;
          bottom: 7px;
          content: '';
          display: block;
          height: 4px;
          position: absolute;
          left: 0.375rem;
          top: 1.125rem;
          transform: translateY(-50%);
          width: 4px;
        }
        .ui-select-choices-row-inner {
          background: transparent;
          color: inherit;
        }
      }

      &.active,
      &:focus,
      &:hover {
        .ui-select-choices-row-inner {
          background: transparent;
          color: inherit;
        }
      }
      &.disabled {
        color: $textGreyLightest;
        cursor: not-allowed;

        > a {
          color: currentColor;
        }
        &.active,
        &:hover,
        &:focus {
          background-color: transparent;
        }
      }
    }

    .ui-select-choices-row {
      .ui-select-choices-row-inner {
        .ui-select-add-new-trigger {
          // Padding to allow row for the (+) icon plus breathing room of 8px.
          padding-left: $iconSizeXsEnclosed + 0.5rem;
          position: relative;

          // Special alignment for the add-new (+) icon
          [class*="icn"] {
            position: absolute;
            top: 0.0625rem;
            left: 0;

            // reset inherited styles
            right: auto;
            transform: none;
          }
        }
      }
    }

    // Styles when adding checkboxes to ui-select
    &.c-ui-select-checkboxes {
      .ui-select-choices-row-inner {
        input {
          position: absolute;
          left: 1rem;
          top: 0.5rem;
          margin-top: 4px;
        }
        input + div {
          padding-left: 1.5rem;
          display: inline-block;
          line-height: 20px;
        }
      }
    }

    // Only applies if there are other items in the list.
    &.has-add-new:not(.add-new-only),
    &.has-filter-actions:not(.has-filter-actions-only) {
      // Pin the add-new item to the bottom of the options dropdown. If
      // unsupported, this item will behave like any other item at the end of
      // the list.
      @supports (position: sticky) {
        // Remove the gap below the unstuck sticky item at the end of the list
        // when scrolled to the bottom.
        padding-bottom: 0;

        .add-new-row,
        .filter-actions {
          position: sticky;
          right: 0;
          bottom: 0;
          left: 0;
          background-color: inherit;
          border-top: 1px solid $colorBorder;
        }
      }
    }
  }

  .ui-select-choices-group {
    margin: 0;
    position: relative;

    .caret {
      color: $formInputText;
      top: 1.25rem;
      position: absolute;
      right: 0;
      transition: transform 0.3s ease-in-out;
    }

    &.open {
      .caret {
        transform: rotate(-180deg);
      }
    }

    &:first-of-type .caret {
      top: 0.625rem;
    }
  }

  // If you want icons on the left of the menu options, add this class to the
  // root ui-select element. By default, icons will be displayed on the right.
  &.icons-left {
    .ui-select-match-text {
      [class*="icn"] {
        right: initial;
      }
    }
    .ui-select-choices {
      [class*="icn"] {
        right: initial;
        left: 0.8125rem;
      }
    }
  }

  &.open {
    input.ui-select-search {
      background-color: transparent;
      border-color: $formInputActive;
    }

    // Force visibility when the addNew is on, the options list is empty, and
    // the dropdown is open.
    .ui-select-choices {
      &.add-new-only,
      &.has-filter-actions-only {
        // This overrides the style attrib which is added by JS
        opacity: 1 !important;
      }
    }
  }
  &.direction-up {
    .ui-select-choices {
      margin-top: 1px;
    }
  }
  &.ui-select-multiple,
  &[tagging] {
    @extend %main-ui-select-display-style;
    padding: 0 5px;
    input.ui-select-search {
      height: auto;
      margin: 0;
    }
  }

  // No caret for tagging fields.
  &[tagging]::after {
    content: initial;
  }

  .ui-select-highlight {
    @include font($weight: bold);
    text-decoration: underline;
  }
}

// helps with abs positioning of embedded [class*="icn"]
.ui-select-match-text {
  position: relative;
  display: block;
  width: 100%;
  margin-right: 1.25rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ui-select-bootstrap .ui-select-match-text span {
  line-height: 1.5;
  vertical-align: middle;
}

// uiSelect multiple overrides
.ui-select-container.ui-select-multiple {
  $tagHeight: 1.5rem;
  $closeDiameter: $tagHeight;

  width: 100%;
  min-height: 0.25rem;
  border-color: $formInputInactive;
  border-style: solid;
  border-width: 1px;
  background: trasparent;
  border-radius: $defaultRadius;
  transition-property: border-color, color;
  transition-duration: $defaultDuration;
  transition-timing-function: ease;
  @include input-placeholder {
    @include cog-type-level(input);
    @include font($color: $formPlaceholder);
  }
  input.ui-select-search {
    @include cog-type-level(input);
    padding: 0.4375rem 0.875rem 0.4375rem 0.4375rem;
    color: $formInputText;
    margin-bottom: 0;
  }
  &.open {
    border: 1px solid $formInputActive;
    input.ui-select-search {
      padding: 0.4375rem 0.875rem 0.4375rem 0.4375rem;
      color: $formInputTextFocus;
      @include input-placeholder {
        @include cog-type-level(input);
        @include font($color: $formPlaceholder);
      }
    }
  }
  .ui-select-match-item {
    @extend %c-pill;
    border: none;
    line-height: 1.375rem;
    font-size: inherit;

    &:disabled,
    &[disabled="disabled"] {
      // Don't need 35px padding for the close button when disabled
      padding-right: 10px;
    }
    .close.ui-select-match-close {
      @extend %c-pill-close;
      color: inherit;
      line-height: 1.375rem;
      &::after {
        display: none;
      }
    }

    // styling when ui select choices are locked i.e. user can't remove selected
    // item
    &.select-locked {
      padding-right: 1em;

      & > .ui-select-match-close {
        display: none;
      }
    }
  }
}

@include helix-themes() using ($theme) {
  .ui-select-container.ui-select-multiple {
    .ui-select-match-item {
      background-color: helix-color($theme, background, unselected-chip);
      color: helix-color($theme, foreground, text);

      .close.ui-select-match-close {
        color: helix-color($theme, foreground, secondary-text);
      }
    }
  }
}

.ui-select-multiple.ui-select-bootstrap .ui-select-multiple.ui-select-bootstrap {
  @include font($size: 1.5rem, $leading: 1, $color: $colorWhite);
}

// ui-select styling override for form-sleek styling
.c-form-sleek {
  .ui-select-container {
    height: auto;
    line-height: normal;
    padding: 0;

    &:focus {
      outline: 0;
    }

    // selected pills & selected value ui-select container
    .ui-select-match {
      background: transparent;
      border: 0;
      box-shadow: none;
      outline: 0;
      text-decoration: none;

      // Highlight when ui-select is focused
      &.btn-default-focus .ui-select-toggle {
        border-color: $formInputActive;
      }
    }

    // make space for copy icon
    &[c-copy] > div:first-child {
      // NOTE: This value was 1.5rem, but this caused the input element to wrap
      // prematurely and effectively become double height. The value of 0.625rem
      // addresses this, but it may need to be revisited.
      margin-right: 0;
    }

    .ui-select-match-text {
      margin-right: 0.625rem;

      // single select dropdown text
      span {
        display: initial;
        vertical-align: initial;
        line-height: 1em;
      }
    }


    // dropdown container
    .ui-select-choices {
      width: auto;
      min-width: 100%;
      margin-top: 0.5rem;

       &.constrain-width {
         width: 100%;
       }
    }

    .ui-select-no-choice {
      margin-top: 0;
    }

    // dropdown show/side toggler element
    // not visible in multiselect dropdown case.
    .ui-select-toggle {
      background-color: transparent;
      border-left: 0;
      border-radius: 0;
      border-right: 0;
      border-top: 0;
      line-height: normal;
      padding: 0.5rem 0.64em 0.5rem 0;
    }

    // brings input on top of caret so that caret click will open the dropdown.
    input.ui-select-search {
      position: relative;
      z-index: 1;
    }

    &:not(.ui-select-multiple) input.ui-select-search {
      border-top: 0;
      border-right: 0;
      border-left: 0;
    }

    &.open input.ui-select-search {
      background-color: transparent;
    }

    // ui-select-container styling for multiple select mode
    &.ui-select-multiple {
      background: transparent;
      border-radius: 0;
      border-top: 0;
      border-right: 0;
      border-bottom-width: 1px;
      border-left: 0;

      &:not(.ng-invalid[disabled]) {
        &:hover,
        &:focus,
        &:active {
          border-bottom-color: $formInputActive;
        }
      }
    }

    // ui-select search box styling
    // .open class need to over-ride the specificity issue
    // between .ui-select-container.ui-select-multiple.open input.ui-select-search
    &,
    &.open {
      input.ui-select-search {
        border-radius: 0;
        line-height: 1em;
        padding: 0.5rem 0;
        height: auto;
      }
    }

    &.match-meta-info {
      .ui-select-placeholder,
      input.ui-select-search {
        line-height: $matchMetaInfoLineHeight;
      }
    }
  }
}


// ui-select styling override for elastic form styling
.elastic {

  select-days,
  select-external-target,
  select-replication-target,
  .ui-select-container {
    display: inline-block;
  }

  .ui-select-choices {
    width: auto;
  }

  .ui-select-container {
    // dropdown show/side toggler element
    .ui-select-toggle {
      padding: 0 1rem 0 0;
      vertical-align: top;
      border-bottom-color: transparent;

      &:hover,
      &:focus,
      &:active {
        border-bottom-color: $colorPrimary;
      }

      // caret/arrow customizations
      &::after {
        right: 0.125rem;
      }
    }

    .ui-select-match-text {
      padding-right: 0;
    }

    // ui-select search box styling
    &:not(.ui-select-multiple) input.ui-select-search {
      padding: 0;
    }

    // ui-select-container styling for multiple select mode
    &.ui-select-multiple {
      border: 0;

      &:not(.ng-empty) {
        // to adjust c-pills alignment
        margin-top: -0.125rem;
        padding-right: 1.25rem;
      }
    }

    // styling for dropdown with checkbox
    &.c-ui-select-checkbox {

      // TODO(jeff): make this site wide .c-pill styling.
      .ui-select-match-item {
        background-color: $colorPageBackground;
        color: $textGrey;
        border-radius: 0.5em;
        border: 1px solid $colorBorder;
        padding-right: 0.625rem;
        margin: 0 0.25rem 0 0;
      }

      // ui-select search box styling
      input.ui-select-search {
        cursor: pointer;
        left: 0;
        padding: 0;
        position: absolute;
        height: 100%;
      }

      &.ng-empty input.ui-select-search {
        max-width: 70px;
        position: relative !important;
        width: auto !important;
      }

      // hide multiselect close btn.
      // normally we can disabled an multiselect tag
      // which make that tag to be not selected in dropdown.
      .ui-select-match-close.ui-select-match-close {
        display: none;
      }

      .ui-select-match-item {
        padding: 0 0.625rem;
      }
    }
  }
}

.c-ui-select-hide-overflow {

  &.ui-select-container.ui-select-multiple {

    input.ui-select-search {
      display: block;
      position: absolute;
      left: 0;
      min-width: 100%;
    }

    & > div {
      display: flex;
      align-items: center;
      position: relative;
    }

    .ui-select-match {
      overflow: hidden;
      display: flex;
      align-items: center;
      min-height: 34px;
      z-index: 2;
      pointer-events: none;
      margin-right: 1.5rem;

      &:not(.has-count) {
        flex: 1 1 0;
      }

      & > span {
        margin-right: 0.25rem;
        position: relative;
        display: block;
      }

      &.overflow {
        & > span {
          overflow: hidden;
        }
      }

      &.has-count {
        margin-right: 0;

        & + .overflow-count {
          display: block;
        }
      }
    }

    .ui-select-match-item {
      pointer-events: none;
      z-index: 2;
      margin: 0;
      overflow: hidden;
      position: relative;
      display: block;

      .ui-select-match-close {
        pointer-events: all;
      }

      &.overflow-count {
        display: none;
        pointer-events: all;
        margin-right: 1.5rem;
        padding: 0 0.625rem;
        flex: none;
      }
    }
  }
}

// Indentation to render ui-select choices in a faux-tree.
@for $i from 0 through 9 {
  .indent-#{$i} {
    padding-left: 1em * $i
  }
}

/*
 * Default z-index of dropdown is 1000
 * z-index of modal is calculated as (1050 + topModalIndex * 10)
 * Setting the z-index of dropdown inside modal to 1200
    allows dropdown to be on top of modal even if there are 15 consecutive modals
 */
body.c-slide-modal-open > .ui-select-bootstrap.open {
  z-index: 1200;
}

@include helix-themes() using ($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $underline-color: helix-color($theme, component, input);

  .ui-select-container {
    input.ui-select-search,
    .ui-select-toggle,
    &.ui-select-multiple,
    &[tagging] {
      color: helix-color($theme, foreground, text);
      border-bottom-color: $underline-color;

      // caret
      &::after {
        border-top: 4px solid $underline-color;
      }

      &:hover,
      &:focus,
      &:active {
        &::after {
          border-top-color: helix-color($theme, primary, 500);
        }
      }

      fieldset.error .ui-select-container.ng-invalid::after {
        border-top-color: helix-color($theme, warn, 500);
      }

      &.ui-select-multiple {
        border-bottom-color: $underline-color;
      }

      .text-muted {
        color: rgba(helix-color($theme, foreground, text), 0.5);
      }
    }

    input.ui-select-search {
      .ui-select-toggle {
        .glyphicon {
          color: helix-color($theme, foreground, text);
        }
      }
    }

    .ui-select-choices {
      .ui-select-choices-row {
        &.selected {
          &::after {
            background: helix-color($theme, primary, 500);
          }
        }

        &.active,
        &:focus,
        &:hover {
          background-color: helix-color($theme, background, hover);
          &.active,
          &.selected {
            background-color: helix-color($theme, background, hover);
          }
        }
      }
    }
  }


  .c-form-sleek {
    .ui-select-container {
      border-color: $underline-color;

      // ui-select-container styling for multiple select mode
      &.ui-select-multiple {
        &:not(.ng-invalid[disabled]) {
          &:hover,
          &:focus,
          &:active {
            border-bottom-color: rgba($underline-color, if($is-dark-theme, 0.7, 0.42));
          }
        }
      }

      // ui-select search box styling
      &,
      &.open {
        input.ui-select-search {
          color: helix-color($theme, foreground, text);
        }
      }
    }
  }

  input[uib-typeahead] {
    // override uib-typeahead styles that get added inline on the element.
    background-color: transparent !important;
    color: helix-color($theme, foreground, text);
  }

  // uib-typeahead and other uib dropdown styles.
  .dropdown-menu {
    background-color: helix-color($theme, background, dialog);
    .ui-select-choices-row:hover,
    .ui-select-choices-row:focus {
      background-color: helix-color($theme, background, hover);
    }

    > li {
      > a {
        color: helix-color($theme, foreground, text);
      }
    }
  }

  .error .ui-select-container.ui-select-multiple {
    border-color: helix-color($theme, warn, 500);
  }

}
