// Pulse Styles

$cPulseFontSize: 0.75rem;
$cPulseSmallFontSize: 0.875rem;

c-pulse {
  &[size="xxs"] .c-pulse-bar-container {
    height: 0.25rem;
    position: static;
  }
  &[size="xs"] .c-pulse-bar-container {
    height: 0.5rem;
    position: static;
  }

  &[size="sm"] .c-pulse-bar-container {
    height: 1rem;
    position: static;
  }

  // for xxs & xs size the c-pulse percentage is shown above the progress bar
  // instead of showing that inside the progress bar
  &[size="xxs"],
  &[size="xs"] {
    .c-pulse {
      // percentage text font-size plus breathing padding between text and
      // progress bar
      padding-top: $cPulseSmallFontSize + 0.375rem;
    }

    .c-pulse-bar-container .c-pulse-bar-label-text {
      @include font(
        $size: $cPulseSmallFontSize,
        $leading: $cPulseSmallFontSize
      );

      top: 0;
      transform: none;
    }
  }
}

.c-pulse {
  text-align: center;
  min-width: 6.25rem;
}

.c-pulse-bar-container {
  display: block;
  position: relative;
  width: 100%;
  height: 1.5625rem;
  border-radius: 0.4375rem;
  overflow: hidden;
}

.c-pulse-bar {
  width: 0%;
  float: left;
  height: 100%;
  transition: width $defaultDuration ease;
}

.c-pulse-bar-label-text {
  @include font(
    $size: $cPulseFontSize,
    $leading: $cPulseFontSize
  );
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  text-align: center;
  width: 100%;
}

.c-pulse-label {
  @include font($size: $cPulseFontSize, $leading: $cPulseFontSize);
  margin-top: 0.25rem;
  display: block;
}

@include helix-themes() using ($theme) {
  .c-pulse-bar-container {
    background-color: helix-color($theme, foreground, divider);
    color: helix-color($theme, foreground, secondary-text);
  }

  .c-pulse-bar {
    background-color: helix-color($theme, primary, 500);
  }

  .c-pulse-bar-label-text {
    color: helix-contrast($theme, primary, 500);
  }
}
