//Styles: for landing page of cluster claim process
connected-site {
  .license-types {
    margin: 0 auto;
    width: 60%;
  }

  .table {
    td {
      text-align: center;
    }
  }

  .info-text-cluster-select {
    text-align: center;
    margin: 3rem 9rem;
  }

  .highlight {
    cursor: pointer;
  }

  .move-right {
    margin-left: 40%;
  }

  table {
    table-layout: fixed;
    thead {
      tr {
        th {
          text-align: center;
          position: relative;
        }
      }
    }
    tbody {
      tr {
        td:nth-child(1) {
          text-align: left;
        }
      }
    }
  }

  .icn-helios-green {
    width: 8rem;
    height: 5rem;
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
  }

  .cluster-logo {
    width: 14rem;
    height: 3rem;
    margin-bottom: 0.75rem;
    margin-top: 0.75rem;
    display: inline-block;
  }

  .cluster-management-imgs {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 60%;
    margin: 0 20%;
    justify-content: flex-end;

    i {
      flex-basis: 33%;
    }
  }

  .type-image {
    position: absolute;
  }

  .icn-selected {
    height: 1rem;
    left: 50%;
    bottom: 88%;
    transform: translateX(-50%);
  }

  .icn-check-mark {
    height: 1.65rem;
    width: 1.65rem;
  }

  table {
    &.saas {
      thead {
        tr {
          th:nth-child(2) {
            @include font($color: $chartBrand);
            background-color: $colorPrimaryLight;
            border-top: 2px solid $cSelectBackgroundSelected;
            border-left: 2px solid $cSelectBackgroundSelected;
            border-right: 2px solid $cSelectBackgroundSelected;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(2) {
            border-right: 2px solid $cSelectBackgroundSelected;
            border-left: 2px solid $cSelectBackgroundSelected;
          }
          &:last-of-type {
            td:nth-child(2) {
              border-bottom: 2px solid $cSelectBackgroundSelected;
              border-right: 2px solid $cSelectBackgroundSelected;
              border-left: 2px solid $cSelectBackgroundSelected;
            }
          }
        }
      }
    }

    &.on-cluster {
      thead {
        tr {
          th:nth-child(3) {
            @include font($color: $chartBrand);
            background-color: $colorPrimaryLight;
            border-top: 2px solid $cSelectBackgroundSelected;
            border-left: 2px solid $cSelectBackgroundSelected;
            border-right: 2px solid $cSelectBackgroundSelected;
          }
        }
      }
      tbody {
        tr {
          td:nth-child(3) {
            border-right: 2px solid $cSelectBackgroundSelected;
            border-left: 2px solid $cSelectBackgroundSelected;
          }
          &:last-of-type {
            td:nth-child(3) {
              border-bottom: 2px solid $cSelectBackgroundSelected;
              border-right: 2px solid $cSelectBackgroundSelected;
              border-left: 2px solid $cSelectBackgroundSelected;
            }
          }
        }
      }
    }
  }
}
