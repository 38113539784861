// c-inline-date-range styles

c-inline-date-range {
  display: block;

  .c-inline-date-range-toggle {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    // making space for calendar icon.
    input {
      padding-right: 2rem !important;
      max-width: 200px !important;
    }

    .icn-calendar {
      position: absolute;
      right: 0.25rem;
    }
  }

  // highlight the date cell which are between selected start and end date.
  .c-date-picker-in-range button {
    background-color: $datePickerSelectedBackground;
  }
}

.c-form-sleek {
  c-inline-date-range {
    .c-inline-date-range-toggle {
      .c-input {
        padding: 0.5rem 0;
      }

      .icn-calendar {
        right: 0.25rem;
      }
    }
  }
}