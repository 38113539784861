// Styles - Active Directory

.active-directory-details {
  // This overrides conflicts when adding col-* to a fieldset.
  fieldset[class*="col-"] {
    margin-bottom: $gutter-md;
    padding-left: $bootstrap-col-padding;
    padding-right: $bootstrap-col-padding;
  }

  // Hide the border around each Domain Controller in the edit list.
  [class*="c-dl"] .dl-row.controller-row {
    border: none;
  }

  .edit-mapping-details label {
    color: $colorSecondaryDark;
    font-weight: 100;
  }

  .trusted-domains-list {
    width: 90%;
  }

  [class*="c-dl"] dt {
    clear: none;
  }
}
