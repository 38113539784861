// contains css for graph diagrams (topology)
@use "@angular/material/core/theming/palette" as mat-theme-palette;

.topology-graph {
  height: 100%;
  overflow: auto;

  svg {
    g.topology-node {
      text {
        text-anchor: middle;
      }
    }
  }
}

@include helix-themes() using ($theme) {
  $graph-color: helix-color($theme, foreground, text);
  $graph-node-border-color: helix-color($theme, chart, subtle);
  $graph-node-border-color-critical: helix-color($theme, status, error);
  $graph-text-color: helix-color($theme, foreground, hint-text);

  .topology-graph {
    height: 100%;
    overflow: auto;

    svg {
      path.topology-edge {
        fill: none;
        stroke: $graph-color;
        stroke-width: 1;
      }

      g.topology-node {
        text {
          text-anchor: middle;
          fill: $graph-text-color;
        }

        circle {
          // need fill to ensure mouseover can be captured in the entire circle area
          fill: map_get(mat-theme-palette.$light-theme-background-palette, card);
          stroke: $graph-node-border-color;
          stroke-width: 1;
        }

        &.topology-node-critical {
          circle {
            stroke: $graph-node-border-color-critical;
          }
        }
      }
    }
  }
}
