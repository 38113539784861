@import "common-imports";

/*
Override mat-select default styles or label and description gets wide apart
!important as we want to override the styles.
*/
.mat-option {
  &.mat-multiline-option {
    line-height: 1.5rem !important;
    height: auto !important;
  }
}
