// Component: cSourceMeta component styles

.c-source-meta-popover,
.sources-details-popover {
  font-size: 0.7625rem;
  width: 21rem;
  max-width: none;

  .popover-content {
    max-height: 18.75rem;
    overflow: auto;
  }

  h6 {
    font-weight: bold;
    margin-bottom: 0.55em;
  }

  label {
    @include font($weight: normal);
    margin-bottom: 0;
  }
}

.c-source-meta-grouping {
  margin-bottom: 1.75em;

  &:last-child {
    margin-bottom: 0;
  }
}

.c-source-meta-line-item {
  margin-bottom: 0.5em;
  color: $textGreyLight;

  [class*="icn"] {
    vertical-align: middle;
  }
}

.c-source-tree-icn.icn-view {
  cursor: pointer;
}

.registering-hosts-meta {
  margin-top: 10px;

  c-spinner {
    margin: 0 10px;
  }
}

.aag-listing {
  > ul {
    max-height: 100px;
    overflow: auto;
  }
}
