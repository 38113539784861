.status-icon-container {
  position: relative;

  .more-icon-toggle-container:hover {
    cursor: default;
  }

  .more-icon-toggle {
    @include font($size: 11px, $leading: $iconSizeMd);
    display: inline-block;
    height: $iconSizeMd;
    width: $iconSizeMd;
    border-radius: $iconSizeMd * 0.5;
    background-color: $colorNeutralLight;
    border: 1px solid $colorBorder;
    text-align: center;
    overflow: hidden;
    margin-left: $iconSpacingLg;
    cursor: pointer;
  }
}

.copy-status-icon + .copy-status-icon {
  margin-left: $iconSpacingLg;
}
