// Form Styles
@use "@angular/material/core/theming/theming" as mat;
$inputLabelLeftOffset: 1.25rem;

// should inherit font from parent element
button,
input,
select,
textarea {
  font-family: inherit;
  font-weight: inherit;
}

// Remove bootstrap garbage checkbox/radio margin.
input[type="checkbox"],
input[type="radio"],
.mat-checkbox-layout,
.mat-optgroup-label,
.mat-radio-label,
.value-property-list-text label {
  margin: 0;
}

// undo bootstrap label bolding
label {
  @include font($weight: normal);
  h1 & {
    @include font($weight: light);
  }
}

.form-group {
  // override bootstrap applied margin-bottom
  margin-bottom: $formGroupSpacing;

  .form-horizontal & {
    margin-bottom: 0;
  }
}

// Override Bootstrap default because this was higher than the sticky-buttons.
.input-group .form-control {
  z-index: auto;
}

.form-horizontal .control-label {
  @include cog-type-level(input);
  text-align: left;
}

.label-offset {
  margin-top: 1.5rem;
}

// Buttons are not as tall as inputs and need a bit additional offset.
.label-offset-btn {
  margin-top: #{1.5rem + ($formInputHeight - $buttonHeight) * 0.5};
}

// .checkbox-inline, and .radio-inline are bootstrap provided selectors
.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-left: $inputLabelLeftOffset;
}

// if a checkbox-inline or radio-inline (bootstrap selectors) need to contain a
// standard c-input field inline, the input[type="radio/checkbox"] should be
// given a class of c-input-height, and these selectors will position the
// c-input inline with the label text. Example visible on
// protection/jobs/modify/settings.html when creating/editing a Pure job
[class*="-inline"] .c-input {
  display: inline-block;
  margin-right: 0.3125rem;
  margin-left: 0.3125rem;
}

[class*="-inline"] {
  [type="radio"],
  [type="checkbox"] {
    margin-top: 2px;
  }
}

.fieldset-margin {
  margin-bottom: $fieldsetSpacing;
}

.fieldset-margin-top {
  margin-top: $fieldsetSpacing;
}

.form-group-margin {
  margin-bottom: $formGroupSpacing;
}

.form-group-margin-top {
  margin-top: $formGroupSpacing;
}

.c-input {
  @include cog-type-level(input);
  transition-property: border-color, color;
  transition-duration: $defaultDuration;
  transition-timing-function: ease;
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  border: none;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  &:focus,
  &:active {
    outline: none;
  }

  &[type="number"] {
    // comfortable fits values up to 1,000,000.
    // may need to tweak if we see the need to go higher
    width: 6.25rem;

    &.full-width {
      width: 100%;
    }
  }

}

.c-input:disabled {
  @include disabledProperties();
}

// Use this to hide an input[type="text"] when it still needs to be selectable.
// .select() cannot be called on elements which are hidden (i.e.
// visibility: hidden, display: none, width/height: 0). This is useful for
// having a hidden input[type="text"] for the purpose of copying text to
// clipboard (document.execCommand('copy'), which works by calling select()).
.c-input-hidden {
  position: absolute;
  left: -1000px;
  top: -1000px;
}

// TODO: Migrate styles in here that should apply to all forms.
[class*="c-form"] {
  .info {
    @include font($size: 0.625rem);
  }
}

.c-form {
  label[class*="-inline"] {
    display: inline-block;
    margin-bottom: 0;
  }
}

.c-form {
  display: block;
  .c-box-meta {
    label.checkbox-inline {
      margin-bottom: 0;
    }
  }
  fieldset {
    @include pie-clearfix;
    margin: 0 0 $fieldsetSpacing;
    border: none;
    padding: 0;

    &.supplement {
      margin-top: -15px;
    }
    .info {
      clear: left;
      display: inline-block;
      margin-bottom: 10px;
    }
    .c-input-inline-text {
      display: inline-block;
      height: $formInputHeight;
      line-height: $formInputHeight;
    }
    &:disabled {
      @include disabledProperties();
    }
  }

  .radio label,
  .checkbox label,
  label.radio-inline,
  label.checkbox-inline {
    @include cog-type-level(body-1);
  }

  label {
    @include font(
      $size: 0.875rem,
      $leading: 1,
      $color: $formLabels,
      $weight: normal
    );
    display: block;
    text-align: left;
    margin-bottom: 10px;

    input {
      margin-right: 7px;
      margin-left: 4px;
    }
    &::before {
      // This is placeholder so animation works smoothly. It is hidden when
      // !required
      transition: 0.2s linear;
      display: block;
      float: left;
      content: '*';
      opacity: 0;
      width: 0;
    }
    &.checkbox-label,
    &.radio-label {
      display: inline-block;
    }
    .info {
      @include font($weight: normal);
    }
    .info:hover {
      cursor: pointer;
    }
    &.required {
      &::before {
        opacity: 1;
        width: 0.75em;
      }
    }
    &.label-inline {
      display: inline;
    }
    &.submit-option {
      @include font($weight: normal);
      display: inline-block;
      margin-top: 12px;
    }
    &.compressed {
      margin: 0;

      span.info {
        margin: 0;
      }
    }
  }

  span.ui-select-all-rows-checkbox {
    input {
      margin-left: 0;
    }
  }

  .c-textarea {
    @extend .c-input;
    line-height: 18px;
    min-height: 60px;
    padding: 11px 14px;
  }
  .c-checkbox {
    position: relative;
    margin-right: 5px;
  }
  .c-checkbox-label {
    @include font($weight: normal);
  }
  .c-checkbox,
  .c-checkbox-label:hover {
    cursor: pointer;
  }
  .c-checkbox-label-disabled {
    color: $textGreyLightest;
    .c-checkbox:hover {
      cursor: not-allowed;
    }
  }
  .c-checkbox-label-disabled:hover {
    cursor: default;
  }
  .c-checkbox-horz-group {
    label {
      float: left;
      margin-right: 10px;
    }
  }

  // TODO (David) This should be removed throughout because
  // it duplicates browser default styles and adds nothing
  .c-input-container {
    display: block;
    height: auto;
  }
  .c-input-inline {
    input {
      display: inline-block;
    }
    label {
      display: inline-block;
      margin-top: 7px;
    }
    input + label,
    label + input {
      margin-left: 10px;
    }
  }
}

.c-input-height {
  line-height: $formInputHeight;
  height: $formInputHeight;
  > type-icon [class*="icn-"] {
    vertical-align: middle;
  }
  .c-form-sleek & {
    line-height: $sleekFormInputHeight;
    height: $sleekFormInputHeight;
  }

  .c-form-sleek.elastic & {
    line-height: inherit;
    height: auto;
  }
}

.error-msg {
  clear: both;
  padding: 5px 5px 5px 0;
  width: 100%;
  display: block;
}

// the following class is used to vertically align inline text with form elements
.c-form-text-align {
  line-height: $formInputHeight;
}

h3.c-form-text-align {
  margin-bottom: 0;
}

// select and multiselect lists
// adding body helps these rules override
// action-panel related padding styles
// TODO: consider removing body selection as action-panel
// has been removed from codebase
body .c-select-list,
body .c-multiselect-list {
  @include user-select(none);
  width: 100%;
  display: block;

  ul {
    padding-top: 0;
  }
  // BOOTSTRAP OVERRIDES
  button.dropdown-toggle.btn {
    @include font(
      $size: 0.875rem, $leading: 2.375rem, $color: $formInputText, $weight: normal);
    transition: border-color $defaultDuration ease;
    background-color: $colorWhite;
    box-shadow: none;
    padding: 0 20px 0 12px;
    text-align: left;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    border: 1px solid $colorBorder;
    border-radius: $defaultRadius;
    &:disabled,
    &.disabled {
      @include disabledProperties();
    }
    &:hover,
    &:focus {
      border: 1px solid $formInputActive;
      background-color: $colorWhite;
      box-shadow: none;
      outline: none;
    }
  }
  .dropdown-toggle {
    .caret {
      position: absolute;
      right: 10px;
      top: 16px;
    }
  }
  &.open button.dropdown-toggle {
    box-shadow: none;
    border: 1px solid $formInputActive;
  }
  .dropdown-menu {
    left: 8px;
    top: 39px;
    max-height: 410px;
    overflow-y: auto;
    padding: 0;
    margin: 0;
    min-width: 130px;
    border-color: $formInputActive;
    border-radius: 0px;
    box-shadow: $defaultBoxShadow;
    > li:hover,
    > li:focus {
      background-color: $cSelectBackgroundHover;
    }
    > li.c-multiselect-button:hover,
    > li.c-multiselect-button:hover {
      background-color: transparent;
    }
    > li > a:hover,
    > li > a:focus {
      color: $formInputTextFocus;
      background-color: transparent;
    }
    > li.disabled {
      a {
        color: $textGreyLightest;
        cursor: not-allowed;
      }
      &:hover,
      &:focus {
        background-color: transparent;
      }
    }
    > li.manual-input-container {
      border-top: 1px solid $formInputActive;
      padding: 10px 0;
      span.manual-input-label {
        display: inline-block;
        width: 30px;
        line-height: 41px;
        text-align: center;
      }
      input {
        margin-left: 30px;
        width: calc(100% - 40px);
      }
    }
  }
}

.c-multiselect-list .icn-md {
  position: absolute;
  right: 12px;
  top: 4px;
}

.c-multiselect-list .dropdown-toggle .icn-md {
  top: 9px;
  right: 20px;
}

.c-select-list {
  li {
    a {
      padding: 7px 12px;
      word-wrap: break-word;
      white-space: normal;
      @include font($weight: normal, $size: 0.875rem);
      color: $formInputTextFocus;
    }
    a:hover {
      background-color: $formHover;
    }
  }
}

.c-multiselect-list {
  .placeholder {
    color: $formInputText;
  }
  li {
    position: relative;
    padding: 0;
    a {
      // applying position allows the anchor to
      // have a higher index than icon and checkbox
      // without explicitly setting z-indexes
      position: relative;
      padding: 7px 12px;
      word-wrap: break-word;
      white-space: normal;
      @include font($weight: normal, $size: 0.875rem);
      color: $formInputTextFocus;
      &:hover,
      &.active {
          // this is a style from bootstrap
          // that I'm restore to match c-select
          color: #262626;
      }
    }
    &.selected {
      background-color: $cSelectBackgroundSelected;
    }
    &.option-has-icon {
      a {
          padding: 7px 45px 7px 12px;
      }
    }
  }
  &.multi-select li {
    a {
        padding: 7px 30px;
    }
  }
  input[type=checkbox] {
    position: absolute;
    left: 12px;
    top: 11px;
    margin: 0;
    + a {
      // this position relative brings the anchor to the top
      // by allowing it to assume natural z-indexing order.
      // this prevents user from interacting directly with checkbox
      position: relative;
    }
  }
  .c-multiselect-button {
    margin: 0;
    padding: 0;
    .c-button {
      display: block;
      width: 100%;
      text-align: center;
    }
  }
}

textarea:focus,
input:focus {
  outline: 0;
}

.row.form-buttons {
  button {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

// Branded Overrides for Bootstrap Typeahead Styles
.c-typeahead {
  position: relative;
  // fixes Firefox only alignment bug for typeahead inputs
  input {
    clear: left;
  }
  .dropdown-menu {
    margin: 0;
    width: 100%;
    padding: 0;
    border-radius: 0;
    border: none;
    li a {
      white-space: normal;
      word-wrap: break-word;
    }
  }
  .no-results {
    z-index: 1000;
    width: 100%;
    border-top: 0;
    padding: 5px;
    border: none;
    position: absolute;
    left: 0;
  }
}

@include helix-themes() using ($theme) {
  .c-typeahead {
    .no-results {
      background-color: helix-color($theme, background, dialog);
    }
  }
}

// Time-Zone Selector Specifc Overrides for Bootstrap Typeahead Styles

.c-tz-typeahead {
  @extend .c-typeahead;
  .dropdown-menu {
    max-height: 150px;
    overflow-y: auto;
  }
}

// Input:range, our style
input[type="range"].c-input-range {
  position: relative;

  &::before {
    transition: all $defaultDuration ease;
    content: '';
    height: $cRangeSliderTickHeight;
    border-left: 2px solid currentColor;
    border-right: 2px solid currentColor;
    position: absolute;
    left: 3px;
    right: 4px;
    top: $cRangeSliderTickHeight * -1.5;
  }
}

// ng-file-upload
// NOTE: Doubled selector to avoid using !important
[ngf-drop][ngf-drop] {
  border-radius: $defaultRadius;
  border: 2px dashed transparent;
  transition: border-color 0.3s ease-in-out;

  &.dragover {
    border-color: $colorPrimary;
  }
}

// new form stylings
.c-form-sleek {
  // Override TWBS styles
  .btn.disabled,
  .btn[disabled],
  fieldset[disabled] .btn,
  .form-control {
    box-shadow: none;
  }

  label:not(.mat-form-field-label):not(.c-toggle):not(.label-inline) {
    // Angular Material styling acomplishes this font size via css transform. For
    // AngularJS implementation going with explicit font-size since we aren't
    // implementing the animation from placeholder to label.
    @include font($size: 0.75rem);

    &.checkbox,
    &.radio,
    &.checkbox-inline,
    &.radio-inline {
      @include cog-type-level(body-1);
    }
  }

  .checkbox label:not(.mat-form-field-label):not(.c-toggle),
  .radio label:not(.mat-form-field-label):not(.c-toggle) {
    @include cog-type-level(body-1);
  }


  label {
    @include cog-type-level(input);
    margin-bottom: 0;
    vertical-align: top;

    &.required {
      &::after {
        display: inline;
        content: '*';
      }
    }
  }

  fieldset {
    border: none;
    padding: 0;
    margin-bottom: $fieldsetSpacing;
    margin-left: 0;
  }

  // field error message
  .error-msg {
    @include font($size: 0.75rem);
  }

  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="url"],
  input[type="email"] {
    background-color: transparent;
    border: none;
    padding: 0.5rem 0;
    width: 100%;

    // This override removes border-radius for some implementations
    // (cDatePicker) and can likely be removed once old form stylings have been
    // purged.
    border-radius: 0;

    &[pu-elastic-input] {
      @include cog-type-level(input);
      display: inline-block;
      padding: 0;
      vertical-align: top;
    }
  }

  input[type="number"]:not([pu-elastic-input]) {

    // (lucy) Need to add important since it will be overwrite by elastic class
    width: 5rem !important;
    &.full-width {
      width: 100%;
    }
    &.large-number {
      width: 7rem !important;
    }
    &.small-number {
      width: 2.5rem !important;
    }
  }

  .c-textarea {
    display: block;
    width: 100%;
    background: transparent;
    border: none;
  }

  [class*="-inline"] {
    input[type="radio"],
    input[type="checkbox"] {
      margin-top: 0;
    }
  }

  // Hide input[type=number] spinners/arrows for elastic inputs.
  input[pu-elastic-input] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  &.elastic {
    c-bytes {
      display: inline-block;
    }

    fieldset {
      padding: 0;
      margin-bottom: 0;
    }

    label:not(.mat-form-field-label):not(.c-toggle) {
      @include cog-type-level(input);
      display: inline-block;
    }

    input[type="number"],
    input[type="checkbox"] {
      margin: 0 $gutter-xs;
    }

    // Restore and modify bootstrap checkbox and radio styling because above
    // margin rules interfer with them.
    .checkbox,
    .radio {
      margin: 0;
      input[type="checkbox"],
      input[type="radio"] {
        position: absolute;
        margin-left: -20px;
      }
    }

    input[type="number"] {
      padding: 0 4px;
    }

    input {
      height: 25px;
    }

    label {
      margin-top: 3px;
    }
  }

  /* The below code fixes the cRetentionSelector error message in edit run */
  .elastic span + div[ng-messages] {
    margin-top: -10px;
  }
}

// Styles the clear icon for clearable directive
.clearable-wrapper {
  position: relative;

  input {
    padding-right: 1.5625rem;
  }

  // tag for clear icon
  i {
    position: absolute;
    margin: auto;
    right: 0.3125rem;
    top: 0;
    bottom: 0;
  }
}


@include helix-themes() using ($theme) {
  $foreground: map-get($theme, foreground);
  $component: map-get($theme, component);
  $is-dark-theme: map-get($theme, is-dark);

  // Values emulating Angular Material: _form-field-legacy-theme.scss plus
  // customizations from Helix.
  $label-color: mat.get-color-from-palette($foreground, text);
  $user-input-color: mat.get-color-from-palette($foreground, secondary-text);
  $placeholder-color: mat.get-color-from-palette($foreground, hint-text);
  $underline-color: mat.get-color-from-palette($component, input);
  $warn-color: helix-color($theme, warn, 500);
  $primary-color: helix-color($theme, primary, 500);

  .form-horizontal .control-label {
    color: helix-color($theme, foreground, secondary-text);
  }

  input::selection,
  textarea::selection {
    color: helix-color($theme, foreground, secondary-text);
  }

  .c-input {
    color: $label-color;
    border-bottom: 1px solid $underline-color;

    @include input-placeholder {
      color: map-get($foreground, hint-text);
    }

    &.active,
    &:focus {
      color: helix-color($theme, foreground, text);
      border-bottom: 1px solid helix-color($theme, primary, 500);
    }
  }

  .c-form-sleek {
    label {
      color: $label-color;

      &.required {
        &::after {
          color: $warn-color;
        }
      }
    }

    fieldset {
      &.error {
        label:not(.c-checkbox-label) {
          color: $warn-color;
        }

        input[type="text"],
        input[type="number"],
        input[type="url"],
        input[type="password"],
        input[type="email"] {
          border-bottom-color: $warn-color;
        }
      }
    }

    // field error message
    .error-msg {
      color: $warn-color;
    }

    input[type="text"],
    input[type="number"],
    input[type="password"],
    input[type="url"],
    input[type="email"] {
      border-bottom: 1px solid $underline-color;
      color: $user-input-color;

      @include input-placeholder {
        color: $placeholder-color;
      }

      &:focus,
      &:hover,
      &:active {
        border-bottom-color: $primary-color;
      }

      &.error {
        border-bottom-color: $warn-color;
      }

      &[pu-elastic-input] {
        &:focus,
        &:hover,
        &:active {
          border-bottom-color: $primary-color;
        }
      }
    }

    .c-textarea {
      border-bottom: 1px solid $underline-color;
      color: $user-input-color;

      @include input-placeholder {
        color: $placeholder-color;
      }
    }
  }


  .has-error .form-control,
  .has-error .form-control:focus,
  .has-error .form-control:active {
    border-color: $warn-color;
    box-shadow: none;
  }

  body fieldset.error .c-multiselect-list button,
  body fieldset.error .c-select-list button {
    border: 1px solid $warn-color;
  }

  input[type="range"].c-input-range {
    &::before {
      color: $placeholder-color;
      border-left: 2px solid currentColor;
      border-right: 2px solid currentColor;
    }
    &.active,
    &:focus {
      &::before {
        color: $label-color;
      }
    }
    &:disabled {
      &::before {
        color: helix-color($theme, foreground, disabled-button);
      }
    }
    &.ng-invalid {
      &::before {
        color: $warn-color;
      }
    }
  }
}

// Class for creating smaller form components which can then be used in other
// forms. This removes default fieldset padding in order to give parent forms
// flexibility with layout and alignment.
.sub-form-fragment {
  padding: 0;
}

// This class can be used to indent a div below toggle button.
// It will align indented div with text of toggle button.
.indent-box {
  margin-left: 2.75rem;
}

.coh-radio-group.mat-radio-group {
  display: block;
  margin-bottom: $fieldsetSpacing;

  mat-radio-button {
    margin-right: 2rem;
  }
}

.coh-form {
  .title {
    @include cog-type-level('body-2');
    margin: 0.5rem 0;
  }

  .sub-title {
    @include cog-type-level('caption');
    margin-bottom: 0.5rem;
  }

  mat-form-field {
    display: block;
  }

  mat-radio-group {
    display: block;
    margin: $fieldsetSpacing 0;

    mat-radio-button {
      margin-right: $fieldsetSpacing;
    }
  }

  // Form Submit buttons to be rendered to angular form components.
  .coh-form-action-button {
    display: block;
    margin-top: 0.5rem;

    button {
      margin-right: 1rem;
    }
  }
}

.icon-wrapper {
  line-height: 0;
}

mat-form-field.loading,
mat-form-field.readonly {
  @include disabledProperties();
}

mat-form-field.form-field {
  margin-bottom: 1rem;

  &.form-field-full {
    width: 100%;
  }
}


// Sets mat-form-field to full width
.full-width-field {
  width: 100%;

  .mat-form-field-infix {
    width: inherit;
  }
}