// Bootstrap overrides

.c-time-picker {
  margin-top: -0.4em;

  tr.text-center {
    td {
      height: 8px;
      a {
        height: 8px;
        span {
          height: 8px;
          vertical-align: top;
          top: -4px;
        }
        span.glyphicon-chevron-up {
          @include iconImage('icn-step.png');
          background-color: transparent;
          background-position: 0 0;
          background-repeat: no-repeat;
          height: 8px;
          width: 12px;
        }
        span.glyphicon-chevron-down {
          @include iconImage('icn-step.png');
          background-color: transparent;
          background-position: 0 -15px;
          background-repeat: no-repeat;
          height: 8px;
          width: 12px;
        }
        span.glyphicon-chevron-up:before,
        span.glyphicon-chevron-down:before {
          content: none;
        }
        span.glyphicon-chevron-up:active {
          @include iconImage('icn-step-d.png');
          background-color: transparent;
          background-position: 0 0;
          background-repeat: no-repeat;
        }
        span.glyphicon-chevron-down:active {
          @include iconImage('icn-step-d.png');
          background-color: transparent;
          background-position: 0 -15px;
          background-repeat: no-repeat;
        }
      }
    }
    td:hover {
        span.glyphicon-chevron-up {
          @include iconImage('icn-step-d.png');
          background-color: transparent;
          background-position: 0 0;
          background-repeat: no-repeat;
        }
        span.glyphicon-chevron-down {
          @include iconImage('icn-step-d.png');
          background-color: transparent;
          background-position: 0 -15px;
          background-repeat: no-repeat;
        }
    }
  }
  tr {
    .uib-time.am-pm::before {
      content: '';
      display: inline;
      padding: 0 0.125rem;
    }

    input {
      @extend .c-input;
      width: 50px;
      display: inline-block;
    }
    input.active,
    input:focus {
      border: 1px solid $formInputActive;
      color: $colorPrimaryDark;
    }
    .uib-separator {
      padding: 0 0.25rem;
      text-align: center;
    }
    button {
      border: 1px solid $formInputInactive;
      color: #868686;
      height: 40px;
    }
  }
}

.c-time-picker-sm tr {
  input,
  button {
    height: 30px;
    line-height: initial;
  }
}

// support for c-time-pickers nested in tables
.c-table .c-time-picker td {
  height: auto;

  // uib-separator comes with its padding-right to align correctly
  // so don't reset padding for uib-separator.
  &:not(.uib-separator) {
    padding: 0 !important;
  }

  &.uib-separator {
    padding-top: 0.4rem;
    padding-left: 0;
    padding-bottom: 0;
  }
}

.c-form-sleek {
  .c-time-picker {
    td {
      font-size: inherit;
      line-height: inherit;
    }

    // hour and minute inputs
    tr input {
      display: inline-block;
      width: 22px;
      height: auto;
      box-shadow: none;
      line-height: inherit;
      font-size: 1em;
      padding: 0;
      margin: 0.4375rem 0;
      border: 0;
      border-bottom: 1px solid $colorBorderDark;
      text-align: center;
    }

    // meridian toggle button
    tr button {
      background-color: transparent;
      height: auto;
      width: 22px;
      border-radius: 0;
      border: 0;
      border-bottom: 1px solid $colorBorderDark;
      box-shadow: none;
      padding: 0;
      margin: 0.4375rem 0;
      color: inherit;
      line-height: inherit;
      font-size: inherit;

      // don't want focus or hover styling to overrides outline.
      outline: 0 !important;
      vertical-align: top;

      &:focus {
        border-bottom-color: $colorPrimary;
      }
    }
  }

  // styling of c-time-picker inside c-table.
  .c-table .c-time-picker tr {
    input,
    button {
      padding: 0.2813rem 0;
      line-height: initial;
    }
  }
}

.elastic {

  c-time-picker {
    display: inline-block;
  }

  .c-time-picker {
    tr input,
    tr button {
      margin: 0;
    }
  }
}
