// Styles: selectPolicy component

.select-policy-wrapper {
  position: relative;
}

.select-policy-edit {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(100% + #{$bootstrap-col-padding});
}
