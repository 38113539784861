.c-throttle-wrap {
  select-days#throttle-days-vault {
    min-width: 10rem;
    width: 100%;

    .ui-select-container {
      input[type='search'].ui-select-search {
        max-width: none;
      }
    }
  }
  .c-table {
    .col-width-5 {
      width: 5%;
    }
    .col-width-15 {
      width: 15%;
    }
    .col-width-25 {
      width: 25%;
    }
    .col-width-35 {
      width: 35%;
    }
  }
}
