@mixin cog-core-theme-styles($theme) {
  $foreground: map-get($theme, foreground);

  &.mat-typography,
  .mat-typography {
    // This rule ensures that the default font is applied even if the component
    // is used outside of the standard contexts in which Angular Material applies
    // its color (i.e. takeovers/dialogs/popovers/etc).
    color: map-get($foreground, text);
  }
}
