// Timeline View SCSS

.timeline-container {
  width: 100%;
  position: relative;

  ul.timeline-list {
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    li.timeline-list-item {
      width: 100%;
      position: relative;

      // Connector lines between list element
      &:not(.timeline-title)::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 3px;
        bottom: 5.25em;
      }

      // Arrow at the end of the upgrade flow
      &:last-child::after {
        content: '';
        position: absolute;
        right: 0;
        bottom: 4.65em;
        width: 0;
        height: 0;
      }

      &.timeline-title {
        width: 35%;
        position: relative;

        ul.timeline-title-list {
          list-style-type: none;
          position: absolute;
          bottom: 0;
          right: 0;

          li.timeline-title-list-item {
            padding: 0.375rem 0.625rem;
            margin-bottom: 0.0625rem;
          }
        }
      }

      .timeline-header {
        text-align: left;
        margin-right: 0.625rem;
      }

      .timeline-sub-header {
        font-size: 0.75rem;
        margin-top: 0.5rem;
      }

      .timeline-li-container {
        display: flex;
        flex-direction: row;
        margin-bottom: 0.9rem;

        // Styles for the connector between radio
        .timeline-connector {
          display: inline-flex;
          width: 100%;
          height: 0.1875rem;
          margin: auto;
        }

        // Styles for the radio container. This is a custom style for the radio.
        .timeline-radio-container {
          display: block;
          position: relative;

          // Make the input absolute and hide by reducing the opacity
          input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
          }

          // After the input in checked, make sure the radio button checked
          // appears.
          input:checked ~ .timeline-radio {
            width: 1rem;
            height: 1rem;
          }

          // Only if the input is checked, display the radio mark.
          input:checked ~ .timeline-radio::after {
            display: block;
          }

          // Styles if disabled.
          input:disabled ~ .timeline-radio {
            width: 1rem;
            height: 1rem;
            top: 0;
            left: 0;
          }

          // This is the actually container for radio.
          .timeline-radio {
            margin-top: 2px;
            top: 0;
            left: 0;
            height: 1rem;
            width: 1rem;
            border-radius: 50%;
          }

          // This is the container for the radio selected mark.
          .timeline-radio::after {
            content: '';
            position: absolute;
            display: none;
            top: 0.3125rem;
            left: 0.1875rem;
            width: 0.625rem;
            height: 0.625rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
}

@include helix-themes() using ($theme) {
  $primary-color: helix-color($theme, primary, 500);
  $appbar-color: helix-color($theme, background, app-bar);

  .timeline-container {
    ul.timeline-list {
      li.timeline-list-item {
        // Connector lines between list element
        &:not(.timeline-title)::before {
          background-color: $primary-color;
        }

        // Arrow at the end of the upgrade flow
        &:last-child::after {
          background: $primary-color;
          border: 10px solid $appbar-color;
          border-left-color: $primary-color;
        }

        &.timeline-title {
          ul.timeline-title-list {
            li.timeline-title-list-item {
              &:first-child {
                color: $primary-color;
              }
            }
          }
        }

        .timeline-header {
          color: $primary-color;
        }

        .timeline-sub-header {

          .recommended {
            color: helix-color($theme, status, success);
          }
        }

        .timeline-li-container {
          // Styles for the connector between radio
          .timeline-connector {
            background: $primary-color;
          }

          // Styles for the radio container. This is a custom style for the radio.
          .timeline-radio-container {

            // After the input in checked, make sure the radio button checked
            // appears.
            input:checked ~ .timeline-radio {
              background: $appbar-color;
            }

            // Styles if disabled.
            input:disabled ~ .timeline-radio {
              background: helix-color($theme, background, disabled-button);
              border: 4px solid $appbar-color;
            }

            // This is the actually container for radio.
            .timeline-radio {
              border: 2px solid $primary-color;
              background: $appbar-color;
            }

            // This is the container for the radio selected mark.
            .timeline-radio::after {
              background: $primary-color;
            }
          }
        }
      }
    }
  }

}
