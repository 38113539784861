// custom pagination styles
@use "@angular/material/core/theming/palette" as mat-theme-palette;
@import "common-imports";
// stTable pagination overrides

st-pagination,
[st-pagination] {
  @include user-select(none);
  nav {
    display: flex;
    align-items: center;
  }
  .btn-group {
    vertical-align: baseline;
    .btn {
      padding-right: 6px;
      padding-left: 6px;
    }
  }
}

// Materialized pagination
.material-pagination {
  display: block;
  margin: 0;
  cursor: initial;

  .paginator-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 0.5rem;
    flex-wrap: wrap-reverse;
    width: 100%;
  }

  .paginator-range-label {
    margin: 0 2rem 0 1.5rem;
  }

  .paginator-range-actions {
    display: flex;
    align-items: center;
  }

  .paginator-icon {
    width: 1.75rem;
    fill: currentColor;
  }

  .mat-icon-button {
    line-height: inherit;
    position: relative;
    overflow: hidden;
    transform: translate3d(0, 0, 0);

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      pointer-events: none;
      background-image: radial-gradient(circle, map_get(mat-theme-palette.$light-theme-foreground-palette, base) 10%, transparent 10.01%);
      background-repeat: no-repeat;
      background-position: 50%;
      transform: scale(10, 10);
      opacity: 0;
      transition: transform .5s, opacity 1s;
    }

    &:active::after {
      transform: scale(0, 0);
      opacity: 0.2;
      transition: 0s;
    }

    &:disabled {
      @include disabledProperties();
    }
  }
}

@include reflow() {
  @include reflow-sm() {
    .material-pagination {
      .paginator-container {
        padding: 0 0.25rem;
      }

      .paginator-range-label {
        margin: 0 1rem 0 .5rem;
      }
    }
  }
}
