// Styles: uibDatepicker overrides & Cohesity common styles/patterns

.c-calendar-wrapper {
  display: inline-block;
  text-align: center;
  padding: 0;
  border-radius: $defaultRadius;
  height: 20.3125rem;
  overflow: hidden;
}

.c-calendar-wrapper,
.c-datepicker-embedded table {
  width: 100%;
}

.c-datepicker-embedded {
  width: calc(100% + 1px);
}

.c-calendar-date {
  @include font($size: 1.1875rem);
  margin-top: 0.625rem;
  text-align: center;
}

[uib-datepicker-popup-wrap] .btn-sm.uib-day-btn-today,
.c-datepicker-embedded .btn-sm.uib-day-btn-today {
  border: 1px solid $colorNeutralLight;
  border-radius: 2px;
  font-weight: 300;
  transition: border-color $defaultDuration ease;

  &:hover {
    border-color: $colorNeutralDark;
  }
}

@include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
  .c-calendar-wrapper {
    background-color: map_get($background, card);
    border: 1px solid map_get($foreground, divider);

    .btn-default {
      color: map_get($foreground, text);
    }
  }

  .c-calendar-date {
    color: map_get($accent, 500);
  }
}
