// reports fields Styles.

$cFiltersGutter: 1.6rem;
$cFiltersGutterHalf: $cFiltersGutter * 0.5;

report-fields form {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: -$cFiltersGutterHalf;

  // creating gutter b/w flexbox items.
  // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items#Creating_gutters_between_items
  > * {
    margin: $cFiltersGutterHalf;
  }

  input.c-input[type="number"]:not([pu-elastic-input]) {
    width: 100% !important;
  }

  .report-time-span-type {
    width: 50%;
  }
}
