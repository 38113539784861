@import 'helix/scss/dependencies';
@import '../variables.styles';

.blocklyEditableText {
  > .blocklyFieldRect:is(.blocklyDropdownRect) {
    fill: $field-background-color !important;
  }

  > .blocklyText {
    fill: $field-text-color !important;
  }
}

.blocklyNonEditableText {
  > .blocklyDropdownText {
    fill: #fff !important;
    opacity: 0.5;
  }
}

.blocklyDropDownDiv {
  overflow: hidden;
  border-radius: $field-border-radius;
  z-index: 99999;

  .blocklyMenuItem {
    border-radius: $field-border-radius;
  }
}

.blocklyDropDownContent {
  .blocklyMenuItemContent {
    display: flex;
    align-items: center;
  }

  .coh-dropdown-menu-item-content {
    display: flex;
    align-items: center;

    &.coh-dropdown-menu-item-category {
      @include cog-type-level(chart);
    }

    &.coh-dropdown-menu-item-separator {
      width: 100%
    }
  }

  .blocklyMenuItemCheckbox,
  .coh-dropdown-menu-item-icon {
    margin-right: 0.5rem;

    // By default the image icon SVG is black. Inverting it to make it white.
    filter: invert(1);
  }

  .coh-field-dropdown-item-search {
    padding: 0 !important;
    margin: 0 !important;

    .coh-field-dropdown-item-search-input {
      width: 100%;
      border: none;
      border-bottom: 1px solid $field-placeholder-color;
      background-color: transparent;
      padding: 0.75rem;
      outline: none;
      margin-bottom: 0.5rem;
      color: $field-text-color;

      &::placeholder {
        color: $field-placeholder-color;
      }
    }
  }
}

@include helix-themes() using ($theme) {
  .coh-dropdown-menu-item-content {
    &.coh-dropdown-menu-item-separator {
      border-top: 1px solid transparentize(helix-contrast($theme, accent, 500), 0.5);
    }
  }
}
