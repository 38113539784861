.coh-dialog-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.coh-dialog-close-button {
  position: absolute;
  right: -1rem;
  top: -1rem;
}

.coh-dialog-content {
  position: relative;
  display: block;
  min-width: 64rem;
}

@include reflow() {
  @include reflow-sm() {
    .coh-dialog-content {
      min-width: unset;
      overflow-y: visible;
      overflow-x: hidden;
    }
  }
}
