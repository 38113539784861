c-license-upload-download {
  min-height: 27rem;
  display: block;
  text-align: center;

  .text-left {
    text-align: left;
  }

  .format {
    display: flex;
    flex-direction: column;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    position: absolute;
    width: 90%;
  }

  .licensing-steps {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: baseline;

    .step {
      display: inherit;
      justify-content: space-around;
      align-items: baseline;

      .step-description-div {
        width: 15rem;
      }
    }
  }

  .main-description {
    text-align: center;
    padding: 5rem;
  }

  .step-font {
    font-size: 1rem;
    color: $colorSecondary;
  }

  .blue-backgroud {
    background-color: $colorLightGrey;
    height: 6rem;
    width: 6rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }

  .icn-arrow {
    width: 5rem;
    height: 2rem;

    &.no-arrow {
      display: none;
    }
  }
}
