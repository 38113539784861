@use "@angular/material/core/style/elevation" as mat;

@mixin jazz-card-styles($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);
  border-radius: helix-color($theme, component, dashcard-border-radius);
  @if not $is-ibm-aqua-theme {
    @include mat.elevation(3);
  }
  background-color: helix-color($theme, component, card);
  min-height: 50vh;
  padding: $cohesity-card-padding calc(#{$cohesity-card-padding} + 0.5rem);
}

// This is useful when a selector for your style
// is based on a class attached to the body element
// example:
//
// body.jazz-theme-light.has-c-page-header {
//   .app-frame-content-wrapper {
//
@mixin jazz-theme-with-body-class($bodyClass) {
  .jazz-light-theme#{$bodyClass} {
    @content;
  }
  .jazz-dark-theme#{$bodyClass} {
    @content;
  }
}