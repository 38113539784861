// Cluster Details/Settings Styles

.c-cluster-settings {
  @include pie-clearfix;
  .c-form {
    @include pie-clearfix;
  }
  .send-test-email {
    position: relative;
    top: 10px;
    margin-right: 10px;
    margin-left: 10px;
    .info {
      @include font($weight: normal);
      margin-right: 5px;
    }
  }
}

@include helix-themes() using ($theme) {
  .time-input {
    border: none;
    border-bottom: 1px solid helix-color($theme, component, input);
    line-height: 1.5rem;

    &:focus {
      border-bottom: 2px solid helix-color($theme, helix-primary, 700);
    }
  }
}

#proxy-service-fieldset[disabled] {
  color: $textGreyLight;
}

.ntp-auth-feild-container .see-more-wrapper {
  width: 35rem;
}