button.cog-link-button {
  background: transparent;
  border: 0;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

@include helix-themes() using ($theme) {
  button.cog-link-button {
    color: helix-color($theme, component, anchor);
  }
}