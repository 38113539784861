// This file supports Angular Material theming of the suite of page components. Please see helix-theme.scss to see how
@use "@angular/material/core/theming/theming" as mat;
// these are utilized. Or read the Angular Material guide:
// https://material.angular.io/guide/theming-your-components

@mixin _cog-toolbar-color($palette) {
  background: mat.get-color-from-palette($palette);
  color: mat.get-color-from-palette($palette, default-contrast);
}

@mixin page-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);
  $is-dark: map-get($theme, is-dark);
  $is-jazz-theme: map-get($theme, is-jazz);
  $component: map-get($theme, component);

  .cog-page-wrapper {
    background-color: map-get($background, background);
  }

  .cog-takeover-theme .cog-page-wrapper {
    background-color: map-get($component, takeover-background);
  }

  cog-page-toolbar {
    .cog-page-tall-header-row {
      $border-color: map-get($background, card-border);
      @if $is-dark and $is-jazz-theme {
        $border-color: map-get($foreground, divider);
      }
      background: map-get($background, card);
      border-left: solid 1px $border-color;
      border-right: solid 1px $border-color;
      border-top: solid 1px $border-color;
      border-top-left-radius: map-get($component, dashcard-border-radius);
      border-top-right-radius: map-get($component, dashcard-border-radius);
    }
  }

  // Reset things for dialog scenario.
  .mat-dialog-container {
    .cog-page-wrapper {
      background-color: transparent;
    }
  }
}
