@include helix-themes() using ($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);

  @if $is-jazz-theme {
    tabs:not(.no-cog-content-card-wrapper) {
      .c-tabs {
        > ui-view {
          @include jazz-card-styles($theme);
          margin-left: 1.5rem;
          margin-right: 1.5rem;
        }
      }
    }
  }
}
