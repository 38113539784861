// Job Details Styles

.c-job-details {
  .job-summary-stat {
    @include font($size: 1.125rem, $leading: 1.6875rem);
    display: inline-block;
    float: left;
    clear: both;
    .summary-total {
      @include font($size: 1.5625rem, $leading: 1.6875rem);
    }
  }
  .job-detail-charts {
    margin-top: 40px;
    min-height: 332px;
  }
}

.prev-attempts-toggle-container {
  @include font($size: 0.75rem, $leading: 2.5rem);
  margin-top: 10px;
  height: 40px;
  padding: 0 10px;
}

// Cloud Deploy

.cloud-deploy-table {
  .error-msg-col {
    max-width: 5rem;
  }
}

@include helix-themes() using ($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);

  .c-job-details {
    .job-summary-stat {
      color: helix-color($theme, foreground, text);

      .summary-total {
        color: helix-color($theme, status, info);

        &.summary-total-success {
          color: helix-color($theme, status, success);
        }
        &.summary-total-warning,
        &.summary-total-canceled {
          color: helix-color($theme, status, warning);
        }
        &.summary-total-error {
          color: helix-color($theme, status, error);
        }
      }
    }
    @if $is-jazz-theme {
      div[ui-view="content"] {
        @include jazz-card-styles($theme);
      }
    }

  }

  .prev-attempts-toggle-container {
    background-color: helix-color($theme, background, app-bar);
  }
}
