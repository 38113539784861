// job-sources-object styling

.job-sources {
  // This is a custom styled icon for sourceTree use. Adapting in this context
  // so that it lines up nicely with surrounding `.icn-md` imlementations.
  .autoprotect-checkbox {
    width: $iconSizeMd;
    height: $iconSizeMd;
    background-position: center;
  }
}

.job-sources-list {

  &.expanded {
    .c-entity-tree-node {
      transition: background-color $defaultDuration ease-out;
      &:hover {
        transition: background-color 0s linear;
      }
    }
  }
}

.job-objects-toggle {
  margin-left: 0.1875rem;
}

.job-objects-grouping {
  margin-top: $gutter-xs;
  margin-bottom: $gutter-md;

  &:last-of-type {
    margin-bottom: 0;
  }
}

@include helix-themes() using ($theme) {
  .job-sources-list {
    border-top: 1px solid helix-color($theme, foreground, divider);
    border-bottom: 1px solid helix-color($theme, foreground, divider);

    &.expanded {
      &, c-entity-tree {
        background-color: helix-color($theme, background, app-bar);
      }

      .c-entity-tree-node {
        &:hover {
          background-color: helix-color($theme, background, hover);
        }
      }
    }
  }
}
