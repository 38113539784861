// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-card.scss).

@mixin cog-mat-stepper-theme($theme) {
  .mat-stepper-horizontal, .mat-stepper-vertical {
    background-color: transparent;
  }

  .mat-table,
  .mat-paginator {
    background-color: transparent;
  }
}
