@import 'dependencies';

.shimmer {
  width: 100%;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
}

cog-card-section-glancebar .shimmer.stats-list-loader {
  height: 5rem;
}

cog-card-section .shimmer.stats-list-loader {
  height: 10rem;
}

@include helix-themes() using ($theme) {
  .shimmer {
    background-color: rgba(helix-color($theme, chart, subtle), 0.5);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      animation : shimmer 2s infinite;
      background: linear-gradient(
        to right,
        rgba(helix-color($theme, component, card), 0) 0,
        rgba(helix-color($theme, component, card), 0.2) 20%,
        rgba(helix-color($theme, component, card), 0.6) 60%,
        rgba(helix-color($theme, component, card), 0)
      );
      content: '';
    }
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}