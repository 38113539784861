// Object Protection Trends

$colorBlockObjectWidth: 12.5rem;
$colorBlockHeight: 2rem;

.color-block-table {
  width: 100%;
  overflow-x: auto;
}

div.color-block-header {
  > div {
    line-height: 1.25rem;
    height: 1.25rem;
    padding-left: 0.125rem;
  }
  .color-block-flex-row > div {
    background-color: transparent;
  }
}

.color-block-row {
  position: relative;
}

.color-block-object {
  display: block;
  position: sticky;
  float: left;
  left: 0;
  width: $colorBlockObjectWidth;
  padding-right: 0.625rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: $colorBlockHeight;
  z-index: 1;
}

.color-block-pagination {
  display: block;
  position: sticky;
  float: right;
  left: 0;
}

.color-block-flex-row {
  margin-left: $colorBlockObjectWidth;
  display: flex;
  flex-direction: row;
  > div {
    flex-basis: 100%;
    min-width: 5rem;
    height: $colorBlockHeight;
    border-radius: 0.25rem;
  }
}

.color-block-wrapper {
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.color-block-success,
.color-block-error {
  float: left;
  height: 100%;
}

.trend-connector {
  display: none;
  z-index: 1;
  width: 0rem;
  height: 1.5625rem;
  position: absolute;
  transform: translate(-50%, 100%);
  left: 50%;
  bottom: 0;
  z-index: 1;

  &::after,
  &::before {
    content: '';
    display: block;
    position: absolute;
    width: 0.4375rem;
    height: 0.4375rem;
    left: -0.25rem;
    transform: translateY(-50%);
    border-radius: 50%;
  }

  &::after {
    bottom: 0;
    transform: translateY(50%);
  }

  .selected & {
    display: block;
  }
}

.color-block-runs-wrapper {
  position: sticky;
  left: 0;
  right: 0;
}

.color-block-runs {
  padding: $cohesity-card-padding;
  margin: $cohesity-card-padding 0;
  border: 1px solid $colorPrimary;
  border-radius: $defaultRadius;
}

.color-block-trend-details-close-btn {
  position: absolute;
  top: 0.3125rem;
  right: 0.1875rem;
  height: 1.25rem;
  width: 1.25rem;
  padding: 0;
  border-radius: 50%;
}

@include helix-themes() using ($theme) {
  $card-color: helix-color($theme, background, card);
  $foreground: map-get($theme, foreground);
  $primary-color: helix-color($theme, primary, 500);

  div.color-block-header {
    > div {
      color: $textGreyLightest;
      background-color: $card-color;
    }
  }

  .color-block-object {
    background-color: $card-color;
    box-shadow: inset -7px 0 9px -7px rgba($colorNeutralDark, 0.7);
  }

  .color-block-flex-row {
    > div {
      background-color: helix-color($theme, background, background);
      border: 1px solid $card-color;

      &.selected {
        border-color: $primary-color;
      }
    }
  }

  .color-block-success {
    background-color: helix-color($theme, status, success);
  }
  .color-block-error {
    background-color: helix-color($theme, status, error);
  }

  .trend-connector {
    border-left: 1px solid $primary-color;

    &::after,
    &::before {
      background: $primary-color;
    }
  }
}
