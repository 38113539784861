.mat-typography {
  .mat-standard-chip {
    padding: 0.125rem .5rem;
    border-radius: 0.75rem;
    min-height: 1.5rem;
    font-weight: $font-weight-normal;
  }

  .mat-chip-list-wrapper .mat-standard-chip,
  .mat-chip-list-wrapper input.mat-input-element {
    margin: 0.125rem .25rem;
  }
}
