// Page Layout Styles

// .no-margin and .no-padding placed before other rules so they can
// be used as a reset and then margin and/or padding applied as desired
.no-margin {
  margin: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.no-border {
  border: 0 !important;
}

// Margins for all sides
.margin-xs {
  margin: $gutter-xs !important;
}
.margin-sm {
  margin: $gutter-sm !important;
}
.margin-md {
  margin: $gutter-md !important;
}
.margin-lg {
  margin: $gutter-lg !important;
}
.margin-xl {
  margin: $gutter-xl !important;
}

// Padding for all sides
.padding-xs {
  padding: $gutter-xs !important;
}
.padding-sm {
  padding: $gutter-sm !important;
}
.padding-md {
  padding: $gutter-md !important;
}
.padding-lg {
  padding: $gutter-lg !important;
}
.padding-xl {
  padding: $gutter-xl !important;
}

// Margin and padding for specific sides of the box model.
@each $pos in $gutter-positions {
  // Single side margin & padding resets
  .no-margin-#{$pos} {
    margin-#{$pos}: 0 !important;
  }
  .no-padding-#{$pos} {
    padding-#{$pos}: 0 !important;
  }
  .no-border-#{$pos} {
    border-#{$pos}: 0 !important;
  }

  .margin-#{$pos}-xs {
    margin-#{$pos}: $gutter-xs !important;
  }
  .margin-#{$pos}-sm {
    margin-#{$pos}: $gutter-sm !important;
  }
  .margin-#{$pos} {
    margin-#{$pos}: $gutter-md !important;
  }
  .margin-#{$pos}-lg {
    margin-#{$pos}: $gutter-lg !important;
  }
  .margin-#{$pos}-xl {
    margin-#{$pos}: $gutter-xl !important;
  }

  .negative-margin-#{$pos}-xs {
    margin-#{$pos}: -$gutter-xs !important;
  }
  .negative-margin-#{$pos}-sm {
    margin-#{$pos}: -$gutter-sm !important;
  }
  .negative-margin-#{$pos} {
    margin-#{$pos}: -$gutter-md !important;
  }
  .negative-margin-#{$pos}-lg {
    margin-#{$pos}: -$gutter-lg !important;
  }
  .negative-margin-#{$pos}-xl {
    margin-#{$pos}: -$gutter-xl !important;
  }

  .padding-#{$pos}-xs {
    padding-#{$pos}: $gutter-xs !important;
  }
  .padding-#{$pos}-sm {
    padding-#{$pos}: $gutter-sm !important;
  }
  .padding-#{$pos} {
    padding-#{$pos}: $gutter-md !important;
  }
  .padding-#{$pos}-lg {
    padding-#{$pos}: $gutter-lg !important;
  }
  .padding-#{$pos}-xl {
    padding-#{$pos}: $gutter-xl !important;
  }

}

// Utility class for those times when you want an inline element to be hidden
// but need it to hold its place in the flow so things don't awkwardly collapse
// in its absence.
.ghost {
  visibility: hidden !important;
}

.hidden {
  display: none !important;
}

// Our own media element pattern in Flexbox (based on Subbornella's)
.c-media {
  $mediaGutter: $bootstrap-col-padding;

  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;

  &.c-media-align-center {
    align-items: center;
  }

  > .c-media-object {
    position: relative;
    order: -1;
    flex: 0 0 auto;
    margin: 0 $mediaGutter 0 0;

    .icn-lg {
      margin-right: 0;
    }

    .icn-sm {
      margin-top: 0.4rem;
    }
  }
  > .c-media-body {
    flex: 1 1 auto;

    &.center-body {
      display: flex;
      align-items: center;
    }
  }
  &.reverse {
    > .c-media-object {
      order: 1;
      margin: 0 0 0 $mediaGutter;
    }
  }
}

.c-popover {
  font-size: 0.7625rem;

  h6 {
    font-weight: bold;
    margin-bottom: 0.55em;
  }
}

// Utility class for enabling cursor pointer within an element.
.clickable-container {
  cursor: pointer;
}

.align-bottom {
  vertical-align: text-bottom;
}

// preformatted mat-tooltip matTooltipClass
.coh-multiline-mat-tooltip {
  white-space: pre-wrap;
}

// resize snapshot selector menu
.coh-snapshot-selector-menu + * .mat-menu-panel {
  min-width: 40rem;
  padding: 1rem;
}

// General show-hide utility classes
// These should not be used since removing useful information
// from zoomed viewports is not WCAG 2.1 compliant.
// Unless you are re-arranging where things display at zoomed viewports.
.hide-block\@xs,
.hide-block\@xxs {
  display: block;
}
.show-block\@xs,
.show-block\@xxs {
  display: none !important;
}

@include media($breakpoint-xs) {
  .show-block\@xs {
    display: block !important;
  }
  .hide-block\@xs {
    display: none !important;
  }
}

@include media($breakpoint-xxs) {
  .show-block\@xxs {
    display: block !important;
  }
  .hide-block\@xxs {
    display: none !important;
  }
}
