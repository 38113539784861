// TOPIC: Type Styles

@use "sass:math";

$h1DefaultSize: 1.625rem;
$h2DefaultSize: 1.25rem;

// Heading styles
h1 {
  word-wrap: break-word;
}

// inline header variant
.inline-header {
  display: inline-block;
}

a {
  text-decoration: none;
  cursor: pointer;

  &.inline-header-link {
    @include cog-type-level(button);
    margin: 0 0.625rem;
    white-space: nowrap;
  }
}

// reset anchor styling if link is not accessible by logged in user and used to
// reset ui-sref or ui-state links.
$noAccess: ".no-access";
a#{$noAccess} {
  color: inherit;
  text-decoration: inherit;
  cursor: default;

  &:hover,
  &:focus {
    color: inherit;
  }

  &.inline-header-link {
    @include font($size: inherit, $leading: inherit, $weight: inherit);
    margin: inherit;
    white-space: inherit;
  }

  // custom style not accessible link as bold.
  &[no-access-styled-strong] {
    @include font($weight: bold);
  }
}

// hide icons link if it is not accessible.
i#{$noAccess},
#{$noAccess}[class*="icn"],

// hide not accessible links inside page header.
c-page-header a#{$noAccess},

// hide if links are explicitly marked to hide.
#{$noAccess}[access-required] {
  display: none;
}

// ui-sref can put an href on any element. This rule ensures it obvious that
// such elements are clickable.
[href] {
  cursor: pointer;
}

small {
  // Dialed in to make a size 14px inherited font into 12px.
  $smallSize: math.div(12, 14);
  font-size: #{$smallSize}em;
}

// Auto truncates strings that are longer than 100% width with an ellipsis.
// Can override the display if needed.
.auto-truncate {
  display: inline-block;
  white-space: nowrap;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;

  &[class*="user-data"] {
    width: auto;
  }
}

.flex-truncate-wrapper {
  display: flex;
  align-items: center;

  // Standard behavior, will be effectively fixed width, using needed space.
  > * {
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: 0.5rem;
    }
  }

  // Child element to truncate text of based on available space.
  > .flex-truncate-text {
    // Applying styles to the class and decendates of the class allows for
    // truncation of a child element (for example, a nested h2)
    &,
    & > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.c-highlight {
  display: inline-block;
}

.node-name label {
  font-weight: normal;
}

// This simply changes font whereas the native <code> element changes color and
// background. This class is for a less prominent rendering.
.code {
  font-family: $monoFontStack;
}

@include helix-themes() using ($theme) {
  $success-color: helix-color($theme, status, success);

  .metadata {
    color: helix-color($theme, foreground, hint-text);
  }

  .c-highlight {
    background-color: rgba($success-color, 0.3);
  }

  kbd {
    background-color: helix-color($theme, background, dialog);
    color: rgba($success-color, 0.7);
  }

  pre {
    background-color: helix-color($theme, background, dialog);
    border: 1px solid helix-color($theme, foreground, divder);
    color: helix-color($theme, foreground, text);
  }
}

@include reflow() {
  @include reflow-sm() {
    // Use this to disable auto-truncated text
    // or similar classes because at very small
    // viewports, the text may be more truncated
    .text-wrap\@sm {
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
      overflow-wrap: anywhere;
    }
  }
  @include reflow-xs() {
    .text-wrap\@xs {
      white-space: normal;
      overflow: visible;
      text-overflow: unset;
      overflow-wrap: anywhere;
    }
  }
}
