// NOTE: If a variable in here has a `!default` marker it is being overwritten
// by @cohesity-common for nextgen UI implementation.

// Global Vars
$iconBasePath: '/assets/i/icn/';
$iconDarkBasePath: '/assets/i/icn-dark/';

$defaultRadius: 0.25rem;

$colorDatePickerNeutral: $colorLightGrey;

// DIMENSIONS
$cPageHeaderVerticalPadding: 0.625rem;
$cPageHeaderContentHeight: $page-header-height - ($cPageHeaderVerticalPadding * 2);

$cRangeSliderTickHeight: 0.75em;
$cSliderWidth: 50rem;

$bootstrap-col-padding: 0.5rem;

// triangle arrow size
$arrowSize: 0.25rem;
$cTilePadding: 0.9375rem;

// Grid
$screenMinDesktop: 75rem;
$screenMinTablet: 48rem;

// Layout
$cBoxNestedVerticalPadding: 1rem;
$cBoxNestedHorizontalPadding: 1rem;
$cBoxNestedPadding: 0 $cBoxNestedHorizontalPadding;

$fieldsetSpacing: 1.5rem;
$formGroupSpacing: $fieldsetSpacing * 2;

// Form fields
$formInputHeight: 2.5rem;
$sleekFormInputHeight: 2.1875rem;

$shelfLabelWidth: 12.5rem;
$iconSpacing: 0.1788rem;
$iconSpacingLg: 0.625rem;
$iconSpacingXl: 1.25rem;

$disabledOpacity: 0.45;
$defaultBoxShadow: 0 1px 8px 0 rgba(black, 0.24);
$defaultInsetBoxShadow: 0 -1px 3px 1px $colorNeutralMedium inset;
$darkInsetBoxShadow: 0 0 3px 1px $colorGrey inset;
$flaggedTextShadow: 0 1px 8px rgba(0, 0, 0, 0.24);

// popover styling
$popoverShadow: 0 0 0.75em 0 rgba(black, 0.3);
$popoverArrowBorder: rgba(black, 0.15);
$popoverSides: (top, bottom, right, left);

// cSnapshotPicker and cSnapshotCalendar
$cSnapshotSuiteHeight: 20.3125rem;

// CSS cubic-bezier timing functions. Timing functions courtesy of jquery.easie
// (github.com/jaukia/easie) Timing functions are the same as demo'ed here:
// http://jqueryui.com/resources/demos/effect/easing.html

// EASE IN
$easeInQuad: cubic-bezier(0.55, 0.085, 0.68, 0.53);
$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeInQuart: cubic-bezier(0.895, 0.03, 0.685, 0.22);
$easeInQuint: cubic-bezier(0.755, 0.05, 0.855, 0.06);
$easeInSine: cubic-bezier(0.47, 0, 0.745, 0.715);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeInCirc: cubic-bezier(0.6, 0.04, 0.98, 0.335);
$easeInBack: cubic-bezier(0.6, -0.28, 0.735, 0.045);

// EASE OUT
$easeOutQuad: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeOutQuart: cubic-bezier(0.165, 0.84, 0.44, 1);
$easeOutQuint: cubic-bezier(0.23, 1, 0.32, 1);
$easeOutSine: cubic-bezier(0.39, 0.575, 0.565, 1);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeOutCirc: cubic-bezier(0.075, 0.82, 0.165, 1);
$easeOutBack: cubic-bezier(0.175, 0.885, 0.32, 1.275);

// EASE IN OUT
$easeInOutQuad: cubic-bezier(0.455, 0.03, 0.515, 0.955);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInOutQuart: cubic-bezier(0.77, 0, 0.175, 1);
$easeInOutQuint: cubic-bezier(0.86, 0, 0.07, 1);
$easeInOutSine: cubic-bezier(0.445, 0.05, 0.55, 0.95);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);
$easeInOutCirc: cubic-bezier(0.785, 0.135, 0.15, 0.86);
$easeInOutBack: cubic-bezier(0.68, -0.55, 0.265, 1.55);

// Animation/transition related vars

// DEFAULT TRANSITION DURATION
$defaultDuration: 0.3s;
$fastDuration: 0.1s;
$slowDuration: 0.75s;

$angularAnimationDuration: 0.5s;
$angularAnimationStaggerDelay: 0.08s;

$gutter-xs: $bootstrap-col-padding * 0.5;
$gutter-sm: $bootstrap-col-padding;
$gutter-md: $bootstrap-col-padding * 2;
$gutter-lg: $bootstrap-col-padding * 3;
$gutter-xl: $bootstrap-col-padding * 6;
$gutter-xxl: $bootstrap-col-padding * 8;

$gutter-positions: (
  top,
  right,
  bottom,
  left
);

$uiSelectDropdownMaxHeight: 15.7rem;
