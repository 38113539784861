// Performance Styles

.c-statistics {
  h3 {
    padding-top: 10px;
  }
}

a.reset-zoom {
  display: inline-block;
  margin-top: 8px;
  font-size: 15px;
  color: $textLink;
  &:hover,
  &:focus {
    text-decoration: none;
  }
  .icn-md {
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin-right: 0;
  }
}

.statstics-entity-selector {
  position: relative;
  top: -4px;
}

// allow right edge alignment of the datepicker
// popup based on presence of class
.stats-datepicker {
  position: relative;
  &.right-align-popup {
    .dropdown-menu {
      left: auto !important;
      right: 0;
    }
  }
}

.stats-chart-sidebar {
  height: 150px;
  // bring to front so .facet-container overflow scrollbar can be interacted with
  z-index: 2;
}

.performance-chart-title {
  position: relative;
  @include font($weight: light, $size: 1.25rem, $leading: 1.25rem, $color: $textGrey);
  border-top: 1px solid #d7d2c4;
  margin: 0 0 20px;
  // using padding here for positioning of :after element
  padding-top: 5px;
  // extend border to the edge of the chart
  &::after {
    display: block;
    width: 30px;
    height: 1px;
    position: absolute;
    top: -1px;
    right: -30px;
    border-top: 1px solid #d7d2c4;
    content: '';
  }
}

.performance-chart-title-suffix {
  @include font(
    $weight: light, $size: 0.8125rem, $leading: 1, $color: $textGreyLight);
}

.diagnostics-job-runs {
  // Make sure the diagnostics job run chart stretchs the entire content.
  height: 100% !important;
}

.stats-avg-info {
  @include font($weight: light, $size: 0.8125rem, $leading: 1, $color: $textGrey);
  margin-left: 24px;
  & + .stats-avg-info {
    margin-top: 15px;
  }
}

.stats-avg-number {
  @include font(
    $weight: light, $size: 1.125rem, $leading: 1.25rem, $color: $textGreyLight);
}

.facet-container {
  // add bootstrap col gutters to float over gap
  width: calc(100% + #{$bootstrap-col-padding * 2});
  height: 150px;
  overflow: auto;
}

.series-facet {
  @include font(
    $weight: light, $size: 0.625rem, $leading: 1.1, $color: $colorWhite);
  position: relative;
  display: inline-block;
  padding: 4px 20px 4px 4px;
  margin: 5px 10px 0 0;
  background-color: $tableFooter;
  border: $colorBorder 1px solid;
  border-radius: 4px;
  .series-remove {
    @include font(
      $weight: bold, $size: 0.9375rem, $leading: 1, $color: $colorWhite);
    position: absolute;
    top: 1px;
    right: 4px;
  }
}

.add-plots-message {
  display: block;
  height: 150px;
  margin: 0;
  line-height: 150px;
  text-align: center;
  border-top: 1px solid #d7d2c4;
}

.row-stats-chart {
  transition-property: opacity, height;
  transition-duration: $defaultDuration;
  transition-timing-function: ease;
  &.ng-enter {
    opacity: 0;
    height: 0;
    overflow: hidden;
    &.ng-enter-active {
      opacity: 1;
      height: 150px;
    }
  }
  &.ng-leave {
    opacity: 1;
    height: 150px;
    overflow: hidden;
    &.ng-leave-active {
      opacity: 0;
      height: 0;
    }
  }
}
