c-pulse-events-list {
  .c-box-meta {
    padding: $gutter-md;
  }
}

ajs-pulse-logs-list {
  .event-log td {
    height: 1rem;
    border: none;
  }

  // Changing to 0 for Angular use as extra padding looks bad.
  .c-box-meta {
    padding: 0;
  }
}
