// NOTE: If working with colors, please leverage the related _*.theme.scss file.

@mixin _chip-tab-offsets {
  margin-left: -$cohesity-card-padding;
  margin-right: -$cohesity-card-padding;
}

@mixin _chip-tab-label {
  @include cog-type-level(h6);
  height: auto;
  min-width: auto;
  opacity: 1;
  padding: 0.75rem 1rem;
}

.mat-tab-list {
  margin-left: 0.5rem;
}

.mat-tab-group {

  .mat-tab-header {
    @include _chip-tab-offsets;
  }

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-label {
    @include _chip-tab-label;
  }

  .mat-tab-body-content {
    margin-top: 0.5rem;
    height: calc(100% - 0.5rem);
  }
}

.mat-tab-nav-bar {
  @include _chip-tab-offsets;

  .mat-ink-bar {
    display: none;
  }

  .mat-tab-link {
    @include _chip-tab-label;
  }
}

.mat-card {
  .mat-tab-group,
  .mat-tab-nav-bar {
    margin-bottom: $cohesity-card-padding;
  }
}

//
// PageToolbar specific stylings
// Including here so PageToolbar can maintain style encapsulation.
//
.mat-toolbar-row.cog-page-nav-row,
.mat-toolbar-row.tall-header-row-content {
  .mat-tab-nav-bar {
    margin: 0 0 0 -1.5rem;
    border-bottom: none;

    .mat-ink-bar {
      display: none;
    }

    .mat-tab-links {
      padding: 0;
    }
  }
}

// PageToolbar and and PageContentHeaderNavigation primary navigation should both
// have slightly larger chip/tab sizes.
.mat-toolbar-row.cog-page-nav-row,
.mat-toolbar-row.tall-header-row-content,
cog-page-content-header-navigation.cog-page-content-header-navaigation-primary {
  .mat-tab-link {
    @include _chip-tab-label;

    // Primary tabs use a slightly enlarged font size.
    @include cog-type-level(h5);
    padding: 0.8Z75rem 1rem;
  }
}

//
// Dashcard sepecific styling
//
.cog-dashcard {
  .mat-tab-list {
    margin-left: 0;
  }

  .mat-tab-group .mat-tab-labels,
  .mat-tab-nav-bar .mat-tab-links {
    padding-left: 0.75rem;
  }

  .mat-tab-group .mat-tab-label,
  .mat-tab-nav-bar .mat-tab-link {
    padding: 0.4rem 0.75rem;
  }

  // Super specificity to overpower the rule set in the  theme file.
  .mat-tab-group .mat-tab-list .mat-tab-label-active::after {
    right: 0.75rem;
    left: 0.75rem;
  }
}

@include reflow() {
  @include reflow-xs() {
    // This allows the built in .mat-tab-nav-bar to
    // display it's left <-> right pagination
    .mat-toolbar-row.cog-page-nav-row,
    .mat-toolbar-row.tall-header-row-content {
      .mat-tab-nav-bar {
        margin-left: 0;
      }
    }
  }
}
