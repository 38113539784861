// Carousel css

.carousel-container {
  padding: 3rem 0;

  > [class*='carousel-'] {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .carousel-navigator {
    align-items: center;
  }

  .slick-slide {
    img {
      width: 100%;
    }
    margin: 0 1rem;
    outline: none;
    cursor: pointer;
  }

  .slick-slide:not(.slick-active){
    img {
      height: 12.75rem;
      margin: 3.5rem 0;
    }
    pointer-events: none;
  }

  %carousel-arrow {
    width: 2.5rem;
    height: 2.5rem;
    position: relative;
    z-index: 10;
    cursor: pointer;
  }

  .arrow-prev {
    @extend %carousel-arrow;
    left: 4.25rem;
  }

  .arrow-next {
    @extend %carousel-arrow;
    right: 4.25rem;
  }
}
