// cEntityTree styles

c-entity-tree {
  display: block;

  .c-entity-tree-node-wrapper{
    [class*="source-icon"] {
      float: left;
      margin-top: 0.6rem;
    }
  }
}

.c-entity-tree {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  .c-entity-tree {
    margin-left: 1.25rem;
  }
}

.c-entity-tree-node-wrapper {
  display: block;
  position: relative;
  right: 0;
  margin: 0;
  padding: 0 0 0 0.9375rem;
  vertical-align: middle;
  .leaves-only > & {
    padding-left: 0;
  }
  .c-ul-inline {
    display: inline-block;
    margin: 0 0.75rem;
    color: $textGreyLightest;
    line-height: 1em;
    vertical-align: middle;
    & > li {
      vertical-align: middle;
      margin-bottom: 0.2rem;
    }
  }
  [class*="source-icon"] {
    float: left;
    margin-top: 0.4rem;
  }
}

input[type="checkbox"].c-entity-tree-node-checkbox.c-entity-tree-node-checkbox {
  float: left;
  margin-top: 1.15rem;
}

c-entity-tree-pub .c-entity-tree-node-wrapper {
  border-top: 0;
}

.c-entity-tree-header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0.625rem;

  .c-form-sleek & .c-input {
    width: 40%;
  }
}

.c-entity-tree-node-text {
  line-height: 1.5rem;
  margin-top: 0.7rem;
  margin-bottom: 0.7rem;
  max-width: 37rem;

  &.auto-truncate {
    vertical-align: middle;
  }
}

.c-entity-tree-node-name {
  width: auto;
  top: 0.375rem;
  position: relative;
}

.c-entity-tree-node-name-meta {
  vertical-align: middle;
}

@include helix-themes() using ($theme) {
  .c-entity-tree-node-wrapper {
    border-top: 1px solid helix-color($theme, foreground, divider);
  }

  .connection-state-problem {
    color: helix-color($theme, status, error);
    opacity: 0.45;
  }
}

.c-entity-tree-node-toggle {
  transform: rotate(-90deg);
  transition: transform $defaultDuration ease;
  display: block;
  width: 0.75rem;
  height: 0.5625rem;
  position: absolute;
  left: 0;
  top: 0.6875rem;
  @include iconImage('icn-arrow-row-toggle.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  &.expanded {
    transform: rotate(0deg);
  }
}

.c-entity-tree-node-meta {
  margin: 0 0.3125rem;
}

.c-entity-tree-node-meta-icons {
  .icn-md {
    vertical-align: middle;
  }
}

.c-entity-tree-cart-actions {
  height: 2.375rem;
  line-height: 2.375rem;
  .icn-md {
    vertical-align: middle;
    margin-top: -0.125rem;
  }
}

.c-entity-tree-node-icon {
  display: inline-block;
  width: 1.625rem;
  height: 1.625rem;
  margin: -0.1875rem 0.3125rem 0 0.125rem;
  vertical-align: middle;
  background-size: contain;
}

.azure-generic {
  @include iconImage('entities/azure.svg');
}

.aws-iamuser {
  @include iconImage('entities/aws.svg');
}

.aws-region {
  @include iconImage('entities/region.svg');
}

.cassandra-cluster {
  @include iconImage('entities/cassandra/object-cluster.svg');
}

.cassandra-keyspace {
  @include iconImage('entities/cassandra/object-keyspace.svg');
}

.cassandra-system-keyspace {
  @include iconImage('entities/cassandra/object-system-keyspace.svg');
}

.cohesity-box-logo {
  @include iconImage('../cohesity-box-logo.svg');
}

.gcp-iamuser {
  @include iconImage('entities/gcp.svg');
}

.gcp-project {
  @include iconImage('entities/project.svg');
}

.kubernetes-namespace {
  @include iconImage('kubernetes-namespace.svg');
}

.object-table {
  @include iconImage('entities/cassandra/object-table.svg');
}

.os-aix {
  @include iconImage('os-aix.svg');
}

.os-hpux {
  @include iconImage('os-hpux.svg');
}

.os-linux {
  @include iconImage('os-linux.svg');
}

.os-solaris {
  @include iconImage('os-solaris.svg');
}

.os-windows {
  @include iconImage('os-windows.svg');
}

.os-unix-cluster {
  @include iconImage('os-unix-cluster.svg');
}

.vcloud-dir {
  @include iconImage('entities/vmware/vcloud-dir.svg');
}

.oracle-rac {
  @include iconImage('entities/oracle/rac.svg');
}

.vcloud-org {
  @include iconImage('entities/vmware/vcloud-org.svg');
}

.vcloud-vapp {
  @include iconImage('entities/vmware/vcloud-vapp.svg');
}

.vmware-vcenter {
  @include iconImage('entities/vmware/vcenter.svg');
}

.vmware-folder {
  @include iconImage('entities/vmware/folder.svg');
}

.datacenter,
.vmware-datacenter {
  @include iconImage('entities/vmware/datacenter.svg');
}

.vmware-compute-resource {
  @include iconImage('entities/vmware/compute-resource.svg');
}

.vmware-cluster-compute-resource {
  @include iconImage('entities/vmware/cluster-compute-resource.svg');
}

.vmware-resource-pool {
  @include iconImage('entities/vmware/resource-pool.svg');
}

.vmware-data-store {
  @include iconImage('entities/vmware/data-store.svg');
}

.vmware-host-system {
  @include iconImage('entities/vmware/host.svg');
}

.vmware-stand-alone-ESX-host {
  @include iconImage('entities/vmware/stand-alone-ESX-host.svg');
}

.vmware-virtual-machine {
  @include iconImage('entities/vmware/vm.svg');
  background-size: 135%;
  background-position: -0.3rem -0.3rem;
}

.vmware-vm-template {
  @include iconImage('entities/vmware/vm-template.svg');
}

.vmware-vapp {
  @include iconImage('entities/vmware/vapp.svg');
}

.vmware-vapp-template {
  @include iconImage('entities/vmware/vapp-template.svg');
}

.vmware-tag {
  @include iconImage('entities/vmware/tag.svg');
}

.object-salesforce {
  @include iconImage('entities/salesforce/object-salesforce.svg');
}

.object-azure-resting {
  @include iconImage('entities/azure/object-azure-resting.svg');
}

.object-azure-subscriptions-resting {
  @include iconImage('entities/azure/object-azure-subscriptions-resting.svg');
}

.object-azure-resource-group-resting {
  @include iconImage('entities/azure/object-azure-resource-group-resting.svg');
}

.object-azure-entra-id {
  @include iconImage('entities/azure/object-azure-entra-id.svg');
}

.object-mongodb-organization {
  @include iconImage('entities/mongodb/organization.svg');
}

.object-mongodb-project {
  @include iconImage('entities/mongodb/project.svg');
}

.object-mongodb-cluster {
  @include iconImage('entities/mongodb/cluster.svg');
}

.object-mongodb-ops-manager {
  @include iconImage('entities/mongodb/ops-manager.svg');
}
