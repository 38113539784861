// built-in reports Styles.

helios-built-in-reports {

  .reports-render-box-container {
    position: relative;

    // Hack to hide the grey background which comes inside the iframe
    // TODO: Set the height and width manually on jReports server.
    &:before {
      position: absolute;
      display: block;
      content: '';
      width: 100%;
      height: 100%;

      // Border width doesn't acknowledge percentages, so using the width of
      // the main-container.
      border-width: 0 56px 0 4px;
      border-color: $colorWhite;
      border-style: solid;
      pointer-events: none;
    }

    .reports-render-box {
      width: 100%;
      height: 83rem;
      display: block;
    }
  }

  .report-spinner {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
  }
}
