// Styles: cSnapshotPicker

$widgetPadding: 1.25rem;
$pitRangeHeight: 0.3125rem !default;
$snapshotsMarkerHeight: $pitRangeHeight * 2.4 !default;
$snapshotsMarkerWidth: $snapshotsMarkerHeight !default;
$snapshotRangeLineHeight: 1px !default;
$snapshotTimelineHeight: 10.625rem;
$highlightTransition: $defaultDuration ease-in;

c-snapshot-picker {
  position: relative;

  .c-snapshot-selector {
    min-height: $cSnapshotSuiteHeight;
    padding: $widgetPadding;
    padding-top: 0;
    position: relative;

    > h2 {
      margin: 0;
      margin-top: -1em;
    }
  }

  .snapshot-details {
    padding: 1em;
    border-radius: 0.25rem;
    transition: $highlightTransition;
  }

  // Container for the list of PIT ranges and Magneto snapshots markers.
  .snapshot-timeline {
    height: $snapshotTimelineHeight;
    display: block;
    margin: 0;
    padding: 0;
    list-style-type: none;
    position: absolute;
    left: $widgetPadding;
    right: $widgetPadding;
  }

  .timeline-tick {
    position: absolute;
    top: 67%;
    left: 0;

    @for $pos from 2 to 6 {
      &:nth-of-type(n + #{$pos}) {
        @if ($pos == 5) {
          left: auto;
          right: 0;
          transform: translateX(0);

          &::before {
            left: auto;
            right: 0;
          }
        } @else {
          left: (100% * 0.25) * ($pos - 1);
          transform: translateX(-50%);
        }
      }
    }
    &:first-of-type {
      &::before {
        left: 0;
      }
    }

    // Tick lines
    &::before {
      content: '';
      border-left: 1px solid currentColor;
      width: 0;
      height: 80%;
      position: absolute;
      bottom: 1.33em;
      left: 50%;
    }
  }

  // PIT range
  .snapshot-pit-range {
    position: absolute;
    top: 50%;
    border-radius: $pitRangeHeight;
    min-width: 0.25rem;
    height: $pitRangeHeight;
    margin: 0;
    padding: 0;
    cursor: pointer;

    // Set this to 1 to raise it above the rzslider
    z-index: 1;
  }

  // Component-specific rzslider overrides. Should be mostly positioning. Global
  // rzslider styles are declared in assets/scss/rzslider-6.6.1.scss
  .rzslider {
    position: relative;
    height: $snapshotTimelineHeight;
    margin: 0;
    padding: 0;
    z-index: 10;

    // The main visible horizontal slider line
    .rz-bar-wrapper {
      padding: 0;

      // This is invisible, but it is the click area for the widget, so we make
      // it big and easy to hit.
      height: 4rem;
      top: calc(50% - 1rem);
      margin: 0;
      width: auto;
      right: $widgetPadding * -0.25;

      &:not([style*="hidden"]) {
        .rz-bar {
          background: transparent;
          height: 1rem;
          width: 100%;
          top: 0.7rem;
          cursor: pointer;

          // This is the baseline of the timeline.
          &::before {
            content: '';
            position: relative;
            display: block;
            border: none;
            width: 100%;
            height: $snapshotRangeLineHeight;
            top: 40%;
          }
        }
      }
    }

    // Time tooltip
    .rz-bubble {
      bottom: auto;
      top: 10%;
      padding: 0.3em 0.4688rem;
      text-align: center;
      min-width: 8.125rem;
      height: 3.1875rem;

      .flex-row > div:not(:first-child) {
        padding-left: 0.4688rem;
        margin-left: 0.4688rem;
      }
    }

    // The list of index ticks
    .rz-ticks {
      top: 50%;

      .rz-tick {
        font-size: $snapshotsMarkerHeight;
        line-height: 1;
        width: 1em;
        height: 1em;
        border-radius: 1em;
        top: -0.25em;
        left: $snapshotsMarkerHeight * -0.25;
      }
      .rz-tick-legend {}
    }

    .rz-pointer {
      top: calc(50% - #{$pitRangeHeight * 0.5});
      font-size: $pitRangeHeight * 2;
      line-height: 1;
      height: 1em;
      width: 1em;
      border: currentColor 1px solid;
      border-radius: 1em;

      // Line to snapshot details (below timeline)
      &::before,
      &::after {
        content: '';
        z-index: 0;
        position: absolute;
        top: 1.25rem;
        left: calc(50% - 10px);

        // Width and background gradient enable a wider surface area for easier
        // user interaction but maintains the hairline display. Can't use
        // ::before or ::after of already ::before and ::after pseudo-elements
        // :(
        width: 19px;
        border: none;

        // This emulates a 1px vertical hairline, like a border. The color
        // changes with the font `color`.
        background: linear-gradient(
          to right,
          transparent,
          transparent 10px,
          currentColor 10px,
          currentColor 11px,
          transparent 11px,
          transparent 19px
        );
        transition: $highlightTransition;
        height: 4.8438rem;
      }

      // Line to time bubble (above timeline)
      &::before {
        top: auto;
        bottom: 1em;
        height: 0.9063rem;
      }

      // This forces a repaint. The background gradient forming the vertical
      // connectors from the slider handle don't change color when they're
      // supposed to.
      &:hover {
        transform: translateZ(10);
      }
    }

    // Drop the disabled slider behind everything else.
    &[disabled] {
      z-index: 0;
      cursor: default;

      .rz-pointer {
        display: none;
      }
    }
  }

  label.label-inline {
    vertical-align: super;
  }

  .recover-button-bottom {
    display: flex;
    justify-content: flex-end;
    padding-right: 0.25rem;
  }
}

.c-snapshot-selector-compact-view {
  // When the order of pagination and recover button is reversed (pagination
  // and then recover button).
  .material-pagination .paginator-range-label {
    margin: 0 0.5rem 0 0;
  }
}

@include helix-themes() using ($theme) {
  $primary-color-400: helix-color($theme, primary, 400);
  $pit-range-color: lighten(helix-color($theme, status, success), 25);
  $divider-color: helix-color($theme, foreground, divider);
  $snapshot-marker-color: helix-color($theme, primary, 500);

  c-snapshot-picker {
    .snapshot-details {
      background: helix-color($theme, background, card);
      border: 1px solid $divider-color;

      &.highlight-full {
        border-color: $primary-color-400;
      }
      &.highlight-pit {
        border-color: $pit-range-color;
      }
    }

    .timeline-tick {
      color: $divider-color;

      // Tick lines
      &::before {
        border-left: 1px solid $divider-color;
      }
    }

    // PIT range
    .snapshot-pit-range {
      background: $pit-range-color;
    }

    // Component-specific rzslider overrides.
    .rzslider {
      // The main visible horizontal slider line
      .rz-bar-wrapper {
        &:not([style*="hidden"]) {
          .rz-bar {
            // This is the baseline of the timeline.
            &::before {
              background: $divider-color;
            }
          }
        }
      }

      // Time tooltip
      .rz-bubble {
        background: helix-color($theme, background, dialog);
        border: 1px solid $divider-color;

        .flex-row > div:not(:first-child) {
          border-left: 1px solid $divider-color;
        }
      }

      // The list of index ticks
      .rz-ticks {
        .rz-tick {
          background: $snapshot-marker-color;
        }
      }

      .rz-pointer {
        color: helix-color($theme, status, success);
        background: helix-contrast($theme, status, success);

        // Line to snapshot details (below timeline)
        &::before,
        &::after {
          color: $primary-color-400;
        }

        // Line to time bubble (above timeline)
        &::before {
          color: $divider-color;
        }
        &::after {
          color: $pit-range-color;
        }
      }

      &.full-snapshot-selected .rz-pointer {
        color: $snapshot-marker-color;

        &::after {
          color: $primary-color-400;
        }
      }
    }
  }

}
