// Overrides for Material List since the UI doesn't use "1rem" font size and
// "normal" font weight as the default values for the font.
.mat-list-option,
.mat-list-item {
  // Angular material applies a "font-size: 1rem;" to the selector
  // ".mat-list-base .mat-list-option" and ".mat-list-base .mat-list-item". The
  // default font size for the UI is not 1rem, but 0.875rem ($body-font-size).
  font-size: inherit !important;

  .mat-list-text > * {
    // Angular material applies a "font-weight: normal; font-size: inherit;"
    // to the selector ".mat-list-base .mat-list-option .mat-list-text>*" and
    // ".mat-list-base .mat-list-item .mat-list-text>*". The default font weight
    // for the UI is not "normal", but 300 ($font-weight-normal).
    font-weight: inherit !important;
  }

  // Don't enforce the default black font color for checkbox.
  .mat-pseudo-checkbox {
    color: inherit;
  }
}
