// styling for connection status component.

.connection-status.show-status {
  position: relative;
  margin-left: 0.9375rem;

  &::before {
    width: 0.3125rem;
    height: 0.3125rem;
    border-radius: 0.3125rem;
    display: inline-flex;
    position: absolute;
    top: 0.5rem;
    left: -0.9375rem;
    content: '';
  }

  &.align-as-column {
    &::before {
      top: 0.3125rem;
    }
  }
}

@include helix-themes() using($theme) {
  .connection-status.show-status {
    &::before {
      background-color: helix-color($theme, status, success);
    }

    &.connection-error::before {
      background-color: helix-color($theme, status, error);
    }

    &.connection-warning::before {
      background-color: helix-color($theme, status, warning);
    }

    &.connection-disabled::before {
      background-color: helix-color($theme, foreground, text);
    }

    > small {
      color: helix-color($theme, foreground, secondary-text);
    }
  }
}
