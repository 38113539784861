// bifrost settings

bifrost-list {
  c-color-dot {
    width: 0.5rem;
    height: 0.5rem;
  }

  .bifrost-proxy-ips {
    width: 300px;
   }
}
