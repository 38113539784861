// cTabs styling + bootstrap tabs normalization

.nav-tabs {
  cursor: default;

  > li {
    a,
    a:hover,
    a:focus {
      border: 0;
      padding: 0.5rem 1rem;
    }
    &.active {
      a,
      a:hover,
      a:focus {
        cursor: pointer;
        background: transparent;
        border: 0;
      }
    }
    &.disabled {
      opacity: 0.7;
    }
  }
}

.c-tabs {
  // Providing an opt-out mechanism for edge cases where the tabs shouldn't
  // bleed into the margins. i.e. reports, as the content area is indepedently
  // scrollable, which means the tabs can extend into the overflow area and
  // be slightly cut-off from view.
  &:not(.standard-width) {
    margin-left: -#{$cohesity-card-padding};
    margin-right: -#{$cohesity-card-padding};

    .nav-tabs > li:first-child {
      margin-left: 0.5rem;
    }
  }

  margin-bottom: 1.5rem;

  .tab-container {
    margin-bottom: 1.5rem;
  }

  .nav-tabs > li a {
    @include cog-type-level(h5);
    height: auto;
    min-width: auto;
    opacity: 1;
    margin: 0;
    padding: 0.75rem 1rem;
    background-color: transparent;
    border-bottom: 0;
    border-radius: 0;
    outline: none;
  }

  // For implementations using the integrated ui-view in c-tabs-new.html, add
  // padding so content maintains correct positioning.
  ui-view {
    display: block;
    padding: 0 $cohesity-card-padding;

    // If for some unfathomable reason a ui-view is nested inside of a ui-view
    // in a legacy .c-tabs implement, then don't duplicate the above padding.
    ui-view {
      padding: 0;
    }
  }
}

@include helix-themes() using ($theme) {
  $active-color: map-get($theme, helix-base, active);
  $primary-color: map-get($theme, helix-base, primary);
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  .c-tabs {
    .nav-tabs {
      border-bottom: 1px solid helix-color($theme, background, card-border);

      > li a {
        color: helix-color($theme, helix-base, resting);

        @if $is-ibm-aqua-theme {
          font-weight: $font-weight-normal;
        }

        &:focus {
          background-color: helix-color($theme, state-secondary, focus);
        }
      }

      > li.active a,
      > li.active a:hover,
      > li.active a:active,
      > li.active a:focus {
        // Special coloring for active page tab
        color: $active-color;

        @if $is-ibm-aqua-theme {
          font-weight: $font-weight-bold;
        }

        &::after {
          content: '';
          display: block;
          overflow: hidden;
          position: absolute;
          left: 1rem;
          bottom: 0;
          right: 1rem;
          height: 2px;

          @if $is-ibm-aqua-theme {
            background-color: $primary-color;
          } @else {
            background-color: $active-color;
          }
        }
      }
    }
  }
}
