
// PARTIAL: login.scss

// -------------------------------
// -- LOGIN OLD ------------------
// -------------------------------
//
// THIS FILES CONTAINS STYLES ONLY APPLIED TO
// THE OLD LOGIN FORMS BEFORE REBRANDING AS
// COHESITY DATA CLOUD
//
// THIS IS BEHIND A FEATURE FLAG AND CAN BE
// DELETED AFTER SUCCESSFUL DEPLOY TO PRODUCTION
//
// -------------------------------
$heliosFooterHeight: 5rem;
$heliosFooterCopyrightHeight: 1rem;
$heliosLoginContentWidth: 29.6875rem;
$datawaveHeight: 25rem;
$loginCboxWidth: 22rem;
$heliosBackground: #FAFAFA;

.login-old {
  .c-login-form {
    margin: 60px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .c-box {
      width: $loginCboxWidth;
      // Make the logout box a bit bigger so text doesn't wrap awkwardly.
      &.logout {
        width: #{$loginCboxWidth + 3rem};
      }
    }
  }
  .sso-login-container {
    padding: 10px 20px;
  }
  .sso-signin-container {
    width: $loginCboxWidth;
    padding: 1.5rem;
  }
  .helios-login {
    @include cog-type-level(body-lg);
    font-weight: $font-weight-light;
    color: $colorBlack;
    background-color: $heliosBackground;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    #salesforce-login {
      width: 20rem;
      height: 15rem;
      margin: 0 auto;
      * {
        @include cog-type-level(body);
      }
    }
    #sfid-inline-content {
      margin-top: 1rem;
    }
    .sfid-button-label {
      display: none;
    }
    .sfid-button {
      background-color: $colorCohesityGreen;
      color: $colorWhite;
    }
    #sfid-submit.sfid-disabled {
      background-color: rgba($colorCohesityGreen, 0.25);
      color: $colorWhite;
    }
    .helios-form-container {
      margin-top: 10%;
    }
    .helios-login-title {
      @include cog-type-level(gb-value-lg);
      white-space: nowrap;
    }
    .helios-login-description {
      @include cog-type-level('display-2');
      white-space: nowrap;
      margin: 1rem 0;
      color: $colorCohesityGreen;
    }
    .helios-blurb {
      margin-top: -0.25rem;
    }
    .helios-logo {
      margin-top: 10%;
    }
    // Cohesity Employee login is not a valid Helios authentication.
    .sfid-small, div#sfid-social {
      display: none;
    }
  }
  .sso-link {
    display: block;
    @include cog-type-level('body-1');
    text-decoration: none;
  }
  .helios-login-form {
    display: inline-block;
    height: 100%;
    width: 20rem;
    fieldset {
      border: none;
      margin-top: 1rem;
      padding: 0;
    }
    input:not([type=checkbox]) {
      display: block;
      height: 3rem;
      opacity: 0.5;
      padding: 0.125rem 0.5rem;
      width: 100%;
    }
    input:focus {
      opacity: 1;
    }
    .c-btn {
      background-color: $colorCohesityGreen;
      border: 0;
      border-radius: 0.3rem;
      color: $colorWhite;
      display: block;
      margin-top: 1rem;
      padding: 0.25rem 0;
      width: 100%;
      &.sso-button {
        color: $colorPrimary;
        background: transparent;
        border: 0;
        margin-left: 0;
        padding: 0;
        margin-top: 0.5rem;
      }
    }
    .signup-container {
      display: flex;
      flex-direction: row;
      flex: 1 1 auto;
      vertical-align: middle;
      line-height: 3rem;
      justify-content: space-around;
      button {
        display: flex;
        width: auto;
      }
    }
  }
  .helios-login-footer {
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); // centers the div
  }
  .login-banner {
    white-space: pre-wrap;
    max-height: 10rem;
    overflow-y: scroll;
    // custom slim scrollbar.
    @include c-scrollbar();
  }
  #salesforce-login {
    #sfid-forgot-password {
      color: helix-color($jazz-theme-light, primary, 500);
    }
    #sfid-username, #sfid-password {
      border: 1px solid helix-color($jazz-theme-light, foreground, divider);
      background: helix-color($jazz-theme-light, background, background);
    }
    #sfid-forgot-password {
      width: 100%;
      text-align: center;
    }
  }
  .login-options {
    margin: 2rem 0;
    .login-options-separator {
      width: 100%;
      text-align: center;
      border-bottom: 0.1rem solid helix-contrast($jazz-theme-light, helix-primary, 400);;
      line-height: 0.1rem;
      margin: 0 0 2rem;
      .login-options-text {
        background: $heliosBackground;
        padding: 1rem;
      }
    }
    .login-options-back {
      float: left;
    }
    .login-button-sets {
      font-size: smaller;
      display: flex;
      justify-content: center;
      color: $colorGrey;
      .login-option {
        flex-direction: column;
        display: flex;
        padding: 1rem;
      }
      button {
        border: 1px solid helix-color($jazz-theme-light, background, card-border);
        border-radius: 8px;
        background: transparent;
        &:hover {
          box-shadow: 0 0 0.125rem 0.0625rem helix-color($jazz-theme-light, background, selected-button);
        }
      }
    }
  }
}

.ibm-aqua-logout {
  background-size: cover;
  background: transparent url(/assets/i/ibm-aqua-logout.jpg) no-repeat;
  display: flex;
  height: calc(100vh - #{$cohesity-app-bar-height});

  .content {
    display: flex;
    flex-direction: column;
    flex: 0 0 20%;

    .heading,
    .copyright-notice {
      display: flex;
      flex: 1;
    }

    .heading {
      align-items: center;
    }

    .copyright-notice {
      align-items: flex-end;
    }
  }
}

.ibm-baas-logout {
  height: calc(100vh - #{$cohesity-app-bar-height});

  .content {
    margin: 30vh auto 0 auto;
    width: fit-content;

    .c-btn {
      margin-top: 3rem;
    }

    img {
      width: 48px;
      height: 48px;
      margin-bottom: .25rem;
    }
  }
}

@include helix-themes() using ($theme) {
  .ibm-aqua-logout {
    .content {
      .heading,
      .copyright-notice {
        color: helix-contrast($theme, primary, 500);
      }
    }
  }
}
