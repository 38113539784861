// Component: Hypervisor VM tab in Test & Dev

.vm-status-table {
  .status-type-filter {
    min-width: 15.625rem;
  }

  .hypervisor-vm-status {
    width: 0.5em;
    height: 0.5em;
  }

  a.disabled {
    @include disabledProperties();
  }
}

.kuiper-vm-status-popover {
  width: 27em;
  height: 25em;

  .kuiper-vm-loading {
    height: 17em;
    justify-content: center;
  }
}

.novnc-iframe-container {
  justify-content: center;
  width: 100%;
  height: 100%;

  .novnc-render-box {
    width: 64rem;
    height: 48rem;
  }
}