// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-select.scss).

@mixin cog-mat-select-theme($theme) {
  .mat-select-value {
    color: helix-color($theme, helix-base, active);
  }

  .mat-option {
    &:hover:not(.mat-option-disabled) {
      background-color: helix-color($theme, state-secondary, hover);
    }

    &.mat-active,
    &:focus:not(.mat-option-disabled) {
      background-color: helix-color($theme, state-secondary, focus);
    }
  }

  .mat-option.sticky-option {
    background-color: helix-color($theme, background, card);

    &.top {
      border-bottom: 1px solid helix-color($theme, background, card-border);
    }

    &.bottom {
      border-top: 1px solid helix-color($theme, background, card-border);
    }

    &:focus, &:hover {
      background-color: helix-color($theme, background, card);
    }
  }
}
