// NOTE: this file should only address color changes. If making structural changes to a component
@use "@angular/material/core/theming/theming" as mat;
// please put them in a separate file (i.e mat-card.scss).

@mixin cog-mat-calendar-theme($theme) {
  $primary: map-get($theme, primary);

  // Default MAT styling is to use secondary color for active calendar
  // options. Switching it to primary color.
  .mat-calendar-body-cell-content,
  .mat-date-range-input-separator {
    color: mat.get-color-from-palette($primary, 500);
  }

  .mat-calendar-body-selected {
    background-color: mat.get-color-from-palette($primary, 500);
    color: mat.get-contrast-color-from-palette($primary, 500);
  }
}
