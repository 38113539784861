// COMPONENT: cMessage popup & inline styles.
@use "@angular/material/core/style/elevation" as mat;
@use 'sass:map';

// Mapping is c-message class name to cohesity-"color" from Helix.
$c-message-status-themes: (
  "success": success,
  "error": critical,
  "warn": warning,
);

$cMessageRadius: 0.75rem;
$cMessageMaxWidth: 35.9375rem;
$cMessageInlinePaddingY: 0.875rem;
$cMessageInlinePaddingX: 1.125rem;
$iconMessagePaddingLeft: 2.25rem;
$iconMessageIconLeft: 0.0625rem;
$iconMessageIconTop: -0.25rem;

$cMessageModalPadding: 0.9375rem;

.modal.c-message-window {
  $break-point: $min-page-width + $cohesity-drawer-width;

  @media (min-width: $break-point) {
    margin-left: $cohesity-drawer-width;
  }

  top: auto;
  right: auto;
  bottom: 0;
  left: 0;

  // These two width values copied from Angular Material to match behavior.
  max-width: 33vw;
  min-width: 344px;

  // Pixel values used to match Angular material implementation.
  padding: 14px 16px;

  .modal-dialog {
    margin: 0 auto;
  }

  .c-message {
    border: none;
    border-radius: 0.25rem;
    min-height: 3rem;

    padding: 0.5rem 2rem 0.5rem 1rem;

    // In order to emulate Angular Material snackbars, top-bar is no longer used.
    .top-bar {
      display: none;
    }

    .icon-message {
      @include cog-type-level(body-1);

      .user-data {
        display: flex;
        align-items: center;

        // .c-message min-height minus its top and bottom margin.
        min-height: 2rem;

        .message-container {
          width: 100%;
        }
      }

      &.warn,
      &.error {
        padding: 0 0 0 3.25rem;
      }

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: -0.5rem;
        bottom: -0.5rem;
        left: -1rem;
        width: 3rem;
        border-top-left-radius: 0.25rem;
        border-bottom-left-radius: 0.25rem;
      }

      &::after {
        top: 50%;
        left: -0.5rem;
        transform: translateY(-50%);
        font-size: 2rem;
      }

      &.warn {
        &::after {
          @include iconImage('cmess-warn-snack.svg');

          .cog-dark-theme & {
            @include iconImage('cmess-warn-snack.svg', $iconDarkBasePath);
          }
        }
      }

      &.error {
        &::after {
          @include iconImage('cmess-error-snack.svg');

          .cog-dark-theme & {
            @include iconImage('cmess-error-snack.svg', $iconDarkBasePath);
          }
        }
      }

      &:not(.warn):not(.error) {
        padding-left: 0;

        &::after {
          display: none;
        }
      }
    }

    // Adding additional c-message class to overpower the specificity of the
    // various status based implementations.
    &.c-message {
      .icon-message {
        color: initial;
      }

      .user-data {
        display: flex;
        align-items: center;

        // .c-message min-height minus its top and bottom margin.
        min-height: 2rem;
      }

      .icon-message-title {
        @include cog-type-level(body-2);
        color: currentColor;
        margin: 0 0.5rem 0 0;
      }
    }
  }

  .c-message-action-buttons {
    text-align: right;

    // Pull the buttons into the padding area of the cmessage to get more of
    // a dialog layout.
    margin-right: -1.5rem;

    [class*=c-btn] + [class*=c-btn] {
      // Tighten the margin between these button since they are small variants.
      margin-left: 0.5rem;
    }
  }
}

// Stacking rules
.modal.c-message-window ~ .modal.c-message-window {
  // Hiding additional cMessages. Generally, they get auto closed, but if they
  // have an action we want to maintain them and hide them while the additional
  // message is being displayed.
  visibility: hidden;
}

@include helix-themes using ($theme) {
  $is-dark-theme: map-get($theme, is-dark);

  .modal.c-message-window {
    .c-message {
      $foreground: map.get($theme, foreground);
      $elevation-color: map.get($foreground, elevation);
      $elevation-color-or-default: if($elevation-color == null, elevation.$color, $elevation-color);
      @include mat.elevation(6, $elevation-color-or-default, 1);
      background: if($is-dark-theme, #fafafa, #323232);

      .icon-message {
        .user-data {
          color: if($is-dark-theme, rgba(0, 0, 0, 0.87), rgba(255, 255, 255, 0.7));
        }

        &.warn::before {
          background-color: helix-color($theme, status-background, warning-emphasis);
        }

        &.error::before {
          background-color: helix-color($theme, status-background, critical-emphasis);
        }
      }
    }
  }
}

/////////////////////////////////////////////////////////
// icon message
//

.icon-message {
  max-width: $max-page-width;
  padding: 0 0 0 $iconMessagePaddingLeft;
  position: relative;

  &::after {
    @include iconImage('cmess-info.svg');
    position: absolute;
    top: $iconMessageIconTop;
    left: $iconMessageIconLeft;
    content: '';
    display: block;
    font-size: 1.5rem;
    width: 1em;
    height: 1em;
    background-repeat: no-repeat;
    background-size: 100%;

    .cog-dark-theme & {
      @include iconImage('cmess-info.svg', $iconDarkBasePath);
    }
  }
  h6,
  .icon-message-title {
    font-weight: $font-weight-bold;
    margin-bottom: 0.1875rem;
  }

  @each $class, $color in $c-message-status-themes {
    &.#{$class}::after {
      @include iconImage('cmess-#{$class}.svg');

      .cog-dark-theme & {
        @include iconImage('cmess-#{$class}.svg', $iconDarkBasePath);
      }
    }
  }

  &.xs::after {
    font-size: 0.9375rem;
  }

  &.md::after {
    font-size: 1.25rem;
  }

  &.lg::after {
    font-size: 1.75rem;
  }
}

@include helix-themes() using ($theme) {
  .icon-message {
    color: helix-color($theme, foreground, text);

    h6,
    .icon-message-title {
      color: helix-color($theme, status, info);
    }

    @each $class, $color in $c-message-status-themes {
      &.#{$class} {
        h6,
        .icon-message-title {
          color: helix-color($theme, status, $color);
        }
      }
    }
  }
}

/////////////////////////////////////////////////////////
// c-message
//

.c-message {
  max-width: $cMessageMaxWidth;
  position: relative;
  margin: 0 auto;
  padding: 1rem 2.8125rem 1.125rem 0.5625rem;
  background: $colorWhite;

  border-width: 1px;
  border-style: solid;
  border-radius: $cMessageRadius;

  // top bar is used for a thicker border and progress bar
  //  when a timeout is set for the cMessage
  .top-bar {
    position: absolute;
    top: 0;

    // anchor right for countdown-bar animation
    right: 0;
    width: 100%;
    height: 0.4375rem;
    border-top-left-radius: $cMessageRadius;
    border-top-right-radius: $cMessageRadius;
    overflow: hidden;
    > div {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
    &.countdown-shadow {
      opacity: 0.6;
    }
    &.countdown-bar {
      transition-property: width;

      // transition duration is added via JavaScript based on timeout value
      transition-timing-function: ease-out;
      opacity: 0.7;

      // need to add this class via timeout so animation will happen
      &.countdown-active {
        width: 0%;
        cursor: pointer;
      }
    }
  }

  // c-message specific icon-message customizations
  .icon-message-text {
    // Some error messages are ridiculously long, so these two properties limit
    // the screen takeover and retain readability of the message. Most errors
    // will never trip the max-height threshold.
    max-height: 15.625rem;
    overflow-y: auto;
  }
}

@media screen and (max-width: $cMessageMaxWidth + 1.875rem) {
  // when the viewport is less than our max width (plus some extra to compensate
  // for the overflow close icon) lets go from auto margin to 0.9375rem.
  .c-message {
    margin: 0 0.9375rem;
  }
}

.c-message-close {
  transition: background-color $defaultDuration ease;

  // rotate element so '+' looks like an 'x'
  transform: rotate(45deg);
  position: absolute;
  display: block;
  top: 0.375rem;
  right: 0.25rem;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  text-align: center;

  &:hover,
  &:focus {
    text-decoration: none;
  }
  &::after {
    font-size: 1.375rem;
    line-height: 1.18;
    position: relative;
    top: -0.1875rem;
    content: '+';
    z-index: 1;
  }
}

@include helix-themes() using ($theme) {
  $info-color: helix-color($theme, status, info);
  $success-color: helix-color($theme, status, success);
  $warning-color: helix-color($theme, status, warning);
  $critical-color: helix-color($theme, status, error);
  $secondary-text-color: helix-color($theme, foreground, secondary-text);
  $dialog-color: helix-color($theme, background, dialog);
  $shadow-color: helix-color($theme, background, card);

  .c-message {
    background: $dialog-color;
    border-color: $info-color;
    box-shadow: 0 0.375rem 0.75rem rgba($shadow-color, 0.15);

    // top bar is used for a thicker border and progress bar
    //  when a timeout is set for the cMessage
    .top-bar {
      > div {
        background-color: $info-color;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }

    // cMessage flavors: info, error, success, warn
    &.info {
      border-color: $info-color;
      .top-bar > div {
        background-color: $info-color;
      }
    }
    &.warn {
      border-color: $warning-color;
      .top-bar > div {
        background-color: $warning-color;
      }
    }
    &.error {
      border-color: $critical-color;
      .top-bar > div {
        background-color: $critical-color;
      }
    }
    &.success {
      border-color: $success-color;
      .top-bar > div {
        background-color: $success-color;
      }
    }
  }

  .c-message-close {
    &:hover,
    &:focus {
      background-color: $secondary-text-color;
      text-decoration: none;
    }
    &::after {
      color: $dialog-color;
    }
  }
}

/////////////////////////////////////////////////////////
// c message inline
//

// Mixin that produces c-message-inline error, success, and warn color overrides
@mixin c-message-inline-type($color: $colorPrimary, $matchingTextColors: true) {
  @if $matchingTextColors {
    h1, h2, h3, h4, h5, h6,
    strong {
      color: $color;
    }

    a {
      color: $color;
      text-decoration: underline;
      &:hover,
      &:focus,
      &:active {
        color: rgba($color, 0.85);
        text-decoration: none;
      }
    }
  }
}

// default c-message-inline styling is "info", colorPrimary,
// this color can be overridden by adding error, warn, or success class
[class*="c-message-inline"] {
  box-shadow: none;
  border-radius: 0;
  padding: $cMessageInlinePaddingY $cMessageInlinePaddingX;
  margin-bottom: 1.25rem;
  background-color: $colorWhite;
  overflow: hidden;
  word-wrap: break-word;

  // Ensure a consistent presentation of title treatments within this layout.
  // Many of these implementations use h6, and the switch to our mat typography
  // font treatments made these too small.
  h1, h2, h3, h4, h5, h6 {
    @include cog-type-level(body-1);
  }

  &.c-message-inline-compact {
    padding: 0.625rem;
    font-size: 0.75rem;
  }

  &.icon-message {
    padding-left: $iconMessagePaddingLeft + $cMessageInlinePaddingX;
    &::after {
      top: $iconMessageIconTop + $cMessageInlinePaddingY;
      left: $iconMessageIconLeft + $cMessageInlinePaddingX;
    }

    // need additional space for the icon in c-message-inline-compact
    &.c-message-inline-compact {
      padding-top: 0.9375rem;
      padding-bottom: 0.9375rem;
      &::after {
        top: $iconMessageIconTop + 0.875rem;
      }
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  p {
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

@include helix-themes() using ($theme) {
  [class*="c-message-inline"] {
    @include c-message-inline-type(helix-color($theme, status, info));
    color: helix-color($theme, foreground, secondary-text);
    background-color: helix-color($theme, status-background, info);

    &.error {
      @include c-message-inline-type(helix-color($theme, status, error));
      background-color: helix-color($theme, status-background, critical);
    }
    &.success {
      @include c-message-inline-type(helix-color($theme, status, success));
      background-color: helix-color($theme, status-background, success);
    }
    &.warn {
      @include c-message-inline-type(helix-color($theme, status, warning));
      background-color: helix-color($theme, status-background, warning);
    }
  }
}
