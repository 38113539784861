license-server {
  .license-page {
    display: block;
    .no-border {
      border: none;
      border-radius: 0;
    }

    .cluster-download-link {
      display: flex;
      justify-content: space-between;
    }
  }

  license-usage-widget {
    .flex-row-sm {
      justify-content: space-between;
    }
    .meter-bar {
      line-height: 0.75;
    }
    .sku-details {
      td {
        height: 2rem;
        padding: 0 0;
        text-align: left;
      }
      th {
        padding: 0 0;
        text-align: center;
        font-size: 0.8rem;
        height: 1.5rem;
      }
    }
    .feature-name {
      white-space: nowrap;
      max-width: 27rem;
      overflow: hidden;
      text-overflow:ellipsis;
      display: block;

      &:hover {
        overflow: visible;
      }
    }
    .percentage-indicator {
      align-self: center;
    }
    .usage-bar {
      width: 1.5rem;
      border-radius: 8%;
      height: 6rem;

      .remaining {
        max-height: 100%;
        border-radius: inherit;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
    .usage-indicator {
      display: flex;
      flex-direction: column-reverse;
      justify-content: space-between;
    }
  }

  widgets-layout {
    padding: 0;
    widget-wrapper {
      &.c-box &.sm {
        padding-left: 0;
        padding-right: 0;
      }

      .c-btn-subtle {
        position: relative;
      }

      &.has-child {
        .widget-connector {
          height: 2.7rem;
        }
      }
    }
  }

  widget-wrapper .c-btn-subtle {
    position: relative;
  }

  cluster-wise-detail {
    .graph-text-container {
      .text {
        flex-basis: 30%;
        display: inherit;
        text-align: center;
        justify-content: center;
        align-items: center;
        @include font($weight: light);
        .value {
          @include font($weight: normal, $size: 1.75rem);
          margin: .5rem 0;
        }
      }
      .graph {
        display: inherit;
        justify-content: center;
        flex-basis: 65%;
      }
    }
  }
}

.license-page-modal {
  button.close {
    position: absolute;
    right: 1.5rem;
    z-index: 2;
  }
}

.license-key-modal {
  .license-key-box {
    text-align: center;
  }
  .copy-text {
    margin-top: -0.7rem;
  }
  .c-form-sleek input[type='text'] {
    width: 40%;
  }
}

@include helix-themes() using ($theme) {
  $critical-color: helix-color($theme, status, error);
  $neutral-color: helix-color($theme, status, unknown);
  $success-color: helix-color($theme, status, success);
  $warning-color: helix-color($theme, status, warning);

  license-server {
    license-usage-widget {
      .usage-bar {
        background-color: $success-color;

        .remaining {
          background-color: $neutral-color;
        }

        &.all-green {
          .remaining {
            background-color: $success-color;
          }
        }

        &.over-used {
          background-color: $warning-color;
          .remaining {
            background-color: $critical-color;
          }
        }

        &.no-license {
          .remaining {
            background-color: $critical-color;
          }

          &.no-license-no-usage {
            .remaining {
              background-color: $neutral-color;
            }
          }
        }
      }
    }

    widget-wrapper .c-btn-subtle {
      position: relative;
    }

    cluster-wise-detail {
      hr {
        border-top: solid 0.0625rem helix-color($theme, foreground, divider);
      }
      .graph-text-container .text .usage-warning .value {
        color: $critical-color;
      }
    }
  }
}
