c-chart {
  display: block;
  position: relative;
}

.c-chart {
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: $easeOutExpo;
  opacity: 0;
  // we don't want the chart visible while chart is loading but as soon as the
  // loading graphic starts animating out, begin animating the chart in
  // this is necessary as the loading div now has a transparent background
  .no-data.chart-loading + & {
    opacity: 0;
    .no-data.chart-loading + & {
      opacity: 1;
    }
  }
  // if no-results div is present,
  // we don't want the chart to be visible
  // this is necessary as the loading div now has a transparent background
  .no-data.no-results + &.rendered {
    opacity: 0;
  }
  &.rendered {
    opacity: 1;
  }
}

c-chart {
  .no-data {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    &.ng-leave {
      // only animate the exit. enter should happen
      // immediately to avoid flashing the chart
      // when possibly transitioning from
      // no-data state to loading state
      transition-property: all;
      transition-duration: $angularAnimationDuration;
      transition-timing-function: ease;
      opacity: 1;
    }
    &.ng-leave-active {
      opacity: 0;
    }
    &.ng-enter {
      opacity: 0;
    }
    &.ng-enter.ng-enter-active {
      opacity: 1;
    }
    &.chart-loading {
      background-color: transparent;
      border: 1px solid transparent;
      c-spinner {
        position: relative;
      }
    }
    &.no-results {
      background-color: transparent;
      border: 1px solid $colorBorder;
      padding: 0;

      div {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
      }
    }
  }
}

.c-chart-date-range {
  position: relative;
  top: 2px;
  display: inline-block;
  margin-right: 6px;
  line-height: 40px;
}

//
// donut chart
//
.donut-chart-title-count {
  margin-top: 22px;
  font-size: 35px;
  line-height: 35px;
  text-align: left;
}

.donut-chart-label {
  @include pie-clearfix;
  @include font($weight: normal);
  width: 175px;
  padding: 7px 12px;
  border-radius: 5px;
  .donut-chart-jobs {
    float: right;
  }
  &:hover {
    color: #fff;
  }
}

.legend-count {
  @include font($weight: normal);
  color: #bbb;
  padding-left: 7px;
}

//
// pie chart
//
.pie-chart-title-count {
  margin-top: 15px;
  font-size: 60px;
  line-height: 60px;
  text-align: right;
}

.pie-chart-label {
  @include pie-clearfix;
  @include font($weight: normal);
  width: 400px;
  padding: 7px 12px;
  border-radius: 5px;
  .pie-chart-jobs {
    float: right;
  }
  &:hover {
    color: #fff;
  }
}

//
// redux chart
//
// these chart-labels classes are applied in cCharts.js reduxLabelEvaluation()
// based upon the number of items in the series
// not currently doing it, but we could also use this to reduce font size and cram more labels
//
.chart-labels-all {
  // nothing to do here, all labels show by default
}

.chart-labels-step-2 {
  .highcharts-xaxis-labels text {
    display: none;
  }
  .highcharts-xaxis-labels text:nth-of-type(2n) {
    display: block;
  }
}

.chart-labels-step-3 {
  .highcharts-xaxis-labels text {
    display: none;
  }
  .highcharts-xaxis-labels text:nth-of-type(3n) {
    display: block;
  }
}

.chart-labels-none {
  .highcharts-xaxis-labels text {
    display: none;
  }
}

// line_prediction
.highcharts-plotline-dot {
  border-radius: 0.3125rem;
  background: $colorPrimary;
  padding: 0.25rem;
  width: 2.5rem;
  height: 1.25rem;
  color: $colorWhite;
}

@include helix-themes() using ($theme) {
  .c-chart {
    .highcharts-title {
      // highcharts assigns this inline, thus !important to override it.
      // This is seen on the MS SQL dashboard. in the donut chart with text based
      // % in the donut hole.
      color: helix-color($theme, foreground, secondary-text) !important;
    }
  }
}
