// Styles: Roles Management

.c-role-privileges {
  .bottom-rule {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .btn-group {
    float: left;
  }
  .arrow-left {
    float: left;
    width: 10px;
    margin-top: 10px;
    padding-left: 5px;
    border-bottom: 10px solid transparent;
    border-top: 10px solid transparent;
  }
  .privs-group {
    float: left;
    padding: 10px;
    width: 84%;
  }
  .privs-group fieldset {
    margin-bottom: 0;
  }
}

@include helix-themes() using ($theme) {
  .c-role-privileges {
    .bottom-rule {
      border-bottom: 1px solid helix-color($theme, component, nav-active);
    }
    .arrow-left {
      border-right: 10px solid helix-color($theme, component, nav-active);
    }
    .privs-group {
      background-color: helix-color($theme, component, nav-active);
    }
  }
}
