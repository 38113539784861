// Component Styles: clipboardCta

.clipboard-content-preview {
  padding: $shutterPanelVerticalPad $shutterPanelHorizontalPad;
  background-color: $drawerBackground;
  border: 0.0625rem solid $drawerBorder;
  border-left: 0;
  border-right: 0;
  font-size: 0.75rem;
  color: $textGreyLight;
}

.copy-to-clipboard,
.download-text {
  font-size: 0.75rem;
}

.clipboard-cta-reverse {
  display: flex;
  flex-direction: column-reverse;
}
