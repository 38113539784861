// Loading style inspired by cohesity.com visuals.
// Some variable values marked as comment to avoid importing common-imports
// for performance considerations.

.app-loading {
  transition-property: opacity, height;

  // $angularAnimationDuration
  transition-duration: 0.5s;

  // $easeInOutCirc, $easeOutSine
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86), cubic-bezier(0.445, 0.05, 0.55, 0.95);
  opacity: 1;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;

  // Matches light theme masthead.
  // NOTE: This can't be themed because themes require body class to be
  // updated with theme class name, and that happens AFTER this splash screen appears
  // so this color is displayed to the user and then after a couple seconds
  // angular updates the body class and the theme changes this background color
  background-color: #1D4963;
  z-index: 10000;
  overflow: hidden;

  // The spinner and logo will disappear independently and quickly, as they busy
  // things up if they are allowed to linger throughout the entire transition.
  .icn-logo-s,
  .loading-orbit {
    opacity: 1;
    transition: opacity 0.15s ease;
  }

  &.ng-hide-add-active {
    opacity: 0;

    // $cohesity-app-bar-height
    height: 3.125rem;
    .icn-logo-s,
    .c-spinner {
      opacity: 0;
    }
  }

  .icn-logo-s {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 160px;
    height: 160px;
    margin: -80px;
  }

  .loading-orbit {
    display: block;
    position: absolute;
    top: calc(50% - 110px);
    left: calc(50% - 110px);
    height: 220px;
    width: 220px;
    transition: all 0.09s ease-in;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    animation-name: orbit;
    animation-duration: 6s;

    &::before {
      content: '';
      display: block;
      position: absolute;
      opacity: 0.25;
      border: 2px solid #fff;
      top: 50%;
      left: 50%;
      height: 200px;
      width: 200px;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 12px;
      height: 12px;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin-top: -104.5px;
      background-color: #67bf1b;
      border-radius: 50%;
      overflow: hidden;
    }
  }
}

.generic-loading {
  transition-property: opacity, height;

  // $angularAnimationDuration
  transition-duration: 0.5s;

  // $easeInOutCirc, $easeOutSine
  transition-timing-function: cubic-bezier(0.785, 0.135, 0.15, 0.86), cubic-bezier(0.445, 0.05, 0.55, 0.95);
  opacity: 1;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;

  &.ng-hide-add-active {
    opacity: 0;
  }

  .loader {
    position: absolute;
    top: calc(50% - 1rem);
    left: calc(50% - 1rem);

    width: 2rem;
    height: 2rem;
    border: 0.25rem solid;
    border-color: #D9D9D9;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: orbit 1s linear infinite;
    opacity: 1;
    transition: opacity 1s ease;
  }

  .ibm-aqua-loader {
    top: calc(50% - 6.875rem);
    left: calc(50% - 6.875rem);

    width: 13.75rem;
    height: 13.75rem;
    border: 0.75rem solid;
    border-color: #0f62fe;
    border-bottom-color: transparent;
  }
}

@keyframes orbit {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
