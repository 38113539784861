@import "common-imports";

// Table NG Styles

// ref: mixin from helix/scss/table
// handles on hover cell to show contents
@include table-show-on-hover-cell('td', 'coh-nav-item-menu');

.c-table {
  width: 100%;

  // Edge-to-edge styling rules to match NG layout. Opt out by adding
  // `standard-width` class to the table. Additional classes added to
  // maintain previous styling in odd circumstances.
  &:not(.standard-width):not(.c-meta-table) {
    margin: 0 -#{$helix-table-bleed};

    // Direct descendent selector used to ensure nested tables aren't getting
    // edge-to-edge styling accidentally.
    > tbody td:first-child,
    > tr td:first-child,
    > thead th:first-child,
    > thead th:first-child {
      padding-left: $helix-table-bleed;
    }

    // Direct descendent selector used to ensure nested tables aren't getting
    // edge-to-edge styling accidentally.
    > tbody td:last-child,
    > tr td:last-child,
    > thead th:last-child,
    > tr th:last-child {
      padding-right: $helix-table-bleed
    }
  }

  clear: both;
  // our normalizers set this to collapsed, causing some browsers to generate
  // rendering artifacts. lets put it back to the default.
  border-collapse: separate;

  &.c-table-sm {
    td {
      padding-top: 0.3125rem;
      padding-bottom: 0.3125rem;
    }
  }

  &.c-table-xs {
    td {
      height: auto;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem;
    }
  }

  // If a table is included in the context of a form, treat it like a fieldset.
  .c-form & {
    margin-bottom: $fieldsetSpacing;
  }

  th,
  td {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }

  thead {
    width: 100%;

    tr {
      // $mat-header-row-height from Angular Material, but not exposed.
      height: 56px;
    }
  }

  th {
    // prevent unintended text highlighting when clicking on sort arrows
    @include user-select(none);
    @include cog-type-level('body-2');
  }

  tbody {
    width: 100%;

    // If a table starts with a <tbody> (no <thead>) add a border to the top
    // of the first row of table cells.
    &:first-child > tr:first-child > td {
      border-top: 1px solid $drawerBorder;
    }
  }

  tbody.tbody-row tr:last-child td {
    border-bottom: 1px solid $drawerBorder;
  }

  tbody.tbody-row:last-child tr:last-child td,
  tr:last-child td {
    border-bottom: none;
  }

  tr:hover > td:not(.no-results) {
    transition: background-color 0s linear;
  }

  td {
    padding: 0.625rem 0.3125rem;
    // for tables, height behaves like min-height & min-height property is ignored
    height: 2.8125rem;
    word-wrap: break-word;

    &.no-padding {
      padding: 0;
    }

    &.icn-status {
      text-align: center;
    }

    &.no-wrap {
      white-space: nowrap;
    }

    &.sml {
      width: 9.375rem;
    }

    &.actions {
      text-align: right;
    }

    .msg {
      margin-bottom: 0.4375rem;
    }

    .row-title {
      display: block;
    }

    .row-meta {
      @include font($size: 0.75rem, $leading: 1);
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  tr {
    &.forbidden {
      // Disabled-like overlay for forbidden rows
      @include user-select(none);

      td {
        position: relative;

        // This effectively disables interaction with the row
        &::after {
          @include user-select(none);
          position: absolute;
          background: rgba(white, 0.25);
          content: '';
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
        }
      }
    }

    &.c-row-select {
      @include user-select(none);
      cursor: pointer;

      &.st-selected td {
        background-color: $tableSelectedRow;
      }
      input[type=checkbox] {
        cursor: pointer;
      }
    }

    &,
    &:last-child {
      td.no-results {
        @include font($size: 1.125rem);
        text-align: center;
        padding: 2.5rem 1.25rem;
        border-bottom: none;
      }
    }
  }

  // overrides for form elements included inside tables
  .c-form & {
    label {
      margin-bottom: 0;
    }

    fieldset {
      margin: 0;
    }

    .c-input:not(.c-date-input) {
      padding: 0.1875rem 0.3125rem;
    }

    input[type="radio"],
    input[type="checkbox"] {
      margin: 0;
    }
  }

  th {
    padding-left: 0.3125rem;
    padding-right: 0.3125rem;
    height: 2.5rem;

    &[st-sort] {
      padding-right: 1.625rem;
    }
  }

  // Special table layout for grid of options instead of tabular data.
  &.tic-tac-toe {
    // This padding deviation ensures the perceived table padding is same all
    // around since the <th> has a lot of white space due to default height.
    padding: .5rem 1rem 1rem 1rem;
    width: auto;

    th {
      padding: 0 1rem;
      text-align: center;
    }

    tr {
      td {
        text-align: center;
        height: inherit;
        width: inherit;
      }
      td:last-child {
        border-right: none;
      }
    }
  }

  // Matrix of health-check statuses
  &.pass-fail-matrix {
    th:last-child,
    td:last-child {
      max-width: 4rem;
      width: 4rem;
      text-align: center;
    }
  }
}

// Alternate table style (extends base c-table) for use with repeating tbody
// tags.
.c-table.alt-meta-rows {
  tbody {
    td,
    tr:last-child td,
    tr:nth-child(even) td {
      border-bottom: none;
      background: transparent;
    }
  }

  .tr-meta {
    &:nth-child(even) td,
    td {
      padding: 0 0.625rem;
      border-top: none;

      .c-meta-table {
        width: calc(100% - 1.875rem);
        margin-bottom: 0.625rem;

        th, td {
          @include font($size: 0.75rem, $leading: 1);
        }
        td {
          padding: 0.3125rem 0 0 0;
        }
      }
    }
  }
}

// sortable column headers
th[st-sort],
[st-sort] {
  transition: background-color $defaultDuration ease;
  position: relative;
  cursor: pointer;

  // override bootstrap's styling `th[class*="col-"]` which is applying
  // position static
  &[class*="col-"] {
    position: relative;
  }

  // Base styles for the sort controls
  &::after {
    @include iconImage('sort-desc-arrow.svg');
    transition: opacity $defaultDuration ease;
    content: '';
    height: 0.7925rem;
    width: 0.7925rem;
    position: absolute;
    margin-left: 0.5rem;
    top: calc(50% - 0.39625rem);
    background-position: 0 0;
    background-repeat: no-repeat;
    opacity: 0;
    transform: rotate(-180deg);
    -webkit-transform: rotate(-180deg);

    .touch & {
      opacity: 1;
    }
  }

  // Hovered and general sorted controls styles
  &[class*="st-sort-"],
  &:hover {
    &::after {
      opacity: 1;
    }
  }

  // Ascending sorted controls specific styles
  &.st-sort-ascent,
  &.st-sort-ascent:hover {
    &::after {
      @include iconImage('sort-desc-arrow-on.svg');
      transform: rotate(-180deg);
      -webkit-transform: rotate(-180deg);
    }
  }

  // Descending sorted controls specific styles
  &.st-sort-descent,
  &.st-sort-descent:hover {
    &::after {
      @include iconImage('sort-desc-arrow-on.svg');
      transform: rotate(0deg);
      -webkit-transform: rotate(0deg);
    }
  }
}

.c-input-filter {
  @include iconImage('search.svg');
  @extend .c-input;
  padding-right: 1.875rem;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: right 0.3125rem top 0.4375rem;
  background-size: 1.5625rem 1.5625rem;

  &.spinning {
    background: none;
  }
}

// Nested tables style
.c-table-nested {
  .parent-row-title {
    @include font($size: 1.4375rem, $color: $textBlue, $weight: normal);

    // prevent unintended text highlighting when clicking on sort arrows
    @include user-select(none);
    display: block;
    padding: 0;
  }

  .c-table {
    border-bottom: none;

    th {
      @include cog-type-level('body-2');
      background-color: transparent;
    }
  }
}

// Nested Table Styles
// Used with ng-repeat on tbody
.c-table.c-table-nested {
  tbody {
    td,
    tr:last-child td,
    tr:nth-child(even) td {
      background: transparent;
      border-bottom: 1px solid transparent;
    }

    td,
    td:first-child {
      &.expandable-row-toggle-container {
        position: relative;
        padding-left: 1.5625rem;

        .row-toggle:hover {
          @include user-select(none);
          cursor: pointer;
        }

        .inline-nested-row.row-toggle {
          position: relative;
        }
      }
    }
  }

  tbody:last-child {
    border-bottom: none;
  }

  tr.expandable-row {
    height: 0;

    > td:nth-child(1n) {
      height: auto;
      padding: 0;
      border-bottom: 1px solid;

      .expanded-row-content {
        overflow: auto;
        padding: 0 1.5625rem;
        transition: max-height $defaultDuration ease;

        td {
          padding: 0.3125rem 0 0 0;
          height: 1.5625rem;
        }
      }

      .c-meta-table {
        width: calc(100% - 1.875rem);
        margin-bottom: 0.625rem;
      }
    }
  }

  tr.expandable-row.expanded {
    > td {
      border-bottom: 1px solid transparent;

      .expanded-row-content {
        border-bottom: 1px solid transparent;
      }
    }

    & tr:last-child {
      td {
        border-bottom: none;
      }
    }
  }
}

.c-meta-table {
  padding-left: 1.5rem;

  th, td {
    @include font($size: 0.75rem, $leading: 1);
  }

  tr:nth-child(1n) td {
    padding: 0.3125rem 0;
    background: none;
  }
}

.c-table-input-td {
  text-align: right;
  vertical-align: top;
  width: 1%;

  input[type="radio"],
  input[type="checkbox"] {
    position: relative;
    top: 0.125rem;
  }
}

tr.valign-top > td,
td.valign-top,
tbody[ng-form] td,
tr[ng-form] td {
  vertical-align: top;
}

// Apply this class to a button or other element inside a TD which should be
// vertically aligned with form fields. This faux alignment means the button
// remains aligned with the form fields even when inline validation increases
// the height of the TD.
.valign-input {
  margin-top: 0.5rem;
}

// Apply this class when we have display status icon to the column
.status-icon {
  height: 0.4375rem;
  width: 0.4375rem;
  border-radius: 50%;
  margin-right: 0.625rem;
  display: inline-flex;
}

@include helix-themes() using ($theme) {
  .status-icon {
    &.active {
      background: helix-color($theme, status, success) !important;
    }

    &.inactive {
      background: helix-color($theme, foreground, disabled-text);
    }
  }
}

// Apply this class if you want to align items to columns inside a TD
.align-as-column {
  display: inline-flex;
  flex-direction: column;

  > small {
    padding-top: 0.3125rem;
    color: $colorSecondaryDark;
  }
}


// This style is applicable for material tables only. Add class
// `hover-actions-trigger` to a button to make it visible when the containing
// table row is hovered, or focused. This is generally useful to show context
// actions for the row.
mat-row, .mat-row {
  .hover-actions-trigger:not([aria-expanded="true"]) {
    display: none;
  }

  &:hover,
  &:focus {
    .hover-actions-trigger {
      display: block;
    }
  }
}

@include helix-themes() using ($theme) {
  $divider-color: helix-color($theme, foreground, divider);
  $text-color: helix-color($theme, foreground, text);
  $hover-color: helix-color($theme, state-secondary, hover);

  .c-table {
    th,
    td {
      border-bottom-color: $divider-color;
    }

    th {
      color: $text-color;
    }
    td {
      color: $text-color;
    }

    tr:hover > td:not(.no-results) {
      background-color: $hover-color;
    }

    tr:last-child {
      td.no-results {
        color: $text-color;
      }
    }
  }

  // Emulating cog-table anchor treatments
  // Add special styling for anchors in tables if and only if the user
  // is known to have a hover and fine pointer capabilities (mouse or touchpad).
  @media (hover: hover) and (pointer: fine) {
    .c-table {
      // Anchors in a table should be colored as standard text and on hover of the
      // row should show up as textual links.
      a {
        color: helix-color($theme, foreground, text);
      }
      tr:hover,
      tr:focus-within {
        a {
          color: helix-color($theme, component, anchor);
        }
      }
    }
  }


  .c-table.alt-meta-rows {
    tbody {
      td,
      tr:last-child td,
      tr:nth-child(even) td {
        border-top: 1px solid $divider-color;
      }
    }
  }

  .c-table.c-table-nested {
    tbody {
      border-bottom: 1px solid $divider-color;
    }

    tr.expandable-row {
      > td:nth-child(1n) {
        border-bottom-color: $divider-color;
      }

      &.expanded {
        > td {
          .expanded-row-content {
            border-bottom-color: $divider-color;
          }
        }
      }
    }
  }

  // Special table layout for grid of options instead of tabular data.
  .c-table.tic-tac-toe {
    border: 1px solid $divider-color;
    background-color: helix-color($theme, background, background);

    tr {
      td {
        border-right: 1px solid $divider-color;
        border-bottom: 1px solid $divider-color;
      }
      td:last-child {
        border-right: none;
      }
    }
  }
}

cog-table,
.cog-table {
  .column-actions-single-icon {
    width: 4rem;
    max-width: 4rem;
  }

  .highcharts-spline-cell {
    display: flex;
    flex-direction: row;
    align-items: center;

    &.mat-cell {
      border-bottom: 0;
    }

    cog-sparkline-chart {
      height: 3.25rem;
      width: 10rem;
    }
  }

  cog-pie-chart {
    width: 2rem;
    height: 2rem;
    margin-right: 0.5rem;
  }

  .mat-header-cell .cog-statlist-item label {
    font-weight: inherit;
    font-size: inherit;
    color: inherit;
  }
}

@include reflow() {
  .reflow-c-table-wrapper.reflow-table-x-scrollable-disabled {
    overflow-x: unset;

    > .c-table {
      min-width: unset;
    }
  }

  // default is @sm
  .reflow-c-table-wrapper,
  .reflow-c-table-wrapper.reflow-table-x-scrollable\@sm {
    > .c-table {
      @include reflow-min-width-sm();
    }
  }

  .reflow-c-table-wrapper.reflow-table-x-scrollable\@xs {
    > .c-table {
      @include reflow-min-width-xs();
    }
  }

  @include reflow-sm() {
    .reflow-c-table-wrapper {
      overflow-x: auto;
      padding-top: 2rem;
    }
  }

  @include reflow-xs() {
    .reflow-c-table-wrapper {
      padding-top: unset;
    }

    th[st-sort]::after {
      right: 0.5rem;
    }
  }

  @include reflow-xs() {
    .reflow-c-table-wrapper {
      padding-top: unset;
    }
  }
}
