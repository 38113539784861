@mixin reflow() {
  // This class is applied to body element if reflow is enabled. By wrapping all
  // reflow rules with this class, we can toggle support on/off as desired.
  .cog-reflow-enabled {
    @content;
  }
}

@mixin reflow-themed() {
  // This class is applied to body element if reflow is enabled. Similar to above
  // but it also allows the reflow styles to be themed by generating the
  // selector like .cog-light-theme.cog-reflow-enabled { ...content... }
  &.cog-reflow-enabled {
    @content;
  }
}

// This is useful when a selector for your style
// is based on a class attached to the body element
// example:
//
// body.has-c-page-header {
//   .app-frame-content-wrapper {
//
// this produces:
//
// body.reflow-enabled.has-c-page-header {
//   .app-frame-content-wrapper {
//
@mixin reflow-body-class($bodyClass) {
  .cog-reflow-enabled#{$bodyClass} {
    @content;
  }
}

// media queries

@mixin media-width-or-height($width, $height) {
  @media only screen and (max-width: $width),
              screen and (max-height: $height) {
    @content;
  }
}

@mixin media($width) {
  @media only screen and (max-width: $width) {
    @content;
  }
}

@mixin reflow-xxs() {
  @media only screen and (max-width: $breakpoint-xxs) {
    @content;
  }
}

@mixin reflow-xs() {
  @media only screen and (max-width: $breakpoint-xs) {
    @content;
  }
}

@mixin reflow-sm() {
  @media only screen and (max-width: $breakpoint-sm) {
    @content;
  }
}

@mixin reflow-md() {
  @media only screen and (max-width: $breakpoint-md) {
    @content;
  }
}

@mixin reflow-lg() {
  @media only screen and (max-width: $breakpoint-lg) {
    @content;
  }
}

@mixin reflow-xl() {
  @media only screen and (min-width: $breakpoint-xl) {
    @content;
  }
}

// min-width mixins

@mixin reflow-min-width($width) {
  min-width: $width;
}

@mixin reflow-min-width-sm() {
  @include reflow-min-width($breakpoint-sm);
}

@mixin reflow-min-width-xs() {
  @include reflow-min-width($breakpoint-xs);
}

// x-scrollable mixins ----
// these mixins makes it easy to create horizontal scrolling elements
// like charts / tables that just cannot be displayed on a small viewport
//
// $wrapperElementSelector = any dom selector that wraps the scrollable element
// $scrollableElementSelector = the dom selector for the element that needs to scroll
// $breakpointSize = any pixel size, but use one of the breakpoint sizes defined in variables.scss

// always apply this mixin within reflow(), reflow-xx() wrappers
@mixin reflow-x-scrollable($wrapperElementSelector, $scrollableElementSelector, $breakpointSize) {
  #{$wrapperElementSelector} {
    overflow-x: auto;
    #{$scrollableElementSelector} {
      @include reflow-min-width($breakpointSize);
    }
  }
}

$reflow-scroll-wrapper : '.reflow-x-scrollable-wrapper';
$reflow-scroll-element : '.reflow-x-scrollable-element';

// this mixin automatically includes the reflow flag check, and media queries.
@mixin reflow-x-scrollable-sm() {
  @include reflow() {
    @include reflow-sm() {
      @include reflow-x-scrollable($reflow-scroll-wrapper, $reflow-scroll-element, $breakpoint-sm);
    }
  }
}

// this mixin automatically includes the reflow flag check, and media queries.
@mixin reflow-x-scrollable-xs() {
  @include reflow() {
    @include reflow-xs() {
      @include reflow-x-scrollable($reflow-scroll-wrapper, $reflow-scroll-element, $breakpoint-xs);
    }
  }
}

// this mixin automatically includes the reflow flag check, and media queries.
@mixin reflow-x-scrollable-xxs() {
  @include reflow() {
    @include reflow-xxs() {
      @include reflow-x-scrollable($reflow-scroll-wrapper, $reflow-scroll-element, $breakpoint-xxs);
    }
  }
}
