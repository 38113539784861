// NOTE: this file should only address color changes. If making structural changes to a component
@use "@angular/material/core/theming/theming" as mat;
// please put them in a separate file (i.e cog-chip-toggle.scss).

@mixin cog-mat-button-toggle-theme($theme) {
  $component: map_get($theme, component);

  .mat-button-toggle-group {
    .mat-button-toggle-checked {
      color: mat.get-contrast-color-from-palette($component, nav-active);
      background: mat.get-color-from-palette($component, nav-active);
    }
  }
}
