// NOTE: this file should only address color changes. If making structural changes to a component
@use "@angular/material/core/theming/theming" as mat;
@use '@angular/cdk' as cdk;
// please put them in a separate file (i.e mat-card.scss).

@mixin cog-mat-checkbox-theme($theme) {
  $foreground: map_get($theme, foreground);
  $disabled-color: mat.get-color-from-palette($foreground, disabled-button);

  .mat-checkbox-frame {
    border-color: helix-color($theme, component, checkbox-border);
  }

  .mat-checkbox-checked,
  .mat-checkbox-indeterminate {
    // Make the primary palette the default rather than accent.
    // This is done by targeting slide toggles without a color specified.
    &.mat-accent:not([color]):not(.mat-checkbox-disabled) {
      .mat-checkbox-background {
        background-color: helix-color($theme, primary, 500);
      }
    }
  }

  // Customizing disabled coloring, as these are walled in from material theming.
  .mat-checkbox-disabled {
    &.mat-checkbox-checked,
    &.mat-checkbox-indeterminate {
      .mat-checkbox-background {
        background-color: $disabled-color;
      }
    }

    &:not(.mat-checkbox-checked) {
      .mat-checkbox-frame {
        border-color: $disabled-color;
      }
    }

    .mat-checkbox-label {
      color: $disabled-color;
    }

    @include cdk.high-contrast {
      opacity: 0.5;
    }
  }
}

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .mat-checkbox-background, .mat-checkbox-frame {
      border-radius: 2px;
      border-width: 1px;
    }

    .mat-checkbox-checked,
    .mat-checkbox-indeterminate {
      &.mat-accent:not([color]):not(.mat-checkbox-disabled) {
        .mat-checkbox-background.mat-checkbox-background {
          background-color: helix-color($theme, component, checkbox);
        }
      }
    }

    .mat-checkbox.cdk-focused .mat-checkbox-inner-container {
      // Add focus state outline for buttons. This is for accessibility to
      // highlight the focussed buttons.
      outline-offset: 2px;
      outline: 2px solid helix-color($theme, helix-primary, 500);
    }
  }
}
