.chassis-content {
  border: 1px solid $colorBorderLight;
  border-radius: 0.3125rem;

  // 3.5rem(missing-node-tile) + 3.5rem(missing-node-tile) + padding(0.5rem) * 2
  min-height: 10rem;
}

.node-content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  .product-model {
    padding-left: 1rem;
  }
}

.node-tile {
  border: 1px solid $colorBorderLight;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  height: 4.5rem;
}

.missing-node-tile {
  height: 4.5rem;
  border: 1px solid $colorBorderLight;
  border-radius: 0.3125rem;
  background-color: $colorBorderLight;
  text-align: center;
}

.single-node-tile {
  display: flex;
  border: 1px solid $colorBorderLight;
  border-radius: 0.3125rem;
  height: 9rem;
  justify-content: center;
  align-items: center;
}
