// cBytes component styling

.c-bytes {
  > * {
    display: inline-block;
    margin-right: 5px;
    vertical-align: top;
  }
  .ui-select-container {
    width: 100px;
  }
}

.c-form-sleek .c-bytes {
  .ui-select-container {
    width: auto;
  }
}
