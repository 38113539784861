@import "common-imports";
// cFilter Styles

// this value should match our standard input heights
$controlDefaultHeight: $formInputHeight;

c-controlboard {
  display: block;
  // TODO: when controlboard can self manage booleans,
  // this styling needs to be swapped, and .temp-panel-wrapper
  // (and related show/hide logic) should be removed from
  // the cDateRanger template
  // c-controlboard-panel,
  .temp-panel-wrapper {
    display: block;
    position: relative;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: $defaultRadius;

    .icn-close {
      position: absolute;
      top: 17px;
      right: 17px;
    }
  }
}

.c-controlboard-controls {
  @include pie-clearfix;
  margin-bottom: 10px;

  > cb-control {
    display: block;
    position: relative;
    float: left;
    // For placement of control 'active' state arrow, control should be
    // $controlDefaultHeight high. Exceeding this value may break layout for
    // other control/panel combos
    min-height: $controlDefaultHeight;

    // Assign margin to the adjacent siblings.
    & + cb-control {
      margin-left: $bootstrap-col-padding;
    }

    // if a filter control set is floated right,
    // move the margin from the right to the left.
    // this allows multiple filters to be floated right
    &.pull-right {
      margin-right: 0;
      margin-left: $bootstrap-col-padding;
    }

    // text filter flyout
    &.c-input-filter-flyout {
      position: relative;
      width: 2.25rem;
      input[type="text"].c-input-filter {
        transition-property: width, padding-left;
        transition-duration: $defaultDuration;
        transition-timing-function: ease;
        position: absolute;
        top: 0;
        right: 0;
        min-width: 1.875rem;
        width: 1.875rem;
        padding-left: 0.25rem;
        &:hover,
        &:focus,
        &.user-input {
          width: 150px;
          padding-left: 0.875rem;
        }
        &.flow-right {
          position: relative;
        }
      }

      .c-form-sleek & {
        input[type="text"].c-input-filter {
          padding-left: 0.25rem;
          padding-right: 1.875rem;

          &:hover,
          &:focus,
          &.user-input {
            padding-left: 0.25rem;
          }
        }
      }
    }
  }
  // force min widths for certain input types, as the above inline/floated div
  // will otherwise collapse them as small as possible
  .ui-select-container,
  input[type="text"],
  c-select .c-select-list,
  c-multiselect .c-multiselect-list {
    min-width: 200px;
  }
  input[type="text"].c-input-filter {
    min-width: 150px;
    width: 150px;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-bottom: 0;
    // make line height match font-size so custom margins work as expected
    line-height: 1em;
  }

  // custom margin tops ensure the header is center in the area if it is a
  // single line of content
  h1 {
    margin-top: ($controlDefaultHeight - $h1DefaultSize) * 0.5;
  }
  h2 {
    margin-top: ($controlDefaultHeight - $h2DefaultSize) * 0.5;
  }
}

@include helix-themes() using ($theme) {
  c-controlboard {
    .temp-panel-wrapper {
      background-color: helix-color($theme, background, app-bar);
    }
  }

  .c-controlboard-controls {
    > cb-control {
      // text filter flyout
      &.c-input-filter-flyout {
        input[type="text"].c-input-filter {
          &:hover,
          &:focus,
          &.user-input {
            // box-shadow gives a soft overlap of any text this
            // might slide out and cover up
            box-shadow: -6px 0px 10px 0px helix-color($theme, background, card);
          }
        }
      }
    }
  }
}

@include reflow() {
  @include reflow-xs() {
    .c-controlboard-controls {
      > cb-control {
        clear: both;

        // clear margin on the adjacent siblings.
        & + cb-control {
          margin-left: 0;
        }

        &.c-input-filter-flyout {
          input[type="text"].c-input-filter {
            width: 12rem;
          }
        }
      }
    }
  }
}
