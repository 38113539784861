// cByteMeter styling

$byteMeterHeight: 10px;

.c-byte-meter {
  margin: 0 10px 4px;
  padding: 0 15px;

  .byte-meter-percentage,
  .byte-meter-value {
    font-size: 16px;
    line-height: 1em;
  }

  .byte-meter-percentage {
    float: left;
  }

  .byte-meter-value {
    float: right;
    text-align: right;
  }

  byte-meter-label {
    display: block;
    text-align: center;
    margin-top: 0.5rem;

    > * {
      vertical-align: middle;
    }
  }

  .stat-value-unit {
    font-size: 0.75em;
  }

  .progress {
    clear: both;

    // overriding bootstrap styles
    height: $byteMeterHeight;
    margin-bottom: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: $byteMeterHeight * 0.5;
  }

  .progress-bar {
    line-height: $byteMeterHeight;
    min-width: 0;
  }
}

@include helix-themes() using ($theme) {
  .c-byte-meter {
    .progress {
      background-color: helix-color($theme, chart, subtle);
    }

    .progress-bar {
      background-color: helix-color($theme, status, info);
    }

    .progress-bar-danger {
      background-color: helix-color($theme, status, error);
    }
    .progress-bar-warning {
      background-color: helix-color($theme, status, warning);
    }
    .progress-bar-success {
      background-color: helix-color($theme, status, success);
    }
  }

}
