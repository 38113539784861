// policy-targets Component styling

policy-targets {
  [class*=icn] {
    margin-left: 0 !important;

    &:not(:last-of-type) {
      margin-right: $iconSpacingLg;
    }
  }
}
