@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-checked,
    .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-indeterminate {
      background: helix-color($theme, helix-base, active);
    }

    .mat-pseudo-checkbox {
      border-radius: 1px;

      &:focus {
        // Add focus state outline for buttons. This is for accessibility to
        // highlight the focussed buttons.
        outline-offset: 2px;
        outline: 2px solid helix-color($theme, helix-primary, 500);
        border-radius: 0px;
      }
    }
  }
}
