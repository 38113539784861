// c-inline-date-picker styles

c-inline-date-picker {
  display: block;

  // making compact date-picker by adjusting date cell padding.
  [uib-datepicker] {
    padding: 0.5rem 0.5rem 0 0.5rem;

    .uib-day,
    .uib-month,
    .uib-year {
      .btn {
        padding: 0.25rem 0.5rem;
      }
    }

    // date cell button styling.
    .btn {
      border-color: transparent;
      border-radius: 0;
      box-shadow: none;

      // killing the on focus outline.
      &:focus {
        outline: 0;
      }

      // override active state styling.
      &.active {
        background-color: $colorPrimary;

        // active today date color.
        .text-info {
          color: $contentBackground;
        }
      }
    }

    // date-picker title is used to show current month or year or year range
    // when user is choosing the date.
    .uib-title {
      padding: 0;
      text-align: left;

      strong {
        font-size: 1rem;
      }
    }

    // date-picker navigation like next, previous month, year or year range.
    .uib-left,
    .uib-right {
      border-radius: 50%;
      padding: 3px 4px;
      width: auto;
    }

    .uib-right {
      margin-left: 0.4rem;
    }

    table {
      thead tr:nth-child(even) th,
      tbody tr td,
      thead th,
      tbody td {
        border-top: none;
        border-right: none;
        border-bottom: none;
      }

      tbody tr td span {
        font-size: inherit;
      }

      thead tr:first-child th {
        height: auto;
      }
    }
  }
}