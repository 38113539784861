// Component: Source list tab in Test & Dev

.flex-row.vm-source-list {

  flex-wrap: wrap;

  .source-container {
    width: 33.33%;

    .source-information {
      align-items: center;
      height: 6.875rem;

      .source-name-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0.625rem;
        width: 65%;
      }

      .source-content {
        font-size: 1.25rem;
        padding: 0 0 0.3125rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .source-count {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-end;
        width: 15%;
      }
    }

    .source-add-new {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 10rem;

      .add-new-font {
        font-size: 1.25rem;
      }
    }

    .source-btn-container {
      padding: 0.875rem;
    }
  }
}