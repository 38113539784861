// c-filters Styles.

$cFiltersGutter: 1rem;
$cFiltersGutterHalf: $cFiltersGutter * 0.5;
$cFieldMinHeight: 22.2rem; // Height of the date picker

c-filters,
c-simple-field {
  display: block;
}

.c-filters-modal {
  .c-slide-modal-dialog .c-modal .c-modal-main {
    padding: 0;
  }
}

.c-filters {
  &:not(.shown-all-filters) {
    border-radius: 0.25rem;
    padding: $gutter-sm;

    // killing modal body padding in mini view.
    .modal-body {
      padding: 0;
    }

    report-fields {
      fieldset {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }

  // filter field overrides.
  [c-field] {
    // ui-select overrides if it is used as a filter field.
    .ui-select-container,
    &.ui-select-container {
      width: 15.625rem;

      // resetting multi selection border bottom.
      &.ui-select-multiple {
        border-bottom-width: 0;
      }

      // showing input box border manually because multi selection mode border
      // border is provided at the top ui-select-container to include pills and
      // input box together.
      input.ui-select-search {
        border-top: 0;
        border-right: 0;
        border-bottom: 1px;
        border-left: 0;
        border-style: solid;
      }

      // adjusting the multi selection dropdown caret placement.
      // TODO(veetesh): may be dont show this caret icon
      &.ui-select-multiple,
      &[tagging] {
        &::after {
          top: 1.125rem;
        }
      }
    }

    c-inline-date-range,
    [uib-dropdown] {
      [uib-dropdown-menu] {
        border: none;
      }
    }
  }

  // making flexbox elements.
  .c-filters-body,
  .c-filters-label,
  .c-filters-fields,
  .c-floor-fields,
  .c-ceil-fields,
  floor-fields,
  ceil-fields,
  c-filters-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .c-filters-body {
    margin-bottom: 0;
  }

  // setting up fields gutter.
  floor-fields,
  ceil-fields  {
    height: 100%;

    > * {
      margin-right: $cFiltersGutter;
    }

    // removing last children gutter.
    > :last-child {
      margin-right: 0;
    }
  }

  // filter toggler.
  .c-filters-label {
    margin-right: $gutter-md;
  }

  // making pills inline.
  c-filter-applied ul {
    display: inline;

    .c-pill {
      margin: 0.33em 0.33em 0.33em 0;
    }
  }

  report-fields {
    fieldset {
      > label {
        display: none;
      }
    }
  }

  // styling overrides when pills and filter are shown together.
  &.shown-filters .c-filters-body {
    flex-wrap: wrap;

    c-filter-applied {
      // setting full width to move pills to next line in flex layout.
      width: 100%;
      margin-top: $gutter-sm;
    }
  }

  // styling when all filter fields are shown.
  &.shown-all-filters {
    // making c-field looks like a card.
    [c-field] {
      padding: 0.5rem;
      transition: border $defaultDuration ease;

      /* This is the height of date-picker
         Set this as min-height so that other fields occupy same height as the date-picker */
      min-height: $cFieldMinHeight;

      .ui-select-container.open,
      &.ui-select-container.open {
        input.ui-select-search:not(:focus) {
          border-color: $colorBorder;
        }
      }

      // resetting ui-select dropdown styling when used as a filter field.
      .dropdown-menu {
        position: static;
        box-shadow: none;
        padding: 0;
        float: none;

        // adding custom slim scrollbar.
        @include c-scrollbar();
      }

      c-inline-date-range .c-inline-date-range-inner {
        padding: 0;
      }
    }

    // center align all fields.
    .c-filters-body {
      justify-content: center;
    }

    // stretching all filter fields to have same height.
    .c-filters-fields,
    floor-fields,
    ceil-fields {
      align-items: stretch;
    }

    // creating gutter b/w flexbox items.
    // https://developer.mozilla.org/en-US/docs/Web/CSS/CSS_Flexible_Box_Layout/Mastering_Wrapping_of_Flex_Items#Creating_gutters_between_items
    .c-filters-fields {
      flex-wrap: wrap;
      justify-content: center;
      margin: -$cFiltersGutterHalf;

      > * {
        margin: $cFiltersGutterHalf;
      }
    }

    .ui-select-choices {
      // Field min height - height of search box - (padding + margin-top)
      max-height: calc(#{$cFieldMinHeight} - 32px - 1.5rem);
    }
  }

  // ui-dropdown should look like ui-select when used with filters.
  [uib-dropdown] {
    [uib-dropdown-menu] {
      padding: 0;
      border-radius: 0;

      // setting up custom padding for dropdown menu items.
      li:not(.divider) {
        padding: 0.5rem 1rem;
      }
    }

    .c-field-dropdown-toggle {
      position: relative;

      input {
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }

  // keep uib-typeahead result popup in the page with custom scrollbar when
  // used with filters.
  [uib-typeahead-popup] {
    border-radius: 0;
    border: 0;
    box-shadow: none;
    float: none;
    max-height: $uiSelectDropdownMaxHeight;
    overflow-y: scroll;
    position: static;

    @include c-scrollbar();
  }
}


@include helix-themes() using ($theme) {
  .c-filters {
    &:not(.shown-all-filters) {
      background: helix-color($theme, background, app-bar);
    }

    // highlight the filter field on error.
    .error [c-field] {
      .ui-select-container,
      &.ui-select-container {
        input.ui-select-search {
          border-color: helix-color($theme, status, error);
        }
      }
    }

    // styling when all filter fields are shown.
    &.shown-all-filters {
      // making c-field looks like a card.
      [c-field] {
        background-color: helix-color($theme, background, card);
        border: 1px solid helix-color($theme, background, card-border);

        // highlight the filter field on error.
        .error & {
          border: 1px solid helix-color($theme, status, error);
        }
      }
    }
  }
}
