// This file supports Angular Material theming of the suite of page components. Please see helix-theme.scss to see how
// these are utilized. Or read the Angular Material guide:
// https://material.angular.io/guide/theming-your-components

@mixin donut-chart-theme($theme) {
  $foreground: map_get($theme, foreground);
  $background: map_get($theme, background);

  cog-donut-chart {
    .donut-hole-content {
      color: map_get($foreground, secondary-text);

      // ByteSize pipe output and/or label
      > small,
      div > small,
      label {
        color: map_get($foreground, text);
      }
    }

    .highcharts-pie-series .highcharts-point {
      stroke: map_get($background, background);
    }
  }
}
