// Custom Styles for cVmBrowser

 .c-vm-browser-box {
  height: calc(100% - 7.1875rem);
  position: relative;

  &.shares {
    height: calc(100% - 20rem);
  }
}

.vm-browser-message-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 20;
  width: 100%;
  height: 100%;

  .vm-browser-message-text {
    @include font($size: 1.125rem);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}

.vm-browser-container {
  // height adjusted for c-modal-main -50px to push the container 3.125rem downwards
  height: calc(100% + 3.125rem);
  .vm-browser-panels-container {
    border-top: none;
    position: relative;
    padding-top: 2.5rem;
    height: 100%;

    .spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 999999;
    }
  }
  .vm-browser-panels-container-overlay {
    transition: all 0.1s ease;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
  .vm-browser-panels-container-overlay.enabled {
    opacity: 0.2;
    z-index: 9;
  }
  .finder-tree-container {
    float: none;
    display: inline-block;
    vertical-align: top;
  }
  .vm-browser-panels {
    width: calc(100% - 18.75rem);
    float: none;
    margin-right: 18.75rem;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .vm-browser-panels-message {
    width: 100%;
    float: none;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
  .vm-browser-panels,
  .finder-tree-container,
  .vm-browser-volumes-panel,
  .vm-browser-panels-message,
  .finder-tree-resizer {
    height: 100%;
  }

  .finder-tree {
    width: calc(100% - 29rem);
    ul {
      float: left;
    }
  }
  .finder-tree ul,
  .vm-browser-volumes-panel ul {
    padding-top: 0.4375rem;
  }

  .finder-tree .finder-tree-resizer {
    width: 15.625rem;
    position: relative;
    display: block;
    float: left;

    ul {
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;

      li {
        display: flex;
        height: auto;
        align-items: center;

        .ft_folder {
          width: 1.5rem;
        }

        .ft_span_text {
          flex-grow: 1;
          padding-right: 2rem;
          overflow: visible;
          text-overflow: unset;
          display: block;
          white-space: pre-wrap;
          word-break: break-word;
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
        }
      }
    }
  }
  .vm-browser-preview-panel {
    float: right;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: relative;
    width: 18.75rem;

    .media {

      .icn-lrg.media-object {
        font-size: 5.3125rem;
        position: relative;
        top: -0.75rem;
      }
      .vm-browser-preview-node-name {
        font-size: 1rem;
        word-wrap: break-word;
        padding-bottom: 0.4375rem;
        @include font($weight: bold);
      }
      .vm-browser-preview-node-path {
        word-wrap: break-word;
        padding-bottom: 0.4375rem;
      }
    }
  }
  .cart-has-items {
    position: relative;
    &::before {
      content: '';
      display: inline-block;
      width: 0.625rem;
      height: 0.625rem;
      border-radius: 0.625rem;
      position: absolute;
      top: 0.1875rem;
      right: 0.75rem;
    }
  }
}

/* Volumes Listing Panel */
.vm-browser-volumes-panel {
  position: relative;
  float: left;
  width: 9.375rem;
  margin-right: 0.875rem;
  overflow-y: auto;
  overflow: visible;

  ul {
    height: 100%;
    overflow-x: hidden;
  }

  li {
    padding-left: 0;
    height: 1.5625rem;
    width: 100%;
    background-position: 0.3125rem 0.2188rem;
    background-size: 1.125rem 1.125rem;
    float: left;

    &.volume-name {
      @include font($size: 0.9375rem, $leading: 1.75rem, $weight: light);
      text-overflow: ellipsis;
      text-align: left;
      overflow: hidden;
      white-space: nowrap;
      display: inline-block;
      width: 100%;
      padding-left: 0.625rem;
      height: 1.5625rem;
      .finder-tree-decendant {
        display: none;
      }
    }
  }

  li:hover {
    cursor: pointer;
  }
}

.vm-name-container {
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.selected-path {
  line-height: 2.5rem;
  display: inline-block;
  padding-left: 0.625rem;
  width: calc(100% - 2.8125rem);
  overflow-x: auto;
  white-space: nowrap;
}

.snapshot-select-container {
  width: 60%;
  display: inline-block;
  position: relative;
  top: -0.1875rem;
}

.c-vm-browser-cart-bar {
  height: 2.5rem;
  z-index: 20;
  position: relative;
  padding: $gutter-xs;
  .icn:hover {
    cursor: pointer;
  }
  .c-input {
    border-radius: 0;
    // adjust width to cover border of containing element by 2px
    width: calc(100% + 2px);
    // re-positioning
    margin: -1px -1px 0;
  }
}

.c-vm-browser-cart-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.c-vm-browser-cart {
  transition: all 0.2s ease;
  max-height: 0;
  height: auto;
  overflow: hidden;
  border-radius: 0 0 0.4375rem 0.4375rem;
  position: relative;
  z-index: 10;
  width: 95%;
  margin: 0 auto;
  .icn-lg {
    padding: 0;
    min-height: 0;
  }
}

.c-vm-browser-cart.expanded {
  max-height: 80vh;
  overflow-y: auto;
}

.c-vm-browser-cart-content {
  padding: 0.9375rem;
}

//
// BELOW THIS POINT, BEGIN FINDER TREE DEFAULT STYLES
// NOTE SOME MODIFICATONS HAVE OCCURRED BUT THE BULK REMAINS UNTOUCHED
//

.resizable {
  position: relative;
}

.resizable.no-transition {
  transition: none !important;
}

.rg-right,
.rg-left,
.rg-top,
.rg-bottom {
  display: block;
  width: 0.875rem;
  height: 0.875rem;
  line-height: 0.875rem;
  position: absolute;
  z-index: 1;
}

.rg-right span,
.rg-left span,
.rg-top span,
.rg-bottom span {
  position: absolute;
  box-sizing: border-box;
  display: block;
}

.rg-right span,
.rg-left span {
  border-width: 0 1px;
  top: 50%;
  margin: -0.625rem 0 0 0.1875rem;
  height: 1.25rem;
  width: 0.375rem;
}

.rg-top span,
.rg-bottom span {
  border-width: 1px 0;
  left: 50%;
  margin: 0.2188rem 0 0 -0.625rem;
  width: 1.25rem;
  height: 0.4375rem;
}

.rg-top {
  cursor: row-resize;
  width: 100%;
  top: 0;
  left: 0;
  margin-left: -0.4375rem;
  margin-top: -0.4375rem;
}

.rg-right {
  cursor: col-resize;
  height: 100%;
  right: 0;
  top: 0;
  margin-right: -0.875rem;
  background-image: none;
}

.rg-bottom {
  cursor: row-resize;
  width: 100%;
  bottom: 0;
  left: 0;
  margin-left: -0.4375rem;
  margin-bottom: -0.4375rem;
}

.rg-left {
  cursor: col-resize;
  height: 100%;
  left: 0;
  top: 0;
  margin-left: -0.4375rem;
}

.finder-tree {
  height: inherit;
  display: inline-block;
  vertical-align: top;
  white-space: nowrap;
}

.finder-tree ul {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: 0 0.875rem 0 0;
  width: 9.375rem;
  height: 100%;
}

.finder-tree li {
  padding: 0;
  margin: 0;
  cursor: pointer;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  position: relative;
  height: 1.5625rem;

  .finder-tree-decendant {
    display: none;
  }
}

.vm-browser-volumes-panel li.checked .finder-tree-decendant,
.finder-tree li.checked .finder-tree-decendant {
  left: 0;
  top: 0.625rem;
  position: absolute;
  display: inline-block;
}

.ft_clear {
  clear: both;
}

.ft_span_text {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: inherit;
  padding-right: 2.8125rem;
  line-height: 1.4375rem;
}

.ft_span_text_file {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: inherit;
  padding-right: 2.1875rem;
  line-height: 1.4375rem;
}

.ft_span_text_nomatch {
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: inherit;
  vertical-align: middle;
}

.ft_folder {
  margin-right: 0.3125rem;
}

.ft_file {
  margin-right: 0.3125rem;
}

.ft_caret_right {
  @include iconImage('arrow-r.svg');
  background-repeat: no-repeat;
  background-position: right center;
  padding: 0 0.4688rem 0 0.4688rem;
  position: absolute;
  top: 0;
  right: -0.3125rem;
  bottom: 0;
  margin: auto;
  background-size: 1.5625rem;
}

.ft_caret_right_selected {
  @include iconImage('arrow-r-active.svg');
  @extend .ft_caret_right;
}

.message-panel {
  display: inline-block;
  white-space: normal;
  width: 85%;

  .c-message-inline {
    width: 25rem;
    margin: 10% auto;
  }
}

.helper-text {
  @include font($weight: light);
}

.volume-tile {
  margin-top: 0.625rem;
  margin-left: 0.3125rem;
  text-align: center;
  font-size: 0.75rem;
  max-width: 95%;
}

@include helix-themes() using ($theme) {
  $background: map-get($theme, background);
  $border-color: helix-color($theme, foreground, divider);

  .vm-browser-container .finder-tree .finder-tree-resizer {
    border-right: 1px solid $border-color;
  }

  .vm-browser-message-box {
    background-color: map-get($background, dialog);
    border: 1px solid $border-color;

    .vm-browser-message-text {
      color: helix-color($theme, foreground, secondary-text);
    }
  }

  .vm-browser-container {
    .vm-browser-panels-container {
      border: 1px solid $border-color;
    }
    .vm-browser-panels-container-overlay {
      background: #000;
    }

    .vm-browser-preview-panel {
      background: helix-color($theme, background, app-bar);
      border-left: 1px solid $border-color;
    }
    .cart-has-items {
      &::before {
        background-color: helix-color($theme, status, info);
      }
    }
  }

  // Volumes Listing Panel
  .vm-browser-volumes-panel {
    li.selected {
      background-color: helix-color($theme, primary, 500);
      color: helix-contrast($theme, primary, 500);
    }
  }

  .c-vm-browser-cart-bar {
    border: 1px solid $border-color;
  }

  .c-vm-browser-cart {
    background-color: helix-color($theme, background, dialog);
    table {
      border-top: 1px solid $border-color;
    }
  }

  .rg-right,
  .rg-left,
  .rg-top,
  .rg-bottom {
    background-color: helix-color($theme, status, info);
  }

  .rg-right span,
  .rg-left span,
  .rg-top span,
  .rg-bottom span {
    border: 1px solid lighten($border-color, 25);
  }

  .rg-right {
    background-color: $border-color;
    border-left: 1px solid $border-color;
    border-right: 1px solid $border-color;
  }

  .vm-browser-volumes-panel li.checked .finder-tree-decendant,
  .finder-tree li.checked .finder-tree-decendant {
    background-color: helix-color($theme, status, success);
  }

  .ft_selected {
    background-color: helix-color($theme, primary, 500);
    color: helix-contrast($theme, primary, 500);
  }

  .current-node {
    border-bottom: 1px solid $border-color;
  }

  .volume-tile {
    border-bottom: 1px solid $border-color;
  }
}
