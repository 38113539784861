// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-card.scss).

@mixin cog-mat-general-theme($theme) {
  $background: map_get($theme, background);

  .mat-divider {
    border-top-color: map_get($background, card-border);
  }
}
