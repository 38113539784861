// -------------------------------
// -- LOGIN NEW ------------------
// -- REBRANDED DATA CLOUD LOGIN FORM
// -------------------------------
//
// THIS SECTION CONTAINS STYLES ONLY APPLIED TO
// THE NEW COHESITY DATA CLOUD LOGIN FORMS
//
// THIS IS BEHIND A FEATURE FLAG
//
$loginFormWidth: 15rem;
$heliosBackground: #FAFAFA;

// Current login page needs rework since jazz.
// The login page background is not currently themeable but hard coded
// This hack hides the left margin so the jazz themed background
// do not show in the left margin
.app-frame-content-wrapper.menu-closed.nav-hidden:has(.login-data-cloud-rebranded) {
    margin-left: 0rem;
}

.login-data-cloud-rebranded {
  .sso-login-container {
    padding: 10px 0;
  }

  c-toggle {
    margin-bottom: 1rem;
  }

  .sso-signin-container {
    width: 100%;
    margin-top: 1.5rem;
  }

  .helios-login {
    @include cog-type-level(body-lg);
    font-weight: $font-weight-light;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    label {
      @include cog-type-level('caption');
      text-align: left;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    input:not([type=checkbox]):not([type=submit]) {
      border: none;
      border-radius: 0;
      background: none;
      padding-left: 0;
      height: 2rem;
      margin-bottom: 1rem;

      &:focus {
        border-bottom-width: 2px;
      }
    }

    // hug error messages tight under inputs
    small.error-msg {
      margin-top: -1.5rem;

      // get rid of those animations!
      &.ng-animate {
        transition: 0s;
      }
    }

    .c-form-sleek {
      fieldset {
        margin-bottom: 0;
        margin-top: 0;
      }

      input:not([type=checkbox]) {
        padding-top: 0;
        &:focus {
          border-bottom-width: 2px;
        }
      }
    }

    #salesforce-login {
      min-height: 15rem;
      margin: 0 auto;

      * {
        @include cog-type-level(body);
      }
    }

    #sfid-inline-content {
      margin-top: 2rem;
    }

    .sfid-button-label {
      text-align: left;
    }

    .helios-form-container {
      width: $loginFormWidth;
      margin: 10% auto 0 auto;
    }

    .helios-login-title {
      @include cog-type-level(gb-value-lg);
      white-space: nowrap;
    }

    .helios-login-description {
      @include cog-type-level('display-2');
      margin: 1rem 0;
    }

    .helios-blurb {
      margin-top: -0.25rem;
    }

    .helios-powered-by {
      @include cog-type-level('caption');
      margin-top: .5rem;
    }

    .helios-logo {
      margin-top: 20%;
    }

    // Cohesity Employee login is not a valid Helios authentication.
    .sfid-small,
    div#sfid-social {
      display: none;
    }

    .login-cluster-name {
      margin: 1rem 0 2rem 0;
      white-space: nowrap;

      span {
        @include cog-type-level('body-1');
        margin: 1rem 0 2rem 0;
        white-space: normal;
        overflow-wrap: anywhere;
      }

      svg {
        margin-right: .25rem;
        margin-top: 8px;
      }
    }

    button[type=submit] {
      width: 100%;
      margin-top: 1rem;
    }
  }

  .sso-link {
    display: block;
    @include cog-type-level('body-1');
    text-decoration: none;
    margin-top: 1rem;
  }

  .helios-login-form {
    display: inline-block;
    height: 100%;
    width: 100%;
    margin-top: 1rem;

    fieldset {
      border: none;
      margin-top: 1rem;
      padding: 0;
    }

    input:not([type=checkbox]) {
      display: block;
      height: 3rem;
      padding: 0.125rem 0.5rem;
      width: 100%;
      border: none;
      outline: none;
      background: rgba(0,0,0,0);
    }

    .c-btn {
      border: 0;
      border-radius: 0.3rem;
      display: block;
      // margin-top: 1rem;
      padding: 0.25rem 0;
      width: 100%;

      &.sso-button {
        background: transparent;
        border: 0;
        margin-left: 0;
        padding: 0;
        // margin-top: 0.5rem;
      }
    }

    .signup-container {
      margin-top: 2rem;
    }
  }

  .helios-login-footer {
    @include cog-type-level('body-1');
    bottom: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%); // centers the div
  }

  .login-banner {
    white-space: pre-wrap;
    max-height: 10rem;
    overflow-y: scroll;

    // custom slim scrollbar.
    @include c-scrollbar();

  }

  #salesforce-login {

    #sfid-forgot-password {
      @include cog-type-level('body-1');
      text-align: center;
      width: 100%;
      margin-top: 1.5rem;
    }

    .sfid-button-label {
      @include cog-type-level('caption');
      text-align: left;
      margin-bottom: 0;
      padding-bottom: 0;
    }

    #sfid-username,
    #sfid-password {
      border: none;
      border-radius: 0;
      background: none;
      padding-left: 0;
      height: 2rem;
      margin-bottom: 2rem;
      color: inherit;

      &:focus {
        border-bottom-width: 2px;
      }
    }

    #sfid-selfreg-password {
      text-align: center;
    }
  }

  .login-options {
    margin: 2rem 0;

    .login-options-separator {
      width: 100%;
      text-align: center;
      line-height: 0.1rem;
      margin: 0 0 2rem;

      .login-options-text {
        padding: 1rem;
      }
    }

    .login-options-back {
      text-align: left;
    }

    .login-button-sets {
      font-size: smaller;
      display: flex;
      justify-content: center;
      color: $colorGrey;

      .login-option {
        flex-direction: column;
        display: flex;
        padding: 1rem;
      }

      button {
        border-radius: 8px;
        background: transparent;
      }
    }
  }

}

@include helix-themes() using ($theme) {
  $current-light-theme: $jazz-theme-light;
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);


  @if $is-ibm-aqua-theme {
    $current-light-theme: $ibm-aqua-theme-light;
  }

  .login-data-cloud-rebranded {
    .helios-login {
      color: helix-color($current-light-theme, helix-base, active);
      background-color: $heliosBackground;

      label {
        color: helix-color($current-light-theme, helix-base, active);
      }

      label:has(+ input:focus),
      label:has(+ .c-ui-select.open) {
        color: helix-color($current-light-theme, helix-primary, 800);
      }

      input:not([type="checkbox"]):not([type="submit"]) {
        border-bottom: 1px solid helix-color($current-light-theme, helix-base, active);
        color: helix-color($current-light-theme, helix-base, active);

        &:focus {
          caret-color: helix-color($current-light-theme, helix-primary, 800);
          border-color: helix-color($current-light-theme, helix-primary, 800);
          color: helix-color($current-light-theme, helix-primary, 800);
        }
      }

      .ui-select-container {
        .ui-select-toggle {
          color: helix-color($current-light-theme, helix-base, active);
          border-bottom-color: helix-color($current-light-theme, helix-base, active);
        }

        .ui-select-choices {
          .ui-select-choices-row {
            &.selected {
              &::after {
                // the list item bullet in resting state when selected
                background: helix-color($current-light-theme, helix-base, active);
              }
            }
          }
        }

        // Domain dropdown gets styles from dark mode.  This overrides that.
        // Until we make a dark mode login page
        .dropdown-menu {
          background-color: helix-color($current-light-theme, background, dialog);
          .ui-select-choices-row:hover,
          .ui-select-choices-row:focus {
            background-color: helix-color($current-light-theme, background, hover);
            color: helix-color($current-light-theme, helix-primary, 800);
            &.selected {
              &::after {
                // the list item bullet on hover when selected
                background: helix-color($current-light-theme, helix-primary, 800);
              }
            }
          }
        }

      }


      .c-form-sleek {
        input:not([type="checkbox"]) {
          &:focus {
            caret-color: helix-color($current-light-theme, helix-primary, 800);
            border-color: helix-color($current-light-theme, helix-primary, 800);
            color: helix-color($current-light-theme, helix-primary, 800);
          }
        }
      }

      .sfid-button {
        background-color: helix-color($current-light-theme, helix-base, brand);
        color: helix-contrast($current-light-theme, helix-base, brand);
      }

      #sfid-submit.sfid-disabled {
        background-color: rgba(helix-color($current-light-theme, helix-base, brand), 0.25);
        color: $colorWhite;
      }

      .helios-login-description {
        color: helix-color($current-light-theme, helix-base, brand-accessible);
      }

      .helios-powered-by {
        color: helix-color($current-light-theme, helix-base, resting);
      }

      button[type="submit"] {
        background-color: helix-color($current-light-theme, helix-base, brand);
        color: helix-contrast($current-light-theme, helix-base, brand);
      }
    }

    .sso-link {
      color: helix-color($current-light-theme, helix-base, primary);
    }

    .helios-login-form {
      input:not([type="checkbox"]) {
        background: rgba(0, 0, 0, 0);
      }

      .c-btn {
        background-color: helix-color($current-light-theme, helix-base, brand);
        color: $colorWhite;

        &.sso-button {
          color: helix-color($current-light-theme, helix-base, primary);
        }
      }
    }

    #salesforce-login {
      #sfid-forgot-password {
        color: helix-color($current-light-theme, primary, 500);
      }

      .sfid-button-label:has(+ #sfid-username:focus),
      .sfid-button-label:has(+ #sfid-password:focus) {
        color: helix-color($current-light-theme, helix-primary, 800);
      }

      #sfid-username,
      #sfid-password {
        border-bottom: 1px solid helix-color($current-light-theme, helix-base, resting);

        &:focus {
          caret-color: helix-color($current-light-theme, helix-primary, 800);
          border-color: helix-color($current-light-theme, helix-primary, 800);
          color: helix-color($current-light-theme, helix-primary, 800);
        }
      }
    }

    .login-options {
      .login-options-separator {
        border-bottom: 0.1rem solid helix-contrast($current-light-theme, helix-primary, 400);

        .login-options-text {
          background: $heliosBackground;
        }
      }

      .login-button-sets {
        color: $colorGrey;

        button {
          border: 1px solid helix-color($current-light-theme, background, card-border);

          &:hover {
            box-shadow: 0 0 0.125rem 0.0625rem helix-color($current-light-theme, background, selected-button);
          }
        }
      }
    }
  }

  @if $is-ibm-aqua-theme {
    .login-data-cloud-rebranded {
      .helios-login {
        .sfid-button, button[type="submit"] {
          color: white;
        }
      }
    }
  }

}

@include ibm-aqua-theme() {
  .login-data-cloud-rebranded {
    .helios-login {
      .sfid-button, button[type="submit"] {
        border-radius: 0;
        color: white;
      }
    }
  }
}

@include media($bootstrap-breakpoint-md) {
  .login-data-cloud-rebranded .helios-login {
    overflow-y: auto;

    .helios-form-container {
      margin-top: 5%;
    }
  }
}

@include media($bootstrap-breakpoint-md) {
  .login-data-cloud-rebranded .helios-login-footer {
    position: relative;
    width: 100%;
    text-align: center;
    margin-top: 2rem;
  }
}
