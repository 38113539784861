@import 'helix/scss/dependencies';

.cohesity_helix_blockly-renderer {
  .blocklyIconGroup {
    opacity: 1;
  }
}

@include helix-themes() using ($theme) {
  .cohesity_helix_blockly-renderer {
    .blocklyIconShape {
      fill: helix-color($theme, status, error);
      fill-opacity: 1;
    }
  }
}
