.c-cluster-upgrade {
  .c-radio-item {
    position: relative;
    padding: 10px 5px;
    border-top: 1px solid $colorBorder;
    transition: all 1s $easeInExpo;
    overflow: hidden;
    &:first-of-type {
      border-top: none;
    }
    .icn-sm.icn-delete {
      position: absolute;
      right: 10px;
      top: 12px;
    }
  }
  // starting animations for enter
  .c-radio-item.ng-enter {
    opacity: 0;
    max-height: 0;
  }
  // terminal animations for enter
  .c-radio-item.ng-enter.ng-enter-active {
    opacity: 1;
    max-height: 60px;
  }
  //starting animations for leave
  .c-radio-item.ng-leave {
    opacity: 1;
    max-height: 60px;
  }
  // terminal animations for leave
  .c-radio-item.ng-leave.ng-leave-active {
    opacity: 0;
    max-height: 0;
  }
  .upload-in-progress {
    transition: all 1s $easeInExpo;
    overflow: hidden;
  }
  // starting animations for enter
  .upload-in-progress.ng-enter {
    opacity: 0;
    max-height: 0;
  }
  // terminal animations for enter
  .upload-in-progress.ng-enter.ng-enter-active {
    opacity: 1;
    max-height: 80px;
  }
  //starting animations for leave
  .upload-in-progress.ng-leave {
    opacity: 1;
    max-height: 80px;
  }
  // terminal animations for leave
  .upload-in-progress.ng-leave.ng-leave-active {
    opacity: 0;
    max-height: 0;
  }
  tr.current-package {
    @include font($weight: bold);
  }


  // This is added to reduce min-width so controlboard can show everything without overlapping.
  .c-controlboard-controls {
    .c-ui-select.ui-select-container {
      min-width: 9.375rem;
    }
    .search-bar-control {
      margin-top: 0.35rem;
    }
  }
}

.upgrade-package-action-buttons-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cluster-location-dropdown {
  .dropdown-menu {
    max-height: 70vh;
    overflow: auto;
  }
}

.c-cluster-upgrade-slider {
  margin: $cohesity-card-padding;
}

.upgrade-table {
  .cluster-name {
    width: 25%;
  }

  .health-ok {
    width: 1.25rem;
    height: 1.25rem;
    @include c-icon-property(hand-ok);
    background-size: cover;
    margin-left: 0.625rem;
  }

  .health-critical {
    @include c-icon-property(hand-warning);
  }

  .c-pulse-bar-container {
    height: 0.3125rem;
  }
}

.schedule-upgrade-column {
  display: inline-flex;
}

.c-cluster-upgrade-pulse {
  .node-upgrade-pulse .c-pulse {
    padding-top: 0.25rem;
  }
}

.package-action-button {
  margin: 1rem;
}

.package-details {
  .action-items {
    display: flex;
    justify-content: right;
    gap: 1rem;
    align-items: center;
  }
}

.pre-upgrade-checks {
  .flex-row > .col-xs-3:first-child {
      margin-left: 0!important;
  }
  h3 {
    margin-bottom: 0!important;
  }
  button {
    border-radius: 0.25rem!important;
  }
  .running-icon {
    border-radius: 10px;
    animation: spin-animation 2s linear infinite;
  }
}

@keyframes spin-animation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}

@include helix-themes() using ($theme) {
  .c-form-sleek {
    .active {
      color: helix-color($theme, helix-base, active);
    }
  }
}