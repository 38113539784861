.mat-button,
.mat-raised-button,
.mat-icon-button,
.mat-stroked-button,
.mat-flat-button,
.mat-fab,
.mat-mini-fab {
  @include cog-type-level(h5);

  &[cog-button-small] {
    @include cog-type-level(caption);
    line-height: 2em;
  }
}

// Ideally, 'cog-quick-filter-button' specific styles should be part of the component specific files.
// however, for some reasons, '.cog-quick-filter-button' is directly being applied on the button components.
// To avoid breaking such cases, we need these rules to be present in this file.
// one such example is
// https://gerrit.eng.cohesity.com/plugins/gitiles/iris-ui/+/refs/heads/iris_team/iris/libs/iris/source-tree/src/lib/
// shared/source-tree-controls/source-tree-controls.component.html#32
// All default rules as well as overrides are added to this file.
.mat-stroked-button.cog-quick-filter-button {
  @include cog-type-level(body-1);
  border-radius: 0.75rem;
  padding: 0.25rem 0.75rem;
  line-height: unset;

  cog-icon {
    position: relative;
    top: 0.3rem;
    margin: -0.625rem -0.5rem 0 0;
  }

  &.selected {
    cog-icon {
      border-radius: .75rem;
    }
  }
}

.mat-button,
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 0.75rem;
}

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);
  $is-jazz-theme: map-get($theme, is-jazz);
  $is-dark: map-get($theme, is-dark);

  .mat-button,
  .mat-raised-button,
  .mat-icon-button,
  .mat-stroked-button,
  .mat-flat-button,
  .mat-fab,
  .mat-mini-fab {
    &:focus {
      // Add focus state outline for buttons. This is for accessibility to
      // highlight the focussed buttons.
      outline-offset: 2px;
      outline: 2px solid helix-color($theme, helix-primary, 500);
      @if $is-jazz-theme and $is-dark {
        outline-color: helix-contrast($theme, helix-primary, 50);
      }
    }
  }

  .mat-button,
  .mat-raised-button,
  .mat-stroked-button:not(.cog-quick-filter-button),
  .mat-flat-button,
  .c-btn,
  .c-btn-primary,
  .c-btn-secondary,
  .c-btn-critical,
  .c-btn-subtle,
  .c-btn-warn {
    border-radius: helix-color($theme, component, button-border-radius);
  }

  // Remove box shadow from ajs button.
  .c-btn.c-btn,
  .c-btn-primary.c-btn-primary,
  .c-btn-secondary.c-btn-secondary,
  .c-btn-critical.c-btn-critical,
  .c-btn-subtle.c-btn-subtle,
  .c-btn-warn.c-btn-warn {
    box-shadow: none;
  }

  @if $is-ibm-aqua-theme {
    // remove border and set font weight for all types of buttons.
    .mat-button,
    .mat-raised-button,
    .mat-stroked-button:not(.cog-quick-filter-button),
    .mat-flat-button,
    .c-btn,
    .c-btn-primary,
    .c-btn-secondary,
    .c-btn-critical,
    .c-btn-subtle,
    .c-btn-warn {
      font-weight: $font-weight-normal;
    }

    .c-btn.c-btn {
      border: 1px solid helix-color($theme, helix-primary, 500);

      &:hover {
        background-color: helix-color($theme, helix-primary, 500);
        color: helix-color($theme, component, button-resting);
      }

      &:active {
        background-color: helix-color($theme, component, primary-button-active);
        color: helix-color($theme, component, button-resting);
      }
    }

    // need to increase the specificity to override angular material button theme.
    .mat-button,
    .mat-button[mat-button] {
      &.mat-warn:not(.mat-button-disabled) {
        color: helix-color($theme, component, warn-button-resting);
      }

      &.mat-primary:not(.mat-button-disabled) {
        &:hover {
          background-color: helix-color($theme, component, basic-button-hover);
        }

        &:active {
          background-color: helix-color($theme, component, basic-button-active);
        }
      }
    }

    // need to increase the specificity to override angular material button theme.
    // and mat-button theme, because some components have both mat-button and
    // mat-flat-button directives applied.
    .mat-raised-button,
    .mat-flat-button,
    .mat-raised-button[mat-raised-button],
    .mat-flat-button[mat-flat-button],
    .mat-button.mat-raised-button,
    .mat-button.mat-flat-button {
      &.mat-primary:not(.mat-button-disabled) {
        &:hover {
          background-color: helix-color($theme, component, primary-button-hover);
        }

        &:active {
          background-color: helix-color($theme, component, primary-button-active);
          box-shadow: none;
        }
      }

      &.mat-warn:not(.mat-button-disabled) {
        background-color: helix-color($theme, component, warn-button-resting);

        &:hover {
          background-color: helix-color($theme, component, warn-button-hover);
        }

        &:active {
          background-color: helix-color($theme, component, warn-button-active);
          box-shadow: none;
        }
      }

      &.mat-accent:not(.mat-button-disabled) {
        background-color: helix-color($theme, component, accent-button-resting);

        &:hover {
          background-color: helix-color($theme, component, accent-button-hover);
        }

        &:active {
          background-color: helix-color($theme, component, accent-button-active);
          box-shadow: none;
        }
      }
    }

    // need to increase the specificity to override angular material button theme.
    .mat-stroked-button,
    .mat-stroked-button:not(.mat-button-disabled),
    .mat-stroked-button[mat-stroked-button],
    .mat-stroked-button[mat-stroked-button]:not(.mat-button-disabled) {
      border-color: helix-color($theme, helix-primary, 500);
      color: helix-color($theme, helix-primary, 500);

      &.mat-warn:not(.mat-button-disabled) {
        color: helix-color($theme, component, warn-button-resting);
      }

      &.mat-primary:not(.mat-button-disabled) {
        &:hover {
          background-color: helix-color($theme, component, primary-button-hover);
          color: helix-color($theme, component, button-resting);
        }

        &:active {
          background-color: helix-color($theme, component, primary-button-active);
          box-shadow: none;
          color: helix-color($theme, component, button-resting);
        }
      }
    }

    // need to increase the specificity to override angular material elevation class.
    .mat-raised-button:not([class*=mat-elevation-z]),
    .mat-raised-button:not(.mat-button-disabled):active:not([class*=mat-elevation-z]),
    .mat-raised-button[mat-raised-button]:not([class*=mat-elevation-z]),
    .mat-raised-button[mat-raised-button]:not(.mat-button-disabled):active:not([class*=mat-elevation-z]) {
      box-shadow: none;
    }
  }

  .mat-stroked-button.cog-quick-filter-button {
    background-color: helix-color($theme, component, filter-btn-bg);

    &:not([disabled]) {
      border-color: helix-color($theme, component, filter-btn-border);
      color: inherit;
    }

    &:hover {
      background-color: helix-color($theme, component, filter-btn-bg-hover);
    }

    &.selected {
      background-color: helix-contrast($theme, component, filter-btn-bg);
      border-color: helix-contrast($theme, component, filter-btn-border);
      color: helix-contrast($theme, component, nav-active);

      &:hover {
        cog-icon {
          background-color: helix-contrast($theme, component, filter-btn-icon-bg-hover);
        }
      }
    }
  }
}

@include ibm-aqua-theme() {
  .mat-stroked-button.cog-quick-filter-button {
    @include cog-type-level(caption);
    height: 1.5rem;
    padding: 0 0.5rem 0 0.625rem;

    cog-icon {
      height: 1.5rem;
      padding: 0.25rem;
      width: 1.5rem;
    }

    &.selected {
      cog-icon {
        margin-left: .25rem;
      }
    }

    &:focus {
      border: none;
      outline-offset: 0;
    }

    &:focus .mat-button-focus-overlay,
    &:hover .mat-button-focus-overlay {
      opacity: 0;
    }
  }
}
