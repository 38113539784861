// c-pill styles

%c-pill {
  color: inherit;
  font-size: $body-font-size;
  line-height: 1.5625rem;
  border-radius: 0.25rem;
  position: relative;
  max-width: 23.4375rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  padding: 0 1.5625rem 0 0.625rem;
  display: inline-block;
  overflow: hidden;
  margin: 0 0.33em 0.33em 0;
}

%c-pill-close {
  @include font($weight: bold);
  color: $colorBlack;
  opacity: 0.3;
  font-size: 1.3125rem;
  line-height: 1.5625rem;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  background-color: transparent;
  width: 1.5625rem;
  border: 0;
  border-radius: 1em;
  cursor: pointer;
  text-align: center;
  font-style: normal;
  &::after {
    content: '\00D7';
  }
  &:hover,
  &:active,
  &:focus {
    opacity: 0.5;
  }
}

.c-pill {
  @extend %c-pill;
}

.c-pill-close {
  @extend %c-pill-close;

  .locked & {
    cursor: no-drop;
    opacity: 0.1;
  }
}


@include helix-themes() using ($theme) {
  .c-pill {
    background-color: helix-color($theme, background, unselected-chip);
    color: helix-color($theme, foreground, text);
  }
  .c-pill-close {
    color: helix-color($theme, foreground, secondary-text);
  }
}

.c-pill-separator {
  @include font($size: $body-font-size, $color: $textGreyLight);
  display: inline-block;
  margin: 0 0.625rem;
  border: 1px solid $colorNeutralMedium;
  padding: 1px 0.5rem;
  border-radius: 0.75rem;
  background: $colorWhite;
  vertical-align: top;
}

.c-name-pill {
  @include font(
    $size: 0.6875rem,
    $leading: 1,
    $weight: lighter
  );
  display: inline-block;
  padding: 0.125rem 0.5625rem;
  position: relative;

  h1 & {
    top: -0.1875rem;
  }
}

.c-task-status-pill {
  @include cog-type-level(chart);
  width: min-content;
  text-align: center;
  white-space: nowrap;
  float: right;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
}

@include helix-themes() using ($theme) {
  .c-name-pill {
    color: helix-contrast($theme, primary, 400);
    background-color: helix-color($theme, primary, 400);

    &.success {
      color: helix-contrast($theme, status, success);
      background-color: helix-color($theme, status, success);
    }
    &.warn {
      color: helix-contrast($theme, status, warning);
      background-color: helix-color($theme, status, warning);
    }
    &.error {
      color: helix-contrast($theme, status, error);
      background-color: helix-color($theme, status, error);
    }
  }

  .c-task-status-pill {
    color: helix-color($theme, status, info);
    background-color: helix-color($theme, status-background, info);

    &.warning-contrast {
      background-color: helix-color($theme, status, warning-low);
      color: helix-contrast($theme, status, warning);
    }

    &.c-task-deleted-pill,
    &.c-task-warn-pill,
    &.warning {
      color: helix-color($theme, status, warning);
      background-color: helix-color($theme, status-background, warning);
    }

    &.c-task-error-pill,
    &.c-task-anomalous-snapshot-pill {
      color: helix-color($theme, status, error);
      background-color: helix-color($theme, status-background, critical);
    }
  }
}
