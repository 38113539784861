// cDatePicker styles
@use "@angular/material/core/theming/theming" as mat;

.uib-datepicker:focus,
.uib-datepicker-popup.dropdown-menu:focus,
.uib-datepicker-popup.dropdown-menu li:focus,
.uib-daypicker:focus {
  outline: none;
}

c-date-picker {
  display: inline-block;
  position: relative;
  // if a c-date-picker immediately follows another
  // assume its for a date range and add spacing
  // and a dash accordingly
  & + c-date-picker {
    position: relative;
    margin-left: 30px;
    &::after {
      position: absolute;
      left: -21px;
      top: 6px;
      content: '_';
    }
    // if the second consecutive c-date-picker has a 'tight' class
    // remove the margin and pseudo class based dash
    &.tight {
      margin-left: 0;
      &::after {
        // this cancels out the above ::after styling,
        // as an element can only have a single ::after
        // psuedo class applied to it
        content: '';
      }
    }
  }
  // allow right edge alignment of the datepicker
  // popup based on presence of class
  &.right-align-popup {
    .dropdown-menu {
      left: auto !important;
      right: 0;
    }
  }

}

.c-date-picker {
  position: relative;
  .c-date-input {
    display: table-cell;
    width: 130px;
  }

  // mini calendar icon only visible for c-form-sleek elastic
  .btn [class*=icn].icn-calendar {
    display: none;
  }
  > .input-group {
    .input-group-btn {
        .btn-default {
          position: absolute;
          top: 4px;
          right: 8px;
          border: 0;
          color: #868686;
          width: 30px;
          height: 30px;
          padding: 0 20px 0 0;
          background: transparent;
          border-radius: 0;
          &:hover {
            background: transparent;
          }
          .glyphicon-calendar {
            top: -1px;
            content: '';
            width: 30px;
            height: 30px;
            @include iconImage('calendar.svg');
            background-position: 0 0;
            background-repeat: no-repeat;
            background-size: 30px 30px;
            &:before {
              display: block;
              content: '';
            }
          }
        }
      }
    }

    .c-table & td {
      padding: 5px !important;
    }
}

.c-datepicker-embedded {
  display: inline-block;
}

[uib-datepicker-popup-wrap],
.c-datepicker-embedded {
  .dropdown-menu {
    background: $colorWhite;
    padding: 0;
    border-radius: 8px;
  }
  .glyphicon-chevron-left {
    transform: rotate(90deg);
    width: 12px;
    height: 9px;
    @include iconImage('icn-arrow-row-toggle.svg');
    background-repeat: no-repeat;
  }
  .glyphicon-chevron-right {
    transform: rotate(-90deg);
    width: 12px;
    height: 9px;
    @include iconImage('icn-arrow-row-toggle.svg');
    background-repeat: no-repeat;
  }
  .glyphicon-chevron-left::before,
  .glyphicon-chevron-right::before {
    content: '';
  }
  .btn-default {
    border-color: transparent;
    background-color: transparent;
    &:hover,
    &:active,
    &:focus {
      background-color: inherit;
      outline: none;
      box-shadow: none;
    }
  }
  .btn-sm {
    padding: 6px 8px;
    border-radius: 0;
    &.btn-info {
      color: $colorWhite;
    }
  }
  .btn-info.active {
    color: $colorWhite;
  }
  .text-info {
    color: inherit;
  }
  .btn[disabled] {
    opacity: 0.25;
  }
  [ng-if=showButtonBar] {
    // this div has inline styles for some reason
    padding-top: 0 !important;
    .btn-info,
    .btn-danger,
    .btn-success {
      background: none;
      border: none;
      color: $textLink;
      padding: 5px 10px 5px 0;
    }
    .btn-success {
      padding: 5px 0;
    }
    .btn-info:hover,
    .btn-danger:hover,
    .btn-success:hover {
      text-decoration: underline;
    }
    .btn-info:active,
    .btn-danger:active,
    .btn-success:active {
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      outline: none;
    }
  }

  td button:active,
  td button:focus {
    outline: none;
  }
  td button.btn-info {
    box-shadow: none;
    border-radius: 0;
    &:hover,
    &:focus {
      outline: none;
    }
  }
  td.c-datepicker-range-start button,
  td.c-datepicker-range-end button {
    box-shadow: none;
    &:hover,
    &:focus {
        outline: none;
    }
    &.btn-info {
      box-shadow: none;
      &:hover,
      &:focus {
        outline: none;
      }
    }
  }
}

@include helix-themes() using ($theme) {
  $status-background: map-get($theme, status-background);
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  [uib-datepicker-popup-wrap],
  .c-datepicker-embedded {
    .dropdown-menu {
      background: map-get($background, dialog);
    }
    .btn-sm {
      &.btn-info {
        color: map-get($background, dialog);
      }
    }
    .btn-info.active {
      color: helix-contrast($theme, primary, 500);
    }
    [ng-if=showButtonBar] {
      .btn-info,
      .btn-danger,
      .btn-success {
        color: helix-color($theme, status, info);
      }
    }

    .uib-button-bar {
      border-top: 1px solid map-get($foreground, divider);
    }

    td button .text-muted {
      color: mat.get-color-from-palette($foreground, text, 0.56);
    }
    td button.btn-info {
      color: map-get($background, dialog);
      background-color: map-get($status-background, info-emphasis);
      &:hover,
      &:focus {
        background-color: map-get($status-background, info-enphasis);
      }
      .text-muted {
        color: mat.get-color-from-palette($foreground, text, 0.56);
      }
    }
    td.c-datepicker-range-start button,
    td.c-datepicker-range-end button {
      &.btn.btn-info {
        color: helix-contrast($theme, primary, 500);
        background-color: helix-color($theme, primary, 500);
      }
    }
  }

  .c-datepicker-embedded td.c-datepicker-in-range {
    background-color: map-get($status-background, info);
    .btn {
      color: map-get($foreground, secondary-text);
    }
  }

  // Bootstrap uib-datepicker overrides
  [uib-datepicker] thead tr th,
  [uib-datepicker] tbody tr td {
    border-bottom: 1px solid map-get($foreground, divider);
  }

  .uib-daypicker table thead tr:nth-child(even) th,
  [uib-datepicker] tbody td {
    border-right: 1px solid map-get($foreground, divider);
  }
}


[uib-datepicker] thead tr:first-child th  {
  height: 50px;
}



.uib-daypicker table thead tr:nth-child(even) th:nth-of-type(7),
.uib-daypicker table tbody td:nth-of-type(7) {
  border-right: none;
}

.uib-daypicker table tbody tr:last-child td {
  border-bottom: none;
}

// Month Label
[uib-datepicker] thead tr th strong {
  @include font(regular, 19px, false, $buttonSecondaryText, normal);
}

// Day Column Labels
.uib-daypicker table thead tr th small {
  // Because Bootstrap relies on Angulars Date format
  // we cannot format days of the week labels to single letter
  // We can compensate with CSS
  // Set font-size to 0 hides all letters
  @include font(regular, 0, 38px, $buttonSecondaryText);
  // Because first-letter only works on block level elements
  // we force the small element to display block
  display: block;
  font-size: 0;
  height: 38px;
  &:first-letter {
    // Override the font-size of
    // the first letter of the day of the week labels
    font-size: 16px;
  }
}

// Day Labels
.uib-daypicker table tbody tr td span {
  @include font(regular, 16px, false, false, normal);
}

// form sleek styling for datepicker.
.c-form-sleek {
  c-date-picker {
    display: block;
  }

  &.elastic c-date-picker {
    // hide big calendar icon for c-form-sleek elastic styling
    .glyphicon-calendar {
      display: none;
    }

    .c-date-picker {
      // show mini calendar icon.
      .icn-calendar {
        display: block;
      }

      .input-group {
        input.c-date-input {
          padding: 0;
        }

        .input-group-btn .btn-default {
          top: 0;
          right: 1px;
          padding: 0;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}
