// COMPONENT: cSpinner (and button/input variants).

// Button Spinner

[class*="c-btn"][c-btn-spinner] {
  position: relative;

  ng-spinner {
    display: none;
  }
  &.spinning {
    color: transparent;

    ng-spinner {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}

// Input Spinner

.c-input-spinner-wrapper {
  display: block;
  width: 100%;
  position: relative;

  .elastic & {
    display: inline-block;
  }
}
[c-input-spinner] {
  ~ ng-spinner {
    display: none;
  }
  &.spinning ~ ng-spinner {
    display: block;
    position: absolute;
    right: 0.5rem;
    top: 0.75rem;

    .c-form-sleek & {
      top: 0.75rem;
    }
  }

}
