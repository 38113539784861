@import 'dependencies';

// Mixin function to show the content on hover over the
// element associate to the class with appropriate child selector
// $childSelector - child selector which needs to be visible or hidden
// $parentSelector - container to be specified on the hover element
// Above params are mandatory

// example - 1: div element will be shown only when we hover over the
//              parent 'td'
// td -> $parentSelector
// div -> $childSelector
// td > div
// <cog-table>
//     ...
//     <td>
//         <div>...</div>
//     </td>
//     ...
// </cog-table>

// example - 2: div element will be hidden until we hover over the
//              parent 'td'. But, 'span' won't be hidden
// td -> $parentSelector
// div -> $childSelector
// td > div
// <cog-table>
//     ...
//     <td>
//         <div>...</div>
//         <span>...</span>
//     </td>
//     ...
// </cog-table>
@mixin table-show-on-hover-cell($parentSelector: null, $childSelector: null) {
  @if ($parentSelector == null) or ($childSelector == null) {
    @error "Params $parentSelector and $childSelector are mandatory";
  }

  cog-table,
  .cog-table {
    // Support both mouse (hover) and touch pad ( fine pointer)
    @media (hover: hover) and (pointer: fine) {
      #{$parentSelector} > #{$childSelector} {
        visibility: hidden;
      }

      .mat-row:hover,
      .mat-row:focus-within,
      #{$parentSelector} > #{$childSelector}.is-open {
        #{$parentSelector} > #{$childSelector} {
          visibility: visible;
        }
      }
    }
  }
}

// Including the mixin below, hides all the child elements (*) under a container with
// attribute class="show-on-hover-cell"
// example:
// <cog-table ..>
//   <table ...>
//     <tr>
//       <td .. class="show-on-hover-cell">
//         <div..> .... </div>
//       </td>
//     </tr>
//   </table>
// </cog-table>
// In the above case the td will be shown only when it has been hovered.
@include table-show-on-hover-cell('.show-on-hover-cell', '*');

cog-table,
.cog-table {
  // Updating per material.io spec. Angular Material has this at 48px whereas
  // material.io spec pegs at 52px (3.25rem).
  $mat-row-height: 3.25rem;
  display: block;
  position: relative;
  margin-left: #{$helix-table-bleed * -1};
  margin-right: #{$helix-table-bleed * -1};

  mat-row,
  mat-footer-row {
    min-height: $mat-row-height;
  }

  tr.mat-row,
  tr.mat-footer-row {
    height: $mat-row-height;
  }

  .mat-header-cell {
    @include cog-type-level(body-2);
  }

  &.standard-width {
    margin-left: 0;
    margin-right: 0;
  }

  &:not(.standard-width) {
    td.mat-cell:first-of-type,
    td.mat-footer-cell:first-of-type,
    th.mat-header-cell:first-of-type,
    mat-cell:first-of-type,
    mat-header-cell:first-of-type,
    mat-footer-cell:first-of-type {
      padding-left: $helix-table-bleed;
    }

    td.mat-cell:last-of-type,
    td.mat-footer-cell:last-of-type,
    th.mat-header-cell:last-of-type,
    mat-cell:last-of-type,
    mat-header-cell:last-of-type,
    mat-footer-cell:last-of-type {
      padding-right: $helix-table-bleed;
    }

    .mat-paginator-container {
      padding-right: $helix-table-bleed;
    }
  }

  .mat-table {
    width: 100%;
    @include cog-type-level('button');
  }

  // Ensure content doesn't touch other columns content.
  td.mat-cell,
  th.mat-header-cell,
  mat-cell.mat-cell,
  mat-header-cell.mat-header-cell {
    padding-right: 0.5rem;
  }

  // Table rows that have uiSref are clickable.
  tr[uiSref],
  mat-row[uiSref] {
    cursor: pointer;
  }

  // TODO(dcoblentz) The cell padding and top align settings only work for html
  // tables and not flex tables.

  // Padding is usually needed if vertical borders need to be shown within the
  // table.
  &.cell-padding {
    td.mat-cell,
    th.mat-header-cell {
      padding: 0 1rem;
    }
  }

  &.top-align {
    td.mat-cell {
      vertical-align: top;
      padding-top: 0.875rem;
    }
    td.mat-column-select {
      padding-top: 1rem;
    }
  }

  // general settings for select column
  .mat-column-select {
    width: 2.75rem;

    .mat-checkbox label {
      margin: 0;
    }
  }

  .mat-column-action {
    width: 4rem;
    max-width: 4rem;
  }

  // For flex layout tables only, always top align the cells
  mat-footer-row,
  mat-header-row,
  mat-row {
    align-items: flex-start;
  }

  // Common settings to apply to any table with a select column
  .mat-column-select {
    // For flex layout tables only, reset flex from 1 to initial so that the
    // column width doesn't change.
    flex: initial;
  }

  .loading-row {
    cog-spinner {
      display: block;
      margin: 1rem;
    }
  }
}

.cog-table-actions-row {
  // subtract header bottom border from header height
  height: calc(3.5rem - 1px);
  padding: 0 $helix-table-bleed;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  border-radius: 0.25rem 0.25rem 0 0;

  &.hidden {
    display: none;
  }

  &::before {
    border-radius: 0.25rem 0.25rem 0 0;
  }

  .mat-stroked-button {
    & + .mat-stroked-button {
      margin-left: 0.5rem;
    }

    cog-icon {
      transform: scale(0.65);
    }
  }
}

.cog-table-object {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: center;

  > cog-icon,
  .cog-table-object-visual {
    position: relative;
    order: -1;
    flex: 0 0 auto;
    margin: 0 0.5rem 0 0;
  }
}

.cog-table-object-body {
  @include cog-type-level(body);
  flex: 1 1 auto;

  // If an unorder list doesn't make sense for an implementation, this class
  // can be used to get a consistent visual treatment.
  .cog-table-object-meta {
    @include cog-type-level(caption);
  }

  ul {
    @include cog-type-level(caption);
    line-height: 1em;
    list-style-type: none;

    // Using this margin to substitute for line-height so the border/seperator
    // isn't oversized compared to text.
    margin: 0.25em 0;
  }

  li {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    margin-bottom: 0;
    padding-right: 0.5em;
    margin-right: 0.5em;

    &:last-child {
      padding-right: 0;
      margin-right: 0;
    }
  }
}

@include helix-themes() using ($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $base: map-get($theme, helix-base);
  $resting-color: map-get($base, resting);
  $active-color: map-get($base, active);

  .cog-table-actions-row {
    background: helix-color($theme, background, card);
    z-index: 1;

    &::before {
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      position: absolute;
      content: '';
      background: helix-color($theme, component, table-header-selected);
    }

    .mat-stroked-button.mat-primary {
      @if $is-dark-theme {
        // In dark theme, the contrast color here pops hard and
        // makes the button noticable.
        background: helix-contrast($theme, helix-base, primary);
        color: helix-color($theme, helix-base, primary);
      } @else {
        // In a light theme, just the primary color as the button
        // background so the button pops harder.
        background: helix-color($theme, helix-base, primary);
        color: helix-contrast($theme, helix-base, primary);
      }
    }
  }

  .cog-table-object-body {
    li {
      border-right: 1px solid $resting-color;

      &:last-child {
        border-right: none;
      }
    }
  }

  cog-table,
  .cog-table {
    .mat-header-cell {
      color: $resting-color;
    }

    .mat-cell.mat-table-sticky {
      border-right: solid 2px helix-color($theme, foreground, divider);
    }

    // If a table object is not an anchor, give it active color. If it's an
    // anchor there will be special handling applied in the media query below.
    .cog-table-object-name:not(a) {
      color: $active-color;
    }

    // Add special styling for anchors in tables if, and only if, the user
    // is known to have a hover and fine pointer capabilities (mouse or touchpad).
    @media (hover: hover) and (pointer: fine) {
      // Anchors in a table should be colored as standard text and on hover of the
      // row should show up as textual links.
      a {
        color: $resting-color;
      }

      a.cog-table-object-name {
        color: $active-color;
      }

      mat-row,
      .mat-row {
        &:hover,
        &:focus,
        &:focus-within {
          background-color: helix-color($theme, state-secondary, hover);

          a,
          a.cog-table-object {
            color: helix-color($theme, component, anchor);
          }
        }
      }
    }
  }

  .hover-element:hover {
    background-color: helix-color($theme, state-secondary, hover);
  }
}

@include reflow() {
  cog-table.reflow-table-x-scrollable-disabled {
    overflow-x: unset;
    > .mat-table {
      min-width: unset;
    }
  }

  cog-table.reflow-table-x-scrollable\@sm > .mat-table {
    @include reflow-min-width-sm();
  }

  cog-table.reflow-table-x-scrollable\@xs > .mat-table {
    @include reflow-min-width-xs();
  }

  @include reflow-sm() {
    cog-table {
      overflow-x: auto;

      .mat-table {
        @include reflow-min-width-xs();

        .mat-header-row.mat-table-sticky {
          position: relative !important;
        }
      }

      .mat-paginator-container {
        justify-content: flex-start;
        flex-wrap: nowrap;
        min-width: fit-content;

        .mat-paginator-range-label {
          white-space: nowrap;
        }

        .mat-paginator-page-size-label {
          white-space: nowrap;
        }
      }
    }
  }
  @include reflow-xs() {
    cog-table {
      .mat-paginator-container {
        flex-wrap: wrap;

        .mat-paginator-range-label {
          margin: unset;
        }

        .mat-paginator-page-size-label {
          white-space: nowrap;
        }
      }
    }
  }
}
