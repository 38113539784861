// NOTE: this file should only address color changes. If making structural changes to a component
@use "@angular/material/core/theming/theming" as mat;
// please put them in a separate file (i.e mat-card.scss).

@mixin cog-mat-radio-theme($theme) {
  $foreground: map_get($theme, foreground);
  $disabled-color: mat.get-color-from-palette($foreground, disabled-button);

  .mat-radio-button.mat-radio-checked {
    // Make the primary palette the default rather than accent.
    // This is done by targeting slide toggles without a color specified.
    &.mat-accent:not([color]):not(.mat-radio-disabled) {
      .mat-radio-outer-circle {
        border-color: helix-color($theme, primary, 500);
      }
      .mat-radio-inner-circle {
        background: helix-color($theme, primary, 500);
      }
    }
  }

  .mat-radio-outer-circle {
    border-color: rgba(map_get($foreground, secondary-text), 0.75);
  }

  // Customizing disabled coloring, as these are walled in from material theming.
  .mat-radio-button.mat-radio-disabled,
  .mat-radio-button.mat-radio-disabled.mat-radio-checked {
    .mat-radio-outer-circle {
      border-color: $disabled-color;
    }

    .mat-radio-inner-circle {
      background-color: $disabled-color;
    }
  }
}

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .mat-radio-outer-circle {
      border-width: 1px;
    }

    .mat-radio-button.mat-radio-checked {
      &.mat-accent:not([color]):not(.mat-radio-disabled) {
        .mat-radio-outer-circle.mat-radio-outer-circle {
          border-color: helix-color($theme, component, radio);
        }

        .mat-radio-inner-circle.mat-radio-inner-circle {
          background: helix-color($theme, component, radio);
        }
      }
    }

    .mat-radio-button.cdk-focused .mat-radio-outer-circle {
      outline-offset: 2px;
      outline: 2px solid helix-color($theme, helix-primary, 500);
    }
  }
}
