// Page Styles
@use "@angular/material/core/theming/theming" as mat;

html {
  min-height: 100%;
}

body {
  min-width: $min-page-width;

  &.has-c-page-header {
    min-width: $min-page-width;
  }

  // This makes sure that the page content do not overflow into the main content area.
  &.in-helios-frame {
    padding: 0 1rem !important;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

// Undo bootstrap's vertical align rule which is screwing up primary nav.
svg {
  vertical-align: inherit;
}

// When a normal modal is open, ensure the nav is below the backdrop (uib modal
// backdrop starts at zIndex of 1040). This needs override rules from
// cohesity-common (!important).
.modal-open .cohesity-app-bar {
  z-index: 1030 !important;
}

// thanks to normalize.css, we need to override
// this special box sizing rule
input[type="search"] {
  box-sizing: border-box;
}

// UI-BOOTSTRAP HREF FIX
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer;
}

@include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
  .c-box {
    background-color: map_get($background, card);
    border: 1px solid map_get($background, card-border);
  }
}

// C-BOX
.c-box {
  padding: $cohesity-card-padding;
  border-radius: 0.25rem;

  // c-boxes inside of .c-form-sleek should have reduced padding
  .c-form-sleek & {
    padding: $cBoxNestedPadding;
  }

  &.xs {
    padding: 0.5rem;
  }

  &.sm {
    padding: 1rem;
  }
}

.c-box-brand {
  position: relative;
  border-top: none;
  padding: 1rem;

  &.lg {
    padding: $cohesity-card-padding;
  }
  &.xs {
    padding: 0.5rem;
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 0.125rem;
  }
}

@include helix-themes using ($theme) {
  .c-box-brand {
    background: helix-color($theme, background, card);
    border: 1px solid helix-color($theme, foreground, divider);

    &::before {
      background: helix-color($theme, primary, 500);
    }
  }
}

.c-box-meta,
.c-box-table-meta {
  border-radius: $defaultRadius;
  padding: $cohesity-card-padding;

  &.c-box-table-meta {
    padding: $cBoxNestedPadding;
    margin: 0 0 15px 0;
  }

  .c-message-inline {
    line-height: 20px;
  }
}

@include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
  .c-box-meta,
  .c-box-table-meta {
    background-color: mat.get-color-from-palette($background, background);
  }

  .c-box-section-secondary {
    background-color: mat.get-color-from-palette($background, app-bar);
  }
}

.c-box-section {
  border-top: 1px solid $colorBorder;
  margin: 0 (-$cBoxNestedHorizontalPadding);
  padding: $cBoxNestedVerticalPadding $cBoxNestedHorizontalPadding;

  // set different padding when box-section is close.
  &.c-box-section-closed {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  // reset border top for  1st child of box section
  &:first-child {
    border-top: none;
  }
}

.c-box-section-header {
  margin-bottom: 1rem;
}

.c-box,
.c-box-meta {
  > .icn-close {
    position: absolute;
    right: $cBoxNestedHorizontalPadding;
    top: $cBoxNestedVerticalPadding;
  }
}

// C-TILE
[class*="c-tile"]:not(.c-tile-content):not(.c-tile-header):not(.c-tile-footer) {
  margin-bottom: 1.25rem;
}


.page-actions {
  text-align: right;
}

.cursor-forbidden {
  cursor: not-allowed;
}

.redux-value {
  &::after {
    position: relative;
    content: 'x';
  }
}

// horizontal rule
hr {
  width: 100%;
  margin: 2rem 0;

  &.xxs {
    margin: 0.25rem 0;
  }
  &.xs {
    margin: 0.5rem 0;
  }
  &.sm {
    margin: 1rem 0;
  }
  &.lg {
    margin: 4rem 0;
  }
}

@include helix-themes() using ($theme) {
  $border-color: helix-color($theme, foreground, divider);

  // horizontal rule
  hr {
    border-top: 0.0625rem dashed $border-color;
  }

  // one off borders
  .border-left {
    border-left: 1px solid $border-color;
  }

  .border-right {
    border-right: 1px solid $border-color;
  }

  .border-top {
    border-top: 1px solid $border-color;
  }

  .border-bottom {
    border-bottom: 1px solid $border-color;

    &-padded {
      border-bottom: 1px solid $border-color;
    }
  }
}

.no-wrap {
  white-space: nowrap !important;
}
.wrap {
  white-space: normal !important;
}

.status-label {
  display: inline-block;
}

@include helix-themes() using ($theme) {
  // Need .c-table td as without it, the following will be override by .jazz-light-theme .c-table td
  .c-table td,
  * {
    &.status-ok {
      color: helix-color($theme, status, success);
    }

    &.status-warning {
      color: helix-color($theme, status, warning);
    }

    &.status-critical {
      color: helix-color($theme, status, error);
    }

    &.status-info {
      color: helix-color($theme, status, info);
    }
  }
}

// Bootstrap popover style overrides
.popover {
  @include font($size: 0.6875rem, $leading: 0.875rem);
  box-shadow: $popoverShadow;
  border-color: transparent;
}

.group-container {
  $containerPadding: 15px;
  $flagSize: 30px;
  box-shadow: $defaultBoxShadow;
  padding: $containerPadding;
  position: relative;

  // NOTE: Flags have been removed, but we still need to push these
  // implementations down a bit so they don't rest directly against the
  // related toggle.
  &.flagged {
    margin-top: 0.5rem;
  }
}

@include helix-themes() using ($theme) {
  $dialog-color: helix-color($theme, background, dialog);

  .popover {
    background-color: $dialog-color;

    @each $side in $popoverSides {
      &.#{$side} > .arrow,
      &.#{$side} > .arrow::after {
        border-#{$side}-color: $dialog-color;
      }
    }
  }

  .group-container {
    background-color: helix-color($theme, background, card);
  }
}

// TWBS Media Object overrides
.media-object {
  // Only affects icons that are children of .media-object.
  [class*='icn-'] {
    margin-right: 0;
  }
}

// Expandable Container Rules
// TODO: investigate replacing this with seeMore component
.expandable-container {
  transition: max-height $defaultDuration ease;
  max-height: 0;
  overflow: auto;
}

.expandable-container.expanded {
  max-height: 1000px;
}


// Styles to restrict the width of content and force it to wrap
// This first one allows full-width wrapping without a width restriction.
// Common use: .user-data-wrap
[class*="user-data"] {
  display: inline-block;
  white-space: normal;
  overflow-wrap: anywhere;
  max-width: 100%;
}
.user-data-xs {
  max-width: 75px;
}
.user-data-sm {
  max-width: 150px;
}
.user-data-md {
  max-width: 225px;
}
.user-data-lg {
  max-width: 300px;
}
.user-data-xl {
  max-width: 500px;
}
.user-data-xxl {
  max-width: 800px;
}

.footer-counts,
.c-form .footer-counts {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  list-style: none;
  li {
    float: left;
    margin-left: 15px;
    text-align: center;
    .footer-counts-value {
      @include font($weight: light, $size: 1.25rem, $leading: 1);
      transition-property: transform;
      transition-duration: $defaultDuration;
      transition-timing-function: ease;
      // by adding an angular-animate class to footer-counts-value
      // and adding a class with the contained value, the number will
      // spin in place when it changes
      // @example
      // <div class="footer-counts-value angular-animate count-{{jobObjectsCountByType[1]}}">
      //     {{jobObjectsCountByType[1]}}
      // </div>
      &[class*="-remove"] {
        transform: rotate(360deg);
        transition-duration: 0s;
      }
    }
  }
  label {
    @include font($size: 0.75rem, $leading: 1);
    margin: 3px 0 0;
  }
}

// Override bootstrap muted text. further overwritten for uiSelect.
// TODO: theme
.text-muted {
  color: $colorSecondaryDark;
  font-weight: 100;
}

// hide all IE specific text input 'clear' buttons
::-ms-clear {
  width: 0;
  height: 0;
}

// Bootstrap Media Object overrides
.media {
  // Because .media is overflow:hidden by necessity, some things get clipped
  // by a px or so on the left edge. Also on the right for pull-right?
  > .pull-left {
    padding-left: 1px;
  }
}


// Bootstrap uib-timepicker overrides
// We don't want to inherit td border styles on time pickers within c-tables
.c-table td .uib-timepicker tr td {
  border-bottom: none;
}

// make line breaks (via \n) function within a uibTooltip
.tooltip-inner {
  white-space: pre-wrap;

  .tooltip-break-all & {
    word-break: break-word;
    word-wrap: break-word;
    white-space: normal;
  }
}

.clickable {
  cursor: pointer;
}
a[disabled] {
  @include disabledProperties();
  pointer-events: none;
}

// This snippet displays a whitespace formatted block (via the PRE tag) in the
// lower right corner of the viewport. It is low opacity until hover, when it
// goes 100% opaque and increases size for readability.
//
// Usage:
// <pre -debug-json>{{myVar | json}}</pre>
// <pre -debug-json="open">{{myVar | json}}</pre>
//
// Angular usage (currently no forced open usage):
// <pre class="debug-json">{{whatever | json}}</pre>
.debug-json,
[-debug-json] {
  transition: all 0.3s ease-in;
  position: fixed;
  z-index: 9999;
  bottom: 1rem;
  right: 1rem;
  font-size: 0.75rem;
  max-width: 30%;
  max-height: 6rem;
  overflow: hidden;
  opacity: 0.55;

  &::before {
    content: 'Debugging Data';
    display: block;
    font-weight: 700;
  }
  &[-debug-json="open"],
  &:focus,
  &:hover {
    opacity: 1;
    max-height: 80%;
    max-width: 75%;
    overflow: auto;

    &::before {
      content: none;
    }
  }
  &[-debug-json="open"] {
    opacity: 0.75;
    max-width: 45%;
  }
}

@include helix-themes() using ($theme) {
  .debug-json,
  [-debug-json] {
    color: helix-color($theme, status, error);
  }
}

.centered {
  text-align: center;
}

// Override z-index for cdk components as uib-modal sits at z-index of 1051.
body:not(.from-takeover) .cdk-overlay-container {
  z-index: 1051;
}

// Overrid z-index for ngx-material-timepicker as it's default value is 999
// which makes it come under the modals/dialogs (1051).
.timepicker-overlay {
  z-index: 1052 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1052 !important;
}

@include reflow() {
  // When reflow is enabled, we need to remove min-width rules so
  // the page can collapse/reflow. This rule be applied to the body via
  // the reflow mixin.
  min-width: auto;

  &.has-c-page-header {
    min-width: auto;
  }
}
