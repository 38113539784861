// Component: cSnapshotsCalendar

$activeButtonRadius: 2.5rem !default;

.c-snapshots-calendar-wrapper {
  height: $cSnapshotSuiteHeight;
  padding: $cohesity-card-padding;
}
c-snapshots-calendar {
  // UIB Calendar overrides
  [uib-daypicker][uib-daypicker] {
    thead th:nth-child(1n),
    tbody td {
      position: relative;
      border: none;
    }
    tr {
      border: none;
    }
    td button[class*="btn"] {
      @include font($size: $activeButtonRadius * 0.45);
      border-radius: $activeButtonRadius;
      min-width: 0;
      width: $activeButtonRadius;
      height: $activeButtonRadius;
      margin: 0 auto;
      line-height: 1;
      padding: 0 0;
      color: $colorPrimary;

      > span {
        @include font($size: inherit);
      }
      &:not([disabled]) {
        transition: 0.2s ease-out;

        &.btn-info.btn-info {
          background: $colorPrimary;
          color: $colorWhite;
          box-shadow: 1px 1px 0.5rem rgba($colorBlack, 0.5);
        }

        &:hover {
          transform: scale(1.2);
          background: $buttonDisabledBackground;
        }
      }
    }
  }
}
