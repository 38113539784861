
// For seperating groupings of form elements that sit side-by-side.
@use "@angular/material/core/style/elevation" as mat;
.cog-inline-form-group {
  padding-right: 1.5rem;
}

mat-slide-toggle {
  + mat-hint {
    // Position the hint such that it rests below and inline with the toggle text.
    display: block;
    padding-left: 2.875rem;

    // Make the font smaller, matching treatment of a mat-hint in a mat-form-field.
    font-size: 75%;
  }
}

// Disable extra border added by material styles for indicating focus on cards.
mat-card.mat-focus-indicator::before {
  border: none;
}

[cogFormFocusedHighlight] {
  position: relative;

  [cogFormGroup] {
    position: relative;
  }

  [cogFloatingFormMenu] {
    $floating-menu-width: 3.125rem;

    position: absolute;
    width: $floating-menu-width;
    top: 0;
    right: -($floating-menu-width + 0.5rem);
    padding: 0.3125rem;
    margin: 0;
    display: flex;
    flex-direction: column;
    transition: all .2s cubic-bezier(0.0,0.0,0.2,1);

    &.hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
}

// Adjust the height of .mat-select-trigger when used with .mat-chip-list to prevent height issues.
// https://github.com/angular/components/issues/24747
.mat-select-trigger:has(.mat-chip-list) {
  height: auto;
}

@include helix-themes() using ($theme) {
  .mat-radio-label-content {
    color: helix-color($theme, helix-base, active);
  }

  [cogFormFocusedHighlight] [cogFormGroup].active,
  .mat-card.cog-form-focused-active,
  .cog-form-focused-active {
    @include mat.elevation(3);
    transition: box-shadow .25s cubic-bezier(0.0,0.0,0.2,1);
    z-index: 1;

    &::before {
      background-color: helix-color($theme, primary, 500);
      display: block;
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.125rem;
      overflow: hidden;
      z-index: 1;
    }
  }
}
