.mat-typography {
  h1 {
    @include cog-type-level(h1);
  }

  h2 {
    @include cog-type-level(h2);
  }

  h3 {
    @include cog-type-level(h3);
  }

  h4 {
    @include cog-type-level(h4);
  }

  h5 {
    @include cog-type-level(h5);
  }

  h6 {
    @include cog-type-level(h6);
  }

  .mat-dialog-title {
    @include cog-type-level(modal-title);
  }
}

@include helix-themes() using ($theme) {
  $active-color: helix-color($theme, helix-base, active);

  .mat-dialog-title {
    color: $active-color;
  }

  h1 {
    color: $active-color;
  }

  h2, h3, h4, h5, h6 {
    &.title {
      color: $active-color;
    }
  }
}
