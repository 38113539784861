.throttling-wrapper {
  .slider {
    margin: 1rem 0;
    height: 2px;
  }

  input.percentInput {
    max-width: 2.5rem;
    margin: 1rem;
  }

  span.suffix {
    position: relative;
    right: 2rem;
  }
}
