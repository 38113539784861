.ibm-aqua-light-theme {
  --hlx-typography-font-family: "IBM Plex Sans", sans-serif;

  // Font weight bold
  --typography-font-weight-bold: 600;

  // Typography config
  // Note: Specify cog-type-config values in pixels without unit.
  // This absolute value is further converted into rem.
  // h1
  --typography-absolute-font-size-h1: 28;
  --typography-absolute-line-height-h1: 36;

  // h2
  --typography-absolute-font-size-h2: 20;
  --typography-absolute-line-height-h2: 28;

  // h3
  --typography-absolute-font-size-h3: 16;
  --typography-absolute-line-height-h3: 24;
  --typography-absolute-font-weight-h3: var(--typography-font-weight-bold);

  // h4
  --typography-absolute-font-size-h4: 16;
  --typography-absolute-line-height-h4: 24;

  // h5
  --typography-absolute-font-size-h5: 14;
  --typography-absolute-line-height-h5: 20;

  // h6
  --typography-absolute-font-size-h6: 12;
  --typography-absolute-line-height-h6: 16;
  --typography-absolute-font-weight-h6: #{$font-weight-normal};

  // modal-title
  --typography-absolute-font-size-modal-title: 20;
  --typography-absolute-line-height-modal-title: 28;

  // banner
  --typography-absolute-font-size-banner: 42;
  --typography-absolute-line-height-banner: 50;

  // body
  --typography-absolute-font-size-body: 14;
  --typography-absolute-line-height-body: 20;

  // body-lg
  --typography-absolute-font-size-body-lg: 16;
  --typography-absolute-line-height-body-lg: 24;

  // chart
  --typography-absolute-font-size-chart: 10;
  --typography-absolute-line-height-chart: 16;

  // caption
  --typography-absolute-font-size-caption: 12;
  --typography-absolute-line-height-caption: 16;

  // chart-display
  --typography-absolute-font-size-chart-display: 32;
  --typography-absolute-line-height-chart-display: 40;

  // gb-value
  --typography-absolute-font-size-gb-value: 20;
  --typography-absolute-line-height-gb-value: 28;

  // gb-unit
  --typography-absolute-font-size-gb-unit: 16;
  --typography-absolute-line-height-gb-unit: 24;

  // gb-value-sm
  --typography-absolute-font-size-gb-value-sm: 16;
  --typography-absolute-line-height-gb-value-sm: 24;

  // gb-unit-sm
  --typography-absolute-font-size-gb-unit-sm: 12;
  --typography-absolute-line-height-gb-unit-sm: 16;

  // gb-value-lg
  --typography-absolute-font-size-gb-value-lg: 20;
  --typography-absolute-line-height-gb-value-lg: 28;

  // gb-unit-lg
  --typography-absolute-font-size-gb-unit-lg: 16;
  --typography-absolute-line-height-gb-unit-lg: 24;

  // gb-value-xl
  --typography-absolute-font-size-gb-value-xl: 42;
  --typography-absolute-line-height-gb-value-xl: 50;

  // gb-unit-xl
  --typography-absolute-font-size-gb-unit-xl: 16;
  --typography-absolute-line-height-gb-unit-xl: 24;

  // gb-label
  --typography-absolute-font-size-gb-label: 12;
  --typography-absolute-line-height-gb-label: 16;

  // gb-title
  --typography-absolute-font-size-gb-title: 10;
  --typography-absolute-line-height-gb-title: 16;

  // Mat typography config variables
  // headline
  --typography-absolute-font-size-headline: 28;
  --typography-absolute-line-height-headline: 36;

  // title
  --typography-absolute-font-size-title: 20;
  --typography-absolute-line-height-title: 28;

  // subheading-2
  --typography-absolute-font-size-subheading-2: 16;
  --typography-absolute-line-height-subheading-2: 24;
  --typography-absolute-font-weight-subheading-2: var(--typography-font-weight-bold);

  // subheading-1
  --typography-absolute-font-size-subheading-1: 14;
  --typography-absolute-line-height-subheading-1: 20;
  --typography-absolute-font-weight-subheading-1: var(--typography-font-weight-bold);

  // body-2
  --typography-absolute-font-size-body-2: 14;
  --typography-absolute-line-height-body-2: 20;
  --typography-absolute-font-weight-body-2: #{$font-weight-normal};

  // body-1
  --typography-absolute-font-size-body-1: 14;
  --typography-absolute-line-height-body-1: 20;

  // caption
  --typography-absolute-font-size-caption: 12;
  --typography-absolute-line-height-caption: 16;

  // button
  --typography-absolute-font-size-button: 14;
  --typography-absolute-line-height-button: 14;

  // input
  --typography-absolute-font-size-input: 16;

  // display-1
  --typography-absolute-font-size-display-1: 10;
  --typography-absolute-line-height-display-1: 16;

  // display-2
  --typography-absolute-font-size-display-2: 20;
  --typography-absolute-line-height-display-2: 28;

  // display-3
  --typography-absolute-font-size-display-3: 28;
  --typography-absolute-line-height-display-3: 36;

  // display-4
  --typography-absolute-font-size-display-4: 32;
  --typography-absolute-line-height-display-4: 40;
}

.jazz-light-theme,
.jazz-dark-theme {
  --hlx-typography-font-family: "inter", Helvetica, Arial, sans-serif;

  // Font weight bold
  --typography-font-weight-bold: 500;

  // Typography config
  // Note: Specify cog-type-config values in pixels without unit.
  // This absolute value is further converted into rem.
  // h1
  --typography-absolute-font-size-h1: 25;
  --typography-absolute-line-height-h1: 36;

  // h2
  --typography-absolute-font-size-h2: 21;
  --typography-absolute-line-height-h2: 28;

  // h3
  --typography-absolute-font-size-h3: 18;
  --typography-absolute-line-height-h3: 28;
  --typography-absolute-font-weight-h3: #{$font-weight-normal};

  // h4
  --typography-absolute-font-size-h4: 16;
  --typography-absolute-line-height-h4: 24;

  // h5
  --typography-absolute-font-size-h5: 14;
  --typography-absolute-line-height-h5: 20;

  // h6
  --typography-absolute-font-size-h6: 12;
  --typography-absolute-line-height-h6: 16;
  --typography-absolute-font-weight-h6: var(--typography-font-weight-bold);

  // modal-title
  --typography-absolute-font-size-modal-title: 18;
  --typography-absolute-line-height-modal-title: 32;

  // banner
  --typography-absolute-font-size-banner: 48;
  --typography-absolute-line-height-banner: 60;

  // body
  --typography-absolute-font-size-body: 14;
  --typography-absolute-line-height-body: 20;

  // body-lg
  --typography-absolute-font-size-body-lg: 16;
  --typography-absolute-line-height-body-lg: 24;

  // chart
  --typography-absolute-font-size-chart: 10;
  --typography-absolute-line-height-chart: 16;

  // caption
  --typography-absolute-font-size-caption: 12;
  --typography-absolute-line-height-caption: 16;

  // chart-display
  --typography-absolute-font-size-chart-display: 32;
  --typography-absolute-line-height-chart-display: 48;

  // gb-value
  --typography-absolute-font-size-gb-value: 25;
  --typography-absolute-line-height-gb-value: 36;

  // gb-unit
  --typography-absolute-font-size-gb-unit: 12;
  --typography-absolute-line-height-gb-unit: 28;

  // gb-value-sm
  --typography-absolute-font-size-gb-value-sm: 16;
  --typography-absolute-line-height-gb-value-sm: 24;

  // gb-unit-sm
  --typography-absolute-font-size-gb-unit-sm: 12;
  --typography-absolute-line-height-gb-unit-sm: 16;

  // gb-value-lg
  --typography-absolute-font-size-gb-value-lg: 32;
  --typography-absolute-line-height-gb-value-lg: 48;

  // gb-unit-lg
  --typography-absolute-font-size-gb-unit-lg: 24;
  --typography-absolute-line-height-gb-unit-lg: 32;

  // gb-value-xl
  --typography-absolute-font-size-gb-value-xl: 32;
  --typography-absolute-line-height-gb-value-xl: 32;

  // gb-unit-xl
  --typography-absolute-font-size-gb-unit-xl: 24;
  --typography-absolute-line-height-gb-unit-xl: 32;

  // gb-label
  --typography-absolute-font-size-gb-label: 12;
  --typography-absolute-line-height-gb-label: 16;

  // gb-title
  --typography-absolute-font-size-gb-title: 10;
  --typography-absolute-line-height-gb-title: 16;

  // Mat typography config variables
  // headline
  --typography-absolute-font-size-headline: 25;
  --typography-absolute-line-height-headline: 32;

  // title
  --typography-absolute-font-size-title: 18;
  --typography-absolute-line-height-title: 18;

  // subheading-2
  --typography-absolute-font-size-subheading-2: 16;
  --typography-absolute-line-height-subheading-2: 28;
  --typography-absolute-font-weight-subheading-2: #{$font-weight-normal};

  // subheading-1
  --typography-absolute-font-size-subheading-1: 15;
  --typography-absolute-line-height-subheading-1: 24;
  --typography-absolute-font-weight-subheading-1: #{$font-weight-normal};

  // body-2
  --typography-absolute-font-size-body-2: 14;
  --typography-absolute-line-height-body-2: 16;
  --typography-absolute-font-weight-body-2: var(--typography-font-weight-bold);

  // body-1
  --typography-absolute-font-size-body-1: 14;
  --typography-absolute-line-height-body-1: 16;

  // caption
  --typography-absolute-font-size-caption: 12;
  --typography-absolute-line-height-caption: 16;

  // button
  --typography-absolute-font-size-button: 16;
  --typography-absolute-line-height-button: 16;

  // input
  --typography-absolute-font-size-input: 16;

  // display-1
  --typography-absolute-font-size-display-1: 10;
  --typography-absolute-line-height-display-1: 15;

  // display-2
  --typography-absolute-font-size-display-2: 21;
  --typography-absolute-line-height-display-2: 24;

  // display-3
  --typography-absolute-font-size-display-3: 28;
  --typography-absolute-line-height-display-3: 28;

  // display-4
  --typography-absolute-font-size-display-4: 32;
  --typography-absolute-line-height-display-4: 32;
}
