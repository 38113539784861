// Styles: Audit Logs

.c-audit {
  .c-audit-logs {
    .c-audit-search {
      min-width: 290px !important;
    }

    .c-ui-select {
      min-width: 125px;

      .ui-select-choices {
        width: auto;

        input {
          margin-top: 3px;
        }
      }
    }

    .c-filters-list,
    .c-filters-list-label {
      display: inline-block;
      vertical-align: top;
    }

    .c-filters-list {
      margin-left: 10px;
    }
  }
}
