.c-schedule-info-header-details {
  span {
    margin-right: 10px;
    font-size: 14px;
  }

  span:first-child {
    color: $colorPrimary;
  }

  span:last-child {
    font-style: italic;
    font-size: 12px;
    color: $textGreyLight;
  }
}

.c-schedule-info-header-actions {
  position: absolute;
  right: 0;
  top: 15px;
}

.c-schedule-info-emails,
.c-schedule-info-format,
.c-schedule-info-filters {
  margin: 3px 0 0 0;
  color: $colorSecondaryDark;
}

.c-schedule-info-filters {
  > span {
    margin-right: 5px;
  }

  .c-ul-inline {
    display: inline-block;
  }

  .c-ul-inline li {
    white-space: normal;

    > strong {
      margin-right: 5px;
    }
  }
}
