// STYLES: File Backup Paths

.c-file-paths {
  fieldset {
    position: relative;
  }

  .c-metadata-filepath {
    margin-left: -4px;
  }

  .metadata-filepath-label {
    font-size: 0.815rem;
    color: $textGrey;
  }

  .skip-nested-volumes {
    position: absolute;
    top: 1.5rem;
    right: 12px;
    margin-top: -10px;
    z-index: 3;
  }

  .c-exclusions {
    fieldset {
      position: relative;
      padding: 10px 0 0 30px;

      .c-tree-connector {
        position: absolute;
        z-index: 3;
        left: 17px;
        top: -18px;
        height: 49px;
        width: 13px;
        border-left: 1px #ccc dotted;
        border-bottom: 1px #ccc dotted;
      }

      &:first-of-type .c-tree-connector {
        top: -1px;
        height: 32px;
      }
    }
  }

  .c-exclusion-remove {
    position: absolute;
    top: 1.5rem;
    right: 12px;
    margin-top: -6px;
    z-index: 3;
  }


  // When using Bootstrap input-group, we do not want rounded left corners
  // adjacent to the add-on
  .input-group input {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .c-ui-select li {
    padding: 0;
  }
}
