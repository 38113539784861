@import "helix/scss/dependencies";
// Global Mixins

// creates selectors for a specific icon
//
// $name - the name of the icon, this will match the filename and be included in the class name
// $path - the path to icon assets
// $file-extension - the file extension for the icon assets
//
// EXAMPLE USAGE:
// @include c-icon(protect);
//
// EXAMPLE OUTPUT:
// .icn-protect {
//     background-image: url(/assets/icn/protect.svg);
// }
// a.icn-protect {
//     background-image: url(/assets/icn/protect-h.svg);
// }
@mixin c-icon(
  $name: 'no-name-provided',
  $file-extension: 'svg',
  $basePath: $iconBasePath,
  $darkPath: $iconDarkBasePath) {
  .icn-#{$name},
  a.icn-#{$name}[disabled],
  uib-tab .icn-#{$name} {
    @include c-icon-property($name, normal, $file-extension, $basePath);
  }
  a.icn-#{$name},
  a > .icn-#{$name},
  .tab-container .active .icn-#{$name} {
    @include c-icon-property($name, bright, $file-extension, $basePath);
  }
  .cog-dark-theme {
    .icn-#{$name},
    a.icn-#{$name}[disabled],
    uib-tab .icn-#{$name} {
      @include c-icon-property($name, normal, $file-extension, $darkPath);
    }
    a.icn-#{$name},
    a > .icn-#{$name},
    .tab-container .active .icn-#{$name} {
      @include c-icon-property($name, bright, $file-extension, $darkPath);
    }
  }
}

@mixin btn-icn($name: 'no-name-provided', $file-extension: 'svg') {
  button,
  .mat-button-toggle-label-content,
  .mat-button-wrapper {
    > .icn-#{$name} {
      @include c-icon-property($name, normal, $file-extension);
    }
  }
}

// outputs background-image property for an icon
//
// $name -  the name of the icon, this will match the filename and be included
//          in the class name
// $style - the icon styling, matches with the postfix of the filename
// $file-extension - the file extension for the icon assets
//
// EXAMPLE USAGE:
// .icn-special-icon {
//     @include c-icon-property(special, bright, gif);
// }
//
// EXAMPLE OUTPUT:
// .icn-special-icon {
//     background-image: url(/assets/icn/special-h.gif);
// }
@mixin c-icon-property(
  $name: 'no-name-provided',
  $style: 'normal',
  $file-extension: 'svg',
  $basePath: $iconBasePath) {

  $icon-style-normal: '';
  $icon-style-bright: '-h';
  $icon-style-active: '-active';

  // Set Icon Style
  @if ($style == normal) {
    $style: $icon-style-normal;
  }
  @else if ($style == bright) {
    $style: $icon-style-bright;
  }
  @else if ($style == active) {
    $style: $icon-style-active;
  }
  @else {
    // user provided unknown style
    $style: $icon-style-normal;
  }

  $iconFileName: #{$name + $style + '.' + $file-extension};
  @include iconImage($iconFileName, $basePath);
}

@mixin iconImage($iconFile, $basePath: $iconBasePath) {
  background-image: url(#{$basePath + $iconFile});
}

@mixin helixIconImage($iconFile) {
  @include iconImage($iconFile, '/helix/i/icn');
}

@mixin scrollable($maxHeight: 25rem) {
  display: block;
  max-height: $maxHeight;
  overflow: hidden;
  overflow-y: auto;
}

@mixin horz-gradient($fromColor, $toColor) {
  @include background-image(linear-gradient(to right, $fromColor, $toColor));
}

@mixin vert-gradient($fromColor, $toColor) {
  @include background-image(linear-gradient(to bottom, $fromColor, $toColor));
}

@mixin arrow($direction, $color, $size) {
  width: 0;
  height: 0;
  @if $direction == up {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-bottom: $size solid $color;
  }
  @if $direction == down {
    border-left: $size solid transparent;
    border-right: $size solid transparent;
    border-top: $size solid $color;
  }
  @if $direction == left {
    border-left: $size solid transparent;
    border-bottom: $size solid transparent;
    border-top: $size solid $color;
  }
  @if $direction == right {
    border-right: $size solid transparent;
    border-bottom: $size solid transparent;
    border-top: $size solid $color;
  }
}


// breakpoint helper mixin creates media query block for a selector
//
// @example
//  .cluster-name {
//    width: 62.5rem;
//
//    @include breakpoint(tablet) {
//      width: 125rem;
//    }
//
//    @include breakpoint(desktop) {
//      width: 187.5rem;
//    }
//  }
//
//  // resulted CSS
//
//  .cluster-name {
//    width: 62.5rem;
//  }
//  @media (min-width: 768px) and (max-width: 1200px) {
//    .cluster-name {
//      width: 125rem;
//    }
//  }
//  @media (min-width: 1200px) {
//    .cluster-name {
//      width: 187.5rem;
//    }
//  }
@mixin breakpoint($type) {
  @if ($type == tablet) {
    @media (min-width: remToPx($screenMinTablet)) and
      (max-width: remToPx($screenMinDesktop - 0.0625rem)) {
      @content;
    }
  }
  @else if ($type == desktop) {
    @media (min-width: remToPx($screenMinDesktop)) {
      @content;
    }
  }
  @else {
    @warn 'Breakpoint mixin supports: tablet, desktop';
  }
}

// custom scrollbar
// NOTE: custom scrollbar wont work in IE and firefox.
@mixin c-scrollbar() {
  &::-webkit-scrollbar {
    width: 0.3em;
  }

  &::-webkit-scrollbar-thumb {
    background: $textGreyMedium;
    border-radius: 0.625rem;
  }

  &::-webkit-scrollbar-track {
    background: none;
  }
}

// cohesity logo
@mixin c-logo() {
  $cohesityPureLogoWidth: 12.8rem;

  .logo-wrapper {
    position: relative;
    transition: left $angularAnimationDuration ease-out;
    display: flex;
    align-items: center;

    .logo {
      background-size: 100%;
      background-repeat: no-repeat;
      background-position-y: center;
      display: inline-block;
      padding: 0;
      outline: 0;
      text-indent: -624.9375rem;
      width: $logo-width;
      height: $logo-height;
      background-image: url('/assets/i/cohesity-logo-white.svg');

      &.dark-logo {
        background-image: url('/assets/i/cohesity-logo-black.svg');
      }
    }

    @include reflow() {
      @include reflow_xxs() {
        .logo-wrapper {
          .logo {
            background-image: url('/assets/i/cohesity-logo-s-white.svg');

            &.dark-logo {
              background-image: url('/assets/i/cohesity-logo-s-black.svg');
            }
          }
        }
      }
    }

    .ibm-aqua-logo {
      background-image: url('/assets/i/ibm-storage-defender-logo.svg');
      height: 1.25rem;
      width: 10.125rem;
    }

    .ibm-cloud-baas-logo {
      background-image: url('/assets/i/ibm-cloud-backup-logo.svg');
      height: 1.25rem;
      width: 14.4375rem;
    }

    &.custom-logo {
      text-align: center;
      display: block;

      .logo {
        text-indent: unset;
        color: transparent;
      }

      .cohesity-branding {
        font-size: 0.7rem;
        height: 0.7rem;
      }
    }

    .helios-branding {
      height: 1rem;
      margin-left: 1rem;
    }
  }
}

// Notifications indicator
@mixin c-notification() {
  // Leveraging `body.mat-typography` to overpower increased specificity from Angular Material.
  body.mat-typography & .mat-badge-medium {
    .mat-badge-content {
      @include font($size: 0.625rem, $leading: 1rem);
      width: 1rem;
      height: 1rem;
    }

    &.mat-badge-above .mat-badge-content {
      top: 0.2rem;
    }

    &.mat-badge-overlap.mat-badge-after .mat-badge-content {
      right: 0;
    }
  }
}

// zoom an element
@mixin zoom-percent($percent) {
  transform: scale($percent);
  transform-origin: 0 0;
  -moz-transform: scale($percent);
  -moz-transform-origin: 0 0;
  -o-transform: scale($percent);
  -o-transform-origin: 0 0;
  -webkit-transform: scale($percent);
  -webkit-transform-origin: 0 0;
}
