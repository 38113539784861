// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-tabs.scss).

@mixin _chip-tab-label-active-coloring($color) {
  color: $color;

  &::after {
    content: '';
    display: block;
    overflow: hidden;
    position: absolute;
    left: 1rem;
    bottom: 0;
    right: 1rem;
    height: 2px;
    background-color: $color;
  }

  cog-dashcard &::after {
    left: 0.75rem;
    right: 0.75rem;
  }
}

@mixin cog-mat-tabs-theme($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);
  $is-dark: map-get($theme, is-dark);
  $helix-base: map-get($theme, helix-base);
  $background: map-get($theme, background);
  $component: map-get($theme, component);

  .mat-tab-group {
    .mat-tab-label {
      position: relative;
      color: map-get($helix-base, resting);

      &-active {
        @include _chip-tab-label-active-coloring(map-get($helix-base, primary));
      }
    }
  }
  .mat-tab-nav-bar,
  .mat-tab-header {
    &::before {
      content: '';
      display: block;
      overflow: hidden;
      left: 0;
      bottom: 0;
      right: 0;
      height: 1px;
      background-color: map-get($background, card-border);
    }
  }

  .mat-tab-nav-bar {
    .mat-tab-link {
      color: map-get($helix-base, resting);

      &[class*="active"] {
        @include _chip-tab-label-active-coloring(map-get($helix-base, primary));
      }
    }
  }

  //
  // PageToolbar specific stylings
  // Including here so PageToolbar can maintain style encapsulation.
  //
  .mat-toolbar-row.cog-page-nav-row,
  .mat-toolbar-row.cog-page-tall-header-toolbar,
  cog-page-content-header-navigation.cog-page-content-header-navaigation-primary {
    $inactive-text-color: map-get($helix-base, resting);

    .mat-tab-nav-bar {
      .mat-tab-link[class*="active"] {
        // Special coloring for active page tab
        color: map-get($helix-base, active);

        // for dark, use the tab-active since the
        // $helix-base, active is used in so many
        // places and likely too risky
        @if $is-jazz-theme {
          @if $is-dark {
            color: map-get($component, tab-active);
          }
        }

        &::after {
          background-color: map-get($helix-base, active);
          @if $is-jazz-theme {
            @if $is-dark {
              background-color: map-get($component, tab-active);
            }
          }
        }
      }
    }
  }

  .mat-toolbar-row .cog-page-tall-header-row {
    .mat-tab-nav-bar,
    .mat-tab-header {
      // Don't show the tab border for tall headers
      &::before {
        display: none;
      }
    }
  }
}


@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .mat-toolbar-row.cog-page-nav-row,
    .mat-toolbar-row.cog-page-tall-header-toolbar,
    cog-page-content-header-navigation.cog-page-content-header-navaigation-primary {
      .mat-tab-nav-bar {
        .mat-tab-link.mat-tab-link {
          font-weight: $font-weight-normal;

          &[class*="active"] {
            font-weight: $font-weight-bold;

            &::after {
              background-color: helix-color($theme, helix-base, primary);
            }
          }
        }
      }
    }

    // secondary tab
    .mat-tab-group .mat-tab-label-active.mat-tab-label-active {
      color: helix-color($theme, helix-base, active);

      &::after {
        background-color: helix-color($theme, helix-base, primary);
      }
    }

    .mat-tab-link:focus:not(.mat-tab-disabled) {
      color: helix-color($theme, helix-base, active);
    }
  }
}
