// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-select.scss).

@mixin cog-mat-slide-toggle-theme($theme) {
  .mat-slide-toggle {
    // Make the primary palette the default rather than accent.
    // This is done by targeting slide toggles without a color specified.
    &:not([color]).mat-checked {
      .mat-slide-toggle-thumb {
        background-color: helix-color($theme, primary, 500);
      }
    }
  }
}

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .mat-slide-toggle-bar.mat-slide-toggle-bar {
      background-color: helix-color($theme, component, toggle-background);
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-bar.mat-slide-toggle-bar {
      background-color: helix-color($theme, component, toggle-checked);
    }

    .mat-slide-toggle:not([color]).mat-checked .mat-slide-toggle-thumb.mat-slide-toggle-thumb {
      background-color: helix-color($theme, component, toggle-resting);
    }

    .mat-slide-toggle.cdk-focused .mat-slide-toggle-bar {
      // Add focus state outline for buttons. This is for accessibility to
      // highlight the focussed buttons.
      outline-offset: 2px;
      outline: 2px solid helix-color($theme, helix-primary, 500);
    }
  }
}
