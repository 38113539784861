// Styles: policySynopsis Component

policy-synopsis {
  .c-ul-inline {
    margin-bottom: 0;

    li {
      padding-right: 0.4375rem;

      &:not(:last-child):not(.list-label)::after {
        right: 0.125rem;
      }
    }
  }
}
