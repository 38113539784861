// Generate styles for Highcharts when using (CSS) styledMode.
@use "@angular/material/core/theming/palette" as mat-theme-palette;
// List of variable options can be found here:
// https://www.highcharts.com/docs/chart-design-and-style/custom-themes-in-styled-mode

$background-color: transparent;

$font-family: unquote($defaultFontStack);


@include helix-themes() using ($theme) {
  $chart-status-colors: map-get($theme, chart-status);
  $chart-colors: map-get($theme, chart);

  // Generic colors for data series and points.
  // NOTE: Specific chart implementations have 'theme' options which will utilize
  // different colors from these depending on the use case.
  $colors: map-get($chart-colors, default);

  $storage-donut-cluster-colors: (
    map-get($chart-colors, available),
    map-get($chart-colors, data-written),
    map-get($chart-status-colors, success),
    map-get($chart-colors, storage-consumed-cloud),
  );

  $critical-percentage-colors: (
    map-get($chart-status-colors, error),
    map-get($chart-colors, subtle),
  );

  $data-protection-colors: (
    map-get($chart-status-colors, success),
    map-get($chart-status-colors, warning),
    map-get($chart-colors, subtle),
  );

  $cog-bubble-chart-theme: (
    map-get($chart-colors, bubble-1),
    map-get($chart-colors, bubble-2),
    map-get($chart-colors, bubble-3),
    map-get($chart-colors, bubble-4),
  );

  // Ignore first series because it's the map outline itself, rest are bubble series.
  $health-map-theme: (
    map-get($chart-colors, subtle),
    map-get($chart-status-colors, error),
    map-get($chart-status-colors, warning),
    map-get($chart-status-colors, success),
  );

  $nuetral-percentage-colors: (
    map-get($chart-colors, usage),
    map-get($chart-colors, subtle),
  );

  $storage-line-colors: (
    map-get($chart-colors, data-in),
    map-get($chart-colors, storage-consumed),
    map-get($chart-colors, data-written),
    map-get($chart-colors, storage-saved),
    map-get($chart-status-colors, success),
  );

  $named-series-colors: (
    success: map-get($chart-colors, success),
    warning: map-get($chart-colors, warning),
    warning-low: map-get($chart-colors, warning-low),
    critical: map-get($chart-colors, critical),
    info: map-get($chart-colors, info),
    neutral: map-get($chart-colors, neutral),
    info-1: map-get($chart-status-colors, info),
    secondary: helix-color($theme, helix-secondary, 400),
    border: helix-color($theme, foreground, divider),
    error: map-get($chart-status-colors, error),
    warning-1: map-get($chart-status-colors, warning)
  );

  // This wrapper class `.cog-chart` provides a "namespace" for highcharts styles.
  // Without this old cCharts implementations get messed up by the presence of these
  // colors.
  // NOTE: This requires that chart components have a `cog-chart` class wrapping
  // the chart.
  .cog-chart {
    // This import pulls in the baseline highcharts styledMode css.
    @import  'highcharts/css/highcharts';

    &.clickable-chart .highcharts-series .highcharts-point {
      cursor: pointer;
    }

    @for $i from 1 through length($colors) {
      $color: nth($colors, $i);
      .highcharts-legend-item.highcharts-color-#{$i - 1} rect {
        fill: $color;
      }
    }

    // Override default tooltip text color provided by highcharts.
    .highcharts-tooltip text,
    .highcharts-legend-item > text {
      // Applied as !important because highcharts is still applying inline
      // styles despite being in styled mode.
      fill: helix-color($theme, foreground, legend) !important;
    }

    // Support legend in reporting chart
    .highcharts-legend-item > span {
      color: helix-color($theme, foreground, legend) !important;
    }

    // Customize some highcharts typography.
    .highcharts-axis-labels {
      $text-color: helix-color($theme, foreground, text);
      // TODO(jeff): Investigate how to get this applied properly on bar charts
      // as highcharts is apply styles directly to the svg output.
      @include cog-type-level(chart);

      // Applied as !important because highcharts is still applying inline
      // styles despite being in styled mode.
      fill: $text-color !important;

      text {
        // Applied as !important because highcharts is still applying inline
        // styles despite being in styled mode.
        fill: $text-color !important;
        color: $text-color !important;
      }
    }

    .highcharts-tooltip-box {
      fill: helix-color($theme, background, background);
      fill-opacity: 1;
      color: helix-color($theme, foreground, text);
    }

    .storage-donut-cluster-colors {
      @include generateChartColors($storage-donut-cluster-colors);
    }

    .critical-percentage {
      @include generateChartColors($critical-percentage-colors);
    }

    .data-protection {
      @include generateChartColors($data-protection-colors);
    }

    // health bubble map theme
    .health-map-theme {
      @include generateChartColors($health-map-theme);
      .highcharts-point:not(.highcharts-color-0) {
        fill-opacity: 0.75;
        stroke-width: 1;
        stroke: map_get(mat-theme-palette.$light-theme-background-palette, card);

        &.highcharts-point-select {
          fill-opacity: 0.8;
          fill: inherit;
          stroke: inherit;
          stroke-width: 0.35rem;
        }
      }
    }

    // general map theme
    .map-theme {
      @include generateChartColors(helix-color($theme, chart, subtle), $includeStroke: true);

      .highcharts-tooltip {
        color: helix-color($theme, foreground, tooltipText);
      }

      .highcharts-label-box,
      .highcharts-tooltip-box {
        stroke-width: 0;
        fill: helix-color($theme, background, tooltip);
      }

      .highcharts-point.highcharts-null-point {
          // TODO: An inline style is being applied by Highcharts, thus !important.
          fill: helix-color($theme, chart, subtle) !important;
          stroke-width: 0 !important;
      }

      // prevent the map from fading away when tooltip is displayed
      .highcharts-series-inactive {
        opacity: 1;
      }
    }

    .storage-used {
      @include generateChartColors($nuetral-percentage-colors);
    }

    .highcharts-pie-series {
      .highcharts-point {
        stroke: helix-color($theme, background, card);
        stroke-width: 1px;
      }
      .highcharts-halo {
        display: none;
      }
    }

    .storage-line-colors {
      @include generateChartColors($storage-line-colors, true);
    }

    // TODO(Lucy): Don't know how to generate multiple series highchart color
    // using generateChartColors. So I hard-code here.
    .storage-donut-vb-no-quota-colors {
      .outer-circle {
        // storage consumed on-prem
        .highcharts-color-0 {
          fill: map-get($chart-colors, storage-consumed);
        }

        // storage consumed on-cloud
        .highcharts-color-1 {
          fill: map-get($chart-colors, storage-consumed-cloud);
        }
      }
      .inner-circle{
        // data written
        .highcharts-color-0 {
          fill: map-get($chart-colors, data-written);
        }

        // resiliency
        .highcharts-color-1 {
          fill: map-get($chart-colors, success-2);
        }

        // storage consumed on-cloud
        .highcharts-color-2 {
          fill: map-get($chart-colors, storage-consumed-cloud);
        }
      }
    }

    .storage-donut-vb-quota-colors {
      .outer-circle {
        // available
        .highcharts-color-0 {
          fill: map-get($chart-colors, available);
        }

        // storage consumed on-prem
        .highcharts-color-1 {
          fill: map-get($chart-colors, storage-consumed);
        }

        // storage consumed on-cloud
        .highcharts-color-2 {
          fill: map-get($chart-colors, storage-consumed-cloud);
        }
      }
      .inner-circle {
        // available
        .highcharts-color-0 {
          fill: map-get($chart-colors, available);
        }

        // data written
        .highcharts-color-1 {
          fill: map-get($chart-colors, data-written);
        }

        // resiliency
        .highcharts-color-2 {
          fill: map-get($chart-status-colors, success);
        }

        // storage consumed on-cloud
        .highcharts-color-3 {
          fill: map-get($chart-colors, storage-consumed-cloud);
        }
      }
    }


    @include generateNamedSeriesColors($named-series-colors);
  }

  // .bubble-chart-theme is shared by both .cog-chart and cog-statlist
  .bubble-chart-theme {
    &,
    &.cog-chart {
      @include generateChartColors($cog-bubble-chart-theme);

      .highcharts-point {
        stroke: helix-color($theme, background, card);
        stroke-width: 0.5rem;
      }
    }
  }

  // Generate default colors that can be used for cog-statlist legend.
  .default-chart-theme {
    @include generateChartColors($colors);
  }

  // .health-share-theme is shared by both .cog-chart and cog-statlist
  .health-share-theme {
    $color-set: (
      map-get($chart-colors, critical),

      // TODO: remove the implementation of this "urgent" color. This was based on
      // mocks that weren't grounded in the reality that there is no urgent alert.
      #e37075,
      map-get($chart-status-colors, warning),
      map-get($chart-colors, subtle),
    );

    &,
    &.cog-chart {
      @include generateChartColors($color-set);
    }
  }
}
