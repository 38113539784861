// Styles: Access Management

#c-access-management {

  th.c-table-input-td {
    vertical-align: middle;
  }

  // TODO (David): This hack should be fixed properly, but presently
  // I cannot figure out where some ghost padding is coming from.
  th.icon-column {
    width: 24px;
  }

  // Override style for group labels in Roles ui-select
  .c-roles-ui-select {
    .ui-select-choices-group .divider {
      display: none;
    }

    .ui-select-choices-group-label {
      width: 100%;
      text-align: center;
    }
  }

  .delete-row-anchor {
    margin: $bootstrap-col-padding 0 0 $bootstrap-col-padding;
    float: right;
  }
}

.user-mfa-toggle {

  // Since we don't have text in the component is used in a table rather
  // than a form.
  margin-top: -1.25rem;
}

// Reset alignment of "Select All" checkbox because row alignment is different.
input[type="checkbox"].select-all {
  margin-top: 0;
  top: 0;
}

#toggle-restriction {
  display: inline-block;
}

.support-user-box {
  box-sizing: border-box;
  width: calc(100% + 3rem);
  margin: 0 -1.5rem;
  border-top-width: 2px;
  border-top-style: solid;
}

@include helix-themes() using ($theme) {
  .support-user-box {
    border-top-color: helix-color($theme, foreground, divider);
  }
}

.support-user-content {
  margin: 1rem 1.5rem 0 1.5rem;
}
