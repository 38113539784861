@import './variables.styles';

.blocklyEditableText {
  > .blocklyFieldRect:not(.blocklyDropdownRect) {
    fill: $field-background-color !important;
  }

  > .blocklyText {
    fill: $field-text-color !important;
  }
}

.blocklyNonEditableText {
  > .blocklyFieldRect:not(.blocklyDropdownRect) {
    fill: $field-background-color !important;
  }

  > .blocklyText {
    fill: $field-text-color !important;
    opacity: 0.5;
  }
}

.blocklyWidgetDiv {
  > .blocklyHtmlInput {
    border-radius: $field-border-radius !important;
    background-color: $field-background-color;
    color: $field-text-color !important;
  }
}
