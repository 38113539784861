body {
  background-color: transparent;

  // Since the layout of app-frame wrapper creates a fixed position element that
  // will introducing horizontal scrolling as needed, x-axis body scrolling is
  // disabled in order to prevent double/duplicate horizontal scrollbars.
  // TODO: move this into an appropriate stylesheet once nextgen UI is the
  // standard. It might make sense to implement this via the app-frame component
  // for inclusion in cohesity-common, though this will likely require disabling
  // style encapsulation for the component.
  overflow-x: hidden;
}

body {
  font-size: $body-font-size;
}

a {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: underline;
  }
}

// Elements/components where anchor underline styling isn't desirable.
a.mat-button,
a.mat-icon-button,
a.mat-raised-button,
a.mat-flat-button,
a.mat-raised-button,
a.mat-fab,
a.mat-mini-fab,
a.mat-tab-link,
a.mat-tab-label {
  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

// Allow long tooltip text to wrap even when there is no space inside.
.mat-tooltip {
  word-break: break-word;
  word-wrap: break-word;
  white-space: normal;
  &.pre-line {
    white-space: pre-line;
  }
}

// Class for action button or menu for a page or table on the right.
.default-actions-container {
  &,
  &.mat-button,
  &.mat-icon-button,
  &.mat-stroked-button,
  &.mat-flat-button {
    position: absolute;
    right: 0;
    top: 0;

    .cog-page-content & {
      right: 1.5rem;
      top: 1.5rem;
    }
  }
}

cog-card-section {
  display: block;

  + cog-card-section {
    margin-top: 1rem;
  }
}

cog-card-section-glancebar,
cog-card-section-filters {
  display: block;
}

cog-card-section-glancebar {
  margin-bottom: 1rem;

  // Tighten up this margin if the glancebar comes after filters,
  // as the content, filters, and glancebar are more strongly associated.
  // This scenario presumes the filters update the glancebar.
  cog-card-section-filters + & {
    margin-bottom: 1rem;
  }
}

cog-card-section-filters {
  margin-bottom: 1rem;
}

@include helix-themes() using ($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);
  a {
    color: helix-color($theme, component, anchor);
  }

  ::selection {
    background: helix-color($theme, helix-primary, 200);
    color: helix-contrast($theme, helix-primary, 200);
  }

  @if $is-jazz-theme {
    .cog-content-card-wrapper, .mat-card:not([class*=mat-elevation-z]).cog-content-card-wrapper {
      @include jazz-card-styles($theme);
    }
    .cog-content-card-wrapper-short, .mat-card:not([class*=mat-elevation-z]).cog-content-card-wrapper-short {
      @include jazz-card-styles($theme);
      min-height: unset;
    }
    // Do not wrap jazz content cards inside c-modal
    .c-modal .cog-content-card-wrapper-short {
      box-shadow: none;
      padding: 0;
      border-radius: 0;
    }
  }
}
