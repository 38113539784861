// Style: cChangeLocale

c-change-locale {

  // Override the button styles.
  [type="button"] {
    background-color: transparent;
    border-color: transparent;
    color: $textGrey;

    &:hover,
    &:focus,
    &:active {
      color: $textGrey;
      background-color: transparent;
      border-color: transparent;
      outline: none;
    }

    // Adjust the icon.
    [class*="icn"] {
      margin-top: -0.1em;
      margin-right: 0.3em;
      display: inline-block;
    }
  }

  // Override c-context-menu styles
  .nav-panel {
    a,
    span {
      min-width: 8.75rem;
    }

    li {
      font-size: 0.75rem;
    }
  }
}