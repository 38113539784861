// Component: Recovery Styles

.restore-details {
  .c-ul-inline li {
    white-space: normal;
  }
}

.restore-details-archive {
  td strong {
    padding-bottom: 7px;
    display: inline-block;
  }
  .archive-snapshot-info .icn-md {
    position: relative;
    top: 6px;
    left: 5px;
  }
}

.c-recovery-list {
  .c-ui-select.recovery-type-filter,
  select-tenant {
    display: block;
    width: 250px;
  }
}

.c-restore {
  .c-tile {
    line-height: 50px;
  }
}

.c-parameter-list > * {
  @extend .margin-right-sm;
}

.sub-tasks-panel {
  max-height: 22rem;
  overflow-x: hidden;
  overflow-y: auto;
}

.object-search-nav {
  height: 43rem;
}
