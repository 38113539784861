.snapshot-deleted {
  font-weight: $font-weight-bold;
  white-space: nowrap;
  padding: 0.35rem 0.5rem;
}

@include helix-themes() using ($theme) {
  .snapshot-deleted {
    background-color: helix-color($theme, status-background, warning-muted);
    color: helix-contrast($theme, status-background, warning-muted);
  }
}
