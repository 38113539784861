// Print Styles

@media print {

  body {
    padding-top: 0 !important;
  }

  #background-overlay,
  header[main-nav],
  button,
  input,
  c-controlboard {
    display: none !important;
  }

  .main-content-container {
    padding-top: 0 !important;
  }

  a[href]:after {
    content: none !important;
  }

}
