// Colors
@use "@angular/material/core/theming/theming" as mat;
@use "@angular/material/core/theming/palette" as mat-theme-palette;
// NOTE: Do not use these colors. They are here to support legacy pages and
// need to be refactored out. Use helix-themes() mixin and get a theme color.

$foreground-palette: map-get($jazz-theme-light, foreground);
$background-palette: map-get($jazz-theme-light, background);

// Colors - Branding
$colorPrimary: mat.get-color-from-palette($md-primary, 500);
$colorPrimaryBright: #23aee4;
$colorPrimaryLight: #f2f7fd;
$colorPrimaryDark: mat.get-color-from-palette($md-primary, 600);

$colorSecondary: #c8c7c7;
$colorSecondaryDark: #949494;

$colorSecondaryAlt: #65b5e7;
$colorSecondaryDarkAlt: #296184;

$colorNeutral: #d7d2c4;
$colorNeutralLight: #eeeeee;
$colorNeutralMedium: #e1e1e1;
$colorNeutralDark: #bbb;

// Colors - General
$colorWhite: mat.get-color-from-palette($md-accent, '500-contrast');
$colorCohesityGreen: #67bf1b;
$colorCohesityTextGreen: #468212;
$colorBlack: black;
$colorGrey: #757575;
$colorLightGrey: #f0f0f1;
$colorPurple: #8552BF;

// Colors - Charts
// These should match with colors found in constants.js
$chartBrand: #0295c3;
$chartBrandDark: #0056a6;
$chartOrange: #F79677;
$chartBlue: #4CC0F2;
$chartYellow: #fac27a;

// Colors - Text
// GreyDarkest => Grey => GreyMedium => GreyLight => GreyLightest
$textGreyDarkest: #404041;
$textGrey: #4b4b4b;
$textGreyMedium: #717171;
$textGreyLight: #898989;
$textGreyLightest: #b3b3b3;
$textBlack: #021019;
$textBlue: #2980b9;
$textLink: $colorPrimary;

// Colors - by Element
// Darkest => Dark => normal => Light
$colorBorderDarkest: #666;
$colorBorderDark: $colorSecondary;
$colorBorder: mat.get-color-from-palette(mat-theme-palette.$light-theme-background-palette, card-border);
$colorBorderLight: #e5e5e5;
$colorPageBackground: $colorNeutralLight;

$splashBackground: #111;

$colorTabActive: #d0d1d2;

$datePickerSelectedBackground: #a2cfef;

// header & nav
$mainNavFontColor: #e9e9e9;
$subNavHoverBackground: #404040 !default;
$navHoverBackground: #323232 !default;

$contentBackground: #fff;

$elementActive: $colorPrimary;

$formLabels: $textGrey;
$formHover: #3293d3;
$formInputInactive: $colorBorder;
$formInputActive: $colorPrimary;
$formInputText: $textGrey;
$formInputTextFocus: #3fa9f5;
$formPlaceholder: $colorSecondary;

$cSelectBackground: #fcfcfc;
$cSelectBackgroundHover: #c7efff;
$cSelectBackgroundSelected: #98d0e6;

$buttonDisabledBackground: map_get($background-palette, disabled-button);
$buttonDisabledText: map_get($foreground-palette, disabled-button);
$buttonBorder: map_get($background-palette, card-border);
$buttonSecondaryText: #6d6e70;

$cToggleOffBackground: $colorBorderDark;
$cToggleOffBorder: $textGreyLight;
$cToggleOnBackground: $colorPrimary;
$cToggleOnBorder: $cToggleOnBackground;

$tableHeader: $colorPrimary;
$tableHeaderActive: $colorPrimaryDark;
$tableSelectedRow: #e8f3fb;
$tableFooter: #e6e6e6;

// TODO: replace implementation points with `map_get($background, app-bar)`
// and remove this variable.
$drawerBackground: #f8f8f8;
$drawerBorder: $colorBorderLight;
