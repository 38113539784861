@import 'dependencies';

.cog-loading-mask {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
    content: '';
  }

  &.cog-loading-mask-active::after {
    opacity: 0.35;
  }

  &:not(.cog-loading-mask-active)::after {
    opacity: 0;
    transition: all 250ms ease-in;
    z-index: -1;
  }
}

@include helix-themes() using ($theme) {
  .cog-loading-mask::after {
    background-color: helix-color($theme, chart, subtle);
  }
}
