// Helios CSS Template

.helios-toggle {
  margin-right: 0.625rem;
}

.helios-connection-error {
  margin: 0.625rem 0;
  padding: 0.625rem;
}

.c-helios-connect-text {
  vertical-align: middle;
  margin-top: -0.1875rem;

  > .helios-spinner {
    margin: 0 0.625rem;
  }
}
