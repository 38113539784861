@import "libs/helix/scss/dependencies";
// cPageHeader component styles

// These styles will apply to cPageHeader when leveraging pageTitle and
// pageActions transclusions.
.c-page-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  height: $cPageHeaderContentHeight;
}

.c-page-header-actions {
  // This pushes the flex item to the right edge. Similar to a float.
  margin-left: auto;
  white-space: nowrap;
}

@include helix-themes() using ($theme) {
  c-page-header:not([disabled]) {
    $cohesity-background: helix-color($theme, background, background);
    background-color: $cohesity-background;
    border-bottom: 1px solid $cohesity-background;
  }
  .has-page-y-offset c-page-header:not([disabled]) {
    box-shadow: helix-color($theme, component, page-header-shadow);
  }
}

c-page-header:not([disabled]) {
  display: block;
  height: $page-header-height;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  padding: $cPageHeaderVerticalPadding 0;
  z-index: 4;

  .media-body {
    flex: 1 1 0;
  }

  // This component doesn't allow for mulitple lines, nor is that desirable
  // since its fixed height and carving a footprint out of the usable viewport.
  // Therefore, single line any headers and ellipse their overflow.
  // NOTE: For the sake of consistency, don't use any other headers besides
  // h1 in this component.
  h1 {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    align-items: center;

    > * {
      position: relative;
      display: block;
      flex: 1 1 0;
      overflow: hidden;
      text-overflow: ellipsis;

      &.inline-header-link {
        flex: 0 0 0;
        overflow: visible;
      }
    }

    small {
      font-weight: 100;
      font-size: 0.83rem;
    }
  }

  // In cases where we need to overflow the title a little early to accomodate
  // go to previous page links
  .user-data {
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: middle;
    max-width: 80%;
  }

  header {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1.5rem;
  }
}

@supports (position: sticky) {
  // Apply sticky position when the header is not disabled. Otherwise, the
  // header will render as if the cPageHeader wasn't implemented at all.
  c-page-header:not([disabled]) {
    position: sticky;
    margin: calc(-5.5rem - 1px) -1.5rem 1.5rem;
  }
}

body.has-c-page-header {
  .app-frame-content-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: auto;
    padding: 0;

    // Ensure this is only applied to the ui-view element, as legacy storage domain
    // flow uses a main element as well and this rule was applying to it.
    main[ui-view] {
      // Additional 1.5rem added now that header drop shadow is permanently applied
      // instead of on scroll. This pushes content away from the drop shadow.
      display: block;
      padding: 5rem 1.5rem 1.5rem;
    }
  }
}

@include jazz-theme-with-body-class('.has-c-page-header') {
  // this allows the box-shadow of the jazz content cards
  // space to display, otherwise the box-shadow is cut off
  .app-frame-content-wrapper main[ui-view] {
      padding-left: .5rem;
  }
}

// Additional styling for when running inside an iframe.
body.in-helios-frame {
  // Since the nav items are removed, reset the top to 0.
  c-page-header {
    top: 0 !important;

    // Update the margins to be within the container
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .c-box c-page-header {
    // Reset to regular margins if in c-box
    margin: calc(-6.5rem - 1px) -1.5rem 1.5rem;
  }

  // The new c-page-content determination is based on only the c-page-header height.
  // Not the traditional c-page-header + siteNavHeight.
  &.has-c-page-header {
    margin-top: #{$cohesity-app-bar-height + 2rem} !important;
  }
}

// This is based on having an additional class on the body
@include reflow-body-class('.has-c-page-header') {
  @include reflow-sm() {
    .c-page-header-flex {
      flex-wrap: wrap;
      height: unset;
    }

    // Remove the page header box shadow on small viewports
    .has-page-y-offset c-page-header:not([disabled]) {
      box-shadow: unset;
    }

    c-page-header:not([disabled]) {
      position: relative;
      height: unset;
      margin-top: -4.5rem;
      margin-bottom: 0;

      h1 {
        flex-direction: column-reverse;
        align-items: flex-start;
        word-break: break-all;
        white-space: normal;

        > * {
          flex: unset;

          &.inline-header-link {
            flex: unset;
            overflow: visible;
            margin: unset;
          }
        }

        span {
          word-break: break-all;
        }
      }
    }
  }
  @include reflow-xs() {
    .app-frame-content-wrapper {
      position: relative;
    }

    .c-page-header-actions {
      white-space: normal;
    }
  }
}
