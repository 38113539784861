// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-card.scss).

@mixin cog-mat-card-theme($theme) {
  $background: map_get($theme, background);
  $foreground: map_get($theme, foreground);
  $is-dark: map-get($theme, is-dark);
  $is-jazz-theme: map-get($theme, is-jazz);
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  .mat-card {
    border-color: map_get($background, card-border);

    // Disable the default elevation box-shadow. To override this (on hover or
    // other scenarios) use `@include mat-elevation(N);` in your stylesheet or
    // apply a mat-elevation-z# class to your element.
    &:not([class*="mat-elevation-z"]) {
      box-shadow: none;
    }

    @include cog-mat-elevation-overlay($theme);

    .mat-card-title {
      @include cog-type-level(h3);

      // If a sentence has a really long word which will overflow, break it.
      word-break: break-all;
    }
  }

  .mat-card {
    padding: $mat-card-padding;
    @if $is-ibm-aqua-theme {
      border-radius: 0;
    } @else {
      border-radius: $mat-card-border-radius;
    }
    border-width: 1px;
    border-style: solid;
  }

  @if $is-jazz-theme and $is-dark {
    .cog-takeover-theme,
    cog-page:not(.dashboard) {
      .mat-card {
        border-color: map_get($foreground, divider);
      }
    }
  }

  .mat-card-actions {
    margin-left: -$mat-card-padding * 0.5;
    margin-right: -$mat-card-padding * 0.5;

    .mat-card > &:last-child {
      // Unsetting a negative margin bottom from mat because nobody likes the
      // buttons so close to the card edge.
      margin-bottom: 0;
    }
  }

  .mat-card-image {
    width: calc(100% + #{$mat-card-padding * 2});
    margin: 0 (-$mat-card-padding) 1rem (-$mat-card-padding);
  }

  .mat-card-footer {
    // The negative margins pulls out the element, countering the padding
    // to get the footer to be flush against the side of the card.
    $margin: -$mat-card-padding;

    margin: 0 $margin $margin $margin;
  }

  .mat-card-header-text {
    margin: 0 $mat-card-padding;
  }

  .mat-card-avatar {
    height: $mat-card-header-size;
    width: $mat-card-header-size;
  }

  // if main image is on top, need to place it flush against top
  // (by stripping card's default padding)
  .mat-card-image:first-child {
    margin-top: -$mat-card-padding;
  }
}
