@each $name, $config in $cog-type-config {
  .cog-#{$name} {
    @include cog-type-level($name);
  }
}

@include helix-themes() using($theme) {
  .cog-h1,
  .cog-modal-title {
    color: helix-color($theme, helix-base, active);
  }
}
