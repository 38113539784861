// Styles: Poptips - Customized Tooltips

.poptip.popover {
  $poptipBackgroundColor: $navHoverBackground !default;
  $poptipForegroundColor: $colorWhite !default;
  $poptipFlagHeight: 0.375rem !default;

  // For both Maps below, the key is the side of the trigger on which the box
  // appears.

  // Map to look up the arrow side of the box (for pulling outside the box).
  $poptipArrowPositionPropertyMap: (
    top: bottom,
    right: left,
    bottom: top,
    left: right,
  );

  // Map to look up the offset property of the opposite axis of the arrow side
  // (for centering).
  $poptipArrowPositionOffsetPropertyMap: (
    top: left,
    right: top,
    bottom: left,
    left: top,
  );

  background-color: $poptipBackgroundColor;
  color: $poptipForegroundColor;
  padding: 0.3125rem 0.5rem;
  border-radius: 0.25rem;

  .popover-content {
    padding: 0;
    text-align: center;
  }

  // Hide the arrow provided by uibPopover because we're drawing our own.
  > .arrow {
    display: none;
  }

  // The speech bubble arrows
  &::after {
    position: absolute;
    content: '';
    border: solid transparent $poptipFlagHeight;
  }

  @each $side in $popoverSides {
    // The side of teh box to pull the arrow to.
    $arrowSide: map-get($poptipArrowPositionPropertyMap, $side);

    // The side of the box to center the arrow on.
    $arrowOffsetSide: map-get($poptipArrowPositionOffsetPropertyMap, $side);

    &.#{$side}::after {
      #{$arrowSide}: $poptipFlagHeight * -2;
      #{$arrowOffsetSide}: calc(50% - #{$poptipFlagHeight});
      border-#{$side}-color: $poptipBackgroundColor;
    }
  }
}
