$cRadialStrokeWidth: 4px;
$cRadialStrokeWidthSm: calc(#{$cRadialStrokeWidth} / 1.618);
$cRadialStrokeWidthLg: calc(#{$cRadialStrokeWidth} * 1.618);

//
// Radial cPulse styles
//
c-pulse-radial {
  display: inline-block;
  user-select: none;
  vertical-align: middle;
  cursor: default;

  svg {
    position: relative;

    text {
      font-size: 0.85em;
      text-anchor: middle;
      transition: all 0.4s ease-out;
    }

    // Both the track and the progress arc
    circle {
      transform-origin: center center;
      transform: rotate(89.999deg) scaleX(-1);
      stroke-width: $cRadialStrokeWidth;
      fill: transparent;
      stroke-linecap: round;

      z-index: 5;
      top: 0;
      left: 0;
    }

    // The progress arc
    circle {
      &.c-pulse-radial-progress {
        transition: all 0.4s ease-out;
        stroke-opacity: 1;

        &.percentage-0 {
          // This hides a little dot created by the rounded end caps when the
          // percentage value is at 0.
          stroke-opacity: 0;
        }
      }
      &.c-pulse-radial-track {
        stroke-width: $cRadialStrokeWidth * 0.25;
      }
    }

    &.sm {
      text {
        font-size: 0.6em;
      }
      .c-pulse-radial-progress {
        stroke-width: $cRadialStrokeWidthSm;
      }
    }
    &.lg {
      .c-pulse-radial-progress {
        stroke-width: $cRadialStrokeWidthLg;
      }
      text {
        font-size: 1em;
      }
    }
  }

  // Reverse the Progress direction
  &[reverse] {
    svg circle {
      transform: rotate(-89.999deg);
    }
  }
}

@include helix-themes() using ($theme) {
  c-pulse-radial {
    svg {
      // Both the track and the progress arc
      circle {
        stroke: helix-color($theme, status, success);
      }
      text {
        fill: helix-color($theme, foreground, text);
      }

      // The progress arc
      circle {
        &.c-pulse-radial-track {
          stroke: helix-color($theme, foreground, divider);
        }
      }
    }

    &.error {
      .c-pulse-radial-progress {
        stroke: helix-color($theme, status, error);
      }
      text {
        fill: helix-color($theme, status, error);
      }
    }
    &.warn {
      .c-pulse-radial-progress {
        stroke: helix-color($theme, status, warning);
      }
      text {
        fill: helix-color($theme, status, warning);
      }
    }
    &.ok {
      .c-pulse-radial-progress {
        stroke: helix-color($theme, status, success);
      }
      text {
        fill: helix-color($theme, status, success);
      }
    }
  }
}
