// NOTE: If working with colors, please leverage the related _*.theme.scss file.

// Custom overlay panel for mat-select that needs two lines of text
.cog-mat-select-panel-lg.mat-select-panel {
  .mat-option {
    height: 3.5rem;
    line-height: 1rem;
  }
}

// For grouped selects, add panelClass="no-indent" to keep the left edges
// of items in alignment with their group label. Leverage <strong> (themed
// below) or other custom styling to differentiate between group labels and
// items.
.no-indent .mat-optgroup {
  .mat-option:not(.mat-option-multiple) {
    padding-left: 1rem;
  }
}

// Mat option uses hint-text for disabled options, which is far too subtle for our theme
// Add opacity to the select to make it clearer that the option is disabled.
.mat-option.mat-option-disabled:not(.contains-mat-select-search) {
  opacity: 0.6;
}

// Sticky mat option top or bottom.
.mat-option.sticky-option {
  position: sticky;
  left: 0;
  width: 100%;

  &.top {
    top: 0;
  }

  &.bottom {
    bottom: 0;
  }
}
