.scheduled-emails-header {
  padding: 10px 12px;
  border-bottom: 1px solid $colorBorder;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;

  > p {
    font-size: 18px;
    margin: 0;
  }

  .scheduled-email-report-dropdown {
    flex: 0 1 30%;
  }
}
