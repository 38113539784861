@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .mat-slide-toggle-bar.mat-slide-toggle-bar {
      height: 1.5rem;
      width: 3rem;
      border-radius: 0.75rem;
    }

    .mat-slide-toggle-thumb-container {
      top: 2px;
      left: 4px;
    }

    .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb-container {
      transform: translate3d(21px, 0, 0);
    }
  }
}
