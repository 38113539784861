@use "@angular/material/core/theming/theming" as mat;
// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-select.scss).

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);
  $colorPrimary: mat.get-color-from-palette($md-primary, 500);

  @if $is-ibm-aqua-theme {
    .mat-slider.mat-accent,
    .mat-slider.mat-slider-min-value:not(.mat-slider-thumb-label-showing) {
      .mat-slider-thumb.mat-slider-thumb,
      .mat-slider-track-fill.mat-slider-track-fill {
        background-color: helix-color($theme, component, slider);
      }
    }

    .mat-slider.cdk-focused {
      .mat-slider-thumb.mat-slider-thumb {
        outline-offset: -4px;
        outline: 2px solid helix-color($theme, component, button-resting);
        background-color: $colorPrimary;
      }

      .mat-slider-track-fill.mat-slider-track-fill {
        background-color: $colorPrimary;
      }
    }
  }
}
