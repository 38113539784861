// cStepper Styles

$stepperCircleDiameter: 50px;
$mdStepperCircleDiameter: 26px;
$smStepperCircleDiameter: 10px;

ol.c-stepper {
  display: table;
  width: 100%;
  margin-bottom: 25px;
  list-style-type: none;
  cursor: default;
  height: 100px;

  li {
    display: table-cell;
    margin: 0;
    padding: 0;
    list-style-type: none;
    float: none;
    text-align: center;
    position: relative;

    .stepper-label {
      display: inline-block;
      padding-top: 15px;
      width: 100%;
      position: absolute;
      text-align: center;
      left: 0;
      color: $textGreyLightest;
    }
    &.active {
      // The number circle
      &::before {
        color: $colorWhite;
        background-color: $colorPrimary;
      }
      .stepper-label {
        color: $textGrey;
      }
    }

    // The number circle
    &::before {
      @include font(
        $weight: light, $size: 1.5rem, $leading: 3.25rem, $color: $colorWhite);
      transition: all $slowDuration ease;
      content: attr(data-step);
      background-color: $textGreyLightest;
      width: $stepperCircleDiameter;
      height: $stepperCircleDiameter;
      text-align: center;
      display: block;
      margin: 0 auto;
      z-index: 51;
      position: relative;
      border-radius: ($stepperCircleDiameter * 0.5);
    }

    // The line between steps
    &::after {
      content: '';
      z-index: 50;
      position: absolute;
      top: ($stepperCircleDiameter * 0.5);
      left: 0;
      right: 0;
      height: 1px;
      background-color: $textGreyLightest;
    }
    &:first-child::after {
      left: 50%;
    }
    &:last-child::after {
      right: 50%;
    }
  }
}

ol.c-stepper-sm {
  display: table;
  width: 100%;
  margin-bottom: 25px;
  list-style-type: none;
  cursor: default;
  font-size: 0.7rem;

  li {
    display: table-cell;
    margin: 0;
    padding: 0;
    list-style-type: none;
    float: none;
    text-align: center;
    position: relative;

    .stepper-label {
      display: inline-block;
      padding-top: 5px;
      width: 100%;
      position: absolute;
      text-align: center;
      left: 0;
      color: $colorSecondary;
    }
    &.active {
      // The number circle
      &::before {
        border-color: $colorSecondaryAlt;
      }
      .stepper-label {
        color: $colorSecondaryAlt;
      }
    }

    // The circle
    &::before {
      transition: all $slowDuration ease;
      content: '';
      border-style: solid;
      border-width: 1px;
      border-color: $colorSecondary;
      background-color: $colorWhite;
      width: $smStepperCircleDiameter;
      height: $smStepperCircleDiameter;
      text-align: center;
      display: block;
      margin: 0 auto;
      z-index: 51;
      position: relative;
      border-radius: ($smStepperCircleDiameter * 0.5);
    }

    // The line between steps
    &::after {
      content: '';
      z-index: 50;
      position: absolute;
      top: ($smStepperCircleDiameter * 0.5) - 0.5;
      left: 0;
      right: 0;
      height: 1px;
      background-color: $colorSecondary;
    }
    &:first-child::after {
      left: 50%;
    }
    &:last-child::after {
     right: 50%;
    }
  }
}

ol.c-stepper-md {
  cursor: default;
  display: flex;
  height: 30px;
  justify-content: left;
  list-style-type: none;
  margin-bottom: 25px;
  width: 100%;

  li {
    list-style-type: none;
    position: relative;

    .stepper-label {
      display: flex;
      width: 100%;
      text-align: left;
      margin-top: -1.4rem;
      left: 0;
      color: $textGreyLightest;

      // The line between steps
      &::after {
        content: '';
        flex: 1;
        height: 1px;
        background-color: $textGreyLightest;
        margin-left: 1rem;
        margin-right: 3rem;
        margin-top: 0.6rem;
        width: 4rem;
      }
    }
    &.active, &.completed {
      // The number circle
      &::before {
        color: $colorWhite;
        background-color: $colorPrimary;
      }
      .stepper-label {
        color: $textGrey;
      }
    }
    &.completed {
      &::before {
        content: "\2713";
      }
    }

    // The number circle
    &::before {
      left: -2rem;
      @include font(
        $weight: light, $size: 1rem, $leading: 1.5rem, $color: $colorWhite);
      transition: all $slowDuration ease;
      content: attr(data-step);
      background-color: $textGreyLightest;
      width: $mdStepperCircleDiameter;
      height: $mdStepperCircleDiameter;
      line-height: 1.7rem;
      text-align: center;
      display: inline-block;
      margin: 0 auto;
      position: relative;
      border-radius: ($stepperCircleDiameter * 0.5);
      top: -0.1rem;
    }

    &:last-child {
      .stepper-label{
        &::after {
          display: none;
        }
      }

      min-width: 0 !important;
    }
  }
}