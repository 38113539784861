// Policy Manager Styles

.policy-modify {

  // adjust the policy schedule divide width
  .schedule-policy-divider {
    min-width: 41%;
  }

  .icn-cancel {
    margin-top: 0.125rem;
    flex-shrink: 0;
  }

  // TODO(veetesh): adjust padding bottom because ui-select field is not
  // inheriting height correctly it should be 18px but it is taking 27px.
  .c-box-section {
    padding-bottom: 0.75rem;
  }

  // to abjust the blackout window policy column width
  .col-blackout-window-days {
    width: 18.75rem;
  }

  .col-blackout-window-time {
    width: 21.875rem;
  }

  .col-extended-retention {
    width: 12.5rem;
  }

  .icn-info-wrap {
    position: relative;
    top: -0.3rem;
  }

  .replication-options-wrap {
    margin-left: -4.5rem;

    .icn-info-tip {
      top: 0;
    }
  }

  .cloud-deploy-selection-wrap {
    .temp-azure-resources-wrap {
      display: inline-block;
    }

    .icn-info-tip {
      top: 0;
    }
  }

  .create-policy-name .content-editable-header-description {
    max-width: 100%;
    margin-top: 0.75em;

    .content-editable-header-description-input {
      width: calc(100% - 1rem);
    }
  }

  .see-more-wrapper {
    padding: 0.75rem 0 0.75rem;
    margin-bottom: 0;
    border-top: 1px solid $colorNeutralLight;
  }

  .see-more-wrapper .c-box-section {
    margin: 0;
  }

  .see-more-wrapper > a {
    margin-bottom: 0;
  }

  .see-more-content {
    padding: 0;
  }

  .replication-wrap {
    select-parent-source {
      display: inline-block;
    }
  }
}
