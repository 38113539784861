c-db-migration-task-details {
  .snapshots-list {
    .icn-check {
      margin-left: $gutter-sm * -1;
      position: absolute;
    }
  }

  section.c-db-migration-task-details fieldset {
    margin: 0 0 0 0;

    &[disabled] {
      @include disabledProperties();
    }
  }
}
