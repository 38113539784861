// EULA styles

.c-modal-soft {
  .brand-mark {
    width: auto;
    height: 80px;
    background: transparent
      url('/assets/i/brand-mark-green.svg') center center no-repeat;
    margin: 5px auto 20px;

    &.hpe-brand-mark {
      background: transparent
        url('/assets/i/hpe-logo-eula.png') center center no-repeat;
    }
  }

  .icn-lock {
    display: block;
    margin: 5px auto 15px;
  }

  &.c-modal-eula .modal-dialog {
    width: 100%;
  }

  .eula-wrapper {
    border-radius: $defaultRadius;
    background-color: $colorWhite;
    margin: 0 0 10px 0;
    text-align: left;
    padding: 20px;
    max-height: 350px;
    overflow-y: auto;

    ul, ol {
      margin-left: 10px;
      margin-bottom: 10px;
      li {
        padding-left: 5px;
        padding-bottom: 5px;
      }
    }
  }

  embed {
    height: 15rem;
  }

}

@include helix-themes() using ($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  .c-modal-eula {
    .modal-dialog {
      border-radius: helix-color($theme, component, dashcard-border-radius);
      background-color: helix-color($theme, background, card);
    }
    .modal-content {
      background-color: helix-color($theme, background, card);
      color: helix-color($theme, foreground, text);
    }
    .eula-wrapper {
      color: helix-contrast($theme, helix-primary, 400);
      @if $is-dark-theme {
        color: helix-contrast($theme, primary, 500)
      }
      border: 1px solid helix-color($theme, foreground, divider);
    }
  }
}
