// Tree Control (source tree) style overrides and enchancements

//
// The following styles were pulled from the toolchain tree-control.scss and
// updated to reflect the customized directive names. This block is otherwise
// untouched and should be left as-is. Cohesity application specific stylings
// are below these and can be customized to override these styles as desired.
//
c-treecontrol ul {
    margin: 0;
    padding: 0;
    list-style: none;
    border: none;
    overflow: hidden;
}

c-treecontrol li {
    position: relative;
    padding: 0 0 0 1.25rem;
    line-height: 1.25rem;
}

c-treecontrol li.tree-expanded i.tree-leaf-head, c-treecontrol li.tree-collapsed i.tree-leaf-head {display:none;}
c-treecontrol li.tree-expanded i.tree-branch-head, c-treecontrol li.tree-collapsed i.tree-branch-head {display:inline;}
c-treecontrol li.tree-leaf i.tree-branch-head {display:none;}
c-treecontrol li.tree-leaf i.tree-leaf-head {display:inline;}

c-treecontrol li i.tree-branch-head {
    cursor: pointer;
}

c-treecontrol li .tree-label {
    cursor: pointer;
    display: inline;
}

c-treecontrol li .tree-unselectable {
    cursor: not-allowed;
}

c-treecontrol.tree-classic li.tree-expanded i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/folder.png") no-repeat;
}

c-treecontrol.tree-classic li.tree-collapsed i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/folder-closed.png") no-repeat;
}

c-treecontrol.tree-classic li.tree-leaf i.tree-leaf-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/file.png") no-repeat;
}

c-treecontrol.tree-classic li .tree-selected {
    background-color: #aaddff;
    font-weight: bold;
}

c-treecontrol.tree-classic li .tree-unselectable {
    color: #ddd;
}

c-treecontrol.tree-light li.tree-expanded i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/node-opened-2.png") no-repeat;
}

c-treecontrol.tree-light li.tree-collapsed i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/node-closed-2.png") no-repeat;
}

c-treecontrol.tree-light li.tree-leaf i.tree-leaf-head {
    padding: 0.0625rem 0.625rem;
    width: 1rem;
    height: 1rem;
    background: none no-repeat;
}

c-treecontrol.tree-light li .tree-selected {
    font-weight: bold;
}

c-treecontrol.tree-light li .tree-unselectable {
    color: #ddd;
}

c-treecontrol.tree-dark li.tree-expanded i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/node-opened-light.png") no-repeat;
}

c-treecontrol.tree-dark li.tree-collapsed i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/node-closed-light.png") no-repeat;
}

c-treecontrol.tree-dark li.tree-leaf i.tree-leaf-head {
    padding: 0.0625rem 0.625rem;
    width: 1rem;
    height: 1rem;
    background: none no-repeat;
}

c-treecontrol.tree-dark li .tree-selected {
    font-weight: bold;
}

c-treecontrol.tree-dark li .tree-unselectable {
    color: #777;
}

c-treecontrol.tree-dark {
    color: #ddd;
}

c-treecontrol.tree-boot {
    color: #428bca;
    font-family: inherit;
    font-size: inherit;
}

c-treecontrol.tree-boot > ul > li {
    padding: 0;
}

c-treecontrol.tree-boot li > .tree-label {
    display: block;
    padding: 0.1875rem 0;
    border-radius: 0.25rem;
}

c-treecontrol.tree-boot i.tree-branch-head,
c-treecontrol.tree-boot i.tree-leaf-head {
    float: left;
    height: 1rem;
    margin: 0.3125rem;
}

c-treecontrol.tree-boot li.tree-expanded i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/folder.png") no-repeat;
}

c-treecontrol.tree-boot li.tree-collapsed i.tree-branch-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/folder-closed.png") no-repeat;
}

c-treecontrol.tree-boot li.tree-leaf i.tree-leaf-head {
    padding: 0.0625rem 0.625rem;
    background: url("/assets/images/file.png") no-repeat;
}

c-treecontrol.tree-boot .tree-label.tree-selected,
c-treecontrol.tree-boot .tree-label.tree-selected:hover {
    color: #fff;
    background-color: #428bca;
}

c-treecontrol.tree-boot .tree-label:hover {
    background-color: #eee;
}

//
// Begin Cohesity application specific customizations
//
treecontrol,
c-treecontrol {
  @include user-select(text);

  li .tree-label {
    display: block;
    cursor: default;
  }

  &.tree-classic {
    li {
      &.tree-expanded > i.tree-branch-head {
        float: left;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;
        left: -1.25rem;
        top: 0.5625rem;
        margin-right: -1.375rem;
        @include iconImage('icn-arrow-row-toggle.svg');
        background-size: 0.75rem 0.5625rem;
        background-repeat: no-repeat;
        background-position: 0.25rem;
      }
      &.tree-expanded > i.tree-branch-head {
        float: left;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;
        left: -1.25rem;
        top: 0.5625rem;
        margin-right: -1.375rem;
        @include iconImage('icn-arrow-row-toggle.svg');
        background-size: 0.75rem 0.5625rem;
        background-repeat: no-repeat;
        background-position: 0.25rem;
      }
      &.tree-collapsed > i.tree-branch-head {
        transform: rotate(-90deg);
        float: left;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        position: relative;
        left: -1.25rem;
        top: 0.5625rem;
        margin-right: -1.375rem;
        @include iconImage('icn-arrow-row-toggle.svg');
        background-size: 0.75rem 0.5625rem;
        background-repeat: no-repeat;
        background-position: 0.25rem;
      }
      i.tree-leaf-head {
        display: none;
      }
    }
  }

  .c-source-list-ul {
    overflow: visible;
  }
}

.c-source-list-ul .c-ul-inline {
  display: inline-block;
  margin: -0.125rem 0 0 0.4375rem;
  color: $textGreyLightest;
  line-height: 0.8125rem;
  vertical-align: middle;

  & > li {
    padding: 0 2em 0 0;
    &:last-child {
      padding-right: 0;
    }
    &::after {
      background-color: $textGreyLightest;
    }
  }
}

.source-icon {
  position: relative;
  display: inline-block;
  width: 1.625rem;
  height: 1.625rem;
  margin: -0.1875rem 0.125rem 0 0;
  vertical-align: middle;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  &[class*="protected"]::before {
    @extend .icn-protected;
    position: absolute;
    top: -0.125rem;
    right: -0.4375rem;
    height: 0.9375rem;
    width: 0.9375rem;
    background-size: contain;
    content: '';
  }
  &.protected-partial::before {
    @include c-icon-property(protect-partial, bright);
  }
}

treecontrol,
c-treecontrol {
  .node-details {
    display: inline-block;
    width: 100%;
    line-height: 2.5rem;
    margin-left: -1.375rem;
    padding-left: 1.375rem;
    cursor: default;
    .node-name {
      display: inline-block;
      cursor: pointer;
      margin-bottom: 0;
      vertical-align: baseline;
    }

    .autoprotect-btn {
      cursor: pointer;
    }
  }
}

@include helix-themes() using ($theme) {
  treecontrol,
  c-treecontrol {
    &.protected {
      color: helix-color($theme, status, success);
    }
  }
}
