$field-background-color: rgba(
  $color: #000,
  $alpha: 0.1,
);

$field-placeholder-color: rgba(
  $color: #fff,
  $alpha: 0.7,
);

$field-text-color: #fff;
$field-border-radius: 10px;
