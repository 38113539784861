// grid

// Bootstrap row & column padding override
// TODO: Update bootstrap to compile at run time so we can override defaults in
// a graceful manner.
.row,
.form-horizontal .form-group {
  margin-right: $bootstrap-col-padding * -1;
  margin-left: $bootstrap-col-padding * -1;
}

[class*="col-"] {
  padding-left: $bootstrap-col-padding;
  padding-right: $bootstrap-col-padding;
}


.row {
  // `tabular` modifier class will give a grid row a `table' like presentation
  // with bottom-border and padding. This provides more flexibility in defining
  // column widths by attaching them to a grid col-xs-* rule. This could also
  // allow for more flexible stacked UX implementations.
  &.tabular {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    margin: 0;

    > [class*="col-"] {
      border-bottom: 1px solid $colorBorder;
    }

    > .no-border[class*="col-"] {
      border-bottom: none;
    }

    &:not(.tabular-no-bottom-padding):not(.tabular-no-vertical-padding) {
      > [class*="col-"] {
        padding-bottom: 0.75rem;
      }
    }

    &:not(.tabular-no-top-padding):not(.tabular-no-vertical-padding) {
      > [class*="col-"] {
        padding-top: 0.75rem;
      }
    }
  }
  &.tabular-header {
    font-size: 0.8em;
  }
}

.container-fluid {
  padding: {
    left: $bootstrap-col-padding;
    right: $bootstrap-col-padding;
  }
}

// `.flex-row` is a generic `display: flex;` wrapper with some options.
// NOTE: flex does NOT work on fieldset elements.
//
// The sizing modifiers indicate the amount of margin between each child
// element. Without a size specified, there is no margin between child elements.
//
// A flex row has no margins by default, and you can opt into margins via
// decorators: flex-margin-top, flex-margin-bottom, flex-margin-vertical (both)
//
// <div class="flex-row-sm flex-margin-bottom">[flex things]</div>
[class*="flex-row"] {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;

  &.flex-margin-top > *,
  &.flex-margin-vertical > * {
    margin-top: 0.75rem;
  }

  &.flex-margin-bottom > *,
  &.flex-margin-vertical > * {
    margin-bottom: 0.75rem;
  }

  // reset last child margin
  > :last-child {
    margin-right: 0;
  }

  // Adding this class to the flex-row wrapper will result in all flex items
  // being vertically aligned with each other.
  // <div class="flex-row-md vertical-align">
  //   <label>this text aligns vertically</label>
  //   <input type="text" value="with this text input">
  // </div>
  &.vertical-align {
    align-items: center;
  }

  &.baseline-align {
    align-items: baseline;
  }

  // aligns items to the top of the container
  &.start-align {
    align-items: flex-start;
  }

  // aligns items to the bottom of the container
  &.end-align {
    align-items: flex-end;
  }
}

.flex-row-xxs > * {
  margin-right: 0.25rem;
}

.flex-row-xs > * {
  margin-right: 0.5rem;
}

.flex-row-sm > * {
  margin-right: 1rem;
}

.flex-row-md > * {
  margin-right: 2rem;
}

.flex-row-lg > * {
  margin-right: 4rem;
}

// Equally divide the available width b/w flex children.
.flex-equal-division > * {
  flex: 1;
}

.flex-row-nowrap {
  flex-wrap: nowrap;
}

// A flex-row that is inline.
.flex-row-inline {
  display: inline-flex;
}

// Adding this modifier results in spacing being added between the flex items
// such that they fill the entire width of the flex-row.
.flex-justify-space-between {
  justify-content: space-between;
}

// Justifies the child elements to the flex end.
.flex-justify-flex-end {
  justify-content: flex-end;
}

// Justifies the child elements to the center.
.flex-justify-center {
  justify-content: center;
}

// aligns the child element to the vertical bottom of the flex-row.
.flex-item-self-align-end {
  align-self: flex-end;
}

// pulls the child element to the right edge of the flex-row.
// !important because this is effectively a utility class.
.flex-pull-right {
  margin-left: auto !important;
}

// Allows a child element to consume as much horizontal space as available.
.flex-grow {
  flex-grow: 1;
}

// Flex-basis to set the width of flex elements
.flex-basis-xs {
  flex-basis: 3.125rem;
}

.flex-basis-sm {
  flex-basis: 6.25rem;
}
.flex-basis-md {
  flex-basis: 9.375rem;
}
.flex-basis-lg {
  flex-basis: 12.5rem;
}
.flex-basis-xl {
  flex-basis: 15.625rem;
}

.flex-row-divider {
  border-top: 1px solid $colorBorder;

  .with-bottom-divider {
    border-bottom: 1px solid $colorBorder;
  }
}

// `.flex-column` is a generic `display: flex;` wrapper with some options.
// NOTE: flex does NOT work on fieldset elements.
//
// The sizing modifiers indicate the amount of margin between each child
// element. Without a size specified, there is no margin between child elements.
//
// <div class="flex-column-sm">[flex things]</div>
[class*="flex-column"] {
  display: flex;
  flex-direction: column;

  // reset last child margin
  > :last-child {
    margin-bottom: 0;
  }

  &.horizontal-align {
    align-items: center;
  }
}

.flex-column-xxs > * {
  margin-bottom: 0.25rem;
}

.flex-column-xs > * {
  margin-bottom: 0.5rem;
}

.flex-column-sm > * {
  margin-bottom: 1rem;
}

.flex-column-md > * {
  margin-bottom: 2rem;
}

.flex-column-lg > * {
  margin-bottom: 4rem;
}

// Use this class on a div to distribute remaining width in-between a flex parent's child components.
.spacer {
  flex: 1;
}

@for $i from 0 through 40 {
  $gap: $i * 4;
  @if ($gap % 4==0) {
    .flex-gap-#{$gap} {
      gap: ($gap / 16) * 1rem;
    }
  }
}

@include reflow() {
  @include reflow-sm() {
    [class*="flex-row"] {
      &.flex-wrap\@sm {
        flex-wrap: wrap;
      }

      &.flex-direction-column\@sm {
        flex-direction: column;
      }
    }
  }
  @include reflow-xs() {
    [class*="flex-row"] {
      &.flex-wrap\@xs {
        flex-wrap: wrap;
      }

      &.flex-justify-unset\@xs {
        justify-content: unset;
      }

      &.flex-direction-column\@xs {
        flex-direction: column;
      }
    }
  }
}
