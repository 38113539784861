// Styles: Snmp Configuration

snmp-user {
  display: block;
}

.c-snmp-view {
  div[disabled] {
    @include disabledProperties();
    color: $textGreyLight;
  }
}

.snmp-header {
  position: absolute !important;
  margin: unset !important;
}