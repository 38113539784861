// Breakpoint at which the cog nav menu list will remain opened.
$largeScreenBreakPoint: 1489px;

.c-apps {
  .app-container {
    width: 40%;
  }

  .app-container:nth-child(odd) {
    max-width: calc(40% - 0.9375rem);
  }

  .app-container:nth-child(even) {
    float: right;
  }

  ol, ul {
    list-style: disc;
  }

  .app-icon {
    background: none;
  }

  .app-logo {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 0.5rem;
    display: flex;
    justify-content: center;
  }

  .c-app-name {
    @include font($size: 1.25rem, $leading: 1rem);
    margin-bottom: 0.625rem;
    margin-top: 0.3125rem;
  }

  .avg-review {
    padding: 0.125rem 0 0 0.25rem;
  }

  .app-category-row {
    padding: 0.25rem 0;
  }

  .rating {
    padding: 0.3125rem 0;

    &.disabled {
      cursor: unset;
    }
  }

  .app-review  .rating {
    padding: 0;
  }

  .rating li {
    list-style-type: none;
    display: inline-block;
    padding: 0 0.5rem 0 0;
    cursor: pointer;
  }

  .no-results {
    border: 0;
  }

  .app-title {
    @include font($weight: normal, $size: 1.5625rem, $leading: 1);
    list-style: none;
  }

  .app-spec {
    padding: 0 1.5rem;
  }

  .app-overview-header {
    @include font($weight: normal, $size: 1.3625rem, $leading: 1);
    padding: 1rem 0;
    width: 40%;
  }

  .c-app-checkbox {
    height: 1.125rem;
    width: 1.125rem;
  }

  .view-header span {
    @include font($weight: normal);
  }

  .app-certified {
    @include font(
      $weight: normal,
      $size: 0.875rem
    );
    list-style: none;
  }

  .c-app-description {
    @include font(
      $weight: normal,
      $size: 0.875rem,
      $leading: 1
    );
  }

  c-color-dot {
    width: 0.5rem;
    height: 0.5rem;
  }

  .c-app-review {
    .c-rating-value {
      padding: 0 0 0 0.5rem;
    }

    .c-avg-rating-section {
      width: 23rem;
      padding: 1rem;
      margin: 0 0 0.75rem 0;
    }

    .c-review-section {
      width: 80%;
      padding: 0.625rem 0;
    }

    .c-app-review-title {
      @include font($weight: normal, $size: 1.5rem, $leading: 1);
      padding: 0.75rem 0;
      margin: 0;
    }

    .c-app-review-description {
      @include font(
        $weight: normal,
        $size: 0.875rem,
        $leading: 1
      );
    }
  }

  .c-count-down-text {
    margin-top: -4rem;
    @include font(
      $weight: normal,
      $size: 0.875rem,
      $leading: 1
    );
  }

  .c-app-description,
  .app-details {
    ol, ul {
      padding: 0.25rem 1.25rem;
    }
  }

  // Lightbox css

  .c-lightbox {
    &.hidden {
      display: none;
    }

    section {
      width: 100%;
      height: 100%;
      position: fixed;
      z-index: 10065;
      top: .5rem;
      left:0;
      background-color: rgba(0, 0, 0, 0.80);
      overflow-y: auto;
    }

    .ng-lightbox {
      padding: 2rem 0;

      @media (min-width: $largeScreenBreakPoint) {
        padding: 2rem 0 0 15rem;
      }
    }

    .back-arrow {
      margin: 1.25rem 1rem;
      cursor: pointer;
    }
  }

  .lightbox-container {
    > [class*='lightbox-'] {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .lightbox-navigator {
      align-items: center;
      width: 15%;
    }

    .lightbox-inner {
      width: 70%;
    }

    .slick-slide {
      img {
        width: 100%;
      }
      margin: 0 1rem;
      outline: none;
    }

    .lightbox-img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-height: calc(100vh - 8.5rem);
    }

    .lightbox-arrow {
      width: 2.5rem;
      height: 2.5rem;
      position: relative;
      z-index: 10;
      cursor: pointer;
    }

    .align-lightbox-arrow {
      @media (min-width: $largeScreenBreakPoint) {
        padding: 3rem 0;
      }
    }
  }
}

// Cluster information popover CSS
.c-cluster-popover {
  border-radius: 2px;
}

.rating-status {
  @include font(
    $weight: normal,
    $size: 1.35rem
  );
  padding: 0.25rem 1rem;
}

.user-ssh-key {
  max-width: 20rem;
}

.installed-count {
  width: 14%;
}

@include helix-themes() using ($theme) {
  $is-dark: map-get($theme, is-dark);
  $is-jazz-theme: map-get($theme, is-jazz);

  .c-apps, apps {
    @if $is-jazz-theme and $is-dark {
      .app-icon {
        background: helix-contrast($theme, component, card);
        padding: .25rem;
      }
    }
  }

  .c-apps {
    .app-overview-header {
      border-bottom: 0.15rem solid helix-color($theme, foreground, divider);
    }

    .app-certified {
      color: helix-color($theme, status, success);
    }

    .c-app-description {
      color: helix-color($theme, foreground, hint-text);
    }

    .c-app-review {
      .c-rating-value {
        color: helix-color($theme, status, unknown);
      }

      .c-avg-rating-section {
        background: helix-color($theme, background, app-bar);
      }

      .c-review-section {
        width: 80%;
        padding: 0.625rem 0;
      }

      .c-app-review-title {
        @include font($weight: normal, $size: 1.5rem, $leading: 1);
        padding: 0.75rem 0;
        margin: 0;
      }

      .c-app-review-description {
        color: $textGreyMedium;
      }

      .rating-positive {
        background-color: helix-color($theme, status, success);
      }

      .rating-average {
        background-color: helix-color($theme, status, warning);
      }

      .rating-low {
        background-color: helix-color($theme, status, error);
      }
    }

    .c-count-down-text {
      color: helix-color($theme, foreground, text);
    }

  }

  .failure-reasons-section {
    .failure-reasons-header {
      display: flex;
      align-items: flex-end;
    }
  }

}
