// Styles for c-email-scheduler modal component

.c-email-scheduler {
  .c-email-scheduler-timepicker {
    div.c-time-picker .uib-timepicker td.uib-separator,
    div.c-time-picker .uib-timepicker td.uib-time.minutes {
      display: none;
    }
  }

  // reducing the size of typeahead input.
  .c-typeahead input[type="text"] {
    width: 45%;
  }

  report-fields form {
    flex-direction: column;
    background: $colorNeutralLight;
    border-radius: 0.25rem;
    padding: $gutter-sm;
    margin: 0;
  }

  // Reducing the width in the c-controls time-span field
  input[id="report-time-span-input"] {
    width: 5%;
  }
}
