// When developing new components, you should spin off a theme file that comtains a mixin that takes a $theme as an
@use "@angular/material/core/theming/theming" as mat;
// an argument and outputs the correct colors based on that provided theme. This allows for multiple themes to be
// utliized for the component. T
// More information on how this works can be found on the Angular Material site:
// https://material.angular.io/guide/theming-your-components



// Use this mixin to loop through the themes and generate theme specific color
// rules. NOTE: Probably don't use this as leveraging helix-themes() below with
// helix-color() and helix-contrast() should be more future proof.
// Example:
//   @include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
//     .my-themed-class {
//       background: mat-color($primary, 500);
//       color: mat-contrast($primary, 500);
//     }
//   }
@mixin apply-helix-themes() {
  @each $class, $theme in $helix-themes {
    #{$class} {
      $primary: map-get($theme, primary);
      $accent: map-get($theme, accent);
      $warn: map-get($theme, warn);
      $background: map-get($theme, background);
      $foreground: map-get($theme, foreground);

      @content($primary, $accent, $warn, $background, $foreground);
    }
  }
}

// Use this mixin to loop through the themes and generate theme specific color
// rules. This mixin provides a simplified signature compared to apply-helix-themes()
// but requires you to either:
// a) use helix-color() and helix-contrast() to get the color you want
// b) map-get the palette you desire before pulling colors from it.   $primary: map-get($theme, primary);
// Example:
//   @include helix-themes() using ($theme) {
//     .my-themed-class {
//       background: helix-color($theme, primary, 500);
//       color: helix-contrast($theme, primary, 500);
//     }
//   }
@mixin helix-themes() {
  @each $class, $theme in $helix-themes {
    #{$class} {
      @content($theme);
    }
  }
}

// Gets a color value from the provided theme map and specified palette.
@function helix-color($theme, $pallete: 'primary', $key: '500') {
  @return map-get(map-get($theme, $pallete), $key);
}

// Gets a constrast color value from the provided theme map and specified palette.
@function helix-contrast($theme, $pallete: 'primary', $key: '500') {
  @return mat.get-contrast-color-from-palette(map-get($theme, $pallete), $key);
}

// This mixin takes a list of colors and outputs highcharts compatible classes to implement the color list in a chart.
// This Should be leveraged with a wrapping class in order to create a chart "theme" that is applied via application of
// the wrapping class.
@mixin generateChartColors($chartColors, $includeStroke: false) {
  @for $i from 1 through length($chartColors) {
    $color: nth($chartColors, $i);
    .highcharts-color-#{$i - 1} {

      fill: $color;
      background-color: $color;
      @if ($includeStroke) {
        stroke: $color;
      }

      &.highcharts-tooltip {
          stroke: $color;

        .highcharts-tooltip-box {
          // Applied as !important because highcharts is still applying inline
          // styles despite being in styled mode.
          stroke: $color !important;
        }
      }

      &.highcharts-legend-item {
        rect {
          fill: $color !important;
        }
      }
    }
  }
}

// This mixin takes a list of colors and outputs highcharts compatible classes to implement the color list in a chart.
// You can set the classname directly on a series (or data point for pie charts) to apply the color
@mixin generateNamedSeriesColors($seriesNames) {
  @each $name, $color in $seriesNames {
    .chart-series-#{$name} {
      fill: $color;
      background-color: $color;

      .highcharts-point,
      &.highcharts-point {
        fill: $color !important;
        stroke: $color;
        background-color: $color !important;
      }

      &.highcharts-tooltip {
          stroke: $color;

        .highcharts-tooltip-box {
          // Applied as !important because highcharts is still applying inline
          // styles despite being in styled mode.
          stroke: $color !important;
        }
      }
    }
    .highcharts-series:not(.highcharts-pie-series) {
      .chart-series-#{$name} {
        stroke: $color;
        .highcharts-point,
        &.highcharts-point {
          stroke: $color !important;
        }
      }
    }
  }
}

// This mixin generates highcharts point colors for anomaly sparkline chart.
// Colors are generated as per series.
@mixin generatePointColors($chartColors, $fillColor) {
  @for $i from 1 through length($chartColors) {
    $color: nth($chartColors, $i);
    .highcharts-color-#{$i - 1} .highcharts-point {
      fill: $fillColor;
      stroke: $color;
      stroke-width: 0.125rem;
      fill-opacity: 1;
    }
  }
}

// This mixin takes a list of colors for all the series in a chart and start/end opacity to generate gradient colors
// for highcharts area. This should be leveraged with a wrapping class in order to create a chart "theme"
// that is applied via application of the wrapping class.
// @example
//   // $color-1 and $color-2 should be sourced from the theme and this entire block should happen
//   // inside of `@include helix-themes()`
//   $storage-line-colors: ($color1, $color2);
//
//   .storage-line-colors {
//     @include generateGradientColors($storage-line-colors, 0.2, 0);
//   }
@mixin generateGradientColors($chartColors, $startOpacity: 0.25, $endOpacity: 0) {
  @for $i from 1 through length($chartColors) {
    $color: nth($chartColors, $i);
    $index: $i - 1;

    #gradient-#{$index} stop {
      stop-color: $color;

      &[offset="0"] {
        stop-opacity: $startOpacity;
      }

      &[offset="1"] {
        stop-opacity: $endOpacity;
      }
    }

    .highcharts-color-#{$index} .highcharts-area {
      fill-opacity: 1;
      fill: url("#gradient-#{$index}");
    }
  }
}

// Generate styles for a given map. Uses contrast values if they
// are available in the map or falls back to a default color.
@mixin color-palette($prefix, $palette, $defaultContrast) {
  @each $key, $color in $palette {
    @if $key != 'contrast' {
      &.#{$prefix}-#{$key} {
        background-color: $color;
        @if map-get($palette, contrast) {
          color: map-get(map-get($palette, contrast), $key);
        }
        @else {
          color: $defaultContrast;
        }
      }
    }
  }
}

// Get all available color palettes
@mixin all-color-palette($theme) {
  $light-contrast: map-get(map-get($theme, foreground), text);
  $dark-contrast: map-get(map-get($theme, background), raised-button);
  @include color-palette('primary', map-get($theme, primary), $light-contrast);
  @include color-palette('accent', map-get($theme, accent), $light-contrast);
  @include color-palette('background', map-get($theme, background), $light-contrast);
  @include color-palette('foreground', map-get($theme, foreground), $dark-contrast);
  @include color-palette('helix-primary', map-get($theme, helix-primary), $light-contrast);
  @include color-palette('helix-secondary', map-get($theme, helix-secondary), $light-contrast);
  @include color-palette('component', map-get($theme, component), $light-contrast);
  @include color-palette('status', map-get($theme, status), $light-contrast);
  @include color-palette('status-background', map-get($theme, status-background), $light-contrast);
  @include color-palette('target', map-get($theme, target), $light-contrast);
  @include color-palette('chart', map-get($theme, chart), $light-contrast);
  @include color-palette('helix-base', map-get($theme, helix-base), $light-contrast);
  @include color-palette('state-primary', map-get($theme, state-primary), $light-contrast);
  @include color-palette('state-secondary', map-get($theme, state-secondary), $light-contrast);
  @include color-palette('state-overlay', map-get($theme, state-overlay), $light-contrast);
}
