// Activity Report Styles

.alerts-resolve-desc {
  width: 100%;
  height: 80px;
}

.alerts-resolve-disabled-fields {
  opacity: 0.5;
}

.alert {
  // Make all child text elements inherit the TBS alert color
  p, span, strong,
  li, i, em {
    color: inherit;
  }
}

.alert-notification-modal {

  .alert-notification-email-section {
    margin-bottom: 1rem;
  }

  .alert-notification-webhook-inputs {
    border-right: 1px solid $colorBorder;
  }

  .alert-notification-webhook-outputs {
    word-break: break-all;
  }
}

.alert-notification-rule-page {
  .alert-notification-rule {
    margin-bottom: 1rem;
  }

  .alert-notification-list-group {
    margin-bottom: 1rem;
  }

  .alert-notification-list-title {
    font-size: 0.8rem;
    color: $textGreyLight;
  }

  .alert-notification-list-text {
    margin-bottom: 0.5rem;
    word-break: break-all;
  }

  .alert-notification-email-list {
    list-style-type: none;
  }

  .alert-notification-email-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 0.35rem;

    .icn-md {
      margin-right: 0.5rem;
    }
  }
}
