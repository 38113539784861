// Component Styles: Sticky Footer
// NOTE: Sticky footer has been dumbed down to not be sticky. This is no
// longer an approach that UX is using in their designs.

.c-sticky-footer {
  padding: 0 0 $cohesity-card-padding 0;
  margin-top: $cohesity-card-padding;
  margin-bottom: -$cohesity-card-padding;

  // Modals have custom padding, adjust accordingly.
  .modal-content & {
    padding-bottom: $cohesity-card-padding;
    margin-bottom: -$cohesity-card-padding;
  }
}
