// Theme styles that only apply to the outline appearance of the form-field.
@use "@angular/material/core/theming/theming" as mat;

@mixin cog-mat-form-field-outline-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $warn: map-get($theme, warn);
  $foreground: map-get($theme, foreground);
  $component: map-get($theme, component);
  $is-dark-theme: map-get($theme, is-dark);

  $label-disabled-color: mat.get-color-from-palette($foreground, disabled-text);

  // NOTE: This is the only difference from Angular Material's implementation. They
  // are applying an opacity value to the divider color, which makes implementations
  // too faint. We have created a custom color for input underline.
  $outline-color: mat.get-color-from-palette($component, input);
  $outline-color-hover: mat.get-color-from-palette($component, input);
  $outline-color-primary: mat.get-color-from-palette($primary);
  $outline-color-accent: mat.get-color-from-palette($accent);
  $outline-color-warn: mat.get-color-from-palette($warn);
  $outline-color-disabled: mat.get-color-from-palette($component, input, if($is-dark-theme, 0.15, 0.06));

  .mat-form-field-appearance-outline {
    .mat-form-field-outline {
      color: $outline-color;
    }

    .mat-form-field-outline-thick {
      color: $outline-color-hover;
    }

    &.mat-focused {
      .mat-form-field-outline-thick {
        color: $outline-color-primary;
      }

      &.mat-accent .mat-form-field-outline-thick {
        color: $outline-color-accent;
      }

      &.mat-warn .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }

    // Class repeated so that rule is specific enough to override focused accent color case.
    &.mat-form-field-invalid.mat-form-field-invalid {
      .mat-form-field-outline-thick {
        color: $outline-color-warn;
      }
    }

    &.mat-form-field-disabled {
      .mat-form-field-label {
        color: $label-disabled-color;
      }

      .mat-form-field-outline {
        color: $outline-color-disabled;
      }
    }
  }
}
