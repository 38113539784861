.c-cluster-setup {
  .c-stepper {
    margin-bottom: 30px;
  }

  p.label-notes {
    @include font($size: 0.8125rem, $leading: 1.0625rem);
    margin: 0 0 10px 0;
  }

  .current-operation-label {
    text-align: center;
    font-size: 12px;
  }
}

.encryption-toggle-group {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.c-cluster-setup-nodes {

  .node-setup-container {
    min-height: 50px;
    width: calc(50% - 15px);
    display: inline-block;

    fieldset {
      margin: 0 0 10px 0;
    }

    .node-setup-label-container {
      width: 25%;
      display: inline-block;
      float: left;

      .node-label {
        @include font($weight: bold, $size: 1.3125rem, $leading: 1);
      }

      .connected-to-label {
        display: none;
      }

      .node-id {
        padding-top: 5px;
        display: inline-block;
      }
    }
    .node-setup-label-container.node-connected-to {
      display: inline-block;
    }

    .node-setup-input-container {
      width: 75%;
      display: inline-block;
      float: right;

      fieldset {
        width: calc(50% - 15px);
        padding: 0 7px;
        float: left;
      }
    }

    &:nth-child(even) {
      margin-left: 0.3125rem;
      margin-right: 0.625rem;
    }

    &:nth-child(odd) {
      margin-right: 0.3125rem;
      margin-left: 0.625rem;
    }
  }


  .unselected-node {
    min-height: 75px;
    div.unselected-node-text {
      @include font(
        $weight: bold,
        $size: 21px,
        $leading: 1
      );
      text-align: center;
      padding-top: 25px;
    }
  }

}

.show-password-checkbox-container {
    label.label-offset {
      margin-top: 36px;
      font-size: 12px;
      input {
        float: left;
      }
    }
    label.label-offset:hover {
      cursor: pointer;
    }
}

.node-checkbox-label {
  width: calc(25% - 10px);
  .node-name-label {
    @include font($weight: bold);
    position: relative;
    top: -2px;
  }
  .node-id,
  .connected-to {
    padding-top: 5px;
  }
}

.node-count-label {
  line-height: 1;
  font-size: 32px;
}

@include helix-themes() using ($theme) {
  .c-cluster-setup-nodes {
    .node-setup-container {
      .node-setup-label-container.node-connected-to {
        color: helix-color($theme, status, success);
      }
    }

    .unselected-node {
      div.unselected-node-text {
        color: helix-color($theme, foreground, hint-text);
      }
    }
  }
}

.c-cluster-settings {
  .sw-encryption-info {
    margin-left: 2.75rem;
    .sw-encryption-msg {
      max-width: 33vw;
      .msg-text {
        white-space: pre-wrap;
      }
    }
  }
}