// Component: Restore Oracle shared styles (CLone & Recover)

.c-restore-db {
  .icn-md.icn-type-sql,
  .icn-md.icn-type-oracle,
  .icn-sm.icn-edit {
    vertical-align: middle;
    margin-left: 0.5em;
  }
}

.c-restore-db-search {
  .db-item {
    .media-object {
      margin-left: 0;
      margin-right: 10px;
    }
  }
  .icn-inline.icn-remote {
    // Reduce height because it makes the line it's on too tall relative to
    // lines without icons.
    height: 1.5em;

    // Increasing the below width will truncate this icon
    width: 16px;
  }
}

form[name="snapshotSelectorForm"] {
  c-date-picker {
    margin-top: -1px;
  }
}

#sql-original-location-message {
  margin-bottom: 0;
}

#sql-restore-task-contents {
  th, td {
    vertical-align: top;
  }
  .c-ul-inline {
    vertical-align: middle;

    * {
      vertical-align: inherit;
    }
  }
}

.database-migration-auto-sync-radios {
  fieldset {
    margin: 0;
  }
}

#restore-sql-selected-dbs-modal {
  [class*="icn-"] {
    vertical-align: bottom;
  }
}

// The SQL Instance typeahead dropdown panel
input[uib-typeahead*="sqlInstance.instanceName"] {
  + [uib-typeahead-popup] {
    .icon-message {
      padding-left: 2em;
      margin-top: 0.3em;
      margin-bottom: 0.5em;

      &::before {
        font-size: 1.2em;
        left: 0;
      }
    }
  }
}

// uibTypeahead override
.dropdown-menu {
  > .active {
    > a {
      // Doubling this class in case another selector elsewhere is slightly
      // heavier. This helps avoid using !important
      .icon-message.icon-message {
        color: inherit;
      }
    }
  }
}

// Alt SQL Host typeahead (for when list of alt hosts exceeds the optimal
// UI-Select threshold.
.sql-host-typeahead-popup {
  position: absolute;
  z-index: 1;
  width: 100%;
  top: 100%;

  .mat-option {
    padding: ($gutter-sm * 0.5) $gutter-sm;
    line-height: 1.5em !important;

    &:not(.mat-optgroup-label) {
      cursor: pointer;
    }

    .icn-add {
      position: relative;
      top: 2px;
    }
  }
}

@include helix-themes() using ($theme) {
  .ace_editor {
    height: 100%;
    min-height: 15rem;
    font-size: 0.875rem;
    background: helix-color($theme, background, card);
    border: none;
    box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12), 0px 3px 5px rgba(0, 0, 0, 0.2);
  }

  .ace_gutter,
  .ace_gutter-active-line {
    background-color: helix-color($theme, helix-primary, 50);
    color: helix-color($theme, foreground, text);
    border-right: none;
    z-index: 1;
  }

  .pfile-icn {
    position: relative;
    top: 0.25rem;
    vertical-align: middle;
  }
}
