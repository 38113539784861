// jobSummary styles

job-summary {
  display: block;

  .c-ul.clean li {
    height: 32px;
    overflow: hidden;
  }
}

.volume-details {
  @include font($size: 0.75rem, $leading: 0.875rem);
  margin-top: 8px;

  .volume-details-label {
    margin: 3px 0;
    display: block;
  }

  ol {
    margin-left: 12px;

    li {
      margin-bottom: 6px;
      padding-left: 0;

      &:before {
        content: none;
      }

      div {
        margin-top: 3px;
        padding-left: 15px;
      }
    }
  }
}

.show-volumes {
  margin-left: 10px;
  font-size: 11px;
}

.physical-details .volume-details {
  margin: 0 0 15px 0;
}
