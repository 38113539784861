// Import the classic colors and then override them in this file as desired.
@use "@angular/material/core/theming/theming" as mat;
@use "@angular/material/core/theming/palette" as mat-theme-palette;
// NOTE: Do not use these colors. They are here to support legacy pages and
// need to be refactored out. Use helix-themes() mixin and get a theme color.

@import "_colors";

// TODO: implement theming for these colors, which will require replacing each
// implementation point. These will be pulled from $background.card-border.
$colorBorder: mat.get-color-from-palette(mat-theme-palette.$light-theme-background-palette, card-border);
$drawerBorder: mat.get-color-from-palette(mat-theme-palette.$light-theme-background-palette, card-border);
