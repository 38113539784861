// Component: Recover Files to Source

#c-recover-files {
  tr {
    .media-object {
      position: relative;
    }
    .in-cart-icon {
      display: none;
    }
    &.in-cart {
      .in-cart-icon {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 33px;
      }
    }
  }
}

.vms-or-files-input-container {
  margin-bottom: -10px;
}

.proxy-server-ip {
  line-height: 2rem;
}

@include helix-themes() using ($theme) {
  #c-recover-files {
    tr {
      .in-cart-icon {
        &.enclosed {
          background: helix-color($theme, status, success);
        }
      }
    }
  }
}
