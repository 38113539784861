// Style: cInstruction

.c-instructions {
  display: flex;
  justify-content: space-around;

  .instruction {
    display: flex;
    flex-direction: column;
    align-items: center;

    .instruction-content-container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .instruction-icon {
      width: 4.5rem;
      height: 4.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $colorPrimaryLight;
      border-radius: 50%;

      i {
        width: 2rem;
        height: 2rem;
      }
    }

    .instruction-action {
      margin-top: 1rem;
    }
  }

  .instruction-arrow {
    flex: 1;

    i {
      margin-top: 1.5rem;
      width: 100%;
    }
  }
}