// List styles

// normalize list styles
dl, menu, ol, ul {
  margin: 0;
  list-style: none;
}

dt, dd {
  line-height: 1.43;
}

dd {
  margin: 0;
}

// reset bootstrap label margin when in helix statlists
.cog-statlist-item label {
  margin-bottom: 0;
}

h3.popover-header-title,
.popover-section-title {
  margin-bottom: 0.5rem;
}

.popover-section-title {
  @include cog-type-level(body-2);
  margin-top: 0.5rem;
}

.c-ul.popover-section-ul:not(:last-child) {
  margin-bottom: 1.5rem;
}

// c-ul
.c-ul {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    position: relative;
    padding-left: 0.9375rem;
    margin-bottom: 0;
  }
  li:before {
    position: absolute;
    top: 0;
    left: 0;
    content: "\002022";
    color: $colorPrimary;
  }
  li.padding-bottom-sm {
    padding-bottom: 0.625rem;
  }
}

.c-ul.clean {
  li {
    text-indent: 0;
    padding-left: 0;
  }
  li:before {
    content: none;
  }
}

.c-ul-inline {
  margin-bottom: 0.9375rem;
  list-style-type: none;

  li {
    position: relative;
    display: inline-block;
    white-space: nowrap;
    vertical-align: top;
    margin-bottom: 0;
    padding-right: 2em;
    line-height: 1.2em;

    // Ensure <strong> labeling stays at the top if the content of an li wraps
    > strong {
      // Using normal font-weight as this will be differentiated via color.
      font-weight: $font-weight-normal;
      vertical-align: top;
    }

    // list label will not have the separator border and padding but will come
    // with colon separator.
    &.list-label {
      @include font($weight: bold);

      padding-right: 0.4em;
      margin-right: 0.25rem;

      &::after {
        content: ':';
        right: 0;
        position: absolute;
      }

      &.auto-truncate {
        width: auto;
        white-space: nowrap;
      }
    }

    &:not(:last-child):not(.list-label)::after {
      position: absolute;
      top: 0.15em;
      right: 1em;
      content: '';
      display: block;
      width: 0.0625rem;
      height: 1em;
      background-color: $colorBorderDarkest;

      .c-tile &,
      .c-box & {
        background-color: $colorBorder;
      }
    }
    &:last-child {
      padding-right: 0;
    }

    &.truncate {
      max-width: 50%;
    }
  }

  &.meta-data,
  .c-table & {
    font-size: 0.75rem;
    margin: 0;

    &:first-of-type {
      margin-top: 0.1875rem;
    }

    // tighten up small if it follows directly after a c-ul-inline within
    // the context of a c-table
    & + small {
      position: relative;
      top: -0.1875rem;
    }
  }

  [class*="user-data"] {
    white-space: normal;
  }

  // c-ul-inline nested within c-ul
  .c-ul & {
    $itemGap: 0.75em;

    li {
      margin-left: 0;
      padding-left: 0;
      // Slightly smaller because of whitespace
      margin-right: $itemGap * 0.9;
      padding-right: $itemGap;

      &::before {
        content: none;
      }
    }
  }

  &.breadcrumbs {
    @include cog-type-level(subheading-1);
    line-height: inherit;
    font-weight: $font-weight-normal;
    display: inline-block;
    margin: 0;

    li {
      padding-right: 1rem;

      &:not(:last-child):not(.list-label)::after {
        background: none;
        color: $textGreyLight;
        content: '\203A';
        display: inline-block;
        padding-left: .5em;
        position: static;
      }

      [class*="user-data"] {
        white-space: nowrap;
        vertical-align: bottom;
      }
    }

    // Automatically truncate the breadcrumbs if an item name is really long
    &.truncate {
      width: 100%;

      li {
        max-width: 80%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }

  &.synopsis {
    margin-bottom: 0;
    white-space: normal;

    li {
      &:not(:last-child) {
        padding-right: 1rem;

        &::after {
          right: 0.5rem;
        }
      }
    }
  }
}

@include helix-themes() using ($theme) {
  .c-ul-inline {
    li {
      > strong {
        color: helix-color($theme, foreground, secondary-text);
      }
    }
  }

  .c-ul li:before {
    color: helix-color($theme, foreground, text);
  }
}

// definition list
[class*="c-dl"] {
  @include font($weight: normal);
  margin-bottom: 0.9375rem;

  > *:not(:first-of-type) {
    border-top: 1px solid transparent;
  }

  dt,
  dd {
    display: block;
    width: 50%;
    word-wrap: break-word;
    padding: 0.375rem 1.25rem 0.375rem 0;
    border-top: 1px solid transparent;
    display: inline-block;
  }

  dt {
    @include font($weight: normal);
    clear: both;
    float: left;

    // can be applied to a <div> or <span> for standard
    // font-sizing or to a <small> to decrease the font-size
    .subtext {
      display: block;
    }
  }

  // For nested lists, eliminate the dl's top and bottom padding because it's
  // already built into the containing dd.
  [class*="c-dl"] {
    margin-bottom: 0;

    .dl-row:first-child {
      dt, dd {
        padding-top: 0;
      }
    }

    .dl-row:last-child {
      dt, dd {
        padding-bottom: 0;
      }
    }

    dt {
      @include font($weight: normal);
    }
  }

  // This can be used to group a dt+dd pair when one of them is likely to wrap
  // lines.
  .dl-row {
    position: relative;
    clear: both;
    border-top: 1px solid transparent;

    &.dl-table-header {
      dd,
      dt {
        @include font($weight: normal);
      }
    }

    &.dl-table-row {
      dd,
      dt {
        @include font($weight: normal);
      }
      dd {
        padding-right: 0;
      }
    }

    // Remove the border-bottom when nested inside a .dl-row
    > dd,
    > dt {
      border-top: none;
    }
  }

  > dd:first-of-type,
  > dt:first-of-type,
  > .dl-row:first-child {
    border-top: none;
  }

  // smaller font text, reduced padding
  &.c-dl-compressed {
    dd,
    dt {
      @include font($size: 0.8125rem, $leading: 1);
      padding: 0.3125rem 0;
    }
  }

  &.c-dl-md {
    dt, dd {
      padding: 0.625rem 1.25rem;
    }
  }
  // Reset to 50/50 split. This is necessary for a nested dl if it should be
  // 50/50 and the parent dl is not.
  &[class*="even-split"] {
    dt {
      width: 50%;
    }
    dd {
      width: 50%;
    }
  }

  // add more width to the left side of the DL
  // rather than the default 50/50 split
  &[class*="wide-left"] {
    dt {
      width: 70%;
    }
    dd {
      width: 30%;
    }
  }

  // add more width to the right side of the DL
  // rather than the default 50/50 split
  &[class*="wide-right"] {
    dt {
      width: 30%;
    }
    dd {
      width: 70%;
    }
  }

  &[class*="extra-wide-right"] {
    dt {
      width: 10%;
    }
    dd {
      width: 90%;
    }
  }

  &[class*="multiple-wide-right"] {
    dt {
      width: 40%;
    }
    dd {
      width: 30%;
    }
  }

  &[class*="only-right"] {
    dd {
      width: 100%;
    }
  }


  // add even more width to the right side of the DL rather than the default
  // 50/50 split or the 30/70 wide split.
  &[class*="extra-wide-right"] {
    dt {
      width: 20%;
    }
    dd {
      width: 80%;
    }
  }
  // remove border-top styling to provide a softer visual
  // (no penlines between definition items)
  &.c-dl-clean {
    dt,
    dd,
    .dl-row {
      border: 0;
    }
  }

  // Display as block so that values are stacked vertically
  // rather than horizontally. Also remove borders for vertical
  // lists.
  &.c-dl-vertical {
    dt,
    dd,
    .dl-row {
      border: 0;
      display: block;
      margin: 0;
      padding: 0;
      width: 100%;
    }

    dd {
      margin-bottom: 0.625rem;
    }
  }
  .redux-value {
    position: relative;
    top: 0.1875rem;
    font-size: 1.3125rem;
    line-height: 0.875rem;
  }

  // Make small buttons fit more comfortably within a dl
  [class*="c-btn"].sm {
    padding: 0.125rem 0.5625rem;
  }
}

@include helix-themes() using ($theme) {
  [class*="c-dl"] {
    $divider-color: helix-color($theme, foreground, divider);

    > *:not(:first-of-type) {
      border-top-color: $divider-color;
    }

    dt,
    dd {
      border-top-color: $divider-color;

      &.good {
        color: helix-color($theme, status, success);
      }
      &.bad {
        color: helix-color($theme, status, error);
      }
      &.primary {
        color: helix-color($theme, primary, 500);
      }
      &.chart-good {
        color: helix-color($theme, status, success);
      }
      &.chart-brand {
        color: $chartBrand;
      }
      &.chart-brand-dark {
        color: $chartBrandDark;
      }
    }

    dt {
      color: helix-color($theme, foreground, hint-text);

      strong {
        color: helix-color($theme, foreground, secondary-text);
      }
    }

    // This can be used to group a dt+dd pair when one of them is likely to wrap
    // lines.
    .dl-row {
      border-top-color: $divider-color;

      &.dl-table-header {
        dd,
        dt {
          color: helix-color($theme, foreground, hint-text);
        }
      }

      &.dl-table-row {
        dd,
        dt {
          color: helix-color($theme, foreground, secondary-text);
        }
      }

      // Remove the border-bottom when nested inside a .dl-row
      > dd,
      > dt {
        border-top: none;
      }
    }

    > dd:first-of-type,
    > dt:first-of-type,
    > .dl-row:first-child {
      border-top: none;
    }
  }
}

// Stats list: Used for At a Glance statistics.
[class*="stat-list"] {
  position: relative;
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: center;
  list-style-type: none;

  > li {
    @include cog-type-level(caption);
    position: relative;
    flex: 1 1 auto;
    float: none;
    vertical-align: middle;
    padding: 0 0 0 2rem;

    &:first-child {
      padding-left: 0;
      margin-left: 0;
    }
    &.stat-group {
      border-style: solid;
      border-width: 0;
    }

    // some views like to control the border separator
    // by using this stat-group-start class.  However,
    // it can be handled automatically in most cases
    &.stat-group:not(:first-child),
    &.stat-group-start {
      border-left-width: 1px;
    }

    cog-statlist-item {
      padding-right: 1rem;
    }

    &[class*="status-"] {
      color: inherit;

      [class*="stat-label"] {
        position: relative;
        padding-left: 1rem;

        &::before {
          position: absolute;
          top: 0.2rem;
          left: 0;
          display: inline-block;
          content: '';
          width: 0.75rem;
          height: 0.75rem;
          border-radius: 50%;
        }
      }
    }
  }
}

[class*="stat-value"] {
  @include cog-type-level(headline);
  position: relative;
  clear: both;

  .stat-value-unit {
    font-size: 0.6em;
  }

  // To use these size variants, the base class .stat-value is not required.
  &.stat-value-md {
    font-size: 1.6875rem;
  }

  &.stat-value-sm {
    font-size: 1.5rem;
  }

  &.stat-value-xs {
    font-size: 1.3125rem;
  }
}

[class*="stat-label"] {
  padding-top: 0.125rem;
  clear: both;
  text-overflow: ellipsis;
}


@include helix-themes() using ($theme) {
  [class*="stat-label"] {
    color: helix-color($theme, foreground, icon);
  }

  [class*="stat-list"] {
    > li {
      &.stat-group {
        border-color: helix-color($theme, background, card-border);
      }
    }
  }
  // NOTE: Can't put these inside of the above rule, as the theme class
  // gets rolled into the `&` selector.
  .status-ok [class*="stat-label"]::before {
    background-color: helix-color($theme, status, success);
  }

  .status-warning [class*="stat-label"]::before {
    background-color: helix-color($theme, status, warning);
  }

  .status-critical [class*="stat-label"]::before {
    background-color: helix-color($theme, status, error);
  }

  .status-info [class*="stat-label"]::before {
    background-color: helix-color($theme, status, info);
  }
}


.c-box-list {
  @extend .c-box;
  list-style: none;
  padding: 0;

  > li {
    padding: #{$cohesity-card-padding * 0.5} #{$cohesity-card-padding * 0.5};
    border: 1px solid transparent;

    &:first-of-type {
      border-top-left-radius: $defaultRadius;
      border-top-right-radius: $defaultRadius;
      border-top-color: transparent;
    }

    &:last-of-type {
      border-bottom-left-radius: $defaultRadius;
      border-bottom-right-radius: $defaultRadius;
    }

    &.selected {

      // Hiding the top border of any list item following a selected item so the
      // the default border color doesn't butt up against the selected color.
      & + li {
        border-top-color: transparent;
      }
    }
  }
}

@include helix-themes() using ($theme) {
  .c-box-list {
    > li {
      background-color: helix-color($theme, background, card);
      border-top: 1px solid helix-color($theme, foreground, divider);

      &.selected {
        border-color: helix-color($theme, primary, 500);
      }
    }
  }
}

.c-ol {
  list-style: none;
  counter-reset: c-counter;

  >li {
    counter-increment: c-counter;
    margin-bottom: 3.4rem;

    &::before {
      content: counter(c-counter) ". ";
      font-size: 1.2rem;
      font-weight: 300;
    }

    >h2:first-of-type {
      display: inline-block;
    }
  }
}

.standard-list {
  &:not(:last-of-type)::after {
    content: ', ';
  }
}

// Reducing the padding for the mat-card when it is rendered as a glance bar.
.mat-card.glancebar {
  padding: 0.5rem 1rem;

  cog-spinner {
    margin: 0.5rem;
  }
}

.mat-card-subtitle {
  ul.subtitle-list {
    display: flex;
    align-items: center;

    > li {
      display: flex;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      min-width: 0;

      cog-icon {
        margin-left: 0.25rem;
      }

      &:not(:last-of-type) {
        padding-right: 0.5rem;
        margin-right: 0.5rem;
        border-right: 1px solid;
      }
    }
  }
}

@include helix-themes() using ($theme) {
  .mat-card-subtitle {
    .subtitle-list {
      color: helix-color($theme, foreground, text);
    }
  }

  .c-ul-inline.hint-text,
  .c-ul-inline.meta-data {
    color: helix-color($theme, foreground, hint-text);

    li:not(:last-child):not(.list-label)::after {
      background-color: helix-color($theme, foreground, hint-text);
    }
  }
}

@include reflow() {
  @include reflow-sm() {
    [class*="stat-list"] {
      flex-wrap: wrap;

      > li {
        padding-left: .5rem;

        cog-statlist-item {
          padding-right: 0;
        }

        &.stat-group {
          padding-left: .5rem;
        }
      }
    }
    .mat-card-subtitle {
      .subtitle-list {
        flex-direction: column;
        align-items: flex-start;

        > li a {
          word-break: break-all;
          white-space: normal;
          overflow: unset;
        }
      }
    }
    ul.c-ul-inline {
      li {
        display: block;
      }
    }
    ul.c-ul-inline li:not(:last-child):not(.list-label)::after {
      content: unset
    }
  }
  @include reflow-xs() {
    [class*="stat-list"] {
      flex-wrap: wrap;

      > li {

        &.stat-group {
          padding-left: .25rem;
        }

        &.stat-group,
        &.stat-group-start {
          border-width: 1px;
        }

        // Since stat-group has an outline, do not want any child statlist-items
        // within the group to show their outlines.  It's like double borders
        &.stat-group
          cog-statlist.statlist-horizontal
          .cog-statlist-item {
            border-color: transparent;
        }

        > .stat-value-sm {
          overflow-wrap: anywhere;
          word-break: normal;
        }
      }
    }

    [class*="c-dl"] {
      // Reset all splits to 100% and stack
      &[class*="even-split"],
      &[class*="wide-left"],
      &[class*="wide-right"],
      &[class*="extra-wide-right"],
      &[class*="only-right"] {
        dt {
          width: 100%;
        }

        dd {
          width: 100%;
        }
      }
    }
  }
}
