// Override styles for the cookie notice which is embedded by cookiebot.com on
// Helios login page. Overriding styles using !important is the recommended way
// to add custom styles to cookiebot cookie notice.

// Hide the powered by icon.
#CybotCookiebotDialogPoweredbyCybot {
  display: none !important;
}

// Move the cohesity icon to the top of the display box.
#CybotCookiebotDialogPoweredbyLink {
  align-items: flex-start !important;
}

// -----
// Theming in the cookiebot modal is not supported.
// -----

// customize the thumb toggle switch.
#CybotCookiebotDialog input:checked+.CybotCookiebotDialogBodyLevelButtonSlider:before {
  background-color: #112c3b !important;
}

// customize the toggle switch background when not checked.
#CybotCookiebotDialog input:not(:checked)+.CybotCookiebotDialogBodyLevelButtonSlider {
  background-color: #b6c3ca !important;
}
