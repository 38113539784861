// widgets-layout styling

widgets-layout {
  display: block;

  widget-wrapper,
  c-compile-component {
    display: block;
    position: relative;
    height: 100%;
  }

  // styling for child widgets layout
  widgets-layout {
    &.c-box {
      padding: 1.25rem;
      margin: 0.625rem 0;
      border: 1px solid $colorPrimary;
    }
  }

  .close-btn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);
    height: 1.25rem;
    width: 1.25rem;
    padding: 0;
    border-radius: 50%;
    border-color: $colorBorderDark;
    background-color: $colorWhite;

    [class*="icn"] {
      height: 100%;
      width: 100%;
    }
  }

  widget-wrapper ~ & {
    background: $colorWhite;
    box-shadow: $defaultBoxShadow;
  }
}

// widget header
widget-wrapper {

  h4 {
    @include cog-type-level();
    margin-bottom: 1rem;

    > small {
      @include cog-type-level(body-1);
      float: right;
    }
  }

  // Set up a flex container for widget content that
  // sizes each section equally and shows a border between
  // them. In order to make the section divide show in
  // the center of the widget, the widget title should
  // be contained within the first section.
  .widget-content {
    display: flex;
    flex-direction: column;
    height: 100%;

    section {
      flex: 1;
      display: flex;
      flex-direction: column;

      +section {
        border-top: 0.0625rem dashed $colorBorderLight;
      }
    }
  }

  // child widgets tooltip arrow
  &.has-child {
    border: 1px solid $colorPrimary;
    box-shadow: $defaultBoxShadow;

    .widget-connector {
      width: 0rem;
      height: 1.375rem;
      position: absolute;
      transform: translate(-50%, 100%);
      left: 50%;
      bottom: 0;
      z-index: 1;
      border-left: 1px solid $colorPrimary;

      &::after,
      &::before {
        content: '';
        display: block;
        position: absolute;
        width: 0.4375rem;
        height: 0.4375rem;
        left: -0.25rem;
        transform: translateY(-50%);
        border-radius: 50%;
        background: $colorPrimary;
      }

      &::after {
        bottom: 0;
        transform: translateY(50%);
      }
    }
  }

  .c-btn-subtle {
    position: absolute;
    right: 0;
    top: -0.24rem;
  }

  // style tab inside a widget
  .nav-tabs {
    margin-bottom: 1rem;

    h4 {
      margin-bottom: 0;
    }

    .nav-link,
    .nav-link:hover,
    .nav-link:focus {
      padding: 0 0 0.25rem 0;
      margin-right: 1.25rem;
      outline: 0;
    }
  }

  // style charts inside a widget
  c-chart .no-data.no-results {
    border: 0;
  }
}
