// c-log-list component styling

c-log-list {
  $checkpointPosFromTop: 0.3125rem;

  .log-list {
    @include font($size: 0.75rem, $color: $textGreyMedium);

    max-height: 11.25rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .log-row {
    position: relative;
    min-height: 2.125rem;

    &:first-child {
      border-top: 1px solid $colorBorder;

      // move date connector line down so that it won't come on top of
      // date checkpoint
      .date-connector::before {
        transform: translateY($checkpointPosFromTop);
      }
    }
  }

  .date-connector::before {
    border-left: 1px solid $colorBorder;
    height: 100%;
    position: absolute;
    left: -0.4375rem;
    display: block;
    content: '';
  }

  .date-time {
    min-width: 5.625rem;
    padding-left: 0.25rem;
    position: relative;
  }

  .date-checkpoint {
    display: block;

    &::before {
      border-radius: 50%;
      border: 0.125rem solid $colorPrimaryBright;
      content: '';
      display: block;
      top: $checkpointPosFromTop;
      transform: translateX(-0.875rem);
      padding: 0.125rem;
      position: absolute;
      background-color: $colorWhite;
    }
  }
}
