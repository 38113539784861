@use "sass:math";

// This file should NOT output CSS. It should only include scss variables.
// This == 1rem
$root-font-size: 16px;

// Note: Value for absolute-root-font-size is in pixels without unit.
// This absolute value is used to convert font size from pixels to rem.
$absolute-root-font-size: 16;
$body-font-size: 0.875rem;

$cohesity-app-bar-height: 3.125rem  !default;
$cohesity-drawer-width: 16rem !default;
$cohesity-end-column-width: 25rem !default;
$cohesity-drawer-subnav-padding: 3.25rem !default;
$cohesity-card-padding: 1.5rem !default;
$helix-table-bleed: 0.5rem !default;

$page-width-sm: 53rem;
$page-width-md: 66.5rem;
$page-width-lg: 75rem;
$page-width-xl: 82rem;
$reflow-component-width: 16rem;

$max-page-width: 144rem;
$min-page-width: $page-width-md;

// Using size based named breakpoints based on Angular Material CDK Breapoints const.
// Intentionally eliminating the min-width media query aspects seen there so that
// styles will cascade down and won't have to be defined for each and every breakpoint.
// We are not yet concerning ourselves with their device centric named Breakpoints.
$breakpoint-xxs: 459.98px;
$breakpoint-xs: 599.98px;
$breakpoint-sm: 959.98px;
$bootstrap-breakpoint-md: 1199px;
$breakpoint-md: 1279.98px;
$breakpoint-lg: 1919.98px;

// This particular case is a min-width, which can be used to apply special rules
// for really wide scenarios.
$breakpoint-xl: 1920px;

// If updating page-header-height please update it in c-context-menu.js as well
$page-header-height: 3.5rem;
$app-frame-content-padding: 1.5rem;
$app-frame-app-banner-height: math.div(52px, $root-font-size) * 1rem;
$combined-header-height: $cohesity-app-bar-height + $page-header-height;
$top-sticky-position: $combined-header-height + $app-frame-content-padding;
$content-area-height: calc(100vh - #{$top-sticky-position} - (1rem * 2) - 1.625rem);
$disabled-opacity: 0.45;

$mat-card-padding: $cohesity-card-padding;
$mat-card-border-radius: 0.25rem !default;
$mat-card-header-size: 2rem !default;

$logo-width: 8.638rem;
$logo-s-width: 1.5rem;
$logo-height: 1.4rem;
$logo-s-height: 1.5rem;
