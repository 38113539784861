// cUpload component styling

.c-upload-box {
  border: 1px dashed black;
  .drop-box {
    text-align: center;
  }
  .choose-file {
    color: $colorPrimary;
    font-weight: bold;
  }
  c-pulse[size="xs"] .c-pulse {
    padding-top: 0.5rem;
  }
  .progress-bar-wrapper {
    flex-basis: 90%;
    justify-content: space-around;
    flex-wrap: wrap;
    *:nth-child(odd) {
      flex-basis: 90%;
    }
  }
}
