// NOTE: If working with colors, please leverage the related _*.theme.scss file.

$app-panel-width: 25rem;

@keyframes slideIn {
  100% { right: 0; }
}

.cdk-overlay-pane {
  &.cog-app-panel {
    position: absolute !important;
    top: $cohesity-app-bar-height;
    bottom: 0;
    right: -$app-panel-width;
    width: $app-panel-width;
    padding: 0rem;
    animation: slideIn 0.3s forwards;

    &.cog-app-panel-sm {
      right: -15rem;
      width: 15rem;
    }

    &.cog-app-panel-md {
      right: -30rem;
      width: 30rem;
    }

    &.cog-app-panel-lg {
      right: -46rem;
      width: 46rem;
    }

    .mat-dialog-container {
      border-radius: 0;
    }
  }
}

.mat-dialog-container {
  .mat-dialog-content {
    // adding a single pixel of padding to allow room for mat-card implementions
    // to be shifted 1px downward without creating overflow. In dialogs without
    // mat-cards this 1px should go unnoticed.
    padding-bottom: 1px;

    .mat-card {
      border-radius: 0;
      border-right: 0;
      border-left: 0;

      // Use of position and margin here combined with the mat-dialog-content
      // padding of 1px above allows card to visually have a single pixel of
      // border between them by overlapping consecutive card's bottom and
      // top borders.
      position: relative;
      top: 1px;
      margin: -1px -1.5rem 0;
    }
  }
  .mat-card {
    > .mat-card-actions:last-child {
      margin-bottom: 0;
    }
  }
}

// Double class name selector to increase specificity, as the dialog component styles
// get added to the page inline and therefore otherwise win specificity by last occurence.
.mat-dialog-actions.mat-dialog-actions {
  .mat-button-base + .mat-button-base,
  .mat-mdc-button-base + .mat-mdc-button-base {
    margin-left: 1rem;
  }
}

@include reflow() {
  @include reflow-sm() {
    .mat-dialog-container {
      height: unset;
      max-height: 90vh;
    }

    .mat-dialog-content {
      max-height: unset;
      padding: 0 0.5rem;
    }
  }
  @include reflow-xs() {
    .mat-dialog-content {
      overflow: visible;
    }
  }
}
