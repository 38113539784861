// cFormShutters Component Stylings

$shutterPanelVerticalPad: 0.5rem;
$shutterPanelHorizontalPad: 0.5rem;

c-form-shutters,
c-form-shutters-panel {
  display: block;
}

.c-form-shutters-header {
  padding: 5px 0;
  h4 {
    display: inline-block;
    margin: 0;
  }
  a {
   float: right;
  }
}

c-form-shutters-panel {
  position: relative;

  // Transition rules for switching between view & edit
  transition: background-color 0.4s ease;

  // Hover & transition rules for hovering over view mode.
  &:not(.open) {
    cursor: pointer;
    label {
      cursor: pointer;
    }
    transition: background-color $defaultDuration ease-out;
    &:hover {
      transition: background-color 0s linear;
    }
  }

  .form-group {
    padding-top: $gutter-xs;

    .control-label {
      padding: $shutterPanelVerticalPad;
      padding-right: $bootstrap-col-padding;
      padding-left: $shutterPanelHorizontalPad + $bootstrap-col-padding;
    }
  }

  view,
  edit {
    display: block;

    // padding-right value carves out extra space for "edit"/"close" links
    padding:
      $shutterPanelVerticalPad 4.6875rem $shutterPanelVerticalPad $shutterPanelHorizontalPad;
    &[class*="col-"] {
      padding:
        $shutterPanelVerticalPad 4.6875rem $shutterPanelVerticalPad $bootstrap-col-padding;
    }
  }

  view {
    ul.c-ul-inline:last-of-type {
      margin-bottom: 0;
    }
  }

  edit {
    display: none;

    fieldset:last-of-type {
      margin-bottom: 0;
    }
  }

  .disabled {
    cursor: not-allowed;
  }

}

c-form-shutters-panel.open {
  view {
    display: none;
  }
  edit {
    display: block;
  }
}

@include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
  c-form-shutters,
  c-form-shutters-panel {
    background: map_get($background, card);
  }

  .c-form-shutters-header {
    border-bottom: 1px solid map_get($foreground, divider);
  }

  c-form-shutters-panel {
    background-color: map_get($background, card);
    border-bottom: 1px solid map_get($foreground, divider);

    // Hover rule for hovering over view mode.
    &:not(.open):hover {
      background-color: map_get($background, hover);
    }
  }

  c-form-shutters-panel.open {
    background-color: map_get($background, app-bar);
  }
}

.c-form-shutters-panel-toggle {
  position: absolute;
  top: $shutterPanelVerticalPad;
  right: $shutterPanelHorizontalPad;
}
