// email schedules Styles.

helios-reports-email-schedules {
  .reports-dropdown {
    width: 30rem;
  }

  .schedule-info {
    padding: 1rem;
    border-top: 1px solid $colorBorder;

    &:last-child {
      border-bottom: 1px solid $colorBorder;
    }

    .list-label {
      @include font($weight: bold);

      line-height: 1;
      padding-right: 0.4em;
      margin-right: 0.25rem;
      position: relative;

      &::after {
        content: ':';
        position: absolute;
        top: 0;
      }
    }

    span.list-label {
      display: inline-block;
    }
  }

  .c-ul-inline li {
    white-space: normal;
  }
}
