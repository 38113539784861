// styling for content editable element.
@use "@angular/material/core/theming/theming" as mat;

// editable div styling
[c-content-editable] {
  // inherit input styling
  @extend .c-input;

  outline: 0;
  overflow: hidden;
  position: relative;
  margin-bottom: -0.0625rem;

  // placeholder styling
  &:empty:not(:focus)::before {
    @include font($color: $formPlaceholder);

    content: attr(placeholder);
  }

  // allow scrolling only on focus
  &:focus {
    overflow-y: scroll;
  }

  // hide the scrollbar by placing this content over it, because in Firefox
  // we can't override scrollbar styling.
  // https://stackoverflow.com/questions/15394065/firefox-scroll-bar-hidden?lq=1
  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1.25rem;
  }
}

.c-form-sleek {
  [c-content-editable] {
    border: 0;
    box-shadow: 0 0.0625rem 0 0 $colorBorder;
    border-radius: 0;
    padding: 0.5rem 0;

    &:focus {
      box-shadow: 0 0.0625rem 0 0 $colorPrimary;
    }
  }

  &.elastic,
  .elastic {
    [c-content-editable] {
      // hide bottom border & show only on hover.
      box-shadow: 0 0.0625rem 0 0 transparent;

      &:hover {
        box-shadow: 0 0.0625rem 0 0 $colorBorder;
      }

      &:focus {
        box-shadow: 0 0.0625rem 0 0 $colorPrimary;
      }
    }
  }
}

.c-form-sleek .content-editable-header {
  input[type="text"],
  input[type="number"],
  input[type="password"],
  input[type="url"],
  input[type="email"] {
    &[pu-elastic-input] {
      @include cog-type-level(headline);
    }
  }
}

//
// Content Editable Header implemementation, as seen in Job and Policy modify
//
.content-editable-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 1.875rem;

  h1 {
    margin-bottom: 0;
    max-width: 50%;
    position: relative;

    fieldset {
      padding: 0;
      margin: 0;

      > div {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: stretch;
      }
    }

    label {
      margin-right: 0.5rem;
      flex-shrink: 0;
    }

    // make error messages hanging below the field
    // since we have policy description aligned from bottom which will be
    // misaligned with the other's field error messages as they increases the
    // field container height normally.
    .error-msg {
      position: absolute;
    }
  }
}

// Invoke super specificity to win the day. NOTE: I hate this, but I'm not
// overly concerned with it, as UX is moving away from this pattern and
// someday soon we'll be able to kill this header name/desc implementation
// completely.
.c-form-sleek .content-editable-header label:not(.mat-form-field-label):not(.c-toggle) {
  @include cog-type-level(headline);
}

.content-editable-header-description {
  align-self: flex-end;
  flex-grow: 1;
  position: relative;
  max-width: 50%;
  margin-left: 1.5rem;
  border-bottom: 1px solid transparent;
  color: $textGreyLight;
  font-size: 0.8125rem;

  &:hover {
    cursor: pointer;
  }

  [c-content-editable] {
    line-height: 1.25;
    max-height: 2.0625rem;
    color: $textGreyLight;
    padding: 0 1.5625rem 0 0;
    background-color: transparent;
    z-index: 1;
    position: relative;

    // placeholder
    &:empty:not(:focus)::before {
      color: $textGreyLight;
    }

    &::before {
      color: $textGreyLight;

      // make space for add icon when description is empty.
      margin-left: 1.25rem;
    }

    // show shorter border on hover as want to make space for add icon
    &:hover:empty:not(:focus) {
      box-shadow: 0.875rem 0.5rem 0 -0.4375rem $colorBorder;
    }

    // hide add icon when adding description.
    &:focus + .icn-add {
      display: none;
    }

    &:focus {
      cursor: initial;
    }

    // allow clear description on focus.
    &:focus {
      & ~ .content-editable-header-description-clear {
        display: initial;
      }
    }
  }

  // allow clear description on hover over parent container.
  &:hover .content-editable-header-description-clear {
    display: initial;
  }

  .icn-add {
    position: absolute;
    top: 0;
    left: 0;
  }

  .content-editable-header-description-clear {
    display: none;
    position: absolute;
    z-index: 1;
    padding: 0 0.3125rem;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: $textGreyLight;
    font-size: 1.25rem;

    &:hover {
      text-decoration: none;
    }
  }
}

@include helix-themes() using ($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $foreground: map-get($theme, foreground);
  $underline-color: mat.get-color-from-palette($foreground, divider, if($is-dark-theme, 0.7, 0.42));
  $primary-color: helix-color($theme, primary, 500);

  [c-content-editable] {
    &::after {
      background: helix-color($theme, background, background);
    }
  }

  .c-form-sleek {
    [c-content-editable] {
      box-shadow: 0 0.0625rem 0 0 $underline-color;

      &:focus {
        box-shadow: 0 0.0625rem 0 0 $primary-color;
      }
    }

    &.elastic,
    .elastic {
      [c-content-editable] {
        // hide bottom border & show only on hover.
        box-shadow: 0 0.0625rem 0 0 transparent;

        &:hover {
          box-shadow: 0 0.0625rem 0 0 $colorBorder;
        }

        &:focus {
          box-shadow: 0 0.0625rem 0 0 $primary-color;
        }
      }
    }
  }
}
