// This file should NOT output CSS. It should only include typography related variables and mixins.
@use "sass:math";

@use '@angular/material/core/typography/typography' as mat-typography;
@use '@angular/material/core/typography/typography-utils' as mat-typography-utils;

$defaultFontStack: 'var(--hlx-typography-font-family, var(--default-typography-font-family, "inter", Helvetica, Arial, sans-serif))';

// This family is used in Iris for a custom 'code' class rather than the native
// <code> element.
$monoFontStack: '"Lucida Console", Courier, monospace';

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: var(--typography-font-weight-bold);

@function pxToRem($pixels) {
  @return calc(#{$pixels} / #{$absolute-root-font-size} * 1rem);
}

@function remToPx($remValue) {
  $remSize: math.div($remValue, 0.625rem);
  @return $remSize * $root-font-size;
}

// $input Line-height must be unit-less fraction of the font-size, per
// angular material scss file documentation:
// https://github.com/angular/material2/blob/master/src/lib/core/typography/_typography.scss
//
// Angular Material maps some var names to HTML elements. Its mapped approximmately like:
// h1 = $headline
// h2 = $title
// h3 = $subheading-2
// h4 = $subheading-1
//
// These are four font config properties we are using for custom configuration. We remap these names in cog-type-level()
// below so that they are more meaningful for our use cases.
// $display-1, $display-2, $display-3, $display-4
// See translated cohesity/common names below in $cog-type-to-mat-type.
$mat-type-config: mat-typography.define-legacy-typography-config(
  $font-family: unquote($defaultFontStack),

  $headline: (
    font-size: pxToRem(var(--typography-absolute-font-size-headline)),
    line-height: pxToRem(var(--typography-absolute-line-height-headline)),
    font-weight: $font-weight-normal,
  ),
  $title: (
    font-size: pxToRem(var(--typography-absolute-font-size-title)),
    line-height: pxToRem(var(--typography-absolute-line-height-title)),
    font-weight: $font-weight-normal,
  ),
  $subheading-2: (
    font-size: pxToRem(var(--typography-absolute-font-size-subheading-2)),
    line-height: pxToRem(var(--typography-absolute-line-height-subheading-2)),
    font-weight: var(--typography-absolute-font-weight-subheading-2),
  ),
  $subheading-1: (
    font-size: pxToRem(var(--typography-absolute-font-size-subheading-1)),
    line-height: pxToRem(var(--typography-absolute-line-height-subheading-1)),
    font-weight: var(--typography-absolute-font-weight-subheading-1),
  ),
  $body-2: (
    font-size: pxToRem(var(--typography-absolute-font-size-body-2)),
    line-height: pxToRem(var(--typography-absolute-line-height-body-2)),
    font-weight: var(--typography-absolute-font-weight-body-2),
  ),
  $body-1: (
    font-size: pxToRem(var(--typography-absolute-font-size-body-1)),
    line-height: pxToRem(var(--typography-absolute-line-height-body-1)),
    font-weight: $font-weight-normal,
  ),
  $caption: (
    font-size: pxToRem(var(--typography-absolute-font-size-caption)),
    line-height: pxToRem(var(--typography-absolute-line-height-caption)),
    font-weight: $font-weight-normal,
    letter-spacing: 0.4px,
  ),
  $button: (
    font-size: pxToRem(var(--typography-absolute-font-size-button)),
    line-height: pxToRem(var(--typography-absolute-line-height-button)),
    font-weight: $font-weight-normal,
  ),
  $input: (
    font-size: pxToRem(var(--typography-absolute-font-size-input)),

    // NOTE: It's unclear why, but setting this line-height to equivilant of 18px
    // as desired by UX results in descenders being cutoff. So, sizing it up a bit.
    line-height: 1.1875,
    font-weight: $font-weight-normal,
  ),
  $display-1: (
    font-size: pxToRem(var(--typography-absolute-font-size-display-1)),
    line-height: pxToRem(var(--typography-absolute-line-height-display-1)),
    font-weight: $font-weight-bold,
    letter-spacing: 0.015625rem,
  ),
  $display-2: (
    font-size: pxToRem(var(--typography-absolute-font-size-display-2)),
    line-height: pxToRem(var(--typography-absolute-line-height-display-2)),
    font-weight: $font-weight-normal,
  ),
  $display-3: (
    font-size: pxToRem(var(--typography-absolute-font-size-display-3)),
    line-height: pxToRem(var(--typography-absolute-line-height-display-3)),
    font-weight: $font-weight-normal,
  ),
  $display-4: (
    font-size: pxToRem(var(--typography-absolute-font-size-display-4)),
    line-height: pxToRem(var(--typography-absolute-line-height-display-4)),
    font-weight: $font-weight-normal,
  )
);


$cog-type-config: (
  h1: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-h1)),
    $line-height: pxToRem(var(--typography-absolute-line-height-h1)),
    $font-weight: $font-weight-normal,
  ),
  h2: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-h2)),
    $line-height: pxToRem(var(--typography-absolute-line-height-h2)),
    $font-weight: $font-weight-normal,
  ),
  h3: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-h3)),
    $line-height: pxToRem(var(--typography-absolute-line-height-h3)),
    $font-weight: var(--typography-absolute-font-weight-h3),
  ),
  h4: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-h4)),
    $line-height: pxToRem(var(--typography-absolute-line-height-h4)),
    $font-weight: $font-weight-bold,
  ),
  h5: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-h5)),
    $line-height: pxToRem(var(--typography-absolute-line-height-h5)),
    $font-weight: $font-weight-bold,
  ),
  h6: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-h6)),
    $line-height: pxToRem(var(--typography-absolute-line-height-h6)),
    $font-weight: var(--typography-absolute-font-weight-h6),
  ),
  modal-title: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-modal-title)),
    $line-height: pxToRem(var(--typography-absolute-line-height-modal-title)),
    $font-weight: $font-weight-normal,
  ),
  banner: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-banner)),
    $line-height: pxToRem(var(--typography-absolute-line-height-banner)),
    $font-weight: $font-weight-light,
  ),
  banner-medium: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-banner)),
    $line-height: pxToRem(var(--typography-absolute-line-height-banner)),
    $font-weight: $font-weight-normal,
  ),
  body: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-body)),
    $line-height: pxToRem(var(--typography-absolute-line-height-body)),
    $font-weight: $font-weight-normal,
  ),
  body-lg: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-body-lg)),
    $line-height: pxToRem(var(--typography-absolute-line-height-body-lg)),
    $font-weight: $font-weight-normal,
  ),
  chart: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-chart)),
    $line-height: pxToRem(var(--typography-absolute-line-height-chart)),
    $font-weight: $font-weight-bold,
  ),
  caption: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-caption)),
    $line-height: pxToRem(var(--typography-absolute-line-height-caption)),
    $font-weight: $font-weight-normal,
  ),
  chart-display: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-chart-display)),
    $line-height: pxToRem(var(--typography-absolute-line-height-chart-display)),
    $font-weight: $font-weight-bold,
  ),
  gb-value: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-value)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-value)),
    $font-weight: $font-weight-normal,
  ),
  gb-unit: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-unit)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-unit)),
    $font-weight: $font-weight-normal,
  ),
  gb-value-sm: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-value-sm)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-value-sm)),
    $font-weight: $font-weight-normal,
  ),
  gb-unit-sm: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-unit-sm)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-unit-sm)),
    $font-weight: $font-weight-normal,
  ),
  gb-value-lg: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-value-lg)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-value-lg)),
    $font-weight: $font-weight-normal,
  ),
  gb-unit-lg: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-unit-lg)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-unit-lg)),
    $font-weight: $font-weight-normal,
  ),
  gb-value-xl: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-value-xl)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-value-xl)),
    $font-weight: $font-weight-normal,
  ),
  gb-unit-xl: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-unit-xl)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-unit-xl)),
    $font-weight: $font-weight-normal,
  ),
  gb-label: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-label)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-label)),
    $font-weight: $font-weight-normal,
  ),
  gb-title: mat-typography.define-typography-level(
    $font-size: pxToRem(var(--typography-absolute-font-size-gb-title)),
    $line-height: pxToRem(var(--typography-absolute-line-height-gb-title)),
    $font-weight: $font-weight-bold,
  ),
);

// Provides an easy passthrough for outputing either a Helix specific type config
// or mat typography level styles.
@mixin cog-type-level($level: 'body-1') {

  // Check Helix type config first...
  @if (map-has-key($cog-type-config, $level)) {
    @include mat-typography-utils.typography-level($cog-type-config, $level);
    $level-config: map-get($cog-type-config, $level);
    letter-spacing: map-get($level-config, letter-spacing);
  } @else {
    // ...and if its not a Helix config, use mat type config.
    @include mat-typography-utils.typography-level($mat-type-config, $level);
  }
}

/*
DPRECATED: do not use this mixin. Its only here to support legacy implementations.
@include font(...
  $face
  $size
  $leading
  $color
  $weight
  $style

@include font($size: 16px, $leading: 1.125, $style: italic);

// OUTPUT:

font-size: 16px;
line-height: 1.125;
font-style: italic;
*/
@mixin font(
  $face: false,
  $size: false,
  $leading: false,
  $color: false,
  $weight: false,
  $style: false) {

  @if $face {
    font-family: unquote($defaultFontStack);
  }

  @if $weight {
    @if (stringIncludes($weight, "light")) {
      font-weight: $font-weight-light;
    } @else if (stringIncludes($weight, "bold")) {
      font-weight: $font-weight-bold;
    } @else {
      font-weight: $font-weight-normal;
    }
  }

  @if $size {
    font-size: $size;
  }
  @if $leading {
    line-height: $leading;
  }
  @if $color {
    color: $color;
  }
  @if $style {
    font-style: $style;
  }
}

@mixin font-icon-base() {
  content: '';
  display: inline-block;
  position: relative;
  z-index: 3;
  width: 1em;
  height: 1em;
  vertical-align: middle;
  text-align: center;
  font-size: 1em;
  color: currentColor;
}

@mixin font-icon-encircle() {
  @include font-icon-base();
  content: '\e080';
  float: left;
}

@mixin disabledProperties() {
  opacity: $disabled-opacity;
  pointer-events: none;
}

// Helper Functions
@function stringIncludes($cString, $cSubString) {
  @if (str-index($cString, $cSubString)) {
    @return true;
  }
  @return false;
}
