@import "common-imports";
// Styles: CloudRetrieve styles.
.restore-graphic-wrapper {
  overflow-x: auto;
  padding-bottom: 1rem;
}

#remote-restore-intro-graphic {
  // These two vars lock in the aspect ratio. All other values are percentage to
  // scale.
  $graphicWidth: 55em;
  $graphicHeight: calc(#{$graphicWidth} / 2.4);
  $stepWidth: 23%;
  $stepEdgeOffset: 0;
  $subStepWidth: 14%;
  $subStepOffset: 23.5%;
  $counterName: counter-reset;

  display: block;
  background: transparent url(/assets/i/cloud-retrieval-overview.svg) no-repeat 50% 0;
  background-size: contain;
  margin: auto;
  width: $graphicWidth;
  height: $graphicHeight;
  position: relative;
  user-select: none;
  cursor: default;

  ol {
    list-style-type: none;
    counter-reset: $counterName;

    li {
      position: absolute;
      top: 65%;
      left: calc(50% - #{$stepWidth * 0.5});
      width: $stepWidth;
      z-index: 1;

      .step-heading::before {
        content: counter(#{$counterName})". ";
        counter-increment: $counterName;
      }

      &:first-child {
        left: $stepEdgeOffset;
      }
      &:last-child {
        left: auto;
        right: $stepEdgeOffset;
      }
    }
  }
  ul {
    li {
      position: absolute;
      z-index: 1;
      width: $subStepWidth;
      top: 34%;
      left: $subStepOffset;

      &:last-child {
        left: auto;
        right: $subStepOffset;
      }
    }
  }
}

.cloud-search-details {
  .ui-select-container.ui-select-container {
    width: 18em;
  }
}
.c-restore-table {
  td.status {
    text-align: center;
    width: 4em;
    padding-right: $bootstrap-col-padding;
  }
}
