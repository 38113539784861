// Styles: Policy Details Directive

policy-details {
  display: block;
}

.policy-detail > div {
  min-height: 80px;
  margin-top: 10px;
}

.schedule-tile {
  h4 {
    padding-bottom: 5px;
    border-bottom: 1px solid $textGreyLightest;
  }
}

.snapshot-target-policy-tile {
  position: relative;
  min-height: 150px;
  margin-bottom: 20px;

  small {
    display: block;
    position: absolute;
    bottom: 15px;
  }
}
