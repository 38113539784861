//
// Opt-in c-fade animations
//

@mixin animateTransitionRules() {
  transition-property: opacity, max-height;
  transition-duration: $angularAnimationDuration;
}

@mixin animateOutRules() {
  opacity: 0;
  max-height: 0;
}

@mixin animateInRules() {
  opacity: 1;
  max-height: 600px;

  &.c-fade-sm {
    max-height: 200px;
  }
  &.c-fade-lg {
    max-height: 100vh;
  }
}


[class*="c-fade"] {

  // prevent absolutely positioned elements from spilling
  // out of the containing element during animation
  &.ng-enter,
  &.ng-leave,
  &.ng-hide-add,
  &.ng-hide-remove {
    overflow: hidden;
  }

  // ngIf transition rules
  &.ng-enter {
    @include animateTransitionRules();
    @include animateOutRules();
    &.ng-enter-active {
      @include animateInRules();
    }
  }
  &.ng-leave {
    @include animateTransitionRules();
    @include animateInRules();
    &.ng-leave-active {
      @include animateOutRules();
    }
  }

  // ngShow/ngHide transition rules
  &.ng-hide-add {
    @include animateTransitionRules();
    @include animateInRules();
    &.ng-hide-add-active {
      @include animateOutRules();
    }
  }
  &.ng-hide-remove {
    @include animateTransitionRules();
    @include animateOutRules();
    &.ng-hide-remove-active {
      @include animateInRules();
    }
  }
}

[class*="c-fade"] {
  // Opt-in animation staggering for ng-repeat animations. Can be applied to
  // any .c-fade type.
  // Example: <li class="c-fade animate-staggered"
  //              ng-repeat="thing in things">{{thing}} {{$index}}</li>
  &.animate-staggered {
    // Animate in at the predefined rate
    &.ng-enter-stagger {
      transition-delay: $angularAnimationStaggerDelay;
      animation-delay: $angularAnimationStaggerDelay;
    }
    &.ng-leave {
      transition-delay: 0;
      animation-delay: 0;
    }
  }
}

//
// Error message animations
//
@mixin errorMsgAnimateTransitionRules() {
  transition-property: opacity, line-height, padding-top, padding-bottom;
  transition-duration: $angularAnimationDuration;
}

@mixin errorMsgAnimateOutRules() {
  opacity: 0;
  line-height: 0;
  padding-top: 0;
  padding-bottom: 0;
}

@mixin errorMsgAnimateInRules() {
  opacity: 1;
  line-height: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.error-msg {
  // prevent content from spilling out during animation
  &.ng-enter,
  &.ng-leave {
    overflow: hidden;
  }

  // ngIf transition rules
  &.ng-enter {
    @include errorMsgAnimateTransitionRules();
    @include errorMsgAnimateOutRules();
    &.ng-enter-active {
      @include errorMsgAnimateInRules();
    }
  }
  &.ng-leave {
    @include errorMsgAnimateTransitionRules();
    @include errorMsgAnimateInRules();
    &.ng-leave-active {
      @include errorMsgAnimateOutRules();
    }
  }

}

// NOTE: apply explict height and and widths (100% is fine for width) for the
// following selectors in the given context for an improved flip animation.
// The animation will work with auto widths and heights, but won't spill out
// of its container while flipping.
// .flip-container,
// .flip-container .front,
// .flip-container .back { width: 100%; height: 390px; }

.flip-container {
  perspective: 1400px;

  &.flipped .flipper {
    transform: rotateY(180deg);
  }

  .flip-trigger {
    position: absolute;
    top: 18px;
    right: 15px;
  }
}

.flipper {
  transition: $slowDuration ease-out;
  transform-style: preserve-3d;
  position: relative;

  .front, .back {
    // Hide the back of the card when flipped
    backface-visibility: hidden;

    // marking this !important so it doesn't get overpowered by c-tile rules
    position: absolute !important;
    top: 0;
    left: 0;
  }

  .front {
    z-index: 2;
    transform: rotateY(0deg);
  }

  .back {
    // back starts in rotated position
    transform: rotateY(180deg);
  }
}

.swivelInRules {
  @keyframes swivelIn {
    from { transform: rotateY(-360deg); }
    to { transform: rotateY(0deg); }
  }
  animation: swivelIn 0.5s ease-in-out;
}

.swivelOutRules {
  @keyframes swivelOut {
    from { transform: rotateY(360deg); }
    to { transform: rotateY(0deg); }
  }
  animation: swivelOut 0.5s ease-in-out;
}
