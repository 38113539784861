.mat-paginator,
.mat-paginator-page-size .mat-select-trigger {
  @include cog-type-level(body-1);
}

@include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
  cog-table .mat-paginator {
    &,
    .mat-select-arrow {
      color: map_get($foreground, text);
    }
  }
}
