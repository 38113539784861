// Style: c-accordion styling

uib-accordion {
  .panel[uib-accordion-group] {
    box-shadow: none;
    margin-top: 0 !important;
    border-radius: 0;
    transition: transform $defaultDuration ease;
    background: transparent;

    .panel-heading {
      padding: 1em;

      h4.panel-title {
        @include font($size: 1em, $weight: normal);
      }

      // kill the toggle outline
      .c-accordion-toggle {
        outline: 0;
      }
    }

    // shift body to left to adjust toggle
    .panel-body {
      padding-left: 2.5rem;
    }
  }
}

@include helix-themes() using ($theme) {
  $border-color: helix-color($theme, foreground, divider);

  uib-accordion {
    .panel[uib-accordion-group] {
      border-top: 1px solid $border-color;

      &:last-child {
        border-bottom: 1px solid $border-color;
      }

      // expanded accordion styling
      &.panel-open {
        background-color: helix-color($theme, background, background);
      }
    }
  }

}
