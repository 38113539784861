@mixin cog-mat-dialog-theme($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);
  $background: map-get($theme, background);

  @if $is-jazz-theme {
    .mat-dialog-container {
      border-radius: 1rem;
    }
  }

  .cdk-overlay-backdrop.cdk-overlay-dark-backdrop {
    background: map_get($background, backdrop);
  }
}
