// Styles: cUserAtDomainPicker

.c-form {
  .c-user-at-domain-picker {
    width: 100%;

    & > .input-group-item {
      display: inline-block;
      width: 50%;
    }

    .domain-picker {
      // Round the left corners of the left-most ui-select.
      .ui-select-toggle, input {
        border-radius: 5px 0 0 5px;
      }
    }

    .user-picker {
      // Round the right corners of the right-most ui-select.
      .ui-select-toggle, input {
        border-radius: 0 5px 5px 0;
      }
    }

    .unix-picker {
      vertical-align: top;
      input {
        width: 100%;
        padding-left: 0.6875rem;
        border-radius: 0 5px 5px 0;
      }
    }
  }

  // Since we use a single fieldset to contain both inputs, we have to manually
  // align all inline error messages under the right-most input -- either the
  // Principals ui-select or Unix UID text input -- because it is the only one
  // with inline messaging.
  c-user-at-domain-picker + div[ng-messages] {
    margin-left: 50%;
  }
}

.c-form-sleek {
  .c-user-at-domain-picker  {
    width: 100%;
    display: flex;
    align-items: flex-start;

    & > .input-group-item {
      display: inline-block;
      width: 50%;
    }

    .domain-picker {
      padding-right: 20px;
    }

    .unix-picker {
      vertical-align: top;
      input {
        // This overrides our standard width for input[type=number]. In this
        // particular case, we want the width to match that of its context for
        // uniform appearance.
        width: 100%;
      }
    }
  }

  // Since we use a single fieldset to contain both inputs, we have to manually
  // align all inline error messages under the right-most input -- either the
  // Principals ui-select or Unix UID text input -- because it is the only one
  // with inline messaging.
  c-user-at-domain-picker + div[ng-messages] {
    margin-left: 50%;
  }
}
