// Sources Styles
.c-controlboard-sources {
  cb-control {
    width: 20%;
  }

  .c-controlboard-controls {
    margin-bottom: 0;
  }

  .c-controlboard-controls input[type="text"].c-input-filter {
    width: 100%;
  }
}

.sources-protected-table {
  .c-input {
    width: 20%;
  }

  .sources-protected-filter {
    border-bottom: 1px solid $colorBorder;
  }

  .source-job-name,
  .source-policy-name {
    // margin-left: 0.3125rem;

    > span {
      vertical-align: middle;
    }

    &:first-of-type {
      margin-left: 0;
    }
    &::after {
      content: ',';
    }
    &:last-of-type::after {
      content: '';
    }
  }
}

.source-details-media > .source-details-media-body {
  flex: 1 1 60%;

  div:first-child {
    display: inline-flex;
    align-items: center;
  }

  .icn-view {
    cursor: pointer;
  }
}

.source-details-media .c-ul-inline li {
  span:first-child {
    margin-right: 0.3125rem;
  }

  max-width: 15.625rem;
  min-width: 5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}

.source-details-popover-dl {
  dt, dd {
    padding: 0.1875rem 1.25rem 0.1875rem 0;
  }

  dt {
    width: 40%;
  }
  dd {
    width: 60%;
  }
}

.source-details-status-icon-wrapper {
  flex: 1 1 40%;
  align-self: flex-end;
}

.sources-table-show-more {
  margin-bottom: 2.5rem;
}

/* Old sources styles remove when old sources is fully deprecated */
.source-details {
  .icn-source {
    position: relative;
    top: 4px;
    width: 35px;
    height: 35px;
    background-size: 35px 35px;
    margin-right: 10px;
  }
}

// Hide the group-by labels in the list of Hypervisor types.
#entityType .ui-select-choices-group-label {
  display: none;
}

.c-source-table {
  tbody.c-source-table-body {
    tr td {
      border-bottom: none;

      .c-media-body .icn-xs {
        position: relative;
        top: 0.1875rem;
      }
    }
    tr:last-child td {
      border-bottom: 1px solid $drawerBorder;
    }
  }
  tbody.c-source-table-body:last-child {
    tr:last-child td {
      border-bottom: none;
    }
  }
}

// Effects all stacked c-message-inline.node-extra-details except the first
// under the same DOM node.
[class*="c-message-inline"].node-extra-details:not(:first-of-type) {
  margin-top: 0.5em;
}

.protect-now-btns > .protect-btn {
  display: inline-block;
}

.upload-file-link {
  position: absolute;
  top: -1.5rem;
  right: 0.625rem;
}
