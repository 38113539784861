// Source Tree

c-source-tree-pub {
  .c-controlboard-controls {
    .c-ui-select.status-filter {
      min-width: 270px;
    }
  }
  .c-source-list-label:hover {
    cursor: default;
  }
  .c-source-list-ul:first-child {
    border-top: none;
  }
  treecontrol.tree-classic li .tree-selected,
  c-treecontrol.tree-classic li .tree-selected {
    @include font($weight: normal);
    background: none;
  }

  .source-tree-meta-list {
    display: inline-block;
    margin: -0.125rem 0 0 0.4375rem;
    color: $textGreyLightest;
    line-height: 0.8125rem;
    vertical-align: middle;

    & > li {
      display: inline-block;
      white-space: nowrap;
      vertical-align: top;
      margin-bottom: 0;
      padding: 0 1em 0 0;
      line-height: 1.2em;
      margin-right: 1em;
      border-right: 1px solid currentColor;

      &:last-child {
        padding-right: 0;
        margin-right: 0;
        border-right: none;
      }
    }
  }
}

.source-tree-header {
  @include font($size: 1rem, $leading: 2.5rem);
  height: 40px;

  label {
    padding-left: 0;
  }

  &.refresh-icon {
    a {
      position: relative;
      top: 2px;
    }
  }
}

.source-tree-expand-collapse-all {
  transform: rotate(-90deg);
  transition: transform $defaultDuration ease;
  display: inline-block;
  width: 20px;
  height: 20px;
  position: relative;
  @include iconImage('icn-arrow-row-toggle.svg');
  background-size: 12px 9px;
  background-repeat: no-repeat;
  background-position: 4px;
  vertical-align: middle;
  cursor: pointer;
  &.expanded {
    transform: rotate(0deg);
  }
}

.source-tree-container {
  position: relative;
  background: transparent;
}

.source-tree-filter-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: $contentBackground;
}

.source-tree-filter-spinner {
  position: absolute;
  top: 25px;
  width: 100%;
}

div.no-results {
  @include font($size: 1.125rem, $leading: 1.1875rem);
  text-align: center;
  padding: 1.5rem 2rem;

  button {
    display: block;
    margin: 2rem auto 0 auto;
  }
}

.c-source-list-ul {
  > li {
    margin-bottom: 0;
  }
  .c-message-inline {
    margin-bottom: 0;
  }
}

.node-extra-details-toggle {
  transform: rotate(-90deg);
  transition: transform $defaultDuration ease;
  display: block;
  width: 12px;
  height: 9px;
  position: absolute;
  left: 5px;
  top: 15px;
  @include iconImage('icn-arrow-row-toggle.svg');
  background-repeat: no-repeat;
  cursor: pointer;
  &.expanded {
    transform: rotate(0deg);
    transition: transform $defaultDuration ease;
  }
}

.node-extra-details {
  margin:  0 60px 0 39px;
  .group-container.flagged {
    margin-top: 0;
    padding: 13px 10px 10px 10px;
  }
  .volume-details:first-child {
    margin-top: 0;
  }
  // override margin-bottom as defined for c-message-inline
  &[class*="c-message-inline"] {
    margin-bottom: 0;
  }
}

.node-extra-details-interior {
  padding-bottom: 13px;
}

// standard checkbox/radio
label.c-source-tree-selector {
  height: 36px;
  width: 36px;
  position: relative;
  right: 0;
  margin: 0 -10px 0 0;
  padding: 12px;
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
  input {
    position: absolute;
    left: 12px;
    top: 12px;
    margin: 0;
    cursor: pointer;
    // radio option is used in cBrowseForTarget
    &[type="radio"] {
      top: 11px;
    }
    &:disabled {
      cursor: not-allowed;
    }
  }
  &.disabled {
    cursor: not-allowed;
    input {
      cursor: not-allowed;
    }
  }
}



// Autoprotect iconography
.autoprotect-btn {
  display: inline-block;
  position: relative;
  vertical-align: middle;
  margin-right: -10px;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  @include iconImage('autoprotect-off.svg');

  &.enabled {
    @include iconImage('autoprotect-on.svg');
  }

  &.disabled {
    @include disabledProperties();
  }
}

.autoprotect-checkbox {
  @include iconImage('autoprotect-checkbox.svg');
  display: inline-block;
  width: 36px;
  height: 36px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: 10px;

  &.excluded {
    @include iconImage('autoprotect-checkbox-excluded.svg');
  }
  &.excluded-ancestor {
    @include iconImage('autoprotect-checkbox-excluded-ancestor.svg');
    cursor: not-allowed;
  }
  c-source-tree-pub & {
    margin-right: -10px;
  }
}

.c-upgrade-in-progress {
  padding: 0 15px;

  .c-spinner {
    display: inline-block;
    margin-right: 10px;
  }
}


.c-source-tree-icn {
  display: inline-block;
  position: relative;
  top: 7px;
  margin: 0 5px;

  &.icn-type-sql {
    background-size: 85%;
  }
}

.node-details {
  .description {
    position: relative;
    display: block;
    top: -5px;
    margin: 0 0 5px 38px;
  }
  .c-context-menu {
    margin-top: 10px;
  }
}

c-source-group {
  .source-group {
    margin-bottom: 3rem;
    min-height: 2.8125rem;
    padding-bottom: 1px;
  }

  .group-header {
    position: absolute;

    &.ellipsis {
      width: 275px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    margin-top: 0.5rem;
  }

  c-source-tree-pub c-controlboard:first-child {
    float: right;
  }
}

@include helix-themes() using ($theme) {
  $divider-color: helix-color($theme, foreground, divider);
  $text-color: helix-color($theme, foreground, text);
  $subdued-text-color: rgba($text-color, 0.5);
  $is-dark-theme: map-get($theme, is-dark);

  c-source-group {
    .source-group {
      border-bottom: 1px solid $divider-color;
    }
  }

  .source-tree-filter-overlay {
    background: helix-color($theme, background, background);
  }

  div.no-results {
    color: $text-color;
    border-top: $divider-color;
    border-bottom: $divider-color;
  }

  .c-source-list-ul {
    border-top: 1px solid $divider-color;
    > li {
      border-top: 1px solid $divider-color;
    }
  }

  treecontrol,
  c-treecontrol {
    color: rgba(helix-color($theme, foreground, secondary-text), if($is-dark-theme, 1, 0.75));

    & > .c-source-list-ul:first-child > li:first-child {
      border-top: $divider-color;
    }
  }

  .auto-protect-indicators {
    color: $subdued-text-color;
    .auto-protected-node {
      color: helix-color($theme, primary, 500);
    }
  }

  .c-upgrade-in-progress {
    color: helix-color($theme, warn, 200);
  }

  .node-details {
    .description {
      color: $subdued-text-color;
    }
  }
}

c-controlboard {
  cb-control.c-input-filter-flyout {
    margin-top: 0.3rem;
  }

  cb-control.c-form-sleek {
    margin-right: 1rem;
  }
}

section.source-selection {
  margin: 1rem 0 1rem 0;
}

.source-group-modal-loader {
  ng-spinner {
    margin-top: 1rem;
  }
}
