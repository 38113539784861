// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-card.scss).

@mixin cog-mat-menu-panel-theme($theme) {
  $foreground: map_get($theme, foreground);
  .mat-menu-panel {
    color: map_get($foreground, text);

    .mat-menu-item {
      color: inherit;
    }
  }
}
