// List tenants custom css.

list-tenants {

  #filter-tenant-by-status {
    max-width: 300px;
  }

  c-color-dot {
    width: 0.5rem;
    height: 0.5rem;
  }
}
