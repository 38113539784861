// Theme styles that only apply to the legacy appearance of the form-field.
@use "@angular/material/core/theming/theming" as mat;

@mixin cog-mat-form-field-legacy-theme($theme) {
  $foreground: map-get($theme, foreground);
  $component: map-get($theme, component);
  $helix-base: map-get($theme, helix-base);
  $is-dark-theme: map-get($theme, is-dark);

  // Angular Material applies an opacity value to the divider color, which makes
  // implementations too faint. We have created a custom color for input underlines.
  $underline-color: mat.get-color-from-palette($foreground, base);

  .mat-form-field-appearance-legacy {
    color: mat.get-color-from-palette($foreground, secondary-text);

    .mat-form-field-label {
      color: mat.get-color-from-palette($helix-base, resting);
    }

    &:not(.mat-focused):not(.mat-form-field-disabled) {
      // Add additional rule to make placeholder more faint than the floated
      // label. They are the same DOM element, but we can differentiate via
      // this class.
      .mat-form-field-label.mat-form-field-empty {
        color: mat.get-color-from-palette($helix-base, resting);
      }
    }

    .mat-hint {
      color: mat.get-color-from-palette($foreground, hint-text);
    }

    .mat-form-field-underline {
      background-color: $underline-color;
    }

    &.mat-form-field-disabled {
      .mat-form-field-label {
        color: mat.get-color-from-palette($foreground, disabled-text);
      }

      .mat-form-field-underline {
        background-image: linear-gradient(to right, $underline-color 0%, $underline-color 33%, transparent 0%);
        background-size: 4px 100%;
        background-repeat: repeat-x;
      }
    }

    input[readonly="true"] {
      color: mat.get-color-from-palette($foreground, disabled-text);
    }
  }
}
