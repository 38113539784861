// seeMore styles

.see-more-wrapper {

  // Optional label open/close toggle
  > a {
    display: inline-block;
    position: relative;
    padding-left: 1em;
    margin-bottom: 0.5em;

    &::before {
      transition: transform $defaultDuration ease;
      transform: rotate(-90deg);
      display: block;
      position: absolute;
      content: '';
      width: 1em;
      height: 0.55em;
      left: 0;
      top: calc(50% - 0.4em);
      @include iconImage('icn-arrow-row-toggle.svg');
      background-repeat: no-repeat;
    }
    &.expanded::before {
      transform: rotate(0deg);
    }
  }
}

.see-more-content {
  // use <section> to create consistent spacing between 'sections' within a
  // <see-more>. Use `section > h5` for consistent headers.
  > section {
    // using padding so `.c-fade` classes can be applied without introducing
    // animation jerk
    padding-bottom: 40px;

    &:last-of-type {
      padding-bottom: 0;
    }
  }

}

.see-more-container {
  // Shim in a transparent element with explicit height for proper spacing while
  // maintaining smooth animations.
  &::before {
    display: block;
    position: relative;
    content: '';
    height: 0.5rem;
  }

}

// Add 'compact' class to the see-more element to override the above shim.
see-more {
  &.compact {
    .see-more-container::before {
      height: 0;
    }
  }
}

.see-more-content {
  display: block;

  // This components background has been designed out. The right padding value here
  // is a magic number which aligns the content with the label of a c-toggle component
  // preceeding the content. The other numbers just give breathing room and precision
  // is not as important.
  padding: 1.66em 0 1.66em 2.9rem;
  border-radius: 2px;

  // No background styling. This is an optional class used to suppress the
  // styling of the containing box.
  &.no-background {
    padding: 1em 0;
    background-color: inherit;
    border: none;
    border-radius: inherit;
  }

  p:first-child {
    margin-top: 0;
  }

  fieldset:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}

see-more.slim .see-more-content {
  padding: 0.625rem 1rem;
}

// jazzification
@include helix-themes() using ($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);

  .see-more-content {

    @if $is-jazz-theme {
      background-color: helix-color($theme, background, card);
    } @else {
      background-color: helix-color($theme, background, app-bar);
    }


    // for nested seeMore or seeMore nested inside a cFormShutter
    &:not(.no-background) .see-more-content,
    c-form-shutters & {
      background-color: helix-color($theme, background, card);
    }
  }
}
