.c-nodes {
  .c-table tbody {
    .address-input {
      margin-bottom: .5rem;
    }
    tr:last-child td {
      border-bottom: 1px solid $colorBorderLight;
    }
  }

  .warning-row {
    float: left;
    clear: both;
  }
}

@include helix-themes() using ($theme) {
  $hover-color: helix-color($theme, state-secondary, hover);

  .c-table tbody.node-setup-info:hover > tr td {
    background-color: $hover-color;
  }
}