.c-antivirus {
  .no-threats {
    background: transparent url(/assets/i/no-threats.svg);
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 15rem;
    align-content: center;
  }

  .connection-status {
    &.show-status::before {
      top: 0.3rem;
    }
    .icn-forbidden {
      vertical-align: text-bottom;
    }
  }

  .icn-bug {
    opacity: 0.5;
  }
}

.icap-server-list {
  .icn-remove {
    margin: $gutter-sm 0 0 $gutter-sm;
    float: right;
  }
  fieldset {
    border: 0;
    margin: 0;
  }
  dd {
    padding-left: $gutter-xl;
  }
}
