// STYLES:  Storage Array Network (ie. Pure) details

.c-san-details {
  .other-filters {
    width: 18em;
  }

  .c-table-input-td {
    vertical-align: middle;

    input[type="checkbox"] {
      top: -2px;
    }
  }

  .meta-view-icn,
  .volume-name {
    vertical-align: middle;
  }
}
