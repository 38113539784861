// Sources Styles

.c-jobs-job-list {
  .jobs-running-chart-subtext {
    margin-bottom: 0;
  }

  .c-pulse {
    min-width: 225px;
    display: inline-block;
    float: left;
  }

  .job-type-filter {
    width: 13rem;
    min-width: auto;

    &.job-type-filter-sm {
      width: 9.5rem;
      min-width: auto;
    }
  }
}

.status-col {
  .status-label {
    float: left;
    line-height: $iconSizeMd;
    margin-left: 8px;
  }
}

// App-Consistent Backup conflicts modal
#job-quiesce-conflicts-modal {
  ul {
  padding: 1em;

    li {
        margin-bottom: 1em;
    }
    &.dense {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 15em;
        border: 1px solid $colorBorder;
    }
  }
  .source-location {
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .source-size {
    border-left: 1px solid currentColor;
    padding-left: 0.5em;
    margin-left: 0.5em;
  }
}

// Server Options

.volume-list {
  label {
    position: relative;
    padding-left: 55px;
    line-height: 1.4em;
  }
  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 2px;
  }
  .icn-drive {
    position: absolute;
    left: 26px;
    top: -3px;
    width: 22px;
    height: 22px;
    background-size: 22px 22px;
    }
}

.volume-mount-point {
  color: $textGreyLightest;
}

.volume-metadata {
  display: inline-block;
  color: $textGreyLightest;
  margin: 0;
}

.c-backup-now-modal {
  .scrollable-tree {
    @include scrollable();
  }

  .see-more-wrapper {
    margin-bottom: 10px;
  }

  .end-column {
    text-align: right;
  }
}

.snapshot-manager-section {
  .snapshot-location-radio-wrap {
    label {
      input[type="radio"] {
        margin-top: 0;
      }
    }
  }

  i {
    margin-top: 0.25rem;
  }

  .storage-domain-wrap {
    margin-top: -0.5rem;
  }
}

.oracle-nodes-selector {
  .oracle-node-type-selection {
    > label {
      display: block;
      margin-bottom: 1rem;

      input[type="radio"] {
        display: inline-block;
        margin-right: 0.5rem;
        vertical-align: top;
      }

      > div {
        display: inline-block;
      }
    }
  }

  .see-more-wrapper {
    margin-bottom: 1rem;
  }

  .subtext {
    display: block;
    color: $colorGrey;
    margin-top: 0.1rem;
  }
}

// Align description field with the name field
.job-description {
  margin-top: 0.9em;
}

.cloud-archive-direct-wrap {
  c-toggle {
    display: inline-block;
  }
}

.missing-entity-warning {
  overflow: auto;
  max-height: 15em;
}
