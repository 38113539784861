// Override mat-tooltip default styles, gets solid background to avoid transparency
// !important as we want to override the styles.
// NOTE: this file should only address color changes. If making structural changes to a component
// please put them in a separate file (i.e mat-card.scss).

// fallback color in case theme is missing
$color: black !default;

// The default opacity scaling value for elevation shadows.
$opacity: 1 !default;

// Prefix for elevation-related selectors.
$prefix: 'mat-elevation-z';

// This box-shadow is patterned after how angular material classifies the various parts of the shadow
// cast by an object from a light source.  Imagine a solar eclipse.

// penumbra = the partially shaded outer region of the shadow
@function _get-penumbra-map($color, $opacity) {
  $shadow-color: rgba($color, $opacity * 0.14);

  @return (
    0: '',
    1: '0px 1px 1px #{$shadow-color}',
    2: '0px 2px 2px #{$shadow-color}',
    3: '0px 3px 4px #{$shadow-color}',
    4: '0px 4px 5px #{$shadow-color}',
    5: '0px 5px 8px #{$shadow-color}',
    6: '0px 6px 10px #{$shadow-color}',
    7: '0px 7px 10px #{$shadow-color}',
    8: '0px 8px 10px #{$shadow-color}',
    9: '0px 9px 12px #{$shadow-color}',
    10: '0px 10px 14px #{$shadow-color}',
    11: '0px 11px 15px #{$shadow-color}',
    12: '0px 12px 17px #{$shadow-color}',
    13: '0px 13px 19px #{$shadow-color}',
    14: '0px 14px 21px #{$shadow-color}',
    15: '0px 15px 22px #{$shadow-color}',
    16: '0px 16px 24px #{$shadow-color}',
    17: '0px 17px 26px #{$shadow-color}',
    18: '0px 18px 28px #{$shadow-color}',
    19: '0px 19px 29px #{$shadow-color}',
    20: '0px 20px 31px #{$shadow-color}',
    21: '0px 21px 33px #{$shadow-color}',
    22: '0px 22px 35px #{$shadow-color}',
    23: '0px 23px 36px #{$shadow-color}',
    24: '0px 24px 38px #{$shadow-color}'
  );
}

// a diffused shadow around the umbra and penumbra
@function _get-antumbra-map($color, $opacity) {
  $shadow-color: rgba($color, $opacity * 0.12);

  @return (
    0: '',
    1: '0px 1px 3px #{$shadow-color}',
    2: '0px 1px 5px #{$shadow-color}',
    3: '0px 1px 8px #{$shadow-color}',
    4: '0px 1px 10px #{$shadow-color}',
    5: '0px 1px 14px #{$shadow-color}',
    6: '0px 1px 18px #{$shadow-color}',
    7: '0px 2px 16px #{$shadow-color}',
    8: '0px 3px 14px #{$shadow-color}',
    9: '0px 3px 16px #{$shadow-color}',
    10: '0px 4px 18px #{$shadow-color}',
    11: '0px 4px 20px #{$shadow-color}',
    12: '0px 5px 22px #{$shadow-color}',
    13: '0px 5px 24px #{$shadow-color}',
    14: '0px 5px 26px #{$shadow-color}',
    15: '0px 6px 28px #{$shadow-color}',
    16: '0px 6px 30px #{$shadow-color}',
    17: '0px 6px 32px #{$shadow-color}',
    18: '0px 7px 34px #{$shadow-color}',
    19: '0px 7px 36px #{$shadow-color}',
    20: '0px 8px 38px #{$shadow-color}',
    21: '0px 8px 40px #{$shadow-color}',
    22: '0px 8px 42px #{$shadow-color}',
    23: '0px 9px 44px #{$shadow-color}',
    24: '0px 9px 46px #{$shadow-color}'
  );
}

// umbra = the fully shaded inner region of a shadow
@function _get-umbra-map($color, $opacity, $is-dark-theme) {
  $shadow-color: rgba($color, $opacity * 0.2);
  $z0: '';
  @if $is-dark-theme {
    $z0: '0px 4px 4px' rgba($color, $opacity * 0.25);
  }
  @return (
    0: $z0,
    1: '0px 2px 1px #{$shadow-color}',
    2: '0px 3px 1px #{$shadow-color}',
    3: '0px 3px 3px #{$shadow-color}',
    4: '0px 2px 4px #{$shadow-color}',
    5: '0px 3px 5px #{$shadow-color}',
    6: '0px 3px 5px #{$shadow-color}',
    7: '0px 4px 5px #{$shadow-color}',
    8: '0px 5px 5px #{$shadow-color}',
    9: '0px 5px 6px #{$shadow-color}',
    10: '0px 6px 6px #{$shadow-color}',
    11: '0px 6px 7px #{$shadow-color}',
    12: '0px 7px 8px #{$shadow-color}',
    13: '0px 7px 8px #{$shadow-color}',
    14: '0px 7px 9px #{$shadow-color}',
    15: '0px 8px 9px #{$shadow-color}',
    16: '0px 8px 10px #{$shadow-color}',
    17: '0px 8px 11px #{$shadow-color}',
    18: '0px 9px 11px #{$shadow-color}',
    19: '0px 9px 12px #{$shadow-color}',
    20: '0px 10px 13px #{$shadow-color}',
    21: '0px 10px 13px #{$shadow-color}',
    22: '0px 10px 14px #{$shadow-color}',
    23: '0px 11px 14px #{$shadow-color}',
    24: '0px 11px 15px #{$shadow-color}'
  );
}

@mixin elevations($zValue, $color: $color, $opacity: $opacity, $is-dark-theme) {
  box-shadow: #{map-get(_get-penumbra-map($color, $opacity), $zValue)},
              #{map-get(_get-antumbra-map($color, $opacity), $zValue)},
              #{map-get(_get-umbra-map($color, $opacity, $is-dark-theme), $zValue)};
}

// sets the box-shadow definition for all mat-elevation-zX classes
@mixin cog-mat-elevation-theme($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $foreground: map-get($theme, foreground);
  $color: map-get($foreground, elevation);
  @for $i from 0 through 24 {
    .mat-elevation-z#{$i} {
      @include elevations($i, $color, $opacity, $is-dark-theme)
    }
  }
}

// use this to add the correct overlay background to your elements that have a mat-elevation-zX class.
@mixin cog-mat-elevation-overlay($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $background: map-get($theme, background);
  $color: map-get($background, background);

  // Only applies to dark theme
  @if $is-dark-theme {
    &.mat-elevation-z1 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), $color;
    }
    &.mat-elevation-z2 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.07), rgba(255, 255, 255, 0.07)), $color;
    }
    &.mat-elevation-z3 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.08), rgba(255, 255, 255, 0.08)), $color;
    }
    &.mat-elevation-z4,
    &.mat-elevation-z5 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.09), rgba(255, 255, 255, 0.09)), $color;
    }
    &.mat-elevation-z6,
    &.mat-elevation-z7 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.11), rgba(255, 255, 255, 0.11)), $color;
    }
    &.mat-elevation-z8,
    &.mat-elevation-z9,
    &.mat-elevation-z10,
    &.mat-elevation-z11 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12)), $color;
    }
    &.mat-elevation-z12,
    &.mat-elevation-z13,
    &.mat-elevation-z14,
    &.mat-elevation-z15 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.14)), $color;
    }
    &.mat-elevation-z16,
    &.mat-elevation-z17,
    &.mat-elevation-z18,
    &.mat-elevation-z19,
    &.mat-elevation-z20,
    &.mat-elevation-z21,
    &.mat-elevation-z22,
    &.mat-elevation-z23 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), $color;
    }
    &.mat-elevation-z24 {
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16)), $color;
    }
  }
}
