// Cluster Sumamry Specific Styles

.c-cluster-summary {
    @include pie-clearfix;

    .cluster-storage-data-container {
        padding-right: 15px;
        .storage-data-highlight {
            position: absolute;
            bottom: 0;
            margin-left: 20px;
            .storage-data-highlight-item {
                border-bottom: 1px solid $colorBorder;
                padding: 10px 0;
            }
            .storage-data-highlight-item:last-child {
                border-bottom: none;
                padding-bottom: 0;
            }
        }
        .emphasis {
            @include font($size: 2.5rem, $color: $colorPrimaryDark);
        }
    }
}

.cluster-summary-details {
  dd {
    // Ensure consistent min-height even if <dd> is empty.
    min-height: 2rem;
  }

  .cancel-edit-button {
    margin-left: auto;
  }

  .support-channel-row {
    display: flex;
    flex-direction: row;

    .support-settings {
      flex-wrap: wrap;
    }
  }

  .frequency-count {
    display: flex;
    flex-direction: row;

    label {
      align-self: center;
    }

    .frequency-input {
      margin-right: 0.5rem;
      min-width: 2rem;
    }
  }
}
