// cDateRanger styles

c-date-ranger,
c-date-ranger-toggle {
  position: relative;
  display: block;
}

.c-date-ranger {
  padding: 20px;
  h5 {
    @include font($size: 1.1875rem);
  }
}

.c-date-ranger-buttons {
  position: relative;
  bottom: -20px;
}

.c-date-ranger-ranges {
  label {
    display: block;
    margin-left: 3px;
  }
  input {
    margin-right: 5px;
  }
}

.c-date-ranger-disabled {
  h5,
  .c-date-ranger-date {
    opacity: 0.5;
  }
  .c-calendar-wrapper {
    position: relative;
    opacity: 0.5;

    // psuedo class used to block click access to the "disabled" calendar
    &::after {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      content: '';
    }
  }
}

.c-date-ranger-toggle {
  display: block;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid transparent;
  border-radius: $defaultRadius;
  height: 40px;
  padding: 7px 14px;
  line-height: 14px;
  position: relative;
  user-select: none;

  &.no-today {
    border-style: dotted;
  }

  &.disabled {
    cursor: no-drop;

    a {
      color: inherit;
    }
    // Prevents click access to the link in the toggle. Means we don't have
    // to do fancy template stuff.
    &::after {
      position: absolute;
      content: '';
      z-index: 100;
      cursor: no-drop;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
  .c-date-ranger-range {
    position: relative;
    float: left;
    line-height: 25px;
  }
  .c-date-ranger-value {
    position: relative;
    float: left;
    line-height: 25px;
    margin-left: 10px;
  }
  .icn-calendar {
    margin-left: 10px;
  }
}

.c-form-sleek .c-date-ranger-toggle {
  border-radius: 0;
  height: 35px;
  padding: 7px;

  .icn-calendar {
    height: 18px;
    width: 18px;
    margin-top: 3px;
  }
}


@include helix-themes() using ($theme) {
  .c-date-ranger {
    h5 {
      color: helix-color($theme, foreground, secondary-text);
    }
  }

  .c-date-ranger-toggle {
    border-bottom: 1px solid helix-color($theme, component, input);

    &.no-today {
      border-bottom-color: helix-color($theme, status, warning);
    }

    &.disabled {
      color: helix-color($theme, foreground, disabled-button);
    }
  }
}
