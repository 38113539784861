
// Adding the `cog-dash-grid` class to a MatGridList element will result in
// contained dashcards filling up all available horizontal and vertical space
// within the its containing MatGridTile.
.mat-grid-list.cog-dash-grid {
  mat-grid-tile .mat-grid-tile-content > * {
    width: 100%;
    height: 100%;
    display: block;
  }
}

@include reflow() {
  @include reflow-sm() {
    .mat-grid-list.cog-dash-grid {
      mat-grid-tile.mat-grid-x-scrollable\@sm {
        overflow-x: scroll;
        overflow-y: hidden;
        .mat-grid-tile-content {
          align-items: unset;
          justify-content: unset;
          @include reflow-min-width-sm();
        }
      }
    }
  }
  @include reflow-xs() {
    mat-grid-tile.mat-grid-x-scrollable\@xs {
      overflow-x: scroll;
      .mat-grid-tile-content {
        align-items: unset;
        justify-content: unset;
        @include reflow-min-width-xs();
      }
    }
  }
}
