//Styles: cluster connect dark site flow
cluster-connect-dark-site {

  .increased-width {
    height: 30rem;
    c-license-upload-download {
      .format {
        width: 75%;
      }
    }
  }
  .copy-text {
    width: 9rem;
  }
}
