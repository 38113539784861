// c-slide-modal

$cSlideModalLeftInterval: -35px;

// stacking rules
// :not(.in-remove) allows the animation to start as soon as the neighbor begins
// animating out.
.c-slide-modal-window:not(.in-remove) ~ .c-slide-modal-window {
  left: $cSlideModalLeftInterval;

  // give each stacked cSlideModal an independent modalBackdrop while stacking.
  // This provides for a nicer stacking visual, separating the modals from the
  // content layers below them.
  &::before {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(black, 0.1);
  }

  &:not(.in-remove) ~ .c-slide-modal-window {
    left: $cSlideModalLeftInterval * 2;

    &:not(.in-remove) ~ .c-slide-modal-window {
      left: $cSlideModalLeftInterval * 3;

      &:not(.in-remove) ~ .c-slide-modal-window {
        left: $cSlideModalLeftInterval * 4;
      }
    }
  }
}

.c-slide-modal-window {
  // Left property transition is for stacked cSlideModals. Properties for in/out
  // are defined in .in-add/.in-remove styling
  transition-property: left;
  transition-duration: $slowDuration;
  transition-timing-function: ease;
  display: none;
  height: 0;
  top: $cohesity-app-bar-height;
  margin: 0 auto;
  // explicitly disabling overflow-x, as the :before shadow defined below
  // would otherwise cause horizontal scrolling
  overflow-x: none;
  padding-bottom: 30px;

  &.in {
    display: block;
    height: calc(100% - #{$cohesity-app-bar-height});
  }

  &.in-add,
  &.in-remove {
    display: block;
    transition-property: height, opacity;
  }

  &.in-add {
    height: 0;
    &.in-add-active {
      height: calc(100% - #{$cohesity-app-bar-height});
    }
  }

  &.in-remove {
    height: calc(100% - #{$cohesity-app-bar-height});
    &.in-remove-active {
      height: 0;
    }
  }
}

// NOTE: Place the close button at the bottom of your template, so its natural
// stacking order will put it on top of possibly underlying elements without
// having to use a z-index.
.c-slide-modal-close {
  position: absolute;
  right: 10px;
  top: 20px;
}

@include apply-helix-themes() using ($primary, $accent, $warn, $background, $foreground) {
  .c-slide-modal-dialog.modal-dialog {
    background-color: map_get($background, dialog);
  }
}

.c-slide-modal-dialog {
  &.modal-dialog {
    border-bottom-left-radius: $defaultRadius;
    border-bottom-right-radius: $defaultRadius;
    margin: 0 auto;
    height: 100%;
    position: relative;
  }

  .c-modal .c-modal-main {
    padding: 30px $cohesity-card-padding 20px;
  }
  .modal-content {
    background-color: transparent;
    box-shadow: none;
    border: 0;
    height: 100%;
    overflow-y: auto;
  }
}

@include helix-themes() using ($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);

  @if $is-jazz-theme {
    .c-slide-modal-dialog {
      &.modal-dialog {
        @include jazz-card-styles($theme);
        height: 100%;
        position: relative;
      }
    }
  }
}

// Full Height Variant
body.c-slide-modal-full-height .c-slide-modal-dialog {
  [class*="c-modal"] {
    height: 100%;
    .c-modal-main {
      height: calc(100% - 50px);
    }
  }
}

// Auto height for modal
body.c-slide-modal-auto-height {
  overflow: hidden;

  .c-slide-modal-dialog {
    &.modal-dialog {
      height: auto;
      max-height: 100%;
      overflow-y: auto;
    }
  }
}

// Use this class on a dialog to override the stack order of the modal. This is
// useful when there are multiple modals opened, and one of them should be
// stacked over the other.
.c-slide-modal-override-stack-order {
  z-index: 1055 !important;
}

// Sizing rules
// These are based on bootstraps modal sizing rules, and the media queries
// are necessary to override the specificity introduced by bootstrap's modal
// sizing media queries.

@media (min-width: 768px) {
  .modal .c-slide-modal-dialog {
    &.modal-sm {
      width: 300px;
    }
    &.modal-xl,
    &.modal-full {
      width: calc(100% - 50px);
      // 25px border on each side to preserve the 'feel' of a slider
      max-width: $max-page-width - 3.125rem;
    }
  }
}

@media (min-width: 992px) {
  .modal .c-slide-modal-dialog.modal-lg {
    width: 1000px;
  }
  .modal .c-slide-modal-dialog.modal-xl {
    width: 62.5rem;
  }
}

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .c-slide-modal-dialog {

      h1 {
        @include cog-type-level('h2');
      }
  }
  }
}
