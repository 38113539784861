// COMPONENT: Restore shared styles

.c-restore {
  min-height: 275px;

  #recover-mapping {
    td:first-child {
      width: 50%;
    }
  }
  .icn-cart {
    $count-size: 1rem;
    @include user-select(none);
    position: relative;
    top: 0.25rem;
    left: -0.1875rem;
    background-size: 175%;
    text-align: center;
    cursor: pointer;

    &::before {
      content: attr(count);
      position: relative;
      display: inline-block;
      vertical-align: top;
      top: -0.625rem;
      left: 0.1em;
      font-size: $count-size * 0.75;
      line-height: $count-size;
      min-width: $count-size;
      border-radius: $count-size;
      text-align: center;
      padding: 0 ($count-size * 0.25);
    }
    &.empty {
      cursor: default;
    }
  }

  // The search results on vm selection
  .c-search {
    [st-pagination] {
      display: inline-block;
      vertical-align: middle;
      margin-left: 1em;

      ul.pagination {
        margin: 0;
      }
    }
  }
  .c-search-results {
    input[type="checkbox"] {
      margin-right: 0.5rem;
      width: auto;
    }
  }
  .media label {
    margin-bottom: 0;
  }
  .c-box-meta-content {
    overflow: auto;
    max-height: 20em;
  }
  tr.forbidden {
    td {
      // bring the show VM list to the top
      a.show-vm-list {
        position: relative;
        z-index: 1;
      }
    }
  }
  .more-actions {
    cursor: pointer;
  }

  // c-ui-select overrides for this use-case: Once a single view selection is
  // search field is hidden so no additional options can be "selected". It's
  // a shortcoming of ui-select.
  .c-ui-select {
    &[tagging].has-selection {
      input.ui-select-search {
        visibility: hidden;
      }
    }
  }
}

.c-snapshot-selector {
  // List of supported archiveTargets' icons
  $targetIcons: (local, cloud, tape, csm, remote, vault);

  .selector-label {
    vertical-align: top;
  }

  // Archive location icons
  .archive-target-option {
    display: inline-block;
    margin-left: 0.25em;

    &:first-child {
      margin-left: 0;
    }
  }
  // Icon color styles
  input[type="radio"] {
    + [class*="icn"] {
      cursor: pointer;
    }
    &:disabled {
      + [class*="icn"] {
        opacity: 0.55;
        cursor: no-drop;
      }
    }
    &:checked + [class*="icn"] {
      @each $icn in $targetIcons {
        &.icn-#{$icn} {
          @include c-icon-property(#{$icn}, bright);
        }
      }
    }
  }
  .snapshot-vms-list {
    max-height: 300px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

// TODO: Move this to a more centralized partial. It's also usable in the quiesce
// incompatibility modal (jobs.scss)
// app/protection/jobs/modals/job-quiesce-conflict.html
.dense-list {
  max-height: 33.33333vh;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1em;
  border: 1px solid;
}

.new-network-option {
  display: inline-block;
}

label.vul-scan-status {
  text-align: center;
  padding: 0.35rem;
  border-radius: 0.625rem;
}

@include helix-themes() using ($theme) {
  $is-jazz-theme: map-get($theme, is-jazz);

  .c-restore {
    .icn-cart {
      &::before {
        color: helix-contrast($theme, primary, 500);
        background: helix-color($theme, primary, 500);
        box-shadow: $defaultBoxShadow;
      }
      &.empty {
        cursor: default;

        &::before {
          background: helix-color($theme, background, card);
          color: helix-color($theme, foreground, text);
        }
      }
    }
    // jazz fix
    @if $is-jazz-theme {
      &.c-box {
        background-color: unset;
        border: 0;
        padding: 0;
      }
    }
  }

  .dense-list {
    border: 1px solid helix-color($theme, foreground, divider);
  }

  label.vul-scan-status {
    &.critical {
      background: helix-color($theme, status, error);
      color: helix-contrast($theme, status, error);
    }
    &.high {
      background: helix-color($theme, status, warning);
      color: helix-contrast($theme, status, warning);
    }
    &.medium {
      background: helix-color($theme, status, warning-low);
      color: helix-contrast($theme, status, warning-low);
    }
    &.low {
      background: helix-color($theme, status, success);
      color: helix-contrast($theme, status, success);
    }
    &.none {
      background: helix-color($theme, status, info);
      color: helix-contrast($theme, status, info);
    }
    &.unknown {
      background: helix-color($theme, status, unknown);
      color: helix-contrast($theme, status, unknown);
    }
  }
}

.custom-tag-btn {
  width: 30px;
  height: 30px;
  padding: 0 !important;
  padding-left: 2px !important;
  border-radius: 22px;
}
