// Providing alternative styling for mat-menu panels. Leverage these by putting
// the class (i.e. `cog-primary`) on your mat-menu element.

.mat-menu-panel.cog-primary {
  margin-top: 0.25rem;
}

// Need more specific selector to override .mat-menu-panel
// Allow wider menu when needed to avoid truncated text.
.cdk-overlay-pane .mat-menu-panel {
  max-width: 21rem;
}

@include helix-themes() using ($theme) {
  .mat-menu-panel.cog-primary {
    background-color: helix-color($theme, component, page-fab);

    .mat-menu-item {
      color: helix-contrast($theme, component, page-fab);
    }
  }
}
