// c-copy to clipboard component styling

.c-copy {
  transition: opacity ease $defaultDuration;
  position: absolute;
  top: 6px;
  right: 0;
  cursor: not-allowed;
  opacity: 0.35;

  &:not([disabled]):hover {
    opacity: 1;
    cursor: pointer;
  }
}

.c-copy-wrapper {
  position: relative;
}

// provide clearance for cCopy icon.
.ui-select-container.ui-select-multiple[c-copy] input.ui-select-search {
  padding-right: 20px;
}
