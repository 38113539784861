// remote cluster styling

.remote-cluster-input-ips {
  min-width: 25rem;
  max-width: 37.5rem;
}

.remote-cluster-auto-registration-label {
  width: min-content;
  text-align: center;
  white-space: nowrap;
  float: right;
  padding: 0 0.5rem;
  border-radius: 0.25rem;
  margin-right: 1rem;
}

@include helix-themes() using ($theme) {
  .remote-cluster-auto-registration-label {
    color: helix-color($theme, background, background);
    background-color: helix-color($theme, status, info);
  }
}
