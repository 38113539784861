// Cohesity Buttons (AngularJS buttons)
@use "@angular/material/core/style/elevation" as mat;
// When adding a button to the page, use one of three classes available for
// styling.
// 1) .c-btn - Bare bones button, used for cancel/back buttons
// 2) .c-btn-primary - Branded color button, used for primary CTA
// 3) .c-btn-critical - Very important, panic-riddled CTA.
// 4) .c-btn-subtle - A light colored btn, used when drawing extra attention to
//                    the button is not desired like view more/less etc
//
// @example:
// <button class="c-btn-primary">Do This</button>
// <button class="c-btn">Cancel</button>
// <button class="c-btn-subtle">View More</button>
//
// For small buttons, simply add a .sm class to your button:
// <button class="c-btn-primary sm">Add Item</button>
//
// These explicitly button height's aren't actually used in styling the buttons,
// but provide a reference that selectors throughout the application can use so
// that they can more clearly establish their relationship.
$buttonHeight: 2.25rem;
$buttonHeightSm: 1.5rem;

$contextButtonSeparator: 1rem;

[class*="c-btn"] {
  @include font(
    // Leading (line-height) dictates the height of the button. Need to subtract
    // 2 pixels to account for the button border on top and bottom.
    $leading: calc(#{$buttonHeight} - 2px),
    $size: 0.9375rem,
    $weight: normal,
  );
  position: relative;
  &:focus::after {
    display: block;
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    @include helix-themes() using ($theme) {
      background: helix-color($theme, state-primary, focus);
    }
  }
  transition: all $defaultDuration ease, outline 0s linear, outline-offset 0s linear;
  display: inline-block;
  padding: 0 1.06em;
  text-align: center;
  white-space: nowrap;
  border-width: 1px;
  border-style: solid;

  // Any explicit number larger than half the button height. 50% turns the
  // button into an oval.
  border-radius: 0.25rem;

  &:hover,
  &:focus,
  &.active {
    cursor: pointer;
    text-decoration: none;
    outline: none;
  }
  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    cursor: default;
  }
  & + [class*="c-btn"],
  & + c-context-menu[button],
  & + create-job-button {
    margin-left: $contextButtonSeparator;
  }
  & + .btn-wrapper {
    margin-left: $contextButtonSeparator;
  }
  &.sm {
    @include font(
      // Leading (line-height) dictates the height of the button. Need to subtract
      // 2 pixels to account for the button border on top and bottom.
      $leading: calc(#{$buttonHeightSm} - 2px),
      $size: 0.75rem
    );

    // used to position a button to the right side of a form label.
    // see remote adapter script settings 'Copy Key' button for example
    &.label-btn {
      position: relative;
      top: -0.3125rem;
      float: right;
    }
  }
}

.context-btn {
  margin-left: $contextButtonSeparator;
  &:first-child {
    margin-left: 0;
  }
}

.c-btn-primary {
  @include font($weight: normal);

  &:not([disabled]) {
    @include mat.elevation(2);
  }
  &:not([disabled]):active {
    @include mat.elevation(8);
  }
}

.c-btn-warn {
  &:not([disabled]) {
    @include mat.elevation(2);
  }
  &:not([disabled]):active {
    @include mat.elevation(8);
  }
}

@include helix-themes() using ($theme) {
  $is-dark-theme: map-get($theme, is-dark);
  $primary-color: helix-color($theme, primary, 500);
  $border-color: helix-color($theme, background, card-border);

  // Bootstrap buttons styles used in cDatePicker and possibly other places.
  .btn-default {
    color: helix-color($theme, foreground, text);
  }

  [class*="c-btn"] {
    color: inherit;
    border-color: $border-color;
    background-color: helix-color($theme, background, raised-button);

    &:focus,
    &.active,
    &:focus,
    &:active {
      &:not([disabled])::after {
        // Setup the "ripple" color to be based on the text color. This ensures that the ripples
        // are matching with the current theme palette and are in contrast to the background color
        // (e.g in themed toolbars).
        background-color: currentColor;

        // opacity as found in _button-theme.scss, $_mat-button-ripple-opacity
        opacity: 0.1;
      }
    }
    &[disabled],
    &[disabled]:hover,
    &[disabled]:focus,
    &[disabled]:active {
      color: helix-color($theme, foreground, disabled-button);
      background: helix-color($theme, background, disabled-button);
      border-color: transparent;
    }
  }

  .c-btn-primary {
    background-color: $primary-color;
    border-color: transparent;
    color: helix-contrast($theme, primary, 500);
  }

  .c-btn-critical {
    background-color: helix-color($theme, warn, 500);
    border-color: transparent;
    color: helix-contrast($theme, warn, 500);
  }

  .c-btn-subtle {
    color: $textGreyLight;
    border-color: $colorBorderDark;

    &:hover,
    &:focus,
    &:active {
      // TODO: this? may not be worth our time.
      color: $textGreyLight;
      border-color: $colorBorderDark;
    }
  }

  .c-btn-warn {
    border-color: transparent;
    background-color: helix-color($theme, status, warning);
    color: helix-contrast($theme, status, warning);
  }
}

// resetting a bootstrap z-index that is causing strange positioning issues in
// job flow source tree. See screenshot in ENG-78450.
.btn-group {
  .btn:focus,
  .btn:hover,
  .btn:active,
  .btn.active {
    z-index: 0;
  }
}

// bootstrap button group override
.btn-group {
  > .btn {
    @include font($weight: normal);
    transition-property: background-color, color, box-shadow;
    transition-duration: $defaultDuration;
    transition-timing-function: ease;
    padding: 0 0.75rem;
    box-shadow: none;

    // In theory line-height should be 3rem[48px] to match MAT styling, but in
    // pratice this makes the buttons too tall for the way our UX uses them.
    line-height: 2.25rem;

    > [class*="icn"] {
      display: inline-block;
      vertical-align: middle;
    }
  }

  > .btn:hover,
  > .btn:focus {
    box-shadow: none;
    outline: none;
  }
  > .btn:active,
  > .btn.active {
    box-shadow: none;
  }
  > .btn.active:focus,
  > .btn:active:focus,
  > .btn.active:hover,
  > .btn:active:focus {
    box-shadow: none;
  }
  + .btn-group {
    margin-left: 0.9375rem;
  }
}

// NOTE: It's believed that outside of btn-group implementations, this btn styling
// is only used on AJS recovery search pages (filter button). Removing background
// (as applied by browser stylesheet) will normalize this and make it look good
// in light and dark mode.
.btn {
  background: transparent;
}

@include helix-themes() using ($theme) {
  .btn-group {
    > .btn {
      background-color: helix-color($theme, background, card);
      border-color: helix-color($theme, foreground, divider);
      color: helix-color($theme, foreground, text);
    }
    > .btn:active,
    > .btn.active {
      color: helix-contrast($theme, component, nav-active);
      background: helix-color($theme, component, nav-active);
    }
  }
}

// TODO: style this... where are we using this? likely a ui-bootstrap component
.btn-group-sm {
  > .btn {
    padding: 0.25rem 0.5rem;
    font-size: 0.8125rem;
    border-radius: 0.3125rem;
  }
}

.btn {
  .icn-md {
    margin-bottom: -3px;
  }
}

// uib-tooltip does not work with disabled state.
// This wraps a disabled button so uib-tooltip can be added to this wrapper
.btn-wrapper {
  display: inline;

  & + .btn-wrapper {
    margin-left: $contextButtonSeparator;
  }
  & + [class*="c-btn"] {
    margin-left: $contextButtonSeparator;
  }

  button:disabled {
    pointer-events: none;
  }
}

.mat-mini-fab {
  & cog-icon {
    margin-top: -0.25rem;
  }
}

@include helix-themes() using ($theme) {
  [class*="c-btn"] {
    &:focus {
      // Add focus state outline for buttons. This is for accessibility to
      // highlight the focussed buttons.
      outline-offset: 2px;
      outline: 2px solid helix-color($theme, helix-primary, 500);
    }
  }
}
