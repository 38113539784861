//Report Styles
.c-reports-header-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;

  .c-reports-header-info {
    flex: 1 1 100%;
  }
}

.c-reports-header-actions {
  flex: 1 1 25%;

  .c-ul-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .c-ul-inline li > i {
    margin-right: 5px;
  }
}

.helios-reports-link {
  position: absolute;
  right: 1.5rem;
  z-index: 1;
}

// sidebar / filters
.c-reports-filter {
  padding: 16px 8px;
  margin-bottom: 20px;
  border-radius: $defaultRadius;

  .c-reports-filter-header {
    font-size: 16px;
    padding-bottom: 5px;
    margin: 28px 0px 15px 0px;
  }

  .c-checkbox-label {
    display: inline-block;
    @include font($size: 0.75rem, $leading: 1, $weight: normal);
    .c-checkbox {
      margin-top: 0;
      top: 0;
    }
  }

  .ui-select-choices {
    max-width: 100%;
  }

  .ui-select-bootstrap .ui-select-choices-row > span {
    white-space: normal;
    line-height: 1em;
  }

  .c-btn-primary {
    width: 100%;
  }
}

//
// job details
//
.c-report-job-detail {
  ul {
    clear: both;
    width: 100%;
    display: block;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .job-summary-stat {
    @include font($size: 1.125rem, $leading: 1.6875rem);
    display: inline-block;
    float: left;
    clear: both;
    .summary-total {
      @include font($size: 1.5625rem, $leading: 1.6875rem);
    }
  }
  .job-detail-charts {
    margin-top: 40px;
    min-height: 332px;
  }
  .job-summary-description {
    @include pie-clearfix;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .job-detail-progress-bar-col {
    min-width: 200px;
    padding-right: 25px;
    position: relative;
    .cancel-run {
      position: absolute;
      right: -5px;
    }
  }
}

//
// restore
//
.restore-details {
  .error-message {
    margin-top: 10px;
  }
}

// top jobs
.c-report-top-jobs c-chart {
  margin-bottom: 20px;
}

.task-message {
  @include font($size: 0.75rem, $leading: 0.75rem);
}

// Inventory
.c-report-jobs-inventory .user-data-sm {
  max-width: 100%;
}

.jobs-running-chart-subtext {
  @include font($weight: light, $size: 1.0625rem, $color: $textBlue);
  margin: 0 0 20px 7px;
}

// Override the default layout of the c-byte-meter component when it's inside a
// table.
td .c-byte-meter {
  margin: 0 15px 0 0;
  padding: 0;
}

.c-schedule-info-filters {
  .c-ul-inline li {
    line-height: inherit;
  }
}

.c-schedule-info-header-actions {
  c-spinner {
    display: inline-block;
  }
}

.scrollable-reports-content {
  overflow-x: auto;

  .c-reports-header-container,
  c-controlboard {
    @supports (position: sticky) {
      position: sticky;
      left: 0;
    }
  }
}

@include helix-themes() using ($theme) {
  .c-reports-filter {
    background-color: helix-color($theme, background, background);

    .c-reports-filter-header {
      border-bottom: 1px solid helix-color($theme, foreground, divider);
    }
  }

  .c-report-job-detail {
    .job-summary-stat {
      color: helix-color($theme, foreground, text);

      .summary-total {
        color: helix-color($theme, status, info);
        &.summary-total-success {
          color: helix-color($theme, status, success);
        }
        &.summary-total-warning,
        &.summary-total-canceled {
          color: helix-color($theme, status, warning);
        }
        &.summary-total-error {
          color: helix-color($theme, status, error);
        }
      }
    }
    .job-summary-description {
      border-bottom: 1px solid helix-color($theme, foreground, divider);
    }
  }
}

// Archival Summary
.c-reports {
  .archival-summary-job-column {
    vertical-align: sub;
  }
}
