//Cloud Edition Manager Styles

.cloud-edition-manager-wrap {
  .no-cloud-editions-wrap {
    text-align: center;

    small {
      display: inline-block;
      color: $textGreyLightest;
      line-height: 1.4rem;
    }
  }

  p {
    color: $textGreyLightest;
  }

  .progress-wrap {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    width: 50%;
    margin-top: -1rem;

    small {
      display: inline-block;
    }
  }

  input[type="search"] {
    &::placeholder {
      color: $textGreyLightest;
    }
  }

  th:first-child {
    width: 25%;
  }

  th:last-child {
    width: 4%;
  }

  .ce-deployment-note {
    max-width: 30rem;
    margin: 0 auto;
  }

  .log-header {
    border-bottom: 1px solid $drawerBorder;
  }

  .file-pill {
    background-color: $cSelectBackground;
  }

  .tags-title-wrap {
    h2 {
      display: inline-block;
    }

    .icn-info-tip {
      vertical-align: top;
    }
  }
}

.cloud-edition-details-wrap {
  .cloud-edition-details-table,
  .cloud-edition-logs-table {
    span {
      margin-bottom: 1.5rem;

      &:nth-child(odd) {
        color: $textGreyLightest;
        font-size: 0.8rem;
        margin-top: 0.1rem;
      }

      &.col-xs-1 {
        width: 10%;
      }
    }

    .tab-container {
      margin-bottom: 3.5rem;
    }
  }

  .tag-cols {
    div {
      overflow-wrap: break-word;
    }
  }
}

div[class*="cloud-edition-"] {
  i[class*="icn-"] {
    vertical-align: text-bottom;
    margin-right: 0.5rem;
  }
}

.cloud-edition-progress-wrap {
  .progress-wrap {
    c-pulse[size="xxs"] {
      .c-pulse {
        padding-top: 0;
      }
    }
  }
}

.cloud-edition-logs-table {
  .c-table {
    .outer-row {
      td:first-child {
        width: 20%
      }
    }
  }

  .icn-row-toggle {
    margin-top: -0.1rem;
  }
}

.number-of-nodes {
  line-height: normal;
}

.node-note {
  width: 94%;
}

.ce-success {
  @include c-icon-property(hand-ok);
}

.ce-error {
  @include c-icon-property(hand-warning);
}

.ce-cancelled {
  @include c-icon-property(forbidden);
}

.gcp-client-private-key {
  border: none;
  width: 100%;
}

.release-type-caption {
  color: $textGreyLight;
  line-height: 1.4rem;
}

@include helix-themes() using ($theme) {
  $is-ibm-aqua-theme: map-get($theme, is-ibm-aqua);

  @if $is-ibm-aqua-theme {
    .support-text {
      color: helix-color($theme, helix-base, resting)
    }
  }
}
